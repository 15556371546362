import React, { useState } from 'react';
import RequiredIndicator from '../../../../general/requiredIndicator';

import { useTranslation } from "react-i18next";

const NotableAchievements = (props) => {
  const [formData, setFormData] = useState(props.notableAchievements);
  const { t } = useTranslation();

  const handleChange = (event) => {
    const { name, value } = event.target;
    const updatedData = {
      ...formData,
      [name]: value,
    };
    setFormData(updatedData);
    props.sendNotableAchievements(updatedData);
  };

  return (
    <div className="form-card">
      <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
        <p className='text-bold text-center'>{t("forms.title.notable")}</p>
        <div className='row p-2'>
          <p className="col-form-label text-center col-sm-10">{t("forms.formsquestion.professionalExpertise.notableAchievements")}<RequiredIndicator /></p>
          <div className="col-sm-2">
              <div className='row'>
              <div className="form-check col">
                <input 
                  type="radio"
                  className="form-check-input"
                  name="recognition"
                  value="Yes"
                  checked={formData.recognition === "Yes"}
                  onChange={handleChange}
                />
                <p className="form-check-label">{t("forms.click.yes")}</p>
              </div>
              <div className="form-check col">
                <input 
                  type="radio"
                  className="form-check-input"
                  name="recognition"
                  value="No"
                  checked={formData.recognition === "No"}
                  onChange={handleChange}
                />
                <p className="form-check-label">{t("forms.click.no")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotableAchievements;
