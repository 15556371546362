import React from 'react';
import { Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import CountryShow from '../../../general/countryShow';
import { LegalStatus } from '../../../../services/utils';

const styles = StyleSheet.create({
  page: {
    padding: 20,
    backgroundColor: '#ffffff',
  },
  mainTitle: {
    fontSize: 20,
    fontFamily: 'Helvetica-Bold',
    color: '#0066CC',
    textAlign: 'center',
    marginBottom: 20,
  },
  card: {
    backgroundColor: '#f8f9ff',
    borderRadius: 8,
    padding: 16,
    paddingBottom: 5,
    marginBottom: 10,
    width: '100%',
  },
  cardTitle: {
    fontSize: 14,
    fontFamily: 'Helvetica-Bold',
    textAlign: 'center',
    marginBottom: 10,
  },
  row: {
    flexDirection: 'row',
    marginBottom: 8,
    gap: 8,
  },
  labelColumn: {
    width: '30%',
  },
  valueColumn: {
    width: '20%',
  },
  label: {
    fontSize: 10,
    color: '#000000',
    fontFamily: 'Helvetica',
  },
  value: {
    fontSize: 10,
    padding: '6 8',
    backgroundColor: '#ffffff',
    borderRadius: 4,
    minHeight: 28,
    border: '1px solid #e2e8f0',
    fontFamily: 'Helvetica',
  },
  addressCard: {
    backgroundColor: '#ffffff',
    borderRadius: 4,
    padding: 8,
    marginBottom: 8,
    border: '1px solid #e2e8f0',
  },
});

export default function ResidenceInfoResumePDF(props) {
  const { t } = useTranslation();
  const lang = 'en'; // Assuming 'en' as default language
  
  const renderCurrentAddress = () => (
    <View style={styles.card}>
      <Text style={styles.cardTitle}>{t("forms.title.currentaddress")}</Text>
      <View style={styles.row}>
        <View style={styles.labelColumn}>
          <Text style={styles.label}>{t("forms.formsquestion.residences.country")}</Text>
        </View>
        <View style={styles.valueColumn}>
          <Text style={styles.value}><CountryShow value={props.residences.currentResidence.country} /></Text>
        </View>
        <View style={styles.labelColumn}>
          <Text style={styles.label}>{t("forms.formsquestion.residences.provinceState")}</Text>
        </View>
        <View style={styles.valueColumn}>
          <Text style={styles.value}>
            {props.residences.currentResidence.country === 'CAN'
              ? props.provinces.find(province => province.value === props.residences.currentResidence.provinceState)?.label || ''
              : props.residences.currentResidence.provinceState || ''}
          </Text>
        </View>
      </View>
      <View style={styles.row}>
        <View style={styles.labelColumn}>
          <Text style={styles.label}>{t("forms.formsquestion.residences.city")}</Text>
        </View>
        <View style={styles.valueColumn}>
          <Text style={styles.value}>{props.residences.currentResidence.city}</Text>
        </View>
        <View style={styles.labelColumn}>
          <Text style={styles.label}>{t("forms.formsquestion.residences.streetNumber")}</Text>
        </View>
        <View style={styles.valueColumn}>
          <Text style={styles.value}>{props.residences.currentResidence.streetNumber}</Text>
        </View>
      </View>
      <View style={styles.row}>
        <View style={styles.labelColumn}>
          <Text style={styles.label}>{t("forms.formsquestion.residences.postalCode")}</Text>
        </View>
        <View style={styles.valueColumn}>
          <Text style={styles.value}>{props.residences.currentResidence.postalCode}</Text>
        </View>
      </View>
    </View>
  );

  const renderCanadaStatus = () => (
    <View style={styles.card}>
      <Text style={styles.cardTitle}>{t("forms.title.statusin")} Canada</Text>
      <View style={styles.row}>
        <View style={{width: '70%'}}>
          <Text style={styles.label}>{t("forms.formsquestion.residences.haveStatus")} Canada?</Text>
        </View>
        <View style={{width: '30%'}}>
          <Text style={styles.value}>
            {props.residences.currentResidence.haveStatus === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}
          </Text>
        </View>
      </View>
      {props.residences.currentResidence.haveStatus === 'Yes' && (
        <View style={styles.row}>
          <View style={styles.labelColumn}>
            <Text style={styles.label}>{t("forms.formsquestion.residences.currentStatus")} Canada?</Text>
          </View>
          <View style={styles.valueColumn}>
            <Text style={styles.value}>{LegalStatus(props.residences.statusInCountry.currentStatus, lang)}</Text>
          </View>
        </View>
      )}
    </View>
  );

  const renderImmigrationDocument = () => (
    <View style={styles.card}>
      <Text style={styles.cardTitle}>{t("forms.title.immigrationdoc")}</Text>
      <View style={styles.row}>
        <View style={styles.labelColumn}>
          <Text style={styles.label}>{t("forms.formsquestion.residences.dateOfIssue")}</Text>
        </View>
        <View style={styles.valueColumn}>
          <Text style={styles.value}>{props.immigrationDocument.dateOfIssue}</Text>
        </View>
      </View>
      <View style={styles.row}>
        <View style={styles.labelColumn}>
          <Text style={styles.label}>{t("forms.formsquestion.residences.dateOfExpiration")}</Text>
        </View>
        <View style={styles.valueColumn}>
          <Text style={styles.value}>{props.immigrationDocument.dateOfExpiration}</Text>
        </View>
      </View>
      <View style={styles.row}>
        <View style={styles.labelColumn}>
          <Text style={styles.label}>{t("forms.formsquestion.residences.dateOfEntry")}</Text>
        </View>
        <View style={styles.valueColumn}>
          <Text style={styles.value}>{props.immigrationDocument.dateOfEntry}</Text>
        </View>
      </View>
    </View>
  );

  const renderPreviousAddresses = () => (
    <View style={styles.card}>
      <Text style={styles.cardTitle}>{t("forms.title.previousaddress")}</Text>
      {props.residences.previousAddresses.map((address, index) => (
        <View key={index} style={styles.addressCard}>
          <View style={styles.row}>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("general.table.startdate")}</Text>
            </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>{address.startDate}</Text>
            </View>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("general.table.enddate")}</Text>
            </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>{address.endDate}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("general.table.country")}</Text>
            </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}><CountryShow value={address.country} /></Text>
            </View>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("general.table.province")}</Text>
            </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>
                {address.country === 'CAN'
                  ? props.provinces.find(province => province.value === address.provinceState)?.label || ''
                  : address.provinceState}
              </Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("general.table.city")}</Text>
            </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>{address.city}</Text>
            </View>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("forms.formsquestion.residences.streetNumber")}</Text>
            </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>{address.streetNumber}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("forms.formsquestion.residences.postalCode")}</Text>
            </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>{address.postalCode}</Text>
            </View>
          </View>
        </View>
      ))}
    </View>
  );

  return (
    <Page style={styles.page}>
      <Text style={styles.mainTitle}>{t("client.results.blockTitle.bloc3")}</Text>
      {renderCurrentAddress()}
      {renderCanadaStatus()}
      {(props.residences.statusInCountry.currentStatus !== '' && 
        props.residences.statusInCountry.currentStatus !== 'NS' && 
        props.residences.statusInCountry.currentStatus !== 'No') && renderImmigrationDocument()}
      {props.residences.livedInDifferentAddress === 'Yes' ? renderPreviousAddresses() : <View style={styles.card}>
          <Text style={styles.cardTitle}>{t("general.table.empty.previousaddress")}</Text>
        </View>}
    </Page>
  );
}

