import React, {useState, useEffect} from 'react';
import CurrentResidenceSection from './section_residences/CurrentResidenceSection';
import StatusInCountrySection from './section_residences/StatusInCountrySection';
import ImmigrationDocumentSection from './section_residences/ImmigrationDocumentSection';
import PreviousAddressSection from './section_residences/PreviousAddressSection';
import { toast, ToastContainer } from 'react-toastify';
import RequiredIndicator from '../../../general/requiredIndicator';

import { useTranslation } from "react-i18next";

const Residences = (props) => {
  const [currentResidence, setCurrentResidence] = useState(props.residences.currentResidence);
  const [statusInCountry, setStatusInCountry] = useState(props.residences.statusInCountry);
  const [immigrationDocument, setImmigrationDocument] = useState(props.residences.immigrationDocument);
  const [livedInDifferentAddress, setLivedInDifferentAddress] = useState(props.residences.livedInDifferentAddress);
  const [previousAddresses, setPreviousAddress] = useState(props.residences.previousAddresses);

  const [isSameResidence, setIsSameResidence] = useState(props.isSameResidence);
  const [errors, setErrors] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    const residences = {
      currentResidence,
      statusInCountry,
      immigrationDocument,
      livedInDifferentAddress,
      previousAddresses,
    };
    props.sendResidences(residences);
    props.onValidate('residences', false);
  }, [currentResidence, statusInCountry, immigrationDocument, 
    livedInDifferentAddress, previousAddresses, props]);

  useEffect(() => {
    props.sendIsSameResidence(isSameResidence);
    props.onValidate('residences', false);
  }, [isSameResidence, props]);

  const receivedCurrentResidence = (data) => {setCurrentResidence(data);};
  const receivedStatusInCountry = (data) => {setStatusInCountry(data);};
  const receivedImmigrationDocument = (data) => {setImmigrationDocument(data);};
  const receivedPreviousAddress = (data) => {setPreviousAddress(data);};
  const receivedLivedInDifferentAddress = (data) => {setLivedInDifferentAddress(data);};

  const pressNext = ()=> {
    props.sendNext("Language Proficiency");
  };

  const handleStatus = (event)=> {
    const { name, value } = event.target;
    const updatedFormData = {
      ...currentResidence,
      [name]: value,
    };
    setCurrentResidence(updatedFormData);
    setErrors({});
  }

  const sendIsSame = (event)=> {
    const { name, value } = event.target;
    const updatedFormData = {
      ...isSameResidence,
      [name]: value,
    };
    setIsSameResidence(updatedFormData);
    setErrors({});
  };

  const checkCurrentStatus = () =>{
    if (!currentResidence.haveStatus ||
      (statusInCountry.haveStatus === 'Yes' && 
        ((statusInCountry.currentStatus !== 'NS' && !checkImmigrationDocument()) || !statusInCountry.currentStatus))){

      toast.error(`${t('forms.formsquestion.toast.residences.statusInCountry')}`);
      setErrors(prevErrors =>({
        ...prevErrors,
        statusInCountry: `${t('forms.formsquestion.toast.residences.statusInCountry')}`
      }));
      return false;
    } else {
      delete errors.statusInCountry;
      return true;
    }
  }

  const checkImmigrationDocument = () => {
    if (immigrationDocument.dateOfIssue === '' || immigrationDocument.dateOfExpiration === ''){
      toast.error(`${t('forms.formsquestion.toast.residences.immigrationDocument')}`);
      setErrors(prevErrors =>({
        ...prevErrors,
        immigrationDocument: `${t('forms.formsquestion.toast.residences.immigrationDocument')}`
      }));
      return false;
    } else {
      delete errors.immigrationDocument;
      return true;
    }

  }

  const checkCurrentResidence = () =>{
    if (currentResidence.startDate === '' || 
      currentResidence.country === '' || 
      currentResidence.provinceState === '' || 
      currentResidence.city === '' ||
        currentResidence.streetNumber === ''){

      toast.error(`${t('forms.formsquestion.toast.residences.currentResidence')}`);
      setErrors(prevErrors =>({
        ...prevErrors,
        currentResidence: `${t('forms.formsquestion.toast.residences.currentResidence')}`
      }));
      return false;
    } else {
      delete errors.currentResidence;
      return true;
    }
  }

  const checkSameCurrentResidence = () => {
    if ((props.actor === 'Partner') && (isSameResidence.isSamecurrentResidence === '' || 
        (isSameResidence.isSamecurrentResidence === 'No' && !checkCurrentResidence()))) {
        toast.error(`${t('forms.formsquestion.toast.residences.option')}`);
        setErrors(prevErrors =>({
          ...prevErrors,
          option: `${t('forms.formsquestion.toast.residences.option')}`
        }));
        return false;

    } else if (props.actor === 'Principal' && !checkCurrentResidence()){
      return false;
    } else {
      setErrors({});
      return true;
    }
  }
  

  const checkPreviousResidences = () => {
    if (!livedInDifferentAddress){
      toast.error(`${t('forms.formsquestion.toast.residences.moreInfo')}`);
      setErrors(prevErrors =>({
        ...prevErrors,
        option: `${t('forms.formsquestion.toast.residences.moreInfo')}`
      }));
      return false;
    } else if (livedInDifferentAddress === 'Yes' && props.actor === 'Partner') {
      if (isSameResidence.isSamepreviousAddress === '') {
        toast.error(`${t('forms.formsquestion.toast.residences.previousAddress')}`);
        setErrors(prevErrors =>({
          ...prevErrors,
          isSamepreviousAddress: `${t('forms.formsquestion.toast.residences.previousAddress')}`
        }));
        return false;
      } else if (isSameResidence.isSamepreviousAddress === 'No' && previousAddresses.length === 0){
        toast.error(`${t('forms.formsquestion.toast.residences.atLeastOne')}`);
        setErrors(prevErrors =>({
          ...prevErrors,
          isSamepreviousAddress: `${t('forms.formsquestion.toast.residences.atLeastOne')}`
        }));
        return false;
      }else if (isSameResidence.isSamepreviousAddress === 'No' && previousAddresses.length !== 0){
        setErrors({});
        return true;
      } else if (isSameResidence.isSamepreviousAddress === 'Yes'){
        setErrors({});
        return true;
      }
    } else if (livedInDifferentAddress === 'Yes' && props.actor === 'Principal'){
      if (previousAddresses.length === 0) {
        toast.error(`${t('forms.formsquestion.toast.residences.atLeastOne')}`);
        setErrors(prevErrors =>({
          ...prevErrors,
          previousAddresses: `${t('forms.formsquestion.toast.residences.atLeastOne')}`
        }));
        return false;
      } else {
        setErrors({});
        return true;
      }
    } else if (livedInDifferentAddress === 'No'){
      setErrors({});
      return true;
    }
  }

  const check = () =>{
    if(checkSameCurrentResidence() && checkCurrentStatus() && checkPreviousResidences()){
      
    props.onValidate('residences', true);
    setErrors({});
    toast.success(`${t('forms.formsquestion.toast.sectionValidate')}`)
    } else {
      props.onValidate('residences', false);
    }
  }

  const validateSection = () => {
    props.onValidate('residences', false);
    check();
  };

  const saveForm = () => {
    props.onSave();
  };

  return (
    <fieldset id="step2">
      {/*
      <ul style={{ color: 'red' }}>
        {Object.values(errors).map((value, index) => {
            if (value !== '') {
                return <li key={index}>{value}</li>;
            }
            return null;
        })}
      </ul> 
      <legend>Informations personnelles</legend>
      */}
      {props.actor === 'Partner' && (
      <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
        <div className='row p-2'>
        <p className='col-sm-10 col-form-label'>{t("forms.formsquestion.residences.partnerLiveSameCurrent")}</p>
        <div className='col-sm-2'>
          <div className='row'>
            <div className="col form-check col">
              <input 
                type="radio"
                className="form-check-input rounded-pill"
                id="isSamecurrentResidenceYes"
                name="isSamecurrentResidence"
                value="Yes"
                onChange={sendIsSame}
                checked={isSameResidence.isSamecurrentResidence === "Yes"}
              />
              <label className="form-check-label" htmlFor="isSamecurrentResidenceYes">{t("forms.click.yes")}</label>
            </div>
            <div className="col form-check">
              <input 
                type="radio"
                className="form-check-input rounded-pill"
                id="isSamecurrentResidenceNo"
                name="isSamecurrentResidence"
                value="No"
                onChange={sendIsSame}
                checked={isSameResidence.isSamecurrentResidence === "No"}
              />
              <label className="form-check-label" htmlFor="isSameResidenceNo">{t("forms.click.no")}</label>
            </div>
          </div>
        </div>
        </div>
      </div>
      )}
      <div className="form-card">
        {(props.actor === 'Principal' || isSameResidence.isSamecurrentResidence === 'No') && (
          <>
            < CurrentResidenceSection error={errors.currentResidence ? errors.currentResidence : ''}
            sendCurrentResidence={receivedCurrentResidence} 
            currentResidence={currentResidence} sendIsSame={sendIsSame}
            />
          </>
        ) }

        <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
          {errors.statusInCountry && (
            <>
                <p style={{ marginTop: '20px', padding: '10px', color: 'red'}}>
                    {errors.statusInCountry}
                </p>
            </>
          )}
          <div className='row p-2'>
          <p className='col-sm-10 col-form-label'>{t("forms.formsquestion.residences.haveStatus")} {props.evaluationCountry} ? <RequiredIndicator /></p>
          <div className='col-sm-2'>
            <div className='row'>
              <div className="col form-check">
                <input 
                  type="radio"
                  className="form-check-input rounded-pill"
                  name="haveStatus"
                  value="Yes"
                  onChange={handleStatus}
                  checked={currentResidence.haveStatus === "Yes"}
                />
                <label className="form-check-label">{t("forms.click.yes")} </label>
              </div>
              <div className="col form-check">
                <input 
                  type="radio"
                  className="form-check-input rounded-pill"
                  name="haveStatus"
                  value="No"
                  onChange={handleStatus}
                  checked={currentResidence.haveStatus === "No"}
                />
                <label className="form-check-label">{t("forms.click.no")} </label>
              </div>
            </div>
          </div>
          </div>
        </div>
        
        {/*{((props.actor === 'Partner' && props.principalCurrentResidence.country ==='CAN' && isSameResidence.isSamecurrentResidence === 'Yes') || currentResidence.country === 'CAN' || currentResidence.haveStatus ==='Yes') && ( */}
        {(currentResidence.haveStatus ==='Yes' &&
        <>
          < StatusInCountrySection error={errors.statusInCountry ? errors.statusInCountry : ''}
          sendStatusInCountry={receivedStatusInCountry} 
          statusInCountry={statusInCountry} 
          countryE={props.evaluationCountry}
          />
          {(statusInCountry.currentStatus !== 'NS' && statusInCountry.currentStatus !== '') && (
            < ImmigrationDocumentSection error={errors.immigrationDocument ? errors.immigrationDocument : ''}
            sendImmigrationDocument={receivedImmigrationDocument} 
            immigrationDocument={immigrationDocument}  
            countryE={props.evaluationCountry}
            />
          )}
        </>
        )}
        <>
          < PreviousAddressSection error={errors.previousAddresses ? errors.previousAddresses : ''}
          sendLivedInDifferentAddress={receivedLivedInDifferentAddress} 
          livedInDifferentAddress={livedInDifferentAddress} 
          sendPreviousAddresses={receivedPreviousAddress} 
          previousAddresses={previousAddresses} sendIsSame={sendIsSame} 
          isSamepreviousAddress={isSameResidence.isSamepreviousAddress} 
          actor={props.actor}
          />
        </>
        <div className='d-flex justify-content-center'>
                <div className='col-sm-10'>
                    <div className='d-flex justify-content-between'>
                      <input type="button" name="save" className="col-6 btn flous-btn-gradient rounded-pill mx-1" value={t("forms.click.save")} onClick={saveForm}/>
                      <input type="button" name="next" className="col-6 btn flous-btn-gradient rounded-pill mx-1" value={t("forms.click.next")} onClick={pressNext}/>
                    </div>
                  </div>
                </div>
                <div className='d-flex justify-content-center'>
                  <input type="button" name="validate" className="col-sm-6 btn flous-btn-primary rounded-pill mt-2 mx-2" value={t("forms.click.validate")} onClick={validateSection}/>
                </div>
        </div>
        <ToastContainer />
    </fieldset>
  );
};

export default Residences;
