import React from "react";
import { useTranslation } from "react-i18next";

const SuspendedProgramModal = ({isOpen, onContinue, date}) => {

  const { t } = useTranslation();
  if (!isOpen) {
    return null;
  }

  return (
    <div id="completionform" className="modal fade show" style={{ display: "block" }} role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-body text-center">
            <div className="d-flex align-items-center justify-content-center w-100 my-3">
              <img
                src={`${process.env.PUBLIC_URL}/modals/suspended_program.png`}
                alt="Flous Logo"
                className="brand-image text-center"
                style={{ height: "150px", width: "150px" }}
              />
            </div>
            <h4 className="flous-police-one flous-input-color" id="modalTitle">
            {t("modal.suspended.title")}
            </h4>
            <div className="mb-3 flous-police-one">{t("modal.suspended.message")}</div>
            <div className="mb-3 flous-police-two">{t("modal.suspended.message2")} :</div>
            <div className="col">
            <div className="mb-3 flous-police-one flous-input-color elevation-1 mx-5 p-1" style={{ borderRadius: '5px'}}>{date}</div>
            </div>
            
            <div className="col">
              <button className="btn flous-bg-primary rounded-pill px-5" onClick={onContinue}>
              {t("modal.suspended.submit")}
              </button>
            </div>

            {/*
            <div className="modal-footer d-flex justify-content-center mt-3">
                <button type="button" className="btn btn-lg flous-input-color flous-bg-thirty rounded-pill px-5" data-dismiss="modal" onClick={onCancel}>Cancel</button>
            </div>
             */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuspendedProgramModal;