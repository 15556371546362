import React from 'react';
import CountryShow from '../../../../general/countryShow';

import { useTranslation } from 'react-i18next';
import { gender, mStatus, preferredLang } from '../../../../../services/utils';

export default function PersonalInfoResumeSection(props) {
  const { t } = useTranslation();

  const lang = localStorage.getItem('flouslang') || 'en';
  // Define the personal information
  const genderIdentity = props.personalDetail.genderIdentity; //  
  const nationality = props.personalDetail.nationality; //  
  const maritalStatus = props.personalDetail.maritalStatus; //   
  const preferredLanguage = props.preferredLanguage; //

  const countryName = CountryShow({ value: nationality });

  return (
    <div className='flous-bg-thirty p-3 elevation-2 mb-3' style={{ borderRadius: '15px' }}>
      <div className='text-bold text-center mb-3'>{t("forms.title.personaldetail")}</div>
      <div className='mx-3'>
        <div className="form-group row">
          <label htmlFor="inputGenderIdentity" className="col-sm-3 col-form-label">{t("forms.formsquestion.personalInfo.genderIdentity")}</label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputGenderIdentity" value={gender(genderIdentity, lang)} placeholder="N.A"/>
          </div>
          <label htmlFor="inputNationality" className="col-sm-3 col-form-label">{t("forms.formsquestion.personalInfo.nationality")}</label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputNationality" value={countryName} placeholder="N.A"/>
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="inputMaritalStatus" className="col-sm-3 col-form-label">{t("forms.formsquestion.personalInfo.maritalStatus")}</label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputMaritalStatus" value={mStatus(maritalStatus, lang)} placeholder="N.A"/>
          </div>
          <label htmlFor="inputPreferredLanguage" className="col-sm-3 col-form-label">{t("forms.formsquestion.personalInfo.preferredLanguage")}</label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputPreferredLanguage" value={preferredLang(preferredLanguage)} placeholder="N.A"/>
          </div>
        </div>
      </div>
    </div>
  );
}
