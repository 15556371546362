import React, { useState } from 'react';
import RequiredIndicator from '../../../../../general/requiredIndicator';
import { useTranslation } from 'react-i18next';

const SupportbySpecializedO = (props) => {
  const [formData, setFormData] = useState(props.supportby);
  const { t } = useTranslation();

  const handleChange = (event) => {
    const { name, value } = event.target;
    const updatedData = {
      ...formData,
      [name]: value,
    };
    setFormData(updatedData);
    props.sendSupportBySpecializedO(updatedData);
  };

  return (
    <div className="form-card">
      <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
      <div style={{ color: 'red' }}>{props.error !== '' ? `${t('forms.formsquestion.toast.completeSection')}` : ''}</div>
        <p className='text-bold text-center'>{t("forms.title.supportby")}</p>
        <div className='row p-2'>
          <div className='col-sm-10'>
            <div className=''>
            <p className="">{t("forms.formsquestion.entrepreunarialExperience.receivedServiceOffer")}<RequiredIndicator /></p>
            </div>
          </div>
          <div className='col-sm-2'>
                <div className='row'>
              <div className="form-check col">
                <input 
                  type="radio"
                  className="form-check-input"
                  name="receivedServiceOffer"
                  value="Yes"
                  onChange={handleChange}
                  checked={formData.receivedServiceOffer === "Yes"}
                />
                <p className="form-check-label">{t("forms.click.yes")}</p>
              </div>
              <div className="form-check col">
                <input 
                  type="radio"
                  className="form-check-input"
                  name="receivedServiceOffer"
                  value="No"
                  onChange={handleChange}
                  checked={formData.receivedServiceOffer === "No"}
                />
                <p className="form-check-label">{t("forms.click.no")}</p>
              </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default SupportbySpecializedO;