import React, {useState} from 'react';
import Select from 'react-select';
import i18n from '../../services/i18n'
import LangFlag from './langFlag';

const LanguageSelect = () => {

    const [language, setLanguage] = useState(localStorage.getItem('flouslang') || 'en');

    const handleChange = (event) => {
        localStorage.setItem('flouslang', event.target.value);
        i18n.changeLanguage(event.target.value);
        setLanguage(event.target.value);
      };
    
      const options = [
        { value: 'fr', label: <><LangFlag cca3={'FRA'} lang={"Français"} /></> },
        { value: 'en', label: <><LangFlag cca3={'GBR'} lang={"English"} /></> },
        { value: 'es', label: <><LangFlag cca3={'COL'} lang={"Español"} /></> },
      ];
    
    return (
        <div className="language-select">
            <div className="row pb-3 mr-2">
                <Select
                className="btn btn-block btn-outline"
                menuPlacement="top"
                value={options.find((option) => option.value === language)}
                onChange={(selectedOption) =>
                    handleChange({
                    target: {
                        name: '',
                        value: selectedOption ? selectedOption.value : '',
                    },
                    })
                }
                options={options}
                placeholder="Select language"
                styles={{
                    control: (provided) => ({
                    ...provided,
                    width: '100%',
                    borderRadius: '20px',
                    borderColor: '#ccc',
                    }),
                }}
                />
            </div>
        </div>
    );
};

export default LanguageSelect;