import React, { useEffect, useState } from 'react';
import axios from 'axios';

const LangFlag = ({ cca3, lang }) => {
  const [flagUrl, setFlagUrl] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch the SVG flag URL for the given cca3 code
    if (!cca3){
      setLoading(false)
      return;
    }
    axios.get(`https://restcountries.com/v3.1/alpha/${cca3}`)
      .then(response => {
        const country = response.data[0];
        const svgUrl = country.flags.svg;
        setFlagUrl(svgUrl);
        setLoading(false);
      })
      .catch(error => {
        // console.error('Error fetching the flag data:', error);
        setLoading(false);
      });
  }, [cca3]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {flagUrl ? (
        <div className="flex items-center">
          <img
            className="rounded-full"
            src={flagUrl}
            alt={`Flag of ${cca3}`}
            style={{ width: '30px', height: '30px' }}
          />
          <span className="ml-2">{lang}</span>
        </div>
      ) : (
        <div>{lang}</div>
      )}
    </div>
  );  
};

export default LangFlag;
