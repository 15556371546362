import React, { useState } from 'react';
import CountrySelect from '../../../../general/CountrySelect';
import { countriesWithProvinces, provincesWithCities } from '../../../../../services/utils';
import ProvinceSelect from '../../../../general/ProvinceSelect';
import CitySelect from '../../../../general/CitySelect';
import RequiredIndicator from '../../../../general/requiredIndicator';

import { useTranslation } from "react-i18next";

const JobAddressSection = (props) => {
  const [formData, setFormData] = useState(props.jobAddress);
  const { t } = useTranslation();

  const handleChange = (event) => {
    const { name, value } = event.target;
    const updatedFormData = {
      ...formData,
      [name]: value,
    };
    setFormData(updatedFormData);
    props.sendJobAddress(updatedFormData);
  };

  const handleCountryChange = (e) =>{
    const { name, value } = e.target;
    const updatedFormData = {
      ...formData,
      country: value,
    };
    setFormData(updatedFormData);
    props.sendJobAddress(updatedFormData);
  };

  const handleProvinceStateChange = (e) =>{
    const { name, value } = e.target;
    const updatedFormData = {
      ...formData,
      provinceState: value,
    };
    setFormData(updatedFormData);
    props.sendJobAddress(updatedFormData);
  };

  const handleCityChange = (e) =>{
    const { name, value } = e.target;
    const updatedFormData = {
      ...formData,
      city: value,
    };
    setFormData(updatedFormData);
    props.sendJobAddress(updatedFormData);
  };

  return (
    <div className="form-card">
      <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
      <div style={{ color: 'red' }}>{props.error !== '' ? `${t('forms.formsquestion.toast.completeSection')}` : ''}</div>
        <p className='text-bold text-center'>{t("forms.title.companyaddress")}</p>
        <div className='row p-2'>
          <p htmlFor="country" className="col-sm-4 col-form-label">{t("forms.formsquestion.professionalExperience.country")}<RequiredIndicator /></p>
          <div className="col-sm-8">
            <CountrySelect value={formData.country} onChange={handleCountryChange} required />
          </div>
        </div>
        <div className='row p-2'>
          <p htmlFor="provinceState" className="col-sm-4 col-form-label">{t("forms.formsquestion.professionalExperience.provinceState")} <RequiredIndicator /></p>
          <div className="col-sm-8">
            {countriesWithProvinces.includes(formData.country) ? 
              (<ProvinceSelect 
                value={formData.provinceState} 
                onChange={handleProvinceStateChange} 
                country={formData.country} required
                />
              ):
              (<input 
                type="text" 
                className="form-control rounded-pill flous-input-color" 
                id="provinceState" 
                name="provinceState" 
                value={formData.provinceState} 
                onChange={handleChange} 
                placeholder={`${t('forms.formsquestion.placeholder.province')}`}
              />
              )}
          </div>
        </div>
        <div className='row p-2'>
          <p htmlFor="city" className="col-sm-4 col-form-label">{t("forms.formsquestion.professionalExperience.city")}<RequiredIndicator /></p>
          <div className="col-sm-8">
            {countriesWithProvinces.includes(formData.country) ? 
              (<CitySelect 
                value={formData.city} 
                onChange={handleCityChange} 
                province={formData.provinceState} 
                required
                />
              ):
              (
                <input 
                type="text" 
                className="form-control rounded-pill flous-input-color" 
                id="city" 
                name="city" 
                value={formData.city} 
                onChange={handleChange} 
                placeholder={`${t('forms.formsquestion.placeholder.city')}`}
              />
              )}
          </div>
        </div>
        <div className='row p-2'>
          <p htmlFor="streetNumber" className="col-sm-4 col-form-label">{t("forms.formsquestion.professionalExperience.streetNumber")}<RequiredIndicator /></p>
          <div className="col-sm-8">
            <input 
              type="text" 
              className="form-control rounded-pill flous-input-color" 
              id="streetNumber" 
              name="streetNumber" 
              value={formData.streetNumber} 
              onChange={handleChange} 
              placeholder={`${t('forms.formsquestion.placeholder.streetNumber')}`}
            />
          </div>
        </div>
        <div className='row p-2'>
          <p htmlFor="postalCode" className="col-sm-4 col-form-label">{t("forms.formsquestion.professionalExperience.postalCode")}</p>
          <div className="col-sm-8">
            <input 
              type="text" 
              className="form-control rounded-pill flous-input-color" 
              id="postalCode" 
              name="postalCode" 
              value={formData.postalCode} 
              onChange={handleChange} 
              placeholder={`${t('forms.formsquestion.placeholder.codePostal')}`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobAddressSection;
