import React from 'react';

import { useTranslation } from 'react-i18next';

export default function CompanyInfoResumeSection(props) {
  const { t } = useTranslation();
  // Define the job offer information
  const companyInfo = {
      hourlyRate: props.jobCompensation.hourlyRate, //  
      minAnnualSalary: props.jobCompensation.annualSalary, //  
      companyName: props.companyInfo.companyName, //  
      numberOfEmployed: props.companyInfo.numberOfEmployed, //  
      annualGrossRevenue: props.companyInfo.annualGrossRevenue, //  
      foundingDate: props.companyInfo.foundingDate //  
  };

  return (
    <div className='flous-bg-thirty p-3 elevation-2 mb-3' style={{ borderRadius: '15px' }}>
      <div className='text-bold text-center mb-3'>{t("forms.title.company")}</div>
      <div className='mx-3'>
        <div className='text-bold mb-3'>Job Compensation</div>
        <div className="form-group row">
          <label htmlFor="inputHourlyRate" className="col-sm-3 col-form-label">{t("forms.formsquestion.jobOffer.hourlyRate")}</label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputHourlyRate" value={companyInfo.hourlyRate} placeholder="N.A"/>
          </div>
          <label htmlFor="inputMinAnnualSalary" className="col-sm-3 col-form-label">{t("forms.formsquestion.jobOffer.annualSalary")}</label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputMinAnnualSalary" value={companyInfo.minAnnualSalary} placeholder="N.A"/>
          </div>
        </div>
        <div className='text-bold mb-3'>Company Information</div>
        <div className="form-group row">
          <label htmlFor="inputCompanyName" className="col-sm-3 col-form-label">{t("forms.formsquestion.jobOffer.companyName")}</label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputCompanyName" value={companyInfo.companyName} placeholder="N.A"/>
          </div>
          <label htmlFor="inputNumberOfEmployed" className="col-sm-3 col-form-label">{t("forms.formsquestion.jobOffer.numberOfEmployed")}</label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputNumberOfEmployed" value={companyInfo.numberOfEmployed} placeholder="N.A"/>
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="inputAnnualGrossRevenue" className="col-sm-3 col-form-label">{t("forms.formsquestion.jobOffer.annualGrossRevenue")}</label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputAnnualGrossRevenue" value={companyInfo.annualGrossRevenue} placeholder="N.A"/>
          </div>
          <label htmlFor="inputFoundingDate" className="col-sm-3 col-form-label">{t("forms.formsquestion.jobOffer.foundingDate")}</label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputFoundingDate" value={companyInfo.foundingDate} placeholder="N.A"/>
          </div>
        </div>
      </div>
    </div>
  );
}