import React, { useState, useEffect } from 'react';
import CountrySelect from '../../../general/CountrySelect';
import ProvinceSelect from '../../../general/ProvinceSelect';
import CitySelect from '../../../general/CitySelect';
import Select from 'react-select';
import { countriesWithProvinces, sortDictionaryByValues } from '../../../../services/utils';
import api from '../../../../services/api';
import { toast, ToastContainer } from 'react-toastify';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';

import { useTranslation } from "react-i18next";
import RequiredIndicator from '../../../general/requiredIndicator';
import { translateDictionaryList, translateText } from '../../../../services/general/TranslationServices';

const FamilyMembersInfoSection = (props) => {
    const [relationships, setRelationships] = useState([]);

    const [memebersListIsValid, setMembersListIsValid] = useState(false);

    const [childrenData, setChildrenData] = useState(props.children);
    const [familyInfo, setFamilyInfo] = useState(props.familyInfo);
    const { t } = useTranslation();

    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        nationality: '',
        permanent: '',
        birthday: '',
        familyRelationship: '',
        liveWith: '',
        country: '',
        provinceState: '',
        city: '',
        streetNumber: '',
        postalCode: '',
        deathday: '',
        startDate: '',
    });
    const [members, setMembers] = useState(props.familyMembers);
    const [errors, setErrors] = useState({});

    const [addMemberClicked, setAddMemberClicked] = useState(false);
    const [currentMember, setCurrentMember] = useState(null);
    
    const [relationshipsOriginal, setRelationshipsOriginal] = useState([]);
  
    const lang = localStorage.getItem('flouslang') || 'en';
    const [tooltip1, setTooltip1] = useState("Sister, Stepsister, Brother, Stepbrother.");
    const [tooltip2, setTooltip2] = useState("Father, Stepfather, Mother, Stepmother,\n Sister, Stepsister, Brother, Stepbrother, Grandfather, Grandmother, Nephew, Niece.");
  
    const handleTranslateTooltip = async ()=>{
      if (lang !== 'en') {
        const textTranslated1 = await translateText(tooltip1, lang);
        const textTranslated2 = await translateText(tooltip2, lang);
        setTooltip1(textTranslated1);
        setTooltip2(textTranslated2);
      } else {
        setTooltip1("Sister, Stepsister, Brother, Stepbrother.");
        setTooltip2("Father, Stepfather, Mother, Stepmother,\n Sister, Stepsister, Brother, Stepbrother, Grandfather, Grandmother, Nephew, Niece.");
      }
    };
  
    useEffect(()=>{
      handleTranslateTooltip();
    }, [lang]);

    const handleTranslate = async (data)=>{
      if (lang !== 'en') {
        const relationshipTranslated = await translateDictionaryList(relationshipsOriginal, lang);
        setRelationships(relationshipTranslated);
      } else {
        setRelationships(relationshipsOriginal);
      }
    };
  
    useEffect(()=>{
      handleTranslate();
    }, [lang, relationshipsOriginal]);

    useEffect(() => {
        const fetchRelationship = async () => {
            const response = await api.get('/familyrelationship/');
            const sorted = sortDictionaryByValues(response.data);
            const options = getOptions(sorted);
            setRelationships(options);
            setRelationshipsOriginal(options);
        };
        fetchRelationship();
    }, []);

    const getOptions = (data) => {
        return Object.entries(data).map(([key, val]) => ({
            value: key,
            label: val,
        }));
    };

    const handleChildrenDataChange = (event) => {
        const { name, value } = event.target;
        const updatedFormData = {
          ...childrenData,
          [name]: value,
        };
        setChildrenData(updatedFormData);
        delete errors.childrenData;
    };

    const handleNumberChange = (event) =>{
        const { name, value } = event.target;
        const updatedFormData = {
          ...familyInfo,
          [name]: value,
        };
        setFamilyInfo(updatedFormData);
    }

    useEffect(()=>{
        props.sendChildren(childrenData);
        props.sendFamilyInfo(familyInfo);
        
      }, [childrenData, familyInfo, props])
    
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
        if (name === "permanent" && value === "Yes"){
            setFormData((prevFormData) => ({
                ...prevFormData,
                nationality: "CAN",
            }));
        }
    };

    const handleCountryChange = (e) =>{
        const value = e.target.value;
        const updatedFormData = {
          ...formData,
          country: value,
        };
        setFormData(updatedFormData);
    };

    const handleNationalityChange = (e) =>{
        const { name, value } = e.target;
        const updatedFormData = {
          ...formData,
          nationality: value,
        };
        setFormData(updatedFormData);
    };

    const handleProvinceStateChange = (e) => {
        const value = e.target.value
        setFormData((prevFormData) => ({
            ...prevFormData,
            provinceState: value,
        }));
    };

    const handleCityChange = (e) => {
        const value = e.target.value
        setFormData((prevFormData) => ({
            ...prevFormData,
            city: value,
        }));
        //props.sendFamilyMembers(formData);
    };

    const handleAddMembers = () => {
        if(checkFormData()) {
            if (currentMember === null) {
                setMembers((prevMembers) => [...prevMembers, formData]);
            }else {
                const updateData = [...members];
                updateData[currentMember] = formData;
                setMembers(updateData);
            }
            setFormData({
                firstname: '',
                lastname: '',
                nationality: formData.nationality,
                permanent: '',
                birthday: '',
                familyRelationship: formData.familyRelationship,
                liveWith: '',
                country: formData.country,
                provinceState: formData.provinceState,
                city: '',
                streetNumber: '',
                postalCode: '',
                deathday: '',
                startDate: '',
            });
            setAddMemberClicked(false);
            setCurrentMember(null);
            delete errors.formData;
        }else{
            toast.error(`${t('forms.formsquestion.toast.familyMembers.allRequiredFields')}`);
        }
    };

    const handleCancelMember = () => {
        setAddMemberClicked(false);
        setCurrentMember(null);
        setFormData({
            firstname: '',
            lastname: '',
            nationality: formData.nationality,
            permanent: '',
            birthday: '',
            familyRelationship: formData.familyRelationship,
            liveWith: '',
            country: formData.country,
            provinceState: formData.provinceState,
            city: '',
            streetNumber: '',
            postalCode: '',
            deathday: '',
            startDate: '',
        });
        delete errors.formData;
      }

      const handleEditMember = (index) => {
        const updatedFormData = [...members];
        const member = updatedFormData[index];
        setFormData({
            firstname: member.firstname,
            lastname: member.lastname,
            nationality: member.nationality,
            permanent: member.permanent,
            birthday: member.birthday,
            familyRelationship: member.familyRelationship,
            liveWith: member.liveWith,
            country: member.country,
            provinceState: member.provinceState,
            city: member.city,
            streetNumber: member.streetNumber,
            postalCode: member.postalCode,
            deathday: member.deathday,
            startDate: member.startDate,
        });
        setAddMemberClicked(true);
        setCurrentMember(index);
        delete errors.formData;
      }

    const [validationMessage, setValidationMessage] = useState('');

    const parentRelationships = ["Father", "Stepfather", "Mother", "Stepmother"];
    const parentRelationshipsFR = ["Père", "Beau-père", "Mère", "Belle-mère"];
    const parentRelationshipsES = ["Padre", "Suegro", "Madre", "Suegra"];
    const siblingRelationships = ["Sister", "Stepsister", "Brother", "Stepbrother"];
    const siblingRelationshipsFR = ["Sœur", "Demi-sœur", "Frère", "Demi-frère"];
    const siblingRelationshipsES = ["Hermana", "Hermanastra", "Hermano", "Hermanastro"];
    const canadianOrResidentPermanent = ["Yes"];

    const validateFamilyList = () => {
        const parentCount = members.filter(member => parentRelationships.includes(member.familyRelationship)).length;
        const siblingCount = members.filter(member => siblingRelationships.includes(member.familyRelationship)).length;
        const canOrRpCount = members.filter(member => canadianOrResidentPermanent.includes(member.permanent)).length;

        if (parentCount >= 2 && ((familyInfo.totalSibling - siblingCount) <= 0 && (familyInfo.totalCanadianOrPResident - canOrRpCount) <= 0)) {
            setMembersListIsValid(true);
            setValidationMessage(`${t('forms.formsquestion.toast.familyMembers.criteriaMeet')}`);
        } else {
            setMembersListIsValid(false);
            let msg = `${t('forms.formsquestion.toast.familyMembers.msgIntro')}`;

            if (parentCount < 2) {
                msg += `${2 - parentCount} ${t('forms.formsquestion.toast.familyMembers.parent')} (${lang === 'en'? parentRelationships.join(', '): (lang === 'fr'? parentRelationshipsFR.join(', '):parentRelationshipsES.join(', '))}), `;
            }
            if (familyInfo.totalSibling >= 1 && ((familyInfo.totalSibling - siblingCount) > 0)) {
                msg += ` ${familyInfo.totalSibling} ${t('forms.formsquestion.toast.familyMembers.sibling')} (${lang === 'en'? siblingRelationships.join(', '): (lang === 'fr'? siblingRelationshipsFR.join(', '):siblingRelationshipsES.join(', '))}), `;
            }
            if (familyInfo.totalCanadianOrPResident >= 1 && (familyInfo.totalCanadianOrPResident - canOrRpCount) > 0) {
                msg += `${t('forms.formsquestion.toast.familyMembers.atLeast')} ${familyInfo.totalCanadianOrPResident} ${t('forms.formsquestion.toast.familyMembers.endMsg')}`;
            }
            setValidationMessage(msg);
        }
    };

    const removeMember = (index) => {
        const updatedFamilyList = members.filter((_, i) => i !== index);
        setMembers(updatedFamilyList);
    };

    useEffect(() => {
        validateFamilyList();
    }, [members, familyInfo, lang]);

    const pressNext = ()=> {
        props.sendNext("Residences");
    };
    
    const saveForm = () => {
        props.onSave();
    };

    const checkFormData = () => {
        if (
            // formData.firstname === '' ||
            // formData.lastname === '' ||
            // formData.nationality === '' ||
            formData.permanent === '' ||
            // formData.birthday === '' ||
            formData.familyRelationship === '' ||
            formData.liveWith === '' ||
            (formData.liveWith === 'No' && (
                formData.country === '' ||
                formData.provinceState === '' ||
                formData.city === ''
                //formData.streetNumber === '' ||
                //formData.postalCode === '' ||
                //formData.deathday === '' ||
            )) || (formData.country === 'CAN' && formData.startDate === '')
        ) {
            setErrors(prevErrors => ({
                ...prevErrors,
                formData: `${t('forms.formsquestion.toast.familyMembers.allRequiredFields')}`
            }));
            return false;
        } else {
            delete errors.formData;
            return true;
        }
    };

    const checkDependantChildren = () =>{
        if (
            childrenData.dependantChildren === '' ||
            ( childrenData.dependantChildren === 'Yes' && 
                (childrenData.totalDependantChildren === '' || childrenData.childrenUnder22 === ''))
        ){
            setErrors(prevErrors => ({
                ...prevErrors,
                childrenData: `${t('forms.formsquestion.toast.familyMembers.allRequiredFields')}`
            }));
            return false;

        } else {
            delete errors.childrenData;
            return true;
        }
    }

    const checkFamilyInfo = () =>{
        if (
            familyInfo.totalSibling === '' ||
            familyInfo.totalCanadianOrPResident === ''){
            setErrors(prevErrors => ({
                ...prevErrors,
                familyInfo: `${t('forms.formsquestion.toast.familyMembers.moreInfo')}`
            }));
            return false;

        } else {
            delete errors.familyInfo;
            return true;
        }
    }

    const membersRequirements = () => {
        if (!memebersListIsValid){
            setErrors(prevErrors => ({
                ...prevErrors,
                membersRequirements: `${t('forms.formsquestion.toast.familyMembers.membersRequirements')}`
            }));
            return false;
        } else {
            delete errors.membersRequirements;
            return true;
        }
    }

    const validateSection = () => {
        props.onValidate('familyMembers', false);
        if(checkFamilyInfo() && (membersRequirements() && checkDependantChildren())) {
            props.onValidate('familyMembers', true);
            toast.success(`${t('forms.formsquestion.toast.sectionValidate')}`);
            setErrors({});
        } else {
            toast.error(`${t('forms.formsquestion.toast.familyMembers.membersRequirements')}`);
            props.onValidate('familyMembers', false);
        }
      };

    useEffect(()=>{
        props.sendFamilyMembers(members);
        props.onValidate('familyMembers', false);
    }, [members, props])

    return (
        <div className="form-card">
            <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
                
                {errors.childrenData && (
                    <>
                        <p style={{ marginTop: '20px', padding: '10px', color: 'red'}}>
                            {errors.childrenData}
                        </p>
                    </>
                )}
                <div className='row p-2'>
                    <p className='col-sm-8 col-form-label'>{t("forms.formsquestion.familyMembers.dependantChildren")} <RequiredIndicator /></p>
                    <div className='col-sm-4'>
                    <div className='row'>
                        <div className="col form-check">
                        <input 
                            type="radio"
                            className="form-check-input rounded-pill"
                            name="dependantChildren"
                            value="Yes"
                            onChange={handleChildrenDataChange}
                            checked={childrenData.dependantChildren === "Yes"}
                        />
                        <label className="form-check-label">{t("forms.click.yes")}</label>
                        </div>
                        <div className="col form-check">
                        <input 
                            type="radio"
                            className="form-check-input rounded-pill"
                            name="dependantChildren"
                            value="No"
                            onChange={handleChildrenDataChange}
                            checked={childrenData.dependantChildren === "No"}
                        />
                        <label className="form-check-label">{t("forms.click.no")}</label>
                        </div>
                    </div>
                    </div>
                </div>
                {childrenData.dependantChildren === 'Yes' && (
                    <div>
                        <div className='row p-2'>
                        <p htmlFor="totalDependantChildren" className="col-sm-6 col-form-label">{t("forms.formsquestion.familyMembers.totalDependantChildren")} <RequiredIndicator /></p>
                        <div className="col-sm-6">
                            <input 
                            type="number" 
                            className="form-control rounded-pill flous-input-color" 
                            id="totalDependantChildren" 
                            name="totalDependantChildren"
                            placeholder={`${t('forms.formsquestion.placeholder.dependantChildren')}`}
                            value={childrenData.totalDependantChildren}
                            onChange={handleChildrenDataChange} min={1}
                            />
                        </div>
                        </div>
                        <div className='row p-2'>
                        <p htmlFor="childrenUnder22" className="col-sm-6 col-form-label">{t("forms.formsquestion.familyMembers.childrenUnder22")} <RequiredIndicator /></p>
                        <div className="col-sm-6">
                            <input 
                            type="number" 
                            className="form-control rounded-pill flous-input-color" 
                            id="childrenUnder22" 
                            name="childrenUnder22"
                            placeholder={`${t('forms.formsquestion.placeholder.dependantChildrenUnder22')}`}
                            value={childrenData.childrenUnder22}
                            onChange={handleChildrenDataChange} min={1}
                            />
                        </div>
                        </div>
                    </div>
                )}
            </div>
            <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
                <p className="text-bold text-center">{t("forms.title.family")}</p>
                {errors.familyInfo && (
                    <>
                        <p style={{ marginTop: '20px', padding: '10px', color: 'red'}}>
                            {errors.familyInfo}
                        </p>
                    </>
                )}
                <div className='row p-2'>
                    <p htmlFor="childrenUnder22" className="col-sm-6 col-form-label">{t("forms.formsquestion.familyMembers.siblingRelation")}<RequiredIndicator />
                        <i className="nav-icon fas fa-info-circle"  data-tooltip-id="tooltip1" 
                        data-tooltip-content={tooltip1}>
                        </i>
                    </p>
                    <div className="col-sm-6">
                        <input 
                        type="number" 
                        className="form-control rounded-pill flous-input-color" 
                        id="totalSibling" 
                        name="totalSibling"
                        placeholder={`${t('forms.formsquestion.placeholder.numberSibling')}`}
                        value={familyInfo.totalSibling}
                        onChange={handleNumberChange} min={0}
                        />
                    </div>
                    <Tooltip id='tooltip1' />
                </div>
                <div className='row p-2'>
                    <p htmlFor="totalCanadianOrPResident" className="col-sm-6 col-form-label">{t("forms.formsquestion.familyMembers.permanentOrCitizen")}<RequiredIndicator />
                        <i className="nav-icon fas fa-info-circle"  data-tooltip-id="tooltip2" 
                        data-tooltip-content={tooltip2}>
                        </i>
                    </p>
                    <div className="col-sm-6">
                        <input 
                            type="number" 
                            className="form-control rounded-pill flous-input-color" 
                            id="totalCanadianOrPResident" 
                            name="totalCanadianOrPResident"
                            placeholder={`${t('forms.formsquestion.placeholder.numberCanPR')}`}
                            value={familyInfo.totalCanadianOrPResident}
                            onChange={handleNumberChange} min={0}
                        />
                    </div>
                    <Tooltip id='tooltip2' />
                </div>
                {errors.membersRequirements && (
                    <>
                        <p style={{ marginTop: '20px', padding: '10px', color: 'red'}}>
                            {errors.membersRequirements}
                        </p>
                    </>
                )}
                {memebersListIsValid ?(
                    <>
                        <p style={{ marginTop: '20px', padding: '10px', color: 'green'}}>
                            {validationMessage}
                        </p>
                    </>
                ):(
                    <>
                        <p style={{ marginTop: '20px', padding: '10px', color: 'red'}}>
                            {validationMessage}
                        </p>
                    </>
                )}
                <div>
                    <div className="card-header p-1 mb-1 shadow bg-white" style={{ borderRadius: '15px 15px 0px 0px' }}>
                        <div className="row text-bold">
                            <div className="col-sm col-6 mt-2 text-center">{t("general.table.relation")}</div>
                            <div className="col-sm col-6 mt-2 text-center">{t("general.table.firstname")}</div>
                            <div className="col-sm col-6 mt-2 text-center">{t("general.table.lastname")}</div>
                            <div className="col-sm col-6 mt-2 text-center">{t("general.table.nationality")}</div>
                            <div className="col-sm col-6 mt-2 text-center">{t("general.table.address")}</div>
                            <div className="col-sm col-6 mt-2 text-center">{t("general.table.birthday")}</div>
                            <div className="col-sm col-6 mt-2 text-center">{t("general.table.iscanadian")}</div>
                            <div className="col-sm col-6 mt-2 text-center">{t("general.table.actions")}</div>
                        </div>
                    </div>
                    {!members || members.length === 0 ? (
                        <>
                        <p className="text-center">{t("general.table.empty.familytwo")}</p>
                        <p className="text-center">{t("general.table.empty.familythree")}</p>
                        </>
                    ) : (
                    members.map((member, index) => (
                        <div className="card-header p-1 mb-1 shadow bg-white" style={{ borderRadius: '15px' }}>
                            <div className="row text-center" key={index}>
                                <div className="col-sm col-6 mt-2">{member.familyRelationship}</div>
                                <div className="col-sm col-6 mt-2">{member.firstname}</div>
                                <div className="col-sm col-6 mt-2">{member.lastname}</div>
                                <div className="col-sm col-6 mt-2">{member.nationality}</div>
                                <div className="col-sm col-6 mt-2">{member.country? member.country: 'N.A.'}</div>
                                <div className="col-sm col-6 mt-2">{member.birthday}</div>
                                <div className="col-sm col-6 mt-2">{member.permanent === 'Yes' ? `${t('forms.click.yes')}`: `${t('forms.click.no')}`}</div>
                                <div className="col-sm col-6 mt-2">
                                    <button type='button'
                                        className="btn flous-bg-primary mr-1 mt-1" 
                                        onClick={() => handleEditMember(index)}
                                    >
                                        <i className="nav-icon fas fa-edit"></i>
                                    </button>
                                    <button type='button' className="btn btn-secondary btn-sm mt-1"
                                        onClick={() => removeMember(index)}><i className="nav-icon fas fa-trash"></i></button>
                                </div>
                            </div>
                        </div>
                    )))}
                    {!addMemberClicked &&
                        <div className='mt-3'>
                            <button type='button' className='btn btn-block btn-secondary rounded-pill' onClick={()=>{setAddMemberClicked(true)}}>{t("forms.addMoreButton.addFamilyMember")}</button>
                        </div>
                    }
                </div>
                {addMemberClicked && (
                    <>
                        
                        <div className="mt-2">
                            <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
                                
                                {errors.formData && (
                                    <>
                                        <p style={{ marginTop: '20px', padding: '10px', color: 'red'}}>
                                            {errors.formData}
                                        </p>
                                    </>
                                )}
                                <div className="row p-2">
                                    <p className="col-sm-6 col-form-label">{t("forms.formsquestion.familyMembers.firstname")}</p>
                                    <div className="col-sm-6">
                                        <input
                                            type="text"
                                            id="firstname"
                                            name="firstname"
                                            className="form-control rounded-pill flous-input-color"
                                            onChange={handleChange}
                                            value={formData.firstname}
                                        />
                                    </div>
                                </div>
                                <div className="row p-2">
                                    <p className="col-sm-6 col-form-label">{t("forms.formsquestion.familyMembers.lastname")}</p>
                                    <div className="col-sm-6">
                                        <input
                                            type="text"
                                            id="lastname"
                                            name="lastname"
                                            className="form-control rounded-pill flous-input-color"
                                            onChange={handleChange}
                                            value={formData.lastname}
                                        />
                                    </div>
                                </div>
                                <div className="row p-2">
                                    <p className="col-sm-6 col-form-label">{t("forms.formsquestion.familyMembers.nationality")}</p>
                                    <div className="col-sm-6">
                                        <CountrySelect
                                            value={formData.nationality}
                                            onChange={handleNationalityChange}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="row p-2">
                                    <p className="col-sm-6 col-form-label">{t("forms.formsquestion.familyMembers.dateofbirth")}</p>
                                    <div className="col-sm-6">
                                        <input
                                            type="date"
                                            id="birthday"
                                            name="birthday"
                                            className="form-control rounded-pill flous-input-color"
                                            onChange={handleChange}
                                            placeholder='YYYY/MM/DD'
                                            value={formData.birthday} max={new Date().toISOString().split("T")[0]}
                                        />
                                    </div>
                                </div>
                                <div className="row p-2">
                                    <p className="col-sm-6 col-form-label">{t("forms.formsquestion.familyMembers.deathday")}</p>
                                    <div className="col-sm-6">
                                        <input
                                            type="date"
                                            id="deathday"
                                            name="deathday"
                                            className="form-control rounded-pill flous-input-color"
                                            onChange={handleChange}
                                            value={formData.deathday} max={new Date().toISOString().split("T")[0]}
                                        />
                                    </div>
                                </div>
                                
                                <div className="row p-2">
                                    <p className="col-sm-6 col-form-label">{t("forms.formsquestion.familyMembers.familyRelationship")} <RequiredIndicator /></p>
                                    <div className="col-sm-6">
                                        <Select
                                            id="familyRelationship"
                                            name="familyRelationship"
                                            className="react-select-container rounded-pill flous-input-color"
                                            classNamePrefix="react-select"
                                            value={relationships.find((option) => option.value === formData.familyRelationship)}
                                            onChange={(selectedOption) => handleChange({ target: { name: 'familyRelationship', value: selectedOption ? selectedOption.value : '' } })}
                                            options={relationships}
                                            placeholder={`${t('forms.formsquestion.placeholder.option')}`}
                                            isSearchable
                                            isClearable
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    width: '100%',
                                                    borderRadius: '20px',
                                                    borderColor: '#ccc',
                                                }),
                                            }}
                                            required
                                        />
                                    </div>
                                </div>
                                {(formData.familyRelationship !== '') && (
                                <div className='row p-2'>
                                    <p className='col-sm-10 col-form-label'>{t("forms.formsquestion.familyMembers.permanentOrCan")}<RequiredIndicator /></p>
                                    <div className='col-sm-2'>
                                    <div className='row'>
                                        <div className="col form-check">
                                        <input 
                                            type="radio"
                                            className="form-check-input"
                                            id="permanentYes"
                                            name="permanent"
                                            value="Yes"
                                            onChange={handleChange}
                                            checked={formData.permanent === "Yes"}
                                        />
                                        <label className="form-check-label" htmlFor="permanentYes">{t("forms.click.yes")}</label>
                                        </div>
                                        <div className="col form-check">
                                        <input 
                                            type="radio"
                                            className="form-check-input"
                                            id="permanentNo"
                                            name="permanent"
                                            value="No"
                                            onChange={handleChange}
                                            checked={formData.permanent === "No"}
                                        />
                                        <label className="form-check-label" htmlFor="permanentNo">{t("forms.click.no")}</label>
                                        </div>
                                    </div>
                                    </div>
                                </div>)}
                            </div>
                            {(formData.familyRelationship !== '') && ( 
                            <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
                                <div className='row p-2'>
                                    <p className='col-sm-10 col-form-label'>{t("forms.formsquestion.familyMembers.liveWith")}<RequiredIndicator /></p>
                                    <div className='col-sm-2'>
                                    <div className='row'>
                                        <div className="col form-check">
                                        <input 
                                            type="radio"
                                            className="form-check-input"
                                            id="liveWithYes"
                                            name="liveWith"
                                            value="Yes"
                                            onChange={handleChange}
                                            checked={formData.liveWith === "Yes"}
                                        />
                                        <label className="form-check-label" htmlFor="liveWithYes">{t("forms.click.yes")}</label>
                                        </div>
                                        <div className="col form-check">
                                        <input 
                                            type="radio"
                                            className="form-check-input"
                                            id="liveWithNo"
                                            name="liveWith"
                                            value="No"
                                            onChange={handleChange}
                                            checked={formData.liveWith === "No"}
                                        />
                                        <label className="form-check-label" htmlFor="liveWithNo">{t("forms.click.no")}</label>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                {(formData.liveWith === "No") && (
                                <>
                                <div className="row p-2">
                                    <p className="col-sm-6 col-form-label">{t("forms.formsquestion.familyMembers.country")}<RequiredIndicator /></p>
                                    <div className="col-sm-6">
                                        <CountrySelect
                                            value={formData.country}
                                            onChange={handleCountryChange}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="row p-2">
                                    <p className="col-sm-6 col-form-label">{t("forms.formsquestion.familyMembers.provinceState")}<RequiredIndicator /></p>
                                    <div className="col-sm-6">
                                        {countriesWithProvinces.includes(formData.country) ? (
                                            <ProvinceSelect
                                                value={formData.provinceState}
                                                onChange={handleProvinceStateChange}
                                                country={formData.country}
                                                required
                                            />
                                        ) : (
                                            <input
                                                type="text"
                                                className="form-control rounded-pill flous-input-color"
                                                id="provinceState"
                                                name="provinceState"
                                                value={formData.provinceState}
                                                onChange={handleChange}
                                                placeholder={`${t('forms.formsquestion.placeholder.province')}`}
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className="row p-2">
                                    <p className="col-sm-6 col-form-label">{t("forms.formsquestion.familyMembers.city")}<RequiredIndicator /></p>
                                    <div className="col-sm-6">
                                        {countriesWithProvinces.includes(formData.country) ? (
                                            <CitySelect
                                                value={formData.city}
                                                onChange={handleCityChange}
                                                province={formData.provinceState}
                                                required
                                            />
                                        ) : (
                                            <input
                                                type="text"
                                                className="form-control rounded-pill flous-input-color"
                                                id="city"
                                                name="city"
                                                value={formData.city}
                                                onChange={handleChange}
                                                placeholder={`${t('forms.formsquestion.placeholder.city')}`}
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className="row p-2">
                                    <p className="col-sm-6 col-form-label">{t("forms.formsquestion.familyMembers.streetNumber")}</p>
                                    <div className="col-sm-6">
                                        <input
                                            type="text"
                                            id="streetNumber"
                                            name="streetNumber"
                                            className="form-control rounded-pill flous-input-color"
                                            onChange={handleChange}
                                            value={formData.streetNumber}
                                            placeholder={`${t('forms.formsquestion.placeholder.streetNumber')}`}
                                        />
                                    </div>
                                </div>
                                <div className="row p-2">
                                    <p className="col-sm-6 col-form-label">{t("forms.formsquestion.familyMembers.postalCode")}</p>
                                    <div className="col-sm-6">
                                        <input
                                            type="text"
                                            id="postalCode"
                                            name="postalCode"
                                            className="form-control rounded-pill flous-input-color"
                                            onChange={handleChange}
                                            value={formData.postalCode}
                                            placeholder={`${t('forms.formsquestion.placeholder.codePostal')}`}
                                        />
                                    </div>
                                </div>
                                {(formData.country === 'CAN') && (
                                    <div className="row p-2">
                                        <p className="col-sm-6 col-form-label">{t("forms.formsquestion.familyMembers.startDate")}</p>
                                        <div className="col-sm-6">
                                            <input
                                                type="date"
                                                id="startDate"
                                                name="startDate"
                                                className="form-control rounded-pill flous-input-color"
                                                onChange={handleChange}
                                                value={formData.startDate}
                                                placeholder="Start Date"
                                            />
                                        </div>
                                    </div>
                                )}
                                </>
                                )}
                            </div>)}
                            </div>
                            
                            <div className='d-flex justify-content-center'>
                                <div className='col-sm-10'>
                                    <div className='d-flex justify-content-between'>
                                        <input type="button" className="col-6 btn flous-btn-gradient rounded-pill mx-1" value={`${t("forms.addMoreButton.saveFamilyMember")}`} onClick={handleAddMembers}/>
                                        <input type="button" className="col-6 btn btn-block btn-secondary rounded-pill mx-1" value={`${t("forms.addMoreButton.cancel")}`} onClick={()=>{handleCancelMember()}}/>
                                    </div>
                                </div>
                            </div>
                            {/*
                            <button type='button' className='btn btn-block btn-secondary rounded-pill' onClick={handleAddMembers}>Save Family Member</button>
                        
                            */}
                            
                    </>
                )}
            </div>
            <div className='d-flex justify-content-center'>
                <div className='col-sm-10'>
                    <div className='d-flex justify-content-between'>
                        <input type="button" name="save" className="col-6 btn flous-btn-gradient rounded-pill mx-1" value={t("forms.click.save")} onClick={saveForm}/>
                        <input type="button" name="next" className="col-6 btn flous-btn-gradient rounded-pill mx-1" value={t("forms.click.next")} onClick={pressNext}/>
                    </div>
                </div>
            </div>
            <div className='d-flex justify-content-center'>
                <input type="button" name="validate" className="col-sm-6 btn flous-btn-primary rounded-pill mt-2 mx-2" value={t("forms.click.validate")} onClick={validateSection}/>
            </div>
            <ToastContainer />
        </div>
    );
};

export default FamilyMembersInfoSection;
