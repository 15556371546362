import React from 'react';

import { useTranslation } from 'react-i18next';

export default function CompanyFinanceResumeSection(props) {
  const { t } = useTranslation();
  // Define the company finance information
  const companyFinance = {
    companyValue: props.companyFinances.companyValue, //  
    startupExpenses: props.companyFinances.startupExpenses, //  
    operatingExpenses: props.companyFinances.operatingExpenses //  
  };

  return (
    <div className='flous-bg-thirty p-3 elevation-2 mb-3' style={{ borderRadius: '15px' }}>
      <div className='text-bold text-center mb-3'>{t("forms.title.companyfinance")}</div>
      <div className='mx-3'>
        <div className="form-group row">
          <label htmlFor="inputCompanyValue" className="col-sm-6 col-form-label">
          {t("forms.formsquestion.entrepreunarialExperience.companyValue")}
          </label>
          <div className="col-sm-6">
            <input type="text" className="form-control" id="inputCompanyValue" value={companyFinance.companyValue} placeholder="N.A"/>
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="inputStartupExpenses" className="col-sm-6 col-form-label">
          {t("forms.formsquestion.entrepreunarialExperience.startupExpenses")}
          </label>
          <div className="col-sm-6">
            <input type="text" className="form-control" id="inputStartupExpenses" value={companyFinance.startupExpenses} placeholder="N.A"/>
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="inputOperatingExpenses" className="col-sm-6 col-form-label">
          {t("forms.formsquestion.entrepreunarialExperience.operatingExpenses")}
          </label>
          <div className="col-sm-6">
            <input type="text" className="form-control" id="inputOperatingExpenses" value={companyFinance.operatingExpenses} placeholder="N.A"/>
          </div>
        </div>
      </div>
    </div>
  );
}
