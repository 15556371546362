import React, { useState, useEffect } from 'react';
import RequiredIndicator from '../../../../general/requiredIndicator';

import { useTranslation } from "react-i18next";

const DateofStudySection = (props) => {
  const [formData, setFormData] = useState(props.dateofStudy);
  const { t } = useTranslation();

  const handleChange = (event) => {
    const { name, value } = event.target;
    const updateData = {
      ...formData,
      [name]: value,
    }
    setFormData(updateData);
    props.sendDateofStudy(updateData);
  };

  return (
    <div className="form-card">
      <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
      <div style={{ color: 'red' }}>{props.error !== '' ? `${t('forms.formsquestion.toast.completeSection')}` : ''}</div>
      <p className='text-bold text-center'>{t("forms.title.dateofstudy")}</p>
        <div className='row p-2'>
          <p htmlFor="startDate" className="col-sm-4 col-form-label">{t("forms.formsquestion.educationOutsideCountry.startDate")}<RequiredIndicator /></p>
          <div className="col-sm-8">
            <input 
              type="date" 
              name="startDate"
              className="form-control rounded-pill flous-input-color"
              onChange={handleChange}
              value={formData.startDate} max={new Date().toISOString().split("T")[0]}
            />
          </div>
        </div>
        <div className='row p-2'>
          <p htmlFor="endDate" className="col-sm-4 col-form-label">{t("forms.formsquestion.educationOutsideCountry.endDate")}<RequiredIndicator /></p>
          <div className="col-sm-8">
            <input 
              type="date" 
              name="endDate"
              className="form-control rounded-pill flous-input-color"
              onChange={handleChange}
              value={formData.endDate}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DateofStudySection;