import React, { useState } from 'react';
import { MessageStatesConverter, OccupationConverter } from '../../../services/utils';
import { toast, ToastContainer } from 'react-toastify'
import { deleteContactUsMessage } from '../../../services/flous/inboxServices';
import LoadingOverlay from '../../general/LoadingOverlay';

const FlousDemoRequestItem = ({ request, handleSetRequest }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleDeleteRequest = async (id) =>{
        setLoading(true);
        try{
            const response = await deleteContactUsMessage(id);
            if (response && response.status === 200) {
                setLoading(false);
                window.location.reload();
            }
        } catch (error){
            setLoading(false);
            if (error.response && [400, 500].includes(error.response.status)){
              setError(error.response.data.error);
              toast.error(error.response.data.error);
            }
        }
    }

    if (loading) {
        return <LoadingOverlay />;
    }

    if (error) {
        return <div>Error : {error.message}</div>;
    }
    
    return (
        <div className='card-header p-2 mb-2 shadow bg-white' style={{ borderRadius: '15px' }}>
            <div className='row'>
                <div className='col-sm-2 col-6 mt-2 text-center'>{request.firstName} {request.lastName}</div>
                <div className='col-sm-2 col-6 mt-2 text-center'>{request.request_type}</div>
                <div className='col-sm-2 col-6 mt-2 text-center'>{request.email}</div>
                <div className='col-sm-2 col-6 mt-2 text-center'>{request.phone}</div>
                <div className='col-sm-2 col-12 mt-2 text-center'>{MessageStatesConverter(request.state)}</div>
                <div className='col-sm-2 col-12 mt-2 text-center'>
                    {request.state == 'DN' ?(
                        <span className="btn flous-bg-menu mr-2 disabled" aria-disabled="true">
                            <i className="nav-icon fas fa-check"></i> Accept
                        </span>
                    ):(
                        <button onClick={handleSetRequest} type="button" className="btn flous-bg-menu mr-2" data-toggle="modal" data-target="#newCompany">
                            <i className="nav-icon fas fa-check"></i> Accept
                        </button>
                    )}
                    <button onClick={()=>handleDeleteRequest(request.id)} type="button" className="btn btn-secondary m-1">
                        <i className="nav-icon fas fa-times"></i> {request.state == 'DN'? 'Close':'Deny'}
                    </button>
                </div>
            </div>
            
            <ToastContainer />
        </div>
    );
}

export default FlousDemoRequestItem;