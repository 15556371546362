import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import FlagComponent from '../../general/FlagComponent';
import { useOutletContext } from 'react-router-dom';
import { deleteCustomer } from '../../../services/enterprise/CustomersServices';
import { toast } from 'react-toastify';
import LoadingOverlay from '../../general/LoadingOverlay';

const AgentCustomersItem = ({ customer, advisorCode }) => {
    const { user } = useOutletContext();
    
    const { code } = useParams();

    console.log(code);

    const [loading, setLoading] = useState(false);

    const handleDeleteCustomer = async (code) =>{
        setLoading(true);
        try{
        const response = await deleteCustomer(code);
        if (response && response.status===200) {
            setLoading(false);
        }

        window.location.reload();
        }  catch (error){
            if (error.response && [400, 500].includes(error.response.status)){
                toast.error(error.response.data.error);
                setLoading(false);
            }
        }
    }

    if (loading) {
        return <LoadingOverlay />;
    }

    return (
        <div className='card p-3 mb-3 mx-1' style={{ borderRadius: '15px' }}>
          <div className='row'>
              <div className='col-sm-3 col-12 mt-2'>{customer.country_cca3 ? <FlagComponent cca3={customer.country_cca3} /> : 'Undefined'}</div>
              <div className='col-sm-2 col-6 mt-2 text-center'>{customer.firstName}</div>
              <div className='col-sm-2 col-6 mt-2 text-center'>{customer.lastName}</div>
              <div className='col-sm-2 col-6 mt-2 text-center'>{customer.forms}</div>
              <div className='col-sm-3 col-6 mt-2 text-center'>
                
                {code ? (
                    <>
                        <a href={`/enterprise/customer/${customer.code}`} className="btn flous-bg-primary mr-1"><i className="nav-icon fas fa-eye"></i></a>
                    </>
                ):(
                    <>
                        <a href={user.type_package === 'Professional' ? `/professional/customer/${customer.code}` : `/agent/customer/${advisorCode}/${customer.code}`} className="btn flous-bg-primary mr-2"><i className="nav-icon fas fa-eye"></i></a>
                    </>
                 )}
                    
                <span onClick={() => handleDeleteCustomer(customer.code)}>
                    <span className="btn btn-secondary ml-1">
                        <i className="nav-icon fas fa-trash"></i>
                    </span>
                </span>
              </div>
          </div>
        </div>
    );
}

export default AgentCustomersItem;