import React, { useEffect, useState } from 'react';
import api from '../../../../../services/api';
import { sortDictionaryByValues } from '../../../../../services/utils';
import { toast, ToastContainer } from 'react-toastify';
import Select from 'react-select';
import RequiredIndicator from '../../../../general/requiredIndicator';

import { useTranslation } from "react-i18next";
import { translateDictionaryList } from '../../../../../services/general/TranslationServices';

const EquivalencyProcessQuebecSection = (props) => {
  const [formData, setFormData] = useState(props.equivalencyProcessQuebec);
  const { t } = useTranslation();

  const [studyFields, setStudyFields] = useState([]);
  const [studyLevels, setStudyLevels] = useState([]);
  
  const lang = localStorage.getItem('flouslang') || 'en';
  const [studyFieldsOriginal, setStudyFieldsOriginal] = useState([]);
  const [studyLevelsOriginal, setStudyLevelsOriginal] = useState([]);

  const handleTranslate = async (data)=>{
    if (lang !== 'en') {
      const studyFieldsTranslated = await translateDictionaryList(studyFieldsOriginal, lang);
      setStudyFields(studyFieldsTranslated);
      const studyLevelsTranslated = await translateDictionaryList(studyLevelsOriginal, lang);
      setStudyLevels(studyLevelsTranslated);
    } else {
      setStudyFields(studyFieldsOriginal);
      setStudyLevels(studyLevelsOriginal);
    }
  };

  useEffect(()=>{
    handleTranslate();
  }, [lang, studyFieldsOriginal, studyLevelsOriginal]);

  useEffect(() => {
    const fetchStudyField = async () =>{
        const response = await api.get('/trainingfieldinside/');
        const sorted = sortDictionaryByValues(response.data);
        const options = getOptions(sorted);
        setStudyFields(options);
        setStudyFieldsOriginal(options);
    };
    const fetchStudyLevel = async () =>{
        const response = await api.get('/educationlevel/');
        const sorted = sortDictionaryByValues(response.data);
        const options = getOptions(sorted);
        setStudyLevels(options);
        setStudyLevelsOriginal(options);
    };

    fetchStudyField();
    fetchStudyLevel();

  }, []);

  const getOptions = (data) => {
    return Object.entries(data).map(([key, val]) => ({
      value: key,
      label: val,
    }));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    const updatedData = {
      ...formData,
      [name]: value,
    };
    setFormData(updatedData);
    props.sendEquivalencyProcessQuebec(updatedData);
  };

  return (
    <div className="form-card">
      <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
      <div style={{ color: 'red' }}>{props.error !== '' ? `${t('forms.formsquestion.toast.completeSection')}` : ''}</div>
        <p className='text-bold text-center'>{t("forms.title.equivalentquebec")}</p>
        <div className='row p-2'>
          <p htmlFor="epqObtained" className="col-form-label">{t("forms.formsquestion.educationOutsideCountry.epqObtained")}<RequiredIndicator /></p>
          <div className="form-check ml-5">
            <input 
              type="radio"
              className="form-check-input"
              name="epqObtained"
              value="Yes"
              onChange={handleChange}
              checked={formData.epqObtained === 'Yes'}
            />
            <p htmlFor="epqObtainedYes" className="form-check-p">{t("forms.click.yes")}</p>
          </div>
          <div className="form-check ml-5">
            <input 
              type="radio"
              className="form-check-input"
              name="epqObtained"
              value="No"
              onChange={handleChange}
              checked={formData.epqObtained === 'No'}
            />
            <p htmlFor="epqObtainedNo" className="form-check-p">{t("forms.click.no")}</p>
          </div>
        </div>
        {formData.epqObtained === 'Yes' && (
          <>
          <div className='row p-2'>
            <p htmlFor="recognizedCredential" className="col-sm-4 col-form-label">{t("forms.formsquestion.educationOutsideCountry.recognizedCredential")}<RequiredIndicator /></p>
            <div className="col-sm-8">
              <Select
                name="recognizedCredential"
                className="react-select-container rounded-pill flous-input-color col-sm-12"
                classNamePrefix="react-select"
                value={studyFields.find(option => option.value === formData.recognizedCredential)}
                onChange={(selectedOption) => handleChange({ target: { name: 'recognizedCredential', value: selectedOption ? selectedOption.value : '' } })}
                options={studyFields}
                placeholder={`${t('forms.formsquestion.placeholder.option')}`}
                isSearchable
                isClearable
                styles={{
                  control: (provided) => ({
                    ...provided,
                    width: '100%',
                    borderRadius: '20px', // Ajustez la valeur pour l'arrondissement
                    borderColor: '#ccc',  // Vous pouvez également ajuster la couleur de la bordure si nécessaire
                  }),
                }}
                required
              />
            </div>
          </div>
          <div className='row p-2'>
            <p htmlFor="recognizedDiplomaLevel" className="col-sm-4 col-form-label">{t("forms.formsquestion.educationOutsideCountry.recognizedDiplomaLevel")}<RequiredIndicator /></p>
            <div className="col-sm-8">
              <Select
                name="recognizedDiplomaLevel"
                className="react-select-container rounded-pill flous-input-color col-sm-12"
                classNamePrefix="react-select"
                value={studyLevels.find(option => option.value === formData.recognizedDiplomaLevel)}
                onChange={(selectedOption) => handleChange({ target: { name: 'recognizedDiplomaLevel', value: selectedOption ? selectedOption.value : '' } })}
                options={studyLevels}
                placeholder={`${t('forms.formsquestion.placeholder.option')}`}
                isSearchable
                isClearable
                styles={{
                  control: (provided) => ({
                    ...provided,
                    width: '100%',
                    borderRadius: '20px', // Ajustez la valeur pour l'arrondissement
                    borderColor: '#ccc',  // Vous pouvez également ajuster la couleur de la bordure si nécessaire
                  }),
                }}
                required
              />
            </div>
          </div>
        </>
        
        )}
      </div>
    </div>
  );
};

export default EquivalencyProcessQuebecSection;