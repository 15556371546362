import React, { useState, useEffect } from 'react';
import axios from 'axios';
import api from '../../../../../services/api';
import CountryShow from '../../../../general/countryShow';

import { useTranslation } from 'react-i18next';


export default function PreviousAddressResumeSection(props) {
  const { t } = useTranslation();

  const [provinces, setProvinces] = useState([]);

  const [visibleDetails, setVisibleDetails] = useState({});
    
  const toggleDetailVisibility = (index) => {
    setVisibleDetails(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  useEffect(() => {
      const fetchProvinces = async() => {
        
          const response = await api.get('/canada-provinces/');
          const provinceOptions = Object.entries(response.data).map(([key, val]) => ({
            value: key,
            label: val,
          }));
          setProvinces(provinceOptions);
        
      };
  
      fetchProvinces();
  }, []);

  return (

      <div className='flous-bg-thirty p-3 elevation-2 mb-3' style={{ borderRadius: '15px' }}>
      <div className='text-bold text-center mb-3'>{t("forms.title.previousaddress")}</div>
        <div className='mx-3'>
            <div className="form-group">
              <div>
                <div className='card-header p-1 mb-1 shadow bg-white' style={{ borderRadius: '15px 15px 0px 0px' }}>
                  <div className='row'>
                    <div className='col-sm col-6 mt-2 text-center'>{t("general.table.startdate")}</div>
                    <div className='col-sm col-6 mt-2 text-center'>{t("general.table.enddate")}</div>
                    <div className='col-sm col-6 mt-2 text-center'>{t("general.table.country")}</div>
                    <div className='col-sm col-6 mt-2 text-center'>{t("general.table.province")}</div>
                    <div className='col-sm col-6 mt-2 text-center'>{t("general.table.city")}</div>
                    {props.show !==true && <div className="col-sm col-6 mt-2 text-center">{t("general.table.actions")}</div>}
                  </div>
                </div>

                {props.previousAddresses.map((address, index) => (
                  <div key={index} className='card-header p-1 mb-1 shadow bg-white' style={{ borderRadius: '15px' }}>
                    <div className='row'>
                      <div className='col-sm col-6 mt-2 text-center'>{address.startDate}</div>
                      <div className='col-sm col-6 mt-2 text-center'>{address.endDate}</div>
                      <div className='col-sm col-6 mt-2 text-center'><CountryShow value={address.country}/></div>
                      {address.country === 'CAN' ? (
                      <div className='col-sm col-6 mt-2 text-center'>{provinces.find(province => province.value === address.provinceState)?.label || ''}</div>
                      ): (<div className='col-sm col-6 mt-2 text-center'>{address.provinceState}</div>)}
                      <div className='col-sm col-6 mt-2 text-center'>{address.city}</div>
                      {props.show !==true && <div className='col-sm col-6 mt-2 text-center'>
                        <button onClick={() => toggleDetailVisibility(index)} className="btn flous-bg-primary">
                          <i className="nav-icon fas fa-eye"></i>
                        </button>
                      </div>}
                    </div>
                    {(visibleDetails[index] || props.show ===true) && (
                    <div className='m-3'>
                      <div className="form-group row">
                        <div htmlFor="" className="col-sm-3">{t("forms.formsquestion.residences.streetNumber")}</div>
                        <div className="col-sm-3">
                          <input type="text" className="form-control" id="" value={address.streetNumber} placeholder="N.A"/>
                        </div>
                        <div htmlFor="" className="col-sm-3">{t("forms.formsquestion.residences.postalCode")}</div>
                        <div className="col-sm-3">
                          <input type="text" className="form-control" id="" value={address.postalCode} placeholder="N.A"/>
                        </div>
                      </div>
                    </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
        </div>
      </div>
  );
}
