import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

const CountrySelect = ({ value, onChange, className="flous-input-color" }) => {
  const [countries, setCountries] = useState([]);
      const { t } = useTranslation();

  useEffect(() => {
    axios.get('https://restcountries.com/v3.1/all')
      .then(response => {
        const sortedCountries = response.data.sort((a, b) =>
          a.name.common.localeCompare(b.name.common)
        );
        const countryOptions = sortedCountries.map(country => ({
          value: country.cca3,
          label: country.name.common
        }));
        setCountries(countryOptions);
      })
      .catch(error => {
        console.error('Error fetching countries:', error);
      });
  }, []);

  return (
      <Select
        className={className}
        value={countries.find(option => option.value === value)}
        onChange={(selectedOption) => onChange({ target: { name: 'country', value: selectedOption ? selectedOption.value :''} })}
        options={countries}
        placeholder={`${t('forms.formsquestion.placeholder.countrySelect')}`}
        isSearchable
        isClearable
        styles={{
          control: (provided) => ({
            ...provided,
            borderRadius: '20px', // Ajustez la valeur pour l'arrondissement
            borderColor: '#ccc',  // Vous pouvez également ajuster la couleur de la bordure si nécessaire
          }),
        }}
      />
  );
};

export default CountrySelect;
