import api from '../api';

let isLoading = false;

export const fetchNotifsData = async () => {

    if (isLoading) return null;
    isLoading = true;

    try{
        const response = await api.get(`/flous/get-all-notifications/`);
        return response;

    } catch (error){
        console.error('Error when getting all solo data:', error);
        throw error;
    } finally {
        isLoading = false;
    }  
};

export const readNotification = async (code) => {

    if (isLoading) return null;
    isLoading = true;

    try{
        const response = await api.get(`/flous/read-notification/${code}/`);
        return response;

    } catch (error){
        console.error('Error when getting all solo data:', error);
        throw error;
    } finally {
        isLoading = false;
    }  
};