import React, { useState } from 'react';
import RequiredIndicator from '../../../../general/requiredIndicator';

import { useTranslation } from "react-i18next";

const JobDetailJOSection = (props) => {
  const [formData, setFormData] = useState(props.jobDetail);
  const { t } = useTranslation();

  const handleChange = (event) => {
    const { name, value } = event.target;
    const updatedData = {
      ...formData,
      [name]: value,
    };
    setFormData(updatedData);
    props.sendJobDetail(updatedData);
  };

  return (
    <div className="form-card">
      <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
      <div style={{ color: 'red' }}>{props.error !== '' ? `${t('forms.formsquestion.toast.completeSection')}` : ''}</div>
        <p className='text-bold text-center'>{t("forms.title.jobdetail")}</p>
        <div className='row p-2'>
          <p className="col-sm-4 col-form-label">{t("forms.formsquestion.jobOffer.dateOfIssued")}</p>
          <div className="col-sm-8">
            <input 
              type="date" 
              className="form-control rounded-pill flous-input-color" 
              name="dateOfIssued"
              value={formData.dateOfIssued} 
              onChange={handleChange} max={new Date().toISOString().split("T")[0]}
            />
          </div>
        </div>
        <div className='row p-2'>
        <p className="col-sm-10 col-form-label">{t("forms.formsquestion.jobOffer.currentJob")}<RequiredIndicator /></p>
        <div className="col-sm-2">
          <div className='row'>
            <div className="col form-check">
              <input 
                type="radio"
                className="form-check-input"
                id="currentJobYes"
                name="currentJob"
                value="Yes"
                onChange={handleChange}
                checked={formData.currentJob === "Yes"}
              />
              <label className="form-check-label" htmlFor="currentJobYes">{t("forms.click.yes")}</label>
            </div>
            <div className="col form-check">
              <input 
                type="radio"
                className="form-check-input"
                id="currentJobNo"
                name="currentJob"
                value="No"
                onChange={handleChange}
                checked={formData.currentJob === "No"}
              />
              <label className="form-check-label" htmlFor="currentJobNo">{t("forms.click.no")}</label>
            </div>
          </div>
        </div>
      </div>
      <div className='row p-2'>
        <p className="col-sm-10 col-form-label">{t("forms.formsquestion.jobOffer.lmiaExempt")}<RequiredIndicator /></p>
        <div className="col-sm-2">
          <div className='row'>
            <div className="col form-check">
              <input 
                type="radio"
                className="form-check-input"
                id="lmiaExemptYes"
                name="lmiaExempt"
                value="Yes"
                onChange={handleChange}
                checked={formData.lmiaExempt === "Yes"}
              />
              <label className="form-check-label" htmlFor="lmiaExemptYes">{t("forms.click.yes")}</label>
            </div>
            <div className="col form-check">
              <input 
                type="radio"
                className="form-check-input"
                id="lmiaExemptNo"
                name="lmiaExempt"
                value="No"
                onChange={handleChange}
                checked={formData.lmiaExempt === "No"}
              />
              <label className="form-check-label" htmlFor="lmiaExemptNo">{t("forms.click.no")}</label>
            </div>
          </div>
        </div>
      </div>
      <div className='row p-2'>
        <p className="col-sm-10 col-form-label">{t("forms.formsquestion.jobOffer.provinceValidated")}<RequiredIndicator /></p>
        <div className="col-sm-2">
          <div className='row'>
            <div className="col form-check">
              <input 
                type="radio"
                className="form-check-input"
                id="provinceValidatedYes"
                name="provinceValidated"
                value="Yes"
                onChange={handleChange}
                checked={formData.provinceValidated === "Yes"}
              />
              <label className="form-check-label" htmlFor="provinceValidatedYes">{t("forms.click.yes")}</label>
            </div>
            <div className="col form-check">
              <input 
                type="radio"
                className="form-check-input"
                id="provinceValidatedNo"
                name="provinceValidated"
                value="No"
                onChange={handleChange}
                checked={formData.provinceValidated === "No"}
              />
              <label className="form-check-label" htmlFor="profinceValidatedNo">{t("forms.click.no")}</label>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
};

export default JobDetailJOSection;
