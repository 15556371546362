import React, { useState } from 'react';
import { usePrincipalFormStates } from './../data/principalStates';
import { usePartnerFormStates } from '../data/partnerStates';
import { saveFormDataStructure } from '../../../../services/users/FormsServices';
import { toast, ToastContainer } from 'react-toastify';
import LoadingOverlay from '../../../general/LoadingOverlay';
import GoToBackButton from '../../../general/GoBackLink';

const DataFileUpload = () => {
  
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [evaluationCountry, setevaluationCountry] = useState("Canada");
  const [formTitle, setFormTitle] = useState("");
  const [isSameResidence, setIsSameResidence] = useState({
    isSamecurrentResidence: '',
    isSamepreviousAddress: ''
  });
  const [isSameEExperience, setIsSameEExperience] = useState('');
  const [isCoApplication, setIsCoApplication] = useState(false);

  const principalFormStates = usePrincipalFormStates();
  const partnerFormStates = usePartnerFormStates();


  const handleSavedData = async () =>{

    try {
      
      const data = {
        isCoApplication: isCoApplication,
        evaluationCountry: evaluationCountry,
        formTitle:formTitle,
        principalApplicantData: principalFormStates,
        principalApplicantPartnerData: partnerFormStates,
        isSameEExperience: isSameEExperience,
        isSameResidence: isSameResidence,
      }

      const response = await saveFormDataStructure({ 
        data: data,
      });
      if (response){
        setLoading(false);
        toast.success(response.message);
      }

    } catch (error) {
      setLoading(false);
      if (error.response){
        toast.error(error.response.error);
      }
    }

  }

  if (loading) {
    return <LoadingOverlay />;
  }

  if (error) {
    return <div>Error : {error.message}</div>;
  }


  return (
    <div className='text-center mt-5'>
      <div className="content-header">
        <div className="mb-2 mt-2">
          <GoToBackButton />
        </div>
      </div>
      <h2>Upload Principal JSON data</h2>
      <button className='btn flous-btn-primary' onClick={handleSavedData}>Update Data Structure</button>
      <ToastContainer />
    </div>
  );
};

export default DataFileUpload;
