import React, { useState, useEffect, } from 'react';
import CountrySelect from '../../../../../general/CountrySelect';
import ProvinceSelect from '../../../../../general/ProvinceSelect';
import { countriesWithProvinces, provincesWithCities } from '../../../../../../services/utils';
import CitySelect from '../../../../../general/CitySelect';
import RequiredIndicator from '../../../../../general/requiredIndicator';

import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const CompanyDetailsYesSection = (props) => {
  const [formData, setFormData] = useState(props.companyDetails);
  const { t } = useTranslation();
  // const [allVisitedCities, setAllVisitedCities] = useState(props.companyDetails.visitList);
  const [addClicked, setAddClicked] = useState(false);
/*
  useEffect(() => {
    const updatedFormData = {
      ...formData,
      country: 'CAN',
    };
    setFormData(updatedFormData);
    props.sendCompanyDetails(updatedFormData);
  }, [props.ownership]); */

  const handleChange = (event) => {
    const { name, value } = event.target;
    const updatedFormData = {
      ...formData,
      [name]: value,
    };
    setFormData(updatedFormData);
    props.sendCompanyDetails(updatedFormData);
  };

  const handleCountryChange = (e) =>{
    const { value } = e.target;
    const updatedFormData = {
      ...formData,
      country: value,
    };
    setFormData(updatedFormData);
    props.sendCompanyDetails(updatedFormData);
  };

  const handleProvinceStateChange = (e) =>{
    const { value } = e.target;
    const updatedFormData = {
      ...formData,
      provinceState: value,
    };
    setFormData(updatedFormData);
    props.sendCompanyDetails(updatedFormData);
  };

  const handleCityChange = (e) =>{
    const { value } = e.target;
    const updatedFormData = {
      ...formData,
      city: value,
    };
    setFormData(updatedFormData);
    props.sendCompanyDetails(updatedFormData);
  };

  const handleAllVisitedCities = () =>{
    if (formData.visitDate !== '' && formData.visitedCity !== ''){
      const data = {
        date: formData.visitDate,
        city: formData.visitedCity
      }
      const updatedFormData = {
        ...formData,
        visitList: [...formData.visitList, data],
        visitDate: '',
        visitedCity: ''
      };
      setFormData(updatedFormData);
      props.sendCompanyDetails(updatedFormData);
      setAddClicked(false);
    }
    else{
      toast.error("You must provide the date of visit and the city visited !")
    }
  }

  return (
    <div className="form-card">
      <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
      <div style={{ color: 'red' }}>{props.error !== '' ? `${t('forms.formsquestion.toast.completeSection')}` : ''}</div>
        <p className='text-bold text-center'>{t("forms.title.managementexperience")}</p>
        {props.showEstablishmentDate && (
        <div className='row p-2'>
          <p className="col-sm-6 col-form-label">{t("forms.formsquestion.entrepreunarialExperience.establishmentDate")}<RequiredIndicator /></p>
          <div className="col-sm-6">
            <input 
              type="date" 
              className="form-control rounded-pill flous-input-color"
              name="establishmentDate"
              value={formData.establishmentDate}
              onChange={handleChange} max={new Date().toISOString().split("T")[0]}
            />
          </div>
        </div>
        )}
        <p className='p-2'>{t("forms.formsquestion.entrepreunarialExperience.address")}</p>
        {props.existingCompany && (<div className='row p-2'>
          <p htmlFor="country" className="col-sm-6 col-form-label">{t("forms.formsquestion.entrepreunarialExperience.country")}</p>
          <div className="col-sm-6">
            <CountrySelect value={formData.country} onChange={handleCountryChange} required />
          </div>
        </div>)}
        <div className='row p-2'>
          <p htmlFor="provinceState" className="col-sm-6 col-form-label">{t("forms.formsquestion.entrepreunarialExperience.provinceState")}</p>
          <div className="col-sm-6">
            {countriesWithProvinces.includes(formData.country) ? 
              (<ProvinceSelect 
                value={formData.provinceState} 
                onChange={handleProvinceStateChange} 
                country={formData.country} required
                />
              ):
              (<input 
                type="text" 
                className="form-control rounded-pill flous-input-color" 
                id="provinceState" 
                name="provinceState" 
                value={formData.provinceState} 
                onChange={handleChange} 
                placeholder={`${t('forms.formsquestion.placeholder.province')}`}
              />
              )}
          </div>
        </div>
        <div className='row p-2'>
          <p htmlFor="city" className="col-sm-6 col-form-label">{t("forms.formsquestion.entrepreunarialExperience.city")}<RequiredIndicator /></p>
          <div className="col-sm-6">
            {countriesWithProvinces.includes(formData.country) ? 
              (<CitySelect 
                value={formData.city} 
                onChange={handleCityChange} 
                province={formData.provinceState} 
                required
                />
              ):
              (
                <input 
                type="text" 
                className="form-control rounded-pill flous-input-color" 
                id="city" 
                name="city" 
                value={formData.city} 
                onChange={handleChange} 
                placeholder={`${t('forms.formsquestion.placeholder.city')}`}
              />
              )}
          </div>
        </div>
        <div className='row p-2'>
          <p htmlFor="streetNumber" className="col-sm-6 col-form-label">{t("forms.formsquestion.entrepreunarialExperience.streetNumber")}</p>
          <div className="col-sm-6">
            <input 
              type="text" 
              className="form-control rounded-pill flous-input-color" 
              id="streetNumber" 
              name="streetNumber" 
              value={formData.streetNumber} 
              onChange={handleChange} 
              placeholder={`${t('forms.formsquestion.placeholder.streetNumber')}`}
            />
          </div>
        </div>
        <div className='row p-2'>
          <p htmlFor="postalCode" className="col-sm-6 col-form-label">{t("forms.formsquestion.entrepreunarialExperience.postalCode")}</p>
          <div className="col-sm-6">
            <input 
              type="text" 
              className="form-control rounded-pill flous-input-color" 
              id="postalCode" 
              name="postalCode" 
              value={formData.postalCode} 
              onChange={handleChange} 
              placeholder={`${t('forms.formsquestion.placeholder.codePostal')}`}
            />
          </div>
        </div>
        {formData.provinceState === 'BC' && (
        <>
          <div className='row p-2'>
            <p htmlFor="haveVisitBC" className="col-sm-10 col-form-label">{t("forms.formsquestion.entrepreunarialExperience.haveVisitBC")}<RequiredIndicator /></p>
            <div className="col-sm-2 row">
              <div className="form-check col">
                <input 
                  type="radio"
                  className="form-check-input"
                  name="haveVisitBC"
                  value="Yes"
                  onChange={handleChange}
                  checked={formData.haveVisitBC === "Yes"}
                />
                <p className="form-check-label">{t("forms.click.yes")}</p>
              </div>
              <div className="form-check col">
                <input 
                  type="radio"
                  className="form-check-input"
                  name="haveVisitBC"
                  value="No"
                  onChange={handleChange}
                  checked={formData.haveVisitBC === "No"}
                />
                <p className="form-check-label">{t("forms.click.no")}</p>
              </div>
            </div>
          </div>
          
          {formData.haveVisitBC === 'Yes' && (
            <>
            <div>
              <div className='card-header p-1 mb-1 shadow bg-white' style={{ borderRadius: '15px 15px 0px 0px' }}>
                <div className='row'>
                  <div className='col-sm col-6 mt-2 text-center'>City</div>
                  <div className='col-sm col-6 mt-2 text-center'>Date</div>
                  <div className='col-sm col-6 mt-2 text-center'>Action</div>
                </div>
              </div>
              <div>
                {formData.visitList.length > 0 ? (
                  formData.visitList.map((visitedCity, index) => (
                    <div className='card-header p-1 mb-1 shadow bg-white' style={{ borderRadius: '15px' }} key={index}>
                      <div className='row'>
                        <div className='col-sm col-6 mt-2 text-center'>{visitedCity.city}</div>
                        <div className='col-sm col-6 mt-2 text-center'>{visitedCity.date}</div>
                        <div className='col-sm col-6 mt-2 text-center'>
                          <button
                            className="btn btn-secondary"
                            type='button'
                            onClick={() => {
                              setFormData(prevData => ({
                                ...prevData,
                                visitList: prevData.visitList.filter((_, i) => i !== index),
                              }));
                            }}
                          >
                            <i className="nav-icon fas fa-trash"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className='text-center'>No visited city added yet !</div>
                )}
              
                {!addClicked &&
                  <div className='mt-3'>
                      <button type='button' className='btn btn-block btn-secondary rounded-pill' onClick={()=>{setAddClicked(true)}}>Add City</button>
                  </div>
                }
              </div>
            </div>  
            
          {addClicked && (
            <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
              <div className='row p-2'>
                <p htmlFor="visitedCity" className="col-sm-6 col-form-label">{t("forms.formsquestion.entrepreunarialExperience.visitedCity")}<RequiredIndicator /></p>
                <div className="col-sm-6">
                  <CitySelect 
                  value={formData.visitedCity} 
                  name='visitedCity'
                  onChange={handleChange} 
                  province={formData.provinceState} 
                  required
                  />
                </div>
              </div>
              <div className='row p-2'>
                <p htmlFor="visitDate" className="col-sm-6 col-form-label">{t("forms.formsquestion.entrepreunarialExperience.visitDate")}<RequiredIndicator /></p>
                <div className="col-sm-6">
                  <input 
                    type="date" 
                    className="form-control rounded-pill flous-input-color" 
                    id="visitDate" 
                    name="visitDate" 
                    value={formData.visitDate} 
                    onChange={handleChange} max={new Date().toISOString().split("T")[0]}
                    placeholder=""
                  />
                </div>
              </div> 
              
              <div className='d-flex justify-content-center mb-3'>
                <div className='col-sm-10'>
                  <div className='d-flex justify-content-between'>
                    <input type="button" className="col-6 btn flous-btn-gradient rounded-pill mx-1" value="Save city" onClick={handleAllVisitedCities}/>
                    <input type="button" className="col-6 btn btn-block btn-secondary rounded-pill mx-1" value="Cancel" onClick={()=>{setAddClicked(false)}}/>
                  </div>
                </div>
              </div>
            </div>
          )}
          </>)}
        </>) }
      </div>
    </div>
  );
};

export default CompanyDetailsYesSection;