import React from 'react';
import { Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';

const styles = StyleSheet.create({
  page: {
    padding: 20,
    backgroundColor: '#ffffff',
  },
  mainTitle: {
    fontSize: 20,
    fontFamily: 'Helvetica-Bold',
    color: '#0066CC',
    textAlign: 'center',
    marginBottom: 20,
  },
  card: {
    backgroundColor: '#f8f9ff',
    borderRadius: 8,
    padding: 16,
    paddingBottom: 5,
    marginBottom: 10,
    width: '100%',
  },
  row: {
    flexDirection: 'row',
    marginBottom: 12,
    gap: 12,
  },
  labelColumn: {
    width: '70%',
  },
  valueColumn: {
    width: '30%',
  },
  label: {
    fontSize: 10,
    color: '#000000',
    fontFamily: 'Helvetica',
  },
  value: {
    fontSize: 10,
    padding: '6 8',
    backgroundColor: '#ffffff',
    borderRadius: 4,
    minHeight: 28,
    border: '1px solid #e2e8f0',
    fontFamily: 'Helvetica',
  },
});

export default function ProfessionalExpertiseResumeSectionPDF(props) {
  const { t } = useTranslation();

  const professionalExpertise = {
    workedInSameProfession: props.professionalExpertise.professionalExpertise.workedSameProfession,
    exceptionalExpertise: props.professionalExpertise.professionalExpertise.exceptionalExpertise,
    notableAchievements: props.professionalExpertise.notableAchievements.recognition,
    impactsOfExpertise: props.professionalExpertise.impactofExpertise.impact
  };

  return (
    <Page style={styles.page}>
      <Text style={styles.mainTitle}>{t("client.results.blockTitle.bloc7")}</Text>
      
      <View style={styles.card}>
        <View style={styles.row}>
          <View style={styles.labelColumn}>
            <Text style={styles.label}>{t("forms.formsquestion.professionalExpertise.workedSameProfession")}</Text>
          </View>
          <View style={styles.valueColumn}>
            <Text style={styles.value}>
              {professionalExpertise.workedInSameProfession === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}
            </Text>
          </View>
        </View>

        <View style={styles.row}>
          <View style={styles.labelColumn}>
            <Text style={styles.label}>{t("forms.formsquestion.professionalExpertise.exceptionalExpertise")}</Text>
          </View>
          <View style={styles.valueColumn}>
            <Text style={styles.value}>
              {professionalExpertise.exceptionalExpertise === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}
            </Text>
          </View>
        </View>

        {props.professionalExpertise.professionalExpertise.exceptionalExpertise === 'Yes' && (
          <>
            <View style={styles.row}>
              <View style={styles.labelColumn}>
                <Text style={styles.label}>{t("forms.formsquestion.professionalExpertise.notableAchievements")}</Text>
              </View>
              <View style={styles.valueColumn}>
                <Text style={styles.value}>
                  {professionalExpertise.notableAchievements === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}
                </Text>
              </View>
            </View>

            {props.professionalExpertise.notableAchievements.recognition === 'Yes' && (
              <View style={styles.row}>
                <View style={styles.labelColumn}>
                  <Text style={styles.label}>{t("forms.formsquestion.professionalExpertise.impactofExpertise")}</Text>
                </View>
                <View style={styles.valueColumn}>
                  <Text style={styles.value}>
                    {professionalExpertise.impactsOfExpertise === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}
                  </Text>
                </View>
              </View>
            )}
          </>
        )}
      </View>
    </Page>
  );
}

