import React, {useEffect, useState} from 'react';
import AgentCustomersFormItem from './item/AgentCustomersFormItem';
import PaginationSection from '../Layout/PaginationSection';
import { getCurrentItems } from '../../services/users/FormsServices';
import AgentCustomersResultsItem from './item/AgentCustomersResultsItem';
import NoItemsMessage from '../general/NoItemsToDisplaysItem';
import { useNavigate, useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import LoadingOverlay from '../general/LoadingOverlay';
import { viewMoreAdvisorCustomer, createClientNewForm } from '../../services/agents/CustomersServices';
import { languageConverter } from '../../services/utils';
import GoToBackButton from '../general/GoBackLink';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

export default function AgentCustomerDetail() {

  const [customer, setCustomer] = useState({});
  const [forms, setForms] = useState([]);
  const [evaluations, setEvaluations] = useState([]);

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const {advisorCode, customerCode} = useParams();
  const { t } = useTranslation();

  const formsStates = [
    {abbr: 'AB', name: 'ABANDONED'},
    {abbr: 'CR', name: 'CREATED'},
    {abbr: 'IP', name: 'IN PROGRESS'},
    {abbr: 'CP', name: 'COMPLETETD'},
    {abbr: 'EV', name: 'EVALUATED'},
  ]

  const handleCreateNewForm = async () =>{
    setLoading(true);
    try {

      const response = await createClientNewForm('Canada', customerCode);

      if (response && response.status === 200){
        setLoading(false);
        window.location.reload();
      }
    }catch (error) {
      setLoading(false);
      if (error.response && [400, 500].includes(error.response.status)){
        toast.error(error.response.data.error);
      }
    }

  }

  useEffect(()=>{

    const handleViewMore = async() => {
      setLoading(true);
      try{
        const response = await viewMoreAdvisorCustomer(advisorCode, customerCode);
        
        if (response.status === 200){
          console.log(response.data)
          setCustomer(response.data.customer_data);
          setForms(response.data.raw_data);
          setEvaluations(response.data.app_data);
        }
        
        setLoading(false);
  
      }  catch (error){
        setLoading(false);
        if (error.response && [400, 500].includes(error.response.status)){
          navigate(`/agent/customers`, {replace: true});
          toast.error(error.response.data.error);
        }
      }
    };

    handleViewMore();
  }, [navigate]);

  const [page, setPage] = useState(1);
  const pageSize = 5;
  const onPageChange = (page) => {
    setPage(page + 1);
  };
  
  const currentFormsItems = getCurrentItems(forms, page, pageSize);

  const [pageResults, setPageResults] = useState(1);
  const pageSizeResults = 5;
  const onPageChangeResults = (page) => {
    setPageResults(page + 1);
  };
  
  const currentEvaluationsResults = getCurrentItems(evaluations, pageResults, pageSizeResults);

  if (loading) {
      return <LoadingOverlay />;
  }

  return (
    <>
      {/* Content Header (Page header) */}
      <div className="content-header">
        <div className="container-fluid">
          <div className="mb-2">
            <GoToBackButton />
            <div className="col-sm-6">
              <h1 className="m-0 flous-police-one flous-input-color">{t("advisor.customers.detail.title")}</h1>
            </div>
          </div>
        </div>
      </div>
      {/* /.content-header */}

      {/* Main content */}
      <section className="content">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col">
              <div className="col">
                <div className="row">
                  <span onClick={handleCreateNewForm} className="btn btn-lg flous-bg-primary mx-2 col-lg-6 text-bold">
                  {t("advisor.customers.detail.create")} <i className="nav-icon fas fa-edit"></i>
                  </span>
                </div>
              </div>
              
              <br />
              <div className="card elevation-2">
                <div className="card-body">
                  <div className="row">
                    
                    <div className="col-md-3">
                      {/* Profile Image */}
                      <div className="card card-primary elevation-2" style={{ borderRadius: '15px' }}>
                        <div className="card-body box-profile">
                          <div className="d-flex justify-content-center">
                            <div className="image">
                              {customer.profilePictureUrl ? (
                                <>
                                <img src={`${process.env.PUBLIC_URL}/${customer.profilePictureUrl}`} className="border rounded-circle" alt="User Profile" width="150" height="150"/>
                                </>
                              ):(
                                <>
                                <img src={`${process.env.PUBLIC_URL}/avatar/avatar_2.png`} className="border rounded-circle" alt="User Profile" width="150" height="150"/>
                                </>
                              )}
                            </div>
                          </div>
                          <h3 className="profile-username text-center text-bold">{customer.firstName} {customer.lastName}</h3>
                          <p className="text-center">{customer.role === 'Customer'? 'Client' : (customer.role === 'AssociateAdmin'? 'Administrator' : customer.role)}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col m-3">
                      <h4 className='mx-3 flous-input-color mb-3'>{t("advisor.customers.detail.contact")}</h4>
                      <div className='col-sm-5 btn card'>
                        <div className='row'>
                          <div className='col-4 text-bold text-left'>{t("advisor.customers.detail.language")}: </div>
                          <div className='col text-left'>{customer.communicationLanguage !== 'Undefined' ? languageConverter(customer.communicationLanguage): 'Undefined'}</div>
                        </div>
                      </div>
                      <div className='col-sm-5 btn card'>
                        <div className='row'>
                          <div className='col-4 text-bold text-left'>{t("advisor.customers.detail.phone")}: </div>
                          <div className='col text-left'>{customer.phone}</div>
                        </div>
                      </div>
                      <div className='col-sm-5 btn card'>
                        <div className='row'>
                          <div className='col-4 text-bold text-left'>{t("advisor.customers.detail.email")}: </div>
                          <div className='col text-left'>{customer.email}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card elevation-2" style={{ borderRadius: '15px' }}>
                <div className="card-header">
                <h4 className='flous-police-one flous-input-color'>{t("advisor.customers.detail.form")}</h4>
                <form>
                    <div className="row">
                      {/*<div className="col-sm col-6">
                        <CountrySelect required />  
                      </div> */}
                      <div className="col-sm col-6">
                        <div className="form-group">
                          <Select
                            name="status"
                            classNamePrefix="react-select"
                            options={formsStates.map(state => ({ value: state.abbr, label: state.name }))}
                            placeholder={`${t('forms.formsquestion.placeholder.option')}`}
                            isSearchable={false}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                borderRadius: '20px',
                                borderColor: '#ccc',
                              }),
                            }}
                          />
                        </div>
                      </div>
                      <div className='col-sm-1 col-6 btn flous-input-color'><i className="nav-icon fas fa-search"></i> Search</div>
                      <div className='col-sm-1 col-6 btn flous-input-color'><i className="nav-icon fas fa-sort"></i> Sort</div>
                    </div>
                </form>
                </div>
                <div className="card-body p-2">
                  <div className='flous-bg-thirty m-2' style={{ borderRadius: '15px' }}>
                      <div className='card-header p-3 mb-3 shadow bg-white' style={{ borderRadius: '15px 15px 0px 0px' }}>
                          <div className='row text-bold'>
                            <div className='col-sm col-6 mt-2'>{t("general.table.country")}</div>
                            <div className='col-sm col-6 mt-2 text-center'>{t("general.table.date")}</div>
                            <div className='col-sm col-6 mt-2 text-center'>{t("general.table.title")}</div>
                            <div className='col-sm col-6 mt-2 text-center'>{t("general.table.status")}</div>
                            <div className='col-sm-4 col-12 mt-2 text-center'>{t("general.table.actions")}</div>
                          </div>
                      </div>
                      {currentFormsItems.length > 0 ? (
                        currentFormsItems.map((evaluation) => (
                        <AgentCustomersFormItem userType={"agent"} key={evaluation.id} item={evaluation} />
                        ))
                      ) : (
                        <NoItemsMessage />
                      )}
                  </div>
                </div>
                <PaginationSection
                  page={page}
                  pageSize={pageSize}
                  totalItems={forms.length}
                  onPageChange={onPageChange}
                />
              </div>
              <div className="card elevation-2" style={{ borderRadius: '15px' }}>
                <div className="card-header">
                <h4 className='flous-police-one flous-input-color'>{t("advisor.customers.detail.result")}</h4>
                <form action="" method="get">
                    <div className="row">
                      {/*<div className="col-sm col-12">
                        <CountrySelect required />
                      </div>
                      <div className='col-sm-1 col-6 btn flous-input-color'><i className="nav-icon fas fa-search"></i> Search</div>*/}
                      <div className='col-sm-11 col-12 btn flous-input-color text-right'><i className="nav-icon fas fa-sort"></i> Sort</div>
                    </div>
                    {/* @csrf - React doesn't use CSRF tokens like Laravel, typically handled by session or headers */}
                </form>
                </div>
                <div className="card-body p-2">
                  <div className='flous-bg-thirty m-2' style={{ borderRadius: '15px' }}>
                      <div className='card-header p-3 mb-3 shadow bg-white' style={{ borderRadius: '15px 15px 0px 0px' }}>
                          <div className='row text-bold'>
                              <div className='col-sm col-6 mt-2'>{t("general.table.country")}</div>
                              <div className='col-sm col-6 mt-2 text-center'>{t("general.table.date")}</div>
                              <div className='col-sm col-6 mt-2 text-center'>{t("general.table.title")}</div>
                              <div className='col-sm col-6 mt-2 text-center'>{t("general.table.actions")}</div>
                          </div>
                      </div>
                      {currentEvaluationsResults.length > 0 ? (
                        currentEvaluationsResults.map((evaluation) => (
                        <AgentCustomersResultsItem userType={'agent'} key={evaluation.id} item={evaluation} />
                        ))
                      ) : (
                        <NoItemsMessage />
                      )}
                  </div>
                </div>
                <PaginationSection
                  page={pageResults}
                  pageSize={pageSizeResults}
                  totalItems={evaluations.length}
                  onPageChange={onPageChangeResults}
                />
              </div>
            </div>
          </div>
          {/* /.row */}
        </div>
        <ToastContainer />
      </section>
    </>
  );
};