import React, { useState, useEffect } from 'react';
import RequiredIndicator from '../../../../general/requiredIndicator';

import { useTranslation } from "react-i18next";

const EquivalencyApplicationStatusSection = (props) => {
  const [applicationProcessed, setApplicationProcessed] = useState(props.applicationProcessed);
  const { t } = useTranslation();

  const handleChange = (event) => {
    const value = event.target.value;
    setApplicationProcessed(value);
    props.sendApplicationProcessed(value);
  };

  return (
    <div className="form-card">
      <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
      {props.error && (
        <>
            <p style={{ marginTop: '20px', padding: '10px', color: 'red'}}>
                {props.error}
            </p>
        </>
      )}
      <p className='text-bold text-center'>{t("forms.title.equivalent")}</p>
        <div className='row p-2'>
          <p htmlFor="applicationProcessed" className="col-form-label col-sm-10">{t("forms.formsquestion.educationInCountry.applicationProcessed")}<RequiredIndicator /></p>
        <div className='col-sm-2'>
          <div className='row'>
            <div className="col form-check">
              <input 
                type="radio"
                className="form-check-input" 
                name="applicationProcessed"
                value="Yes"
                onChange={handleChange}
                checked={applicationProcessed === "Yes"}
              />
              <p htmlFor="applicationProcessedYes" className="form-check-p">{t("forms.click.yes")}</p>
            </div>
            <div className="col form-check">
              <input 
                type="radio"
                className="form-check-input" 
                name="applicationProcessed"
                value="No"
                onChange={handleChange}
                checked={applicationProcessed === "No"}
              />
              <p htmlFor="applicationProcessedNo" className="form-check-p">{t("forms.click.no")}</p>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EquivalencyApplicationStatusSection;