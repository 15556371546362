import React, { useState, useEffect } from 'react';
import PaginationSection from '../Layout/PaginationSection';
import { getCurrentItems } from '../../services/users/FormsServices';
import CreateNewCustomersModal from './modal/createNewCustomersModal';
import EnterpriseCustomersItem from './item/EnterpriseCustomersItem';
import { fetchCustomersData, fetchFilteredCustomers } from '../../services/enterprise/CustomersServices';
import { useOutletContext } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import LoadingOverlay from '../general/LoadingOverlay';
import { EnterpriseCustomersNationalities, EnterpriseCustomersStates } from '../../services/general/DashbordServices';
import { fetchAdvisorsData } from '../../services/enterprise/AdvisorsServices';
import FlagComponent from '../general/FlagComponent';
import InfoBoxPrimaryLink from '../general/InfoBoxPrimaryLink';
import InfoBoxThirtyLink from '../general/InfoBoxThirtyLink';
import NoItemsMessage from '../general/NoItemsToDisplaysItem';
import GoToBackButton from '../general/GoBackLink';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import InfoBoxPrimary from '../general/InfoBoxPrimary';
import InfoBoxThirty from '../general/InfoBoxThirty';

export default function EntrepriseCustomersList() {
  
  const [formsData, setFormsData] = useState({
    formsInProgress: 0,
    formsCompleted: 0,
    formsInactive: 0,
    totalActiveCustomers: 0
  });

  const {user} = useOutletContext();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [customers, setCustomers] = useState([]);
  const [advisors, setAdvisors] = useState([]);
  const [nationalities, setNationalities] = useState([]);

  const [code, setCode] = useState('N.A');
  const [country, setCountry] = useState('N.A');
  const { t } = useTranslation();

  const handleCodeChange = (e) =>{
    setCode(e.target.value);
  }

  const handleCountryChange = (e) =>{
    setCountry(e.target.value);
  }
  
  useEffect( () => {
    let isMounted = true;

    const getCustomersNationalities = async () => {
      if (user.id) {
        try {
          const response = await EnterpriseCustomersNationalities();
          if (isMounted) {
            if (response && response.status === 200){
              const nationalities = response.data.data.map(nationality => ({
                value: nationality.country_code,
                label: <FlagComponent cca3={nationality.country_code} />
              }))
              setNationalities(nationalities);
              setLoading(false);
              isMounted = false;
            }
          }
        } catch (error) {
          if (isMounted){
            setError(error.response.data.error);
            setLoading(false);
            if (error.response && [400, 500].includes(error.response.status)){
              toast.error(error.response.data.error);
              isMounted = false;
            }
          }
        }
      }
    };

    getCustomersNationalities();

    return () => {
      isMounted = false;
    };

  }, []);
  
  useEffect( () => {
    let isMounted = true;

    const getAdvisorsDataList = async () => {
      if (user.id) {
        try {
          const response = await fetchAdvisorsData();
          if (isMounted) {
            if (response && response.status === 200){
              const advisors = response.data.data.map(advisor => ({
                value: advisor.code,
                label: `${advisor.firstName} ${advisor.lastName}`
              }))
              setAdvisors(advisors);
              setLoading(false);
              isMounted = false;
            }
          }
        } catch (error) {
          if (isMounted){
            setError(error.response.data.error);
            setLoading(false);
            if (error.response && [400, 500].includes(error.response.status)){
              toast.error(error.response.data.error);
              isMounted = false;
            }
          }
        }
      }
    };

    getAdvisorsDataList();

    return () => {
      isMounted = false;
    };

  }, []);
  
  useEffect( () => {
    let isMounted = true;
  
    const getEnterpriseCustomersStates = async () => {
      if (user.id) {
        try {
          const response = await EnterpriseCustomersStates();
          if (isMounted) {
            if (response && response.status === 200){
              setFormsData(response.data.data);
              setLoading(false);
              isMounted = false;
            }
          }
        } catch (error) {
          if (isMounted){
            setError(error.response.data.error);
            setLoading(false);
            if (error.response && [400, 500].includes(error.response.status)){
              toast.error(error.response.data.error);
              isMounted = false;
            }
          }
        }
      }
    };
  
    getEnterpriseCustomersStates();
  
    return () => {
      isMounted = false;
    };
  
  }, [user]);

  useEffect( () => {
    let isMounted = true;

    const getAdvisorsDataList = async () => {
      if (user.id) {
        try {
          const response = await fetchCustomersData();
          if (isMounted) {
            setCustomers(response.data.data);
            setLoading(false);
          }
        } catch (error) {
          if (isMounted){
            setError(error);
            setLoading(false);
            throw error;
          }
        }
      }
    };

    getAdvisorsDataList();

    return () => {
      isMounted = false;
    };

  }, [user]);

  const [page, setPage] = useState(1);
  const pageSize = 5;
  const onPageChange = (page) => {
    setPage(page + 1);
  };

  const handleFilteredCustomers = async () => {
    try {
      const response = await fetchFilteredCustomers(code, country);
      if (response && response.status===200) {
        setCustomers(response.data.data);
        setLoading(false);
      }
    }
    catch (error) {
      if (error.response && [400, 500].includes(error.response.status)){
        setError(error.response.data.error);
        toast.error(error.response.data.error);
      }
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const queryParams = new URLSearchParams({
      code: code || '',
      country: country || ''
    }).toString();
    filterItems();
    //console.log(queryParams);
  };

  const filterItems = async () => {
    let isMounted = true;
    if (user.id) {
        try {
          const response = await fetchCustomersData();
          if (isMounted) {
            if (country === 'N.A' && code === 'N.A') {
              setCustomers(response.data.data);
              setLoading(false);
              return;
            }
            const filteredItems = response.data.data.filter((item) => {
              const matchesCountry = country && country !== 'N.A' ? item.country_cca3 === country : true;
              const matchesAdvisor = code && code !== 'N.A' ? item.advisor === code : true;
              return matchesCountry && matchesAdvisor;
            });
            setCustomers(filteredItems);
            setLoading(false);
          }
        } catch (error) {
          // console.error('Error when preparing data:', error);
          if (isMounted){
            setError(error);
            setLoading(false);
            throw error;
          }
        }
      }
  };
  
  const currentItems = getCurrentItems(customers, page, pageSize);

  if (loading) {
    return <LoadingOverlay />;
  }

  if (error) {
    return <div>Error : {error}</div>;
  }

  return (
    <div className="content">
      <div className="container-fluid">
        {/* Content Header */}
        <div className="content-header">
          <div className="mb-2">
          <GoToBackButton />
          <div className="col-sm-6">
              <h1 className="m-0 flous-police-one flous-input-color">{t("enterprise.customers.title")}</h1>
            </div>
          </div>
        </div>
        {/* /.content-header */}

        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col">
                <div className="row mb-2">
                  {/* Bouton pour ouvrir la modal */}
                  <button type="button" className="btn btn-lg flous-bg-menu mx-2 col-md-6 col-12" data-toggle="modal" data-target="#newCustomers">
                  {t("enterprise.customers.create")} <i className="nav-icon fas fa-user"></i>
                  </button>
                  <CreateNewCustomersModal />
                </div>
                <div className='card p-2 px-5 elevation-2 mb-5 mt-3' style={{ borderRadius: '15px' }}>
                  <div className="row">
                    <div className="col-lg-3 col-6">
                      <InfoBoxPrimary
                        value={formsData.formsInProgress}
                        text={t("enterprise.customers.infobox.one")}
                        link={t("enterprise.dashboard.infobox.link")}
                        href="#"
                      />
                    </div>
                    <div className="col-lg-3 col-6">
                      <InfoBoxThirty
                        value={formsData.formsCompleted}
                        text={t("enterprise.customers.infobox.two")}
                        link={t("enterprise.dashboard.infobox.link")}
                        href="#"
                      />
                    </div>
                    <div className="col-lg-3 col-6">
                      <InfoBoxPrimary
                        value={formsData.totalActiveCustomers}
                        text={t("enterprise.customers.infobox.three")}
                        link={t("enterprise.dashboard.infobox.link")}
                        href="#"
                      />
                    </div>
                    <div className="col-lg-3 col-6">
                      <InfoBoxThirty
                        value={formsData.formsInactive}
                        text={t("enterprise.customers.infobox.four")}
                        link={t("enterprise.dashboard.infobox.link")}
                        href="#"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="card elevation-2">
                      <div className="card-header">
                        <form onSubmit={handleSubmit}>
                          <div className="row">
                            <div className="col-sm col-12">
                              <div className="form-group">
                              <Select
                                name="code"
                                id="code"
                                classNamePrefix="react-select"
                                value={advisors.find(option => option.value === code)}
                                onChange={(selectedOption) =>
                                  handleCodeChange({ target: { name: 'code', value: selectedOption?.value || 'N.A' } })
                                }
                                options={advisors}
                                placeholder="Select Advisor"
                                isSearchable
                                isClearable
                                styles={{
                                  control: (provided) => ({
                                    ...provided,
                                    width: '100%',
                                    borderRadius: '20px', // Ajustez la valeur pour l'arrondissement
                                    borderColor: '#ccc',  // Vous pouvez également ajuster la couleur de la bordure si nécessaire
                                  }),
                                }}
                              />
                              </div>
                            </div>
                            <div className="col-sm col-12">
                              <div className="form-group">
                              <Select
                                name="country"
                                id="country"
                                classNamePrefix="react-select"
                                value={nationalities.find(option => option.value === country)}
                                onChange={(selectedOption) =>
                                  handleCountryChange({ target: { name: 'country', value: selectedOption?.value || 'N.A' } })
                                }
                                options={nationalities}
                                placeholder="Select Nationality"
                                isSearchable
                                isClearable
                                styles={{
                                  control: (provided) => ({
                                    ...provided,
                                    width: '100%',
                                    borderRadius: '20px', // Ajustez la valeur pour l'arrondissement
                                    borderColor: '#ccc',  // Vous pouvez également ajuster la couleur de la bordure si nécessaire
                                  }),
                                }}
                              />
                              </div>
                            </div>
                            <div className="col-sm-2 col-6">
                              <button type="submit" className="btn flous-input-color">
                                <i className="nav-icon fas fa-search"></i> {t("general.table.search")}
                              </button>
                            </div>
                            <div className='col-sm-2 col-6 btn flous-input-color'><i className="nav-icon fas fa-sort"></i> {t("general.table.sort")}</div>
                          </div>
                          {/* @csrf */}
                        </form>
                      </div>
                      <div className="card-body">
                        <div className='flous-bg-thirty' style={{ borderRadius: '15px' }}>
                          <div className='card-header p-1 mb-2 shadow bg-white' style={{ borderRadius: '15px 15px 0px 0px' }}>
                              <div className='row text-bold'>
                                  <div className='col-sm col-12 mt-2 text-center'>{t("general.table.date")}</div>
                                  <div className='col-sm col-6 mt-2 text-center'>{t("general.table.nationality")}</div>
                                  <div className='col-sm col-6 mt-2 text-center'>{t("general.table.firstname")}</div>
                                  <div className='col-sm col-6 mt-2 text-center'>{t("general.table.lastname")}</div>
                                  <div className='col-sm col-6 mt-2 text-center'>{t("general.table.advisors")}</div>
                                  <div className='col-sm-4 col-12 mt-2 text-center'>{t("general.table.actions")}</div>
                              </div>
                          </div>
                          {currentItems.length > 0 ? (
                            currentItems.map((customer) => (
                              <EnterpriseCustomersItem customer={customer} advisors={advisors}/>
                            ))
                          ) : (
                            <NoItemsMessage />
                          )}
                        </div>
                      </div>
                      <PaginationSection
                        page={page}
                        pageSize={pageSize}
                        totalItems={customers.length}
                        onPageChange={onPageChange}
                      />
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <ToastContainer />
    </div>
  );
};