import React, { useState, useEffect } from 'react';
import { sortDictionaryByValues } from '../../../../../services/utils';
import api from '../../../../../services/api';
import Select from 'react-select';
import RequiredIndicator from '../../../../general/requiredIndicator';

import { useTranslation } from "react-i18next";
import { translateDictionaryList } from '../../../../../services/general/TranslationServices';

const JobDetailSection = (props) => {
  const [formData, setFormData] = useState(props.jobDetail);
  // const [isRegulated, setIsRegulated] = useState(false);

  const [jobs, setJobs] = useState([]);
  const { t } = useTranslation();
  
  const lang = localStorage.getItem('flouslang') || 'en';
  const [jobsOriginal, setJobsOriginal] = useState([]);

  const handleTranslate = async (data)=>{
    if (lang !== 'en') {
      const jobsTranslated = await translateDictionaryList(jobsOriginal, lang);
      setJobs(jobsTranslated);
    } else {
      setJobs(jobsOriginal);
    }
  };

  useEffect(()=>{
    handleTranslate();
  }, [lang, jobsOriginal]);

  useEffect(() => {
    const fetchJob = async () =>{
        const response = await api.get('/professions/');
        const sorted = sortDictionaryByValues(response.data);
        const options = getOptions(sorted);
        setJobs(options);
        setJobsOriginal(options);
    };
    fetchJob();

  }, []);

  const getOptions = (data) => {
    return Object.entries(data).map(([key, val]) => ({
      value: key,
      label: val,
    }));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    const updatedData = {
      ...formData,
      [name]: value,
    };
    setFormData(updatedData);
    props.sendJobDetail(updatedData);
  };

  const fetchRegulation = async () =>{
    try{
      const data = {
        cnp: formData.jobTitle,
      }

      const response = await api.post('/professions/isregulated/', data);
      const updatedData = {
        ...formData,
        isRegulated: response.data.isRegulated,
      };
  
      setFormData(updatedData);
      props.sendJobDetail(updatedData);

    } catch (error){
      // console.log(error);
    }
    
  };

  useEffect(() => {
    if (formData.jobTitle) {
      fetchRegulation();
    }
  }, [formData.jobTitle]);

  return (
    <div className="form-card">
      <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
      <div style={{ color: 'red' }}>{props.error !== '' ? `${t('forms.formsquestion.toast.completeSection')}` : ''}</div>
        <p className='text-bold text-center'>{t("forms.title.jobdetail")}</p>
        <div className='row p-2'>
          <p className="col-sm-6 col-form-label">{t("forms.formsquestion.professionalExperience.jobTitle")}<RequiredIndicator /> </p>
          <div className="col-sm-12">
            <Select
              name="jobTitle"
              className="react-select-container rounded-pill flous-input-color"
              classNamePrefix="react-select"
              value={jobs.find(option => option.value === formData.jobTitle)}
              onChange={(selectedOption) => handleChange({ target: { name: 'jobTitle', value: selectedOption ? selectedOption.value : '' } })}
              options={jobs}
              placeholder={`${t('forms.formsquestion.placeholder.option')}`}
              isSearchable
              isClearable
              styles={{
                control: (provided) => ({
                  ...provided,
                  width: '100%',
                  borderRadius: '20px', // Ajustez la valeur pour l'arrondissement
                  borderColor: '#ccc',  // Vous pouvez également ajuster la couleur de la bordure si nécessaire
                }),
              }}
              required
            />
          </div>
        </div>
        <div className='row p-2'>
          <p className="col-sm-10 col-form-label">{t("forms.formsquestion.professionalExperience.selfEmployed")}<RequiredIndicator /></p>
          <div className="col-sm-2">
            <div className='row'>
              <div className="col form-check">
                <input 
                  type="radio"
                  className="form-check-input"
                  id="selfEmployedYes"
                  name="selfEmployed"
                  value="Yes"
                  onChange={handleChange}
                  checked={formData.selfEmployed === "Yes"}
                />
                <label className="form-check-label" htmlFor="selfEmployedYes">{t("forms.click.yes")}</label>
              </div>
              <div className="col form-check">
                <input 
                  type="radio"
                  className="form-check-input"
                  id="selfEmployedNo"
                  name="selfEmployed"
                  value="No"
                  onChange={handleChange}
                  checked={formData.selfEmployed === "No"}
                />
                <label className="form-check-label" htmlFor="selfEmployedNo">{t("forms.click.no")}</label>
              </div>
            </div>
          </div>
        </div>

        {formData.isRegulated && (
        <div className='row p-2'>
          <p className="col-sm-10 col-form-label">{t("forms.formsquestion.professionalExperience.authorization")} {props.evaluationCountry}?<RequiredIndicator /></p>
          <div className="col-sm-2">
            <div className='row'>
              <div className="col form-check">
                <input 
                  type="radio"
                  className="form-check-input"
                  id="authorizationYes"
                  name="authorization"
                  value="Yes"
                  onChange={handleChange}
                  checked={formData.authorization === "Yes"}
                />
                <label className="form-check-label" htmlFor="authorizationYes">{t("forms.click.yes")}</label>
              </div>
              <div className="col form-check">
                <input 
                  type="radio"
                  className="form-check-input"
                  id="authorizationNo"
                  name="authorization"
                  value="No"
                  onChange={handleChange}
                  checked={formData.authorization === "No"}
                />
                <label className="form-check-label" htmlFor="authorizationNo">{t("forms.click.no")}</label>
              </div>
            </div>
          </div>
        </div>
        )}

        {formData.isRegulated && formData.authorization === 'No' && (
        <div className='row p-2'>
          <p className="col-sm-10 col-form-label">{t("forms.formsquestion.professionalExperience.obtainingAuthorization")}<RequiredIndicator /></p>
          <div className="col-sm-2">
            <div className='row'>
              <div className="col form-check">
                <input 
                  type="radio"
                  className="form-check-input"
                  id="obtainingAuthorizationYes"
                  name="obtainingAuthorization"
                  value="Yes"
                  onChange={handleChange}
                  checked={formData.obtainingAuthorization === "Yes"}
                />
                <label className="form-check-label" htmlFor="obtainingAuthorizationYes">{t("forms.click.yes")}</label>
              </div>
              <div className="col form-check">
                <input 
                  type="radio"
                  className="form-check-input"
                  id="obtainingAuthorizationNo"
                  name="obtainingAuthorization"
                  value="No"
                  onChange={handleChange}
                  checked={formData.obtainingAuthorization === "No"}
                />
                <label className="form-check-label" htmlFor="obtainingAuthorizationNo">{t("forms.click.no")}</label>
              </div>
            </div>
          </div>
        </div>
        )}

        <div className='row p-2'>
          <p className="col-sm-10 col-form-label">{t("forms.formsquestion.professionalExperience.jobInAIOrIT")}<RequiredIndicator /></p>
          <div className="col-sm-2">
            <div className='row'>
              <div className="col form-check">
                <input 
                  type="radio"
                  className="form-check-input"
                  id="jobInAIOrITYes"
                  name="jobInAIOrIT"
                  value="Yes"
                  onChange={handleChange}
                  checked={formData.jobInAIOrIT === "Yes"}
                />
                <label className="form-check-label" htmlFor="jobInAIOrITYes">{t("forms.click.yes")}</label>
              </div>
              <div className="col form-check">
                <input 
                  type="radio"
                  className="form-check-input"
                  id="jobInAIOrITNo"
                  name="jobInAIOrIT"
                  value="No"
                  onChange={handleChange}
                  checked={formData.jobInAIOrIT === "No"}
                />
                <label className="form-check-label" htmlFor="jobInAIOrITNo">{t("forms.click.no")}</label>
              </div>
            </div>
          </div>
        </div>

        <div className='row p-2'>
          <p className="col-sm-10 col-form-label">{t("forms.formsquestion.professionalExperience.currentJob")}<RequiredIndicator /></p>
          <div className="col-sm-2">
            <div className='row'>
              <div className="col form-check">
                <input 
                  type="radio"
                  className="form-check-input"
                  id="currentJobYes"
                  name="currentJob"
                  value="Yes"
                  onChange={handleChange}
                  checked={formData.currentJob === "Yes"}
                />
                <label className="form-check-label" htmlFor="currentJobYes">{t("forms.click.yes")}</label>
              </div>
              <div className="col form-check">
                <input 
                  type="radio"
                  className="form-check-input"
                  id="currentJobNo"
                  name="currentJob"
                  value="No"
                  onChange={handleChange}
                  checked={formData.currentJob === "No"}
                />
                <label className="form-check-label" htmlFor="currentJobNo">{t("forms.click.no")}</label>
              </div>
            </div>
          </div>
        </div>
        
        <div>
          {formData.currentJob && (
            <div className='row p-2'>
              <p htmlFor="startDate" className="col-sm-4 col-form-label">{t("forms.formsquestion.professionalExperience.startDate")}<RequiredIndicator /></p>
              <div className="col-sm-8">
                <input 
                  type="date" 
                  name="startDate"
                  className="form-control rounded-pill flous-input-color"
                  onChange={handleChange}
                  value={formData.startDate} max={new Date().toISOString().split("T")[0]}
                />
              </div>
            </div>
          )}
          {formData.currentJob === 'No' && (
            <div className='row p-2'>
              <p htmlFor="endDate" className="col-sm-4 col-form-label">{t("forms.formsquestion.professionalExperience.endDate")}<RequiredIndicator /></p>
              <div className="col-sm-8">
                <input 
                  type="date" 
                  name="endDate"
                  className="form-control rounded-pill flous-input-color"
                  onChange={handleChange}
                  value={formData.endDate} max={new Date().toISOString().split("T")[0]}
                />
              </div>
            </div>
          )}
        </div>

      </div>
    </div>
  );
};

export default JobDetailSection;
