import api from '../api';

let isLoading = false;

export const fetchAdvisorCustomers = async (code) => {

    if (isLoading) return null;
    isLoading = true;

    try{
        const response = await api.get(`/advisor/get-all-customers/${code}`);
        return response;

    } catch (error){
        throw error;
    } finally {
        isLoading = false;
    }  
};

export const getCustomersDataFromAdvisors = async (customer) => {

    if (isLoading) return null;
    isLoading = true;

    try{
        const response = await api.post(`/advisors/get-customer/${customer}`);
        return response;

    } catch (error){
        throw error;
    } finally {
        isLoading = false;
    }  
};

export const AdvisorCustomersNationalities = async (advisor) => {

    try{
        const response = await api.get(`/advisor/get-customers-nationalities/${advisor}`);
        return response;

    } catch (error){
        throw error;
    }   
};


export const viewMoreAdvisorCustomer = async (advisor, customer) => {
    try{
        const response = await api.get(`/advisor/view-more-customer/${advisor}/${customer}`);
        return response;

    } catch (error){
        throw error;
    }  
}


export const createClientNewForm = async (evaluationCountry, clientCode) => {
    try{
        const response = await api.post(`/general/client/create-new-form/${evaluationCountry}/${clientCode}/`);
        return response;

    } catch (error){
        throw error;
    }   
};
