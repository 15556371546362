import React, { useEffect, useState } from "react";
import CountrySelect from "../../general/CountrySelect";
import ProvinceSelect from "../../general/ProvinceSelect";
import CitySelect from "../../general/CitySelect";
import { countriesWithProvinces, createShortName, provincesWithCities } from '../../../services/utils';
import { toast, ToastContainer } from "react-toastify";
import Select from 'react-select';
import { createDemoFromDemoRequestAccount } from "../../../services/flous/ProfesionnalServices";
import LoadingOverlay from "../../general/LoadingOverlay";
import { useTranslation } from "react-i18next";

const CreateNewCompanyModal = ({request = null}) => {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const [errorMessage, setErrorMessage] = useState('');
    const [message, setMessage] = useState('');
    const [manager, setManager] = useState(request);
    const { t } = useTranslation();
    

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        country: '',
        province: '',
        abbreviation: '',
        city: '',
        companyName: '',
        companyRegisterNumber: '',
        lawyerLicense: '',
        immigrationLicense: '',
        typePackage: 'Professional',
    });

    const typesPackages = [
        { value: 'Basic', label: 'Basic' },
        { value: 'Professional', label: 'Professional' },
        { value: 'Enterprise', label: 'Enterprise' },
    ];

    useEffect(()=>{
        if (request){
            setManager(request);
            setFormData({
                firstName: request.firstName,
                lastName: request.lastName,
                email: request.email,
                phone: request.phone,
                country: '',
                province: '',
                abbreviation: '',
                city: '',
                companyName: '',
                companyRegisterNumber: '',
                lawyerLicense: '',
                immigrationLicense: '',
                typePackage: 'Professional',
            });
        }
    }, [request])

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleCountryChange = (e) => {
        const value = e.target.value
        setFormData(prevData => ({
            ...prevData,
            country: value,
            province: '', // Reset province and city when country changes
            city: ''
        }));
    };

    const handleProvinceChange = (e) => {
        const value = e.target.value
        setFormData(prevData => ({
            ...prevData,
            province: value,
            city: ''
        }));
    };

    const handleCityChange = (e) => {
        const value = e.target.value
        setFormData(prevData => ({
            ...prevData,
            city: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!formData.firstName || !formData.lastName || !formData.email || 
            !formData.country || !formData.province || !formData.city || 
            !formData.typePackage) {
                // setErrorMessage("Please complete the form before create an account !");
                toast.error("Please complete the form!");
                return;
        }
        try {

            setLoading(true);
                    
            const prepareData = async () => {

                const associate = {
                    'name': formData.companyName ? formData.companyName: createShortName(formData.firstName,formData.lastName),
                    'company_register_number': formData.companyRegisterNumber,
                    'immigration_consultant_license': formData.immigrationLicense,
                    'lawyer_license': formData.lawyerLicense,
                    'address':{
                        'city': {
                            'name': formData.city,
                            'province': {
                                'abbreviation': formData.province.slice(0, 2),
                                'country': formData.country,
                                'name': formData.province,
                            }
                        }
                        
                    },
                    'type_package': formData.typePackage,
                    'demo_request_id': request ? manager.id: -1,
                    'manager': {
                        'first_name': formData.firstName,
                        'last_name': formData.lastName,
                        'email': formData.email,
                        'cell_phone_number': formData.phone
                    }
                }

                return associate;
            }
            
            const associate = await prepareData();
            
            const response = await createDemoFromDemoRequestAccount(associate);
            if (response && [200, 201].includes(response.status)) {
                setLoading(false);
                window.location.reload();
                toast.success(response.data.message);
            }
            
        } catch (error) {
            if (error.response && [400, 404, 500].includes(error.response.status)){
                setLoading(false);
                window.location.reload();
                // setError(error.response.data.error);
                toast.error('error');
            }
        }
    }
    

    if (loading) {
        return <LoadingOverlay />;
    }

    if (error) {
        return <div>Error : {error.message}</div>;
    }
    
    return (
        <div id="newCompany" className="modal fade" role="dialog">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="flous-police-one flous-input-color">{t("modal.company.title")}</h4>
                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                    </div>
                    <div className="modal-body">
                        {errorMessage && (
                        <div className="text-center mt-2 mb-2" style={{ color: 'red' }}>
                            {errorMessage}
                        </div>
                        )}
                        {message && (
                        <div className="text-center mt-2 mb-2" style={{ color: 'green' }}>
                            {message}
                        </div>
                        )}
                        <form onSubmit={handleSubmit}>
                        <div className='input-group mb-3 col-lg-10 mx-auto'>
                                <div className='row' style={{ flex: 1 }}>
                                    <div className='col-6'>
                                        <label className="input-group">{t("signin.create.form.name")}</label>
                                        <div className="input-group">
                                            <input 
                                                type="text" 
                                                placeholder={`${t('forms.formsquestion.placeholder.firstname')}`} 
                                                className="form-control" 
                                                name='firstName' 
                                                value={formData.firstName}
                                                required 
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <label className="input-group">{t("signin.create.form.surname")}</label>
                                        <div className="input-group">
                                            <input 
                                                type="text" 
                                                placeholder={`${t('forms.formsquestion.placeholder.lastname')}`} 
                                                className="form-control" 
                                                name='lastName' 
                                                value={formData.lastName}
                                                required 
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <label className="input-group col-lg-10 mx-auto">{t("signin.create.form.email")}</label>
                            <div className="input-group mb-3 col-lg-10 mx-auto">
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-envelope"></span>
                                    </div>
                                </div>
                                <input 
                                    type="email" 
                                    placeholder="Email" 
                                    className="form-control" 
                                    name='email' 
                                    value={formData.email}
                                    onChange={handleChange} 
                                    required
                                />
                            </div>

                            <label className="input-group col-lg-10 mx-auto">Package</label>
                            <div className="mb-3 col-lg-10 mx-auto">
                                <Select
                                    name="typePackage"
                                    classNamePrefix="react-select"
                                    className="react-select-container"
                                    value={typesPackages.find(option => option.value === formData.typePackage)} 
                                    onChange={(selectedOption) => handleChange({ 
                                        target: { 
                                            name: 'typePackage', 
                                            value: selectedOption ? selectedOption.value : '' 
                                        } 
                                    })}
                                    options={typesPackages}
                                    placeholder="Select package"
                                    isSearchable={false}
                                    isClearable={false}
                                    styles={{
                                        control: (provided) => ({
                                          ...provided,
                                          width: '100%',
                                          borderRadius: '20px', // Ajustez la valeur pour l'arrondissement
                                          borderColor: '#ccc',  // Vous pouvez également ajuster la couleur de la bordure si nécessaire
                                        }),
                                      }}
                                    required
                                />
                            </div>

                            <label className="input-group col-lg-10 mx-auto">{t("signin.create.form.companyname")} (optional)</label>
                            <div className="input-group mb-3 col-lg-10 mx-auto">
                                <input 
                                    type="text" 
                                    placeholder={`${t('forms.formsquestion.placeholder.companyName')}`} 
                                    className="form-control" 
                                    name='companyName' 
                                    value={formData.companyName}
                                    onChange={handleChange} 
                                />
                            </div>

                            <label className="input-group col-lg-10 mx-auto">{t("signin.create.form.register")}</label>
                            <div className="input-group mb-3 col-lg-10 mx-auto">
                                <input 
                                    type="text" 
                                    placeholder="Company incorporation number" 
                                    className="form-control" 
                                    name='companyRegisterNumber' 
                                    value={formData.companyRegisterNumber}
                                    onChange={handleChange} 
                                />
                            </div>

                            <label className="input-group col-lg-10 mx-auto">{t("signin.create.form.immigrationlicence")}</label>
                            <div className="input-group mb-3 col-lg-10 mx-auto">
                                <input 
                                    type="text" 
                                    placeholder="Immigration consultant licence number" 
                                    className="form-control" 
                                    name='immigrationLicense' 
                                    value={formData.immigrationLicense}
                                    onChange={handleChange} 
                                />
                            </div>

                            <label className="input-group col-lg-10 mx-auto">{t("signin.create.form.lawyerlicence")}</label>
                            <div className="input-group mb-3 col-lg-10 mx-auto">
                                <input 
                                    type="text" 
                                    placeholder="Lawyer license number" 
                                    className="form-control" 
                                    name='lawyerLicense' 
                                    value={formData.lawyerLicense}
                                    onChange={handleChange} 
                                />
                            </div>

                            <label className="input-group col-lg-10 mx-auto">{t("signin.create.form.country")}</label>
                            <div className="mb-3 col-lg-10 mx-auto">
                                <CountrySelect 
                                    className="flous-input-color"
                                    value={formData.country} 
                                    onChange={handleCountryChange} 
                                    required 
                                />
                            </div>

                            <div className='input-group mb-3 col-lg-10 mx-auto'>
                                <div className='row' style={{ flex: 1 }}>
                                    <div className='col-6'>
                                        <label className="input-group">{t("signin.create.form.province")}</label>
                                        <div className="">
                                            {countriesWithProvinces.includes(formData.country) ? (
                                                <ProvinceSelect 
                                                    className="flous-input-color" 
                                                    value={formData.province} 
                                                    onChange={handleProvinceChange} 
                                                    country={formData.country} 
                                                    required 
                                                />
                                            ) : (
                                                <input 
                                                    type="text" 
                                                    placeholder="Province" 
                                                    name='province' 
                                                    value={formData.province}
                                                    onChange={handleChange} 
                                                    className="form-control" 
                                                    required 
                                                />
                                            )}
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <label className="input-group">{t("signin.create.form.city")}</label>
                                        <div className="">
                                            {countriesWithProvinces.includes(formData.country) ? (
                                                <CitySelect 
                                                    className="flous-input-color" 
                                                    value={formData.city} 
                                                    onChange={handleCityChange} 
                                                    province={formData.province} 
                                                    required 
                                                />
                                            ) : (
                                                <input 
                                                    type="text" 
                                                    placeholder={`${t('forms.formsquestion.placeholder.city')}`} 
                                                    name='city' 
                                                    value={formData.city}
                                                    onChange={handleChange} 
                                                    className="form-control" 
                                                    required 
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-10 mx-auto">
                                <button type="submit" className="btn flous-btn-primary btn-block rounded-pill">Add new Company</button>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-default" data-dismiss="modal">{t("modal.cancel")}</button>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default CreateNewCompanyModal;
