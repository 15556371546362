import React, { useState, useEffect } from 'react';
import RequiredIndicator from '../../../../general/requiredIndicator';

import { useTranslation } from "react-i18next";

const DiplomaRecognitionSection = (props) => {
  const [formData, setFormData] = useState(props.diplomaRecognition);
  const { t } = useTranslation();

  const handleChange = (event) => {
    const { name, value } = event.target;
    const updateData = {
      ...formData,
      [name]: value,
    }
    setFormData(updateData);
    props.sendDiplomaRecognition(updateData);
  };

  return (
    <div className="form-card">
      <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
      {props.error && (
        <>
            <p style={{ marginTop: '20px', padding: '10px', color: 'red'}}>
                {props.error}
            </p>
        </>
      )}
      <p className='text-bold text-center'>{t("forms.title.diplomarecognition")}</p>
      <div className='row p-2'>
        <p className='col-sm-10 col-form-label'>{t("forms.formsquestion.educationInCountry.equivalentProcessCompleted")}<RequiredIndicator /></p>
        <div className='col-sm-2'>
          <div className='row'>
            <div className="col form-check">
              <input 
                type="radio"
                className="form-check-input"
                id="equivalentProcessCompletedYes"
                name="equivalentProcessCompleted"
                value="Yes"
                onChange={handleChange}
                checked={formData.equivalentProcessCompleted === "Yes"}
              />
              <label className="form-check-label" htmlFor="equivalentProcessCompletedYes">{t("forms.click.yes")}</label>
            </div>
            <div className="col form-check">
              <input 
                type="radio"
                className="form-check-input"
                id="equivalentProcessCompletedNo"
                name="equivalentProcessCompleted"
                value="No"
                onChange={handleChange}
                checked={formData.equivalentProcessCompleted === "No"}
              />
              <label className="form-check-label" htmlFor="equivalentProcessCompletedNo">{t("forms.click.no")}</label>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
};

export default DiplomaRecognitionSection;
