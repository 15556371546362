import React, { useEffect, useState } from 'react';
import CountrySelect from '../../../../general/CountrySelect'
import { sortDictionaryByValues } from '../../../../../services/utils';
import api from '../../../../../services/api';
import Select from 'react-select';
import RequiredIndicator from '../../../../general/requiredIndicator';

import { useTranslation } from "react-i18next";
import { translateDictionaryList } from '../../../../../services/general/TranslationServices';

const PersonalDetailSection = (props) => {
  const [formData, setFormData] = useState(
    props.personalDetail);
  const [genders, setGenders] = useState([]);
  const [gendersOriginal, setGendersOriginal] = useState([]);
  const [maritalStatus, setMaritalStatus] = useState([]);
  const [maritalStatusOriginal, setMaritalStatusOriginal] = useState([]);

  const lang = localStorage.getItem('flouslang') || 'en';
  const { t } = useTranslation();
  
  const handleTranslate = async (data)=>{
    if (lang !== 'en') {
      const genderTranslated = await translateDictionaryList(gendersOriginal, lang);
      const maritalStatusTranslated = await translateDictionaryList(maritalStatusOriginal, lang);
      setGenders(genderTranslated);
      setMaritalStatus(maritalStatusTranslated);
    } else {
      setGenders(gendersOriginal);
      setMaritalStatus(maritalStatusOriginal);
    }
  };

  useEffect(()=>{
    handleTranslate();
  }, [lang, gendersOriginal, maritalStatusOriginal]);

  useEffect(() => {
    const fetchGender = async () =>{
        const response = await api.get('/gender/');
        const sorted = sortDictionaryByValues(response.data);
        const options = getOptions(sorted);
        setGenders(options);
        setGendersOriginal(options);
    };
    fetchGender();

    const fetchMaritalStatus = async () =>{
        const response = await api.get('/civilstatus/');
        const sorted = response.data //sortDictionaryByValues(response.data);
        const options = getOptions(sorted);
        setMaritalStatus(options);
        setMaritalStatusOriginal(options);
    };
    fetchMaritalStatus();

  }, []);

  const getOptions = (data) => {
    return Object.entries(data).map(([key, val]) => ({
      value: key,
      label: val,
    }));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    const updatedFormData = {
      ...formData,
      [name]: value,
    };
    setFormData(updatedFormData);
  };

  const handleNationalityChange = (e) =>{
    const { name, value } = e.target;
    const updatedFormData = {
      ...formData,
      nationality: value,
    };
    setFormData(updatedFormData);
  };

  const handleMaritalStatusChange = (event) =>{
    const {value} = event.target
    const updatedFormData = {
      ...formData,
      maritalStatus: value,
      personIncluded: ''
    };
    setFormData(updatedFormData);
  }

  useEffect(()=>{
    props.sendPersonalDetail(formData);
    
  }, [formData])

  const allowedMaritalStatuses = ['Common_law_partner', 'Married'];
  const identity = ['Divorced', 'Widow', 'Separated'];

  return (
      <div className="form-card">
        <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
        <div style={{ color: 'red' }}>{props.error !== '' ? `${t('forms.formsquestion.toast.completeSection')}` : ''}</div>
        <p className='text-bold text-center'>{t("forms.title.personaldetail")}</p>
          <div className='row p-2'>
            <p htmlFor="genderIdentity" className='col-sm-4'>{t("forms.formsquestion.personalInfo.genderIdentity")}<RequiredIndicator /></p>
            <div className='col-sm-8'>
              <Select
                id="genderIdentity"
                name="genderIdentity"
                className="react-select-container rounded-pill flous-input-color"
                classNamePrefix="react-select"
                value={genders.find(option => option.value === formData.genderIdentity)}
                onChange={(selectedOption) => handleChange({ target: { name: 'genderIdentity', value: selectedOption ? selectedOption.value : '' } })}
                options={genders}
                placeholder={`${t('forms.formsquestion.placeholder.option')}`}
                isSearchable
                isClearable
                styles={{
                  control: (provided) => ({
                    ...provided,
                    width: '100%',
                    borderRadius: '20px', // Ajustez la valeur pour l'arrondissement
                    borderColor: '#ccc',  // Vous pouvez également ajuster la couleur de la bordure si nécessaire
                  }),
                }}
                required
              />
            </div>
          </div>
          <div className='row p-2'>
            <p htmlFor="nationality" className="col-sm-4 col-form-label">{t("forms.formsquestion.personalInfo.nationality")}<RequiredIndicator /></p>
            <div className="col-sm-8">
              <CountrySelect value={formData.nationality} onChange={handleNationalityChange} required />
            </div>
          </div>
          {props.actor === 'Principal' && (
          <div className='row p-2'>
            <p htmlFor="maritalStatus" className='col-sm-4'>{t("forms.formsquestion.personalInfo.maritalStatus")}<RequiredIndicator /></p>
            <div className='col-sm-8'>
              <Select
                id="maritalStatus"
                name="maritalStatus"
                className="react-select-container rounded-pill flous-input-color"
                classNamePrefix="react-select"
                value={maritalStatus.find(option => option.value === formData.maritalStatus)}
                onChange={(selectedOption) => handleMaritalStatusChange({ target: { name: 'maritalStatus', value: selectedOption ? selectedOption.value : '' } })}
                options={maritalStatus}
                placeholder={`${t('forms.formsquestion.placeholder.option')}`}
                isSearchable
                isClearable
                styles={{
                  control: (provided) => ({
                    ...provided,
                    width: '100%',
                    borderRadius: '20px', // Ajustez la valeur pour l'arrondissement
                    borderColor: '#ccc',  // Vous pouvez également ajuster la couleur de la bordure si nécessaire
                  }),
                }}
                required
              />
            </div>
          </div>
          )}
          {(props.actor === 'Principal' && allowedMaritalStatuses.includes(formData.maritalStatus)) && (
            <div className='row p-2'>
            <p className='col-sm-8 col-form-label'>{t("forms.formsquestion.personalInfo.partnerInRequest")}</p>
            <div className='col-sm-4'>
              <div className='row'>
                <div className="col form-check">
                  <input 
                    type="radio"
                    className="form-check-input rounded-pill"
                    id="personIncludedYes"
                    name="personIncluded"
                    value="Yes"
                    onChange={handleChange}
                    checked={formData.personIncluded === "Yes"}
                  />
                  <label className="form-check-label" htmlFor="personIncludedYes">{t("forms.click.yes")}</label>
                </div>
                <div className="col form-check">
                  <input 
                    type="radio"
                    className="form-check-input rounded-pill"
                    id="personIncludedNo"
                    name="personIncluded"
                    value="No"
                    onChange={handleChange}
                    checked={formData.personIncluded === "No"}
                  />
                  <label className="form-check-label" htmlFor="personIncludedNo">{t("forms.click.no")}</label>
                </div>
              </div>
            </div>
          </div>
          )}
          {(props.actor === 'Principal' && identity.includes(formData.maritalStatus)) && (
            <>
            <div className='row p-2'>
              <p htmlFor="firstname" className="col-sm-4 col-form-label">{t("forms.formsquestion.personalInfo.firstname")}<RequiredIndicator /></p>
              <div className="col-sm-8">
                <input 
                  type="text" 
                  className="form-control rounded-pill flous-input-color" 
                  id="firstname" 
                  name="firstname" 
                  placeholder={`${t('forms.formsquestion.placeholder.firstname')}`}
                  value={formData.firstname}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className='row p-2'>
              <p htmlFor="lastname" className="col-sm-4 col-form-label">{t("forms.formsquestion.personalInfo.lastname")} <RequiredIndicator /></p>
              <div className="col-sm-8">
                <input 
                  type="text" 
                  className="form-control rounded-pill flous-input-color" 
                  id="lastname" 
                  name="lastname" 
                  placeholder={`${t('forms.formsquestion.placeholder.lastname')}`}
                  value={formData.lastname}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className='row p-2'>
              <p htmlFor="dateofbirth" className="col-sm-4 col-form-label">{t("forms.formsquestion.personalInfo.dateofbirth")}<RequiredIndicator /></p>
              <div className="col-sm-8">
                <input 
                  type="date" 
                  className="form-control rounded-pill flous-input-color" 
                  id="dateofbirth" 
                  name="dateofbirth" 
                  placeholder="DD/MM/YYYY"
                  value={formData.dateofbirth}
                  onChange={handleChange} max={new Date().toISOString().split("T")[0]}
                />
              </div>
            </div>
            </>
          )}
          {(props.actor === 'Principal' && (formData.maritalStatus === "Separated")) && (
            <>
            <div className='row p-2'>
              <p htmlFor="relationStart" className="col-sm-4 col-form-label">{t("forms.formsquestion.personalInfo.relationStart")}<RequiredIndicator /></p>
              <div className="col-sm-8">
                <input 
                  type="date" 
                  className="form-control rounded-pill flous-input-color" 
                  id="relationStart" 
                  name="relationStart" 
                  placeholder="DD/MM/YYYY"
                  value={formData.relationStart}
                  onChange={handleChange} max={new Date().toISOString().split("T")[0]}
                />
              </div>
            </div>
            </>
          )}
          {(props.actor === 'Principal' && (formData.maritalStatus === "Divorced" || formData.maritalStatus === "Widow")) && (
            <>
            <div className='row p-2'>
              <p htmlFor="marriageDate" className="col-sm-4 col-form-label">{t("forms.formsquestion.personalInfo.marriageDate")}<RequiredIndicator /></p>
              <div className="col-sm-8">
                <input 
                  type="date" 
                  className="form-control rounded-pill flous-input-color" 
                  id="marriageDate" 
                  name="marriageDate" 
                  placeholder="DD/MM/YYYY"
                  value={formData.marriageDate}
                  onChange={handleChange} max={new Date().toISOString().split("T")[0]}
                />
              </div>
            </div>
            </>
          )}
          {(props.actor === 'Principal' && (formData.maritalStatus === "Separated" || formData.maritalStatus === "Divorced")) && (
            <>
            <div className='row p-2'>
              <p htmlFor="relationEnd" className="col-sm-4 col-form-label">{t("forms.formsquestion.personalInfo.relationEnd")}<RequiredIndicator /></p>
              <div className="col-sm-8">
                <input 
                  type="date" 
                  className="form-control rounded-pill flous-input-color" 
                  id="relationEnd" 
                  name="relationEnd" 
                  placeholder="DD/MM/YYYY"
                  value={formData.relationEnd}
                  onChange={handleChange} max={new Date().toISOString().split("T")[0]}
                />
              </div>
            </div>
            </>
          )}
          {(props.actor === 'Principal' && (formData.maritalStatus === "Widow")) && (
            <>
            <div className='row p-2'>
              <p htmlFor="spouseDeath" className="col-sm-4 col-form-label">{t("forms.formsquestion.personalInfo.spouseDeath")}<RequiredIndicator /></p>
              <div className="col-sm-8">
                <input 
                  type="date" 
                  className="form-control rounded-pill flous-input-color" 
                  id="spouseDeath" 
                  name="spouseDeath" 
                  placeholder="DD/MM/YYYY"
                  value={formData.spouseDeath}
                  onChange={handleChange} max={new Date().toISOString().split("T")[0]}
                />
              </div>
            </div>
            </>
          )}
        </div>
      </div>
  );
};

export default PersonalDetailSection;
