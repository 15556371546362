import React, { useState, useEffect } from 'react';
import CountrySelect from '../../../../general/CountrySelect';
import { countriesWithProvinces, provincesWithCities, sortDictionaryByValues } from '../../../../../services/utils';
import ProvinceSelect from '../../../../general/ProvinceSelect';
import CitySelect from '../../../../general/CitySelect';
import api from '../../../../../services/api';
import Select from 'react-select';
import RequiredIndicator from '../../../../general/requiredIndicator';

import { useTranslation } from "react-i18next";

const EducationInstitutionSection = (props) => {
  const [formData, setFormData] = useState(props.educationInstitution);
  const { t } = useTranslation();
    const [studyLanguages, setStudyLanguages] = useState([
      {value: 'FR', label: 'French'}, 
      {value: 'EN', label: 'English'}, 
    ]);

  useEffect(() => {
    const fetchStudyLanguage = async () =>{
        const response = await api.get('/languages/');
        const sorted = sortDictionaryByValues(response.data);
        const options = getOptions(sorted);
        setStudyLanguages(options);
    };

    fetchStudyLanguage();

  }, []);

  const getOptions = (data) => {
    return Object.entries(data).map(([key, val]) => ({
      value: key,
      label: val,
    }));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    const updateData = {
      ...formData,
      [name]: value,
    }
    setFormData(updateData);
    props.sendEducationInstitution(updateData);
  };

  const handleCountryChange = (e) =>{
    const { name, value } = e.target;
    const updatedFormData = {
      ...formData,
      country: value,
    };
    setFormData(updatedFormData);
    props.sendEducationInstitution(updatedFormData);
  };

  const handleProvinceStateChange = (e) =>{
    const { name, value } = e.target;
    const updatedFormData = {
      ...formData,
      provinceState: value,
    };
    setFormData(updatedFormData);
    props.sendEducationInstitution(updatedFormData);
  };

  const handleCityChange = (e) =>{
    const { name, value } = e.target;
    const updatedFormData = {
      ...formData,
      city: value,
    };
    setFormData(updatedFormData);
    props.sendEducationInstitution(updatedFormData);
  }

  return (
    <div className="form-card">
      <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
      <div style={{ color: 'red' }}>{props.error !== '' ? `${t('forms.formsquestion.toast.completeSection')}` : ''}</div>
      <p className='text-bold text-center'>{t("forms.title.educationinstitution")}</p>
        <div className='row p-2'>
          <p htmlFor="institutionName" className="col-12 col-form-label">{t("forms.formsquestion.educationOutsideCountry.institutionName")}<RequiredIndicator /></p>
          <div className="col-12">
            <input 
              type="text" 
              name="institutionName"
              className="form-control rounded-pill flous-input-color"
              onChange={handleChange}
              value={formData.institutionName}
              placeholder={`${t('forms.formsquestion.placeholder.institutionName')}`}
            />
          </div>
        </div>
        <div className='row p-2'>
          <p htmlFor="address" className="col-form-label">{t("forms.formsquestion.educationOutsideCountry.address")}</p>
        </div>
        <div className='row p-2'>
          <p htmlFor="country" className="col-sm-4 col-form-label">{t("forms.formsquestion.educationOutsideCountry.country")}<RequiredIndicator /></p>
          <div className="col-sm-8">
            <CountrySelect value={formData.country} onChange={handleCountryChange} required />
          </div>
        </div>
        <div className='row p-2'>
          <p htmlFor="provinceState" className="col-sm-4 col-form-label">{t("forms.formsquestion.educationOutsideCountry.provinceState")}<RequiredIndicator /></p>
          <div className="col-sm-8">
            {countriesWithProvinces.includes(formData.country) ? 
              (<ProvinceSelect 
                value={formData.provinceState} 
                onChange={handleProvinceStateChange} 
                country={formData.country} required
                />
              ):
              (<input 
                type="text" 
                className="form-control rounded-pill flous-input-color" 
                id="provinceState" 
                name="provinceState" 
                value={formData.provinceState} 
                onChange={handleChange} 
                placeholder={`${t('forms.formsquestion.placeholder.province')}`}
              />
              )}
          </div>
        </div>
        <div className='row p-2'>
          <p htmlFor="city" className="col-sm-4 col-form-label">{t("forms.formsquestion.educationOutsideCountry.city")}<RequiredIndicator /></p>
          <div className="col-sm-8">
            {countriesWithProvinces.includes(formData.country) ? 
              (<CitySelect 
                value={formData.city} 
                onChange={handleCityChange} 
                province={formData.provinceState} 
                required
                />
              ):
              (
                <input 
                type="text" 
                className="form-control rounded-pill flous-input-color" 
                id="city" 
                name="city" 
                value={formData.city} 
                onChange={handleChange} 
                placeholder={`${t('forms.formsquestion.placeholder.city')}`}
              />
              )}
          </div>
        </div>
        <div className='row p-2'>
          <p htmlFor="languageOfInstruction" className="col-sm-4 col-form-label">{t("forms.formsquestion.educationOutsideCountry.languageOfInstruction")} <RequiredIndicator /></p>
          <div className="col-sm-8">
            <Select
              name="languageOfInstruction"
              className="react-select-container rounded-pill flous-input-color"
              classNamePrefix="react-select"
              value={studyLanguages.find(option => option.value === formData.languageOfInstruction)}
              onChange={(selectedOption) => handleChange({ target: { name: 'languageOfInstruction', value: selectedOption ? selectedOption.value : '' } })}
              options={studyLanguages}
              placeholder={`${t('forms.formsquestion.placeholder.option')}`}
              isSearchable
              isClearable
              styles={{
                control: (provided) => ({
                  ...provided,
                  width: '100%',
                  borderRadius: '20px', // Ajustez la valeur pour l'arrondissement
                  borderColor: '#ccc',  // Vous pouvez également ajuster la couleur de la bordure si nécessaire
                }),
              }}
              required
            />
          </div>
        </div>
        <div className='row p-2'>
          <p htmlFor="fullTimeStudent" className="col-form-label col-sm-10">{t("forms.formsquestion.educationOutsideCountry.fullTimeStudent")}<RequiredIndicator /></p>
          <div className='col-sm-2'>
            <div className='row'>
              <div className="col form-check">
                <input 
                  type="radio"
                  id="fullTimeStudentYes"
                  name="fullTimeStudent"
                  className="form-check-input" 
                  value="Yes"
                  onChange={handleChange}
                  checked={formData.fullTimeStudent === "Yes"}
                />
                <p htmlFor="fullTimeStudentYes" className="form-check-p">{t("forms.click.yes")}</p>
              </div>
              <div className="col form-check">
                <input 
                  type="radio"
                  id="fullTimeStudentNo"
                  name="fullTimeStudent"
                  className="form-check-input" 
                  value="No"
                  onChange={handleChange}
                  checked={formData.fullTimeStudent === "No"}
                />
                <p htmlFor="fullTimeStudentNo" className="form-check-p">{t("forms.click.no")}</p>
              </div>
            </div> 
          </div>
        </div>
      </div>
    </div>
  );
};

export default EducationInstitutionSection;
