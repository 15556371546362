import React, { useEffect, useState } from "react";
import { createNewCustomer } from "../../../services/enterprise/CustomersServices";
import LoadingOverlay from "../../general/LoadingOverlay";
import { toast, ToastContainer } from "react-toastify";
import { useOutletContext } from "react-router-dom";
import { useTranslation } from "react-i18next";
import NewClientSuccessModal from "./newClientSuccessModal";
// import CountrySelect from "../../general/CountrySelect";
// import { PhoneInput } from "react-international-phone";

const CreateNewCustomersModal = () => {

  const [formData, setFormData] = useState({
    lastname: '',
    firstname: '',
    email: '',
    evaluationCountry: 'Canada',
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const [requestComplete, setRequestComplete] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const {user} = useOutletContext();
  const { t } = useTranslation();

  const handleChange = (event) => {
    const {name, value} = event.target;
    const updatedData = {
      ...formData,
      [name]:value,
    }
    setFormData(updatedData);
  };

  const handleSaveNewCustomer = async (e) => {

    e.preventDefault();

    if (!formData.lastname || !formData.firstname || !formData.email) {
      toast.error("You must complete new Customer form.")
      return;
    } else {

      setLoading(true);
      if (user.id) {
        try{

          const data = {
            firstname: formData.firstname,
            lastname: formData.lastname,
            email: formData.email,
            evaluationCountry: formData.evaluationCountry,
          }
  
          const response = await createNewCustomer(data);
          if (response && [200, 201].includes(response.status)) {
            setLoading(false);
            window.location.reload();
            // setIsOpen(true);
          }
        }  catch (error){
          if (error.response && [400, 500].includes(error.response.status)){
            setLoading(false);
            toast.error(error.response.data.error);
          }
        }
      } else {
        return;
      }
    }
    
  }

  const handleClose = () =>{
    setIsOpen(false);
    window.location.reload();
  }

  if (loading) {
    return <LoadingOverlay />;
  }

  if (error) {
    return <div>Error : {error.message}</div>;
  }

  return (
    <div id="newCustomers" className="modal fade" role="dialog">
      <div className="modal-dialog">
        {/* Modal content */}
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">{t("modal.create.title2")}</h4>
            <button type="button" className="close" data-dismiss="modal">
              &times;
            </button>
          </div>
          <div className="modal-body">

            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
            <form onSubmit={handleSaveNewCustomer}>
              <div className="col">
                <div className="form-group">
                  <label htmlFor="firstname">{t("signin.create.form.name")}</label>
                  <input
                    onChange={handleChange} value={formData.firstname}
                    name="firstname"
                    type="text"
                    className="form-control"
                    placeholder="Enter client's first name ..."
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="lastname">{t("signin.create.form.surname")}</label>
                  <input
                    onChange={handleChange} value={formData.lastname}
                    name="lastname"
                    type="text"
                    className="form-control"
                    placeholder="Enter client's last name ..."
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">{t("signin.create.form.email")}</label>
                  <input
                    onChange={handleChange} value={formData.email}
                    name="email"
                    type="email"
                    className="form-control"
                    placeholder="Enter client's email ..."
                  />
                </div>
              </div>
              <div className="col">
                <input
                  className="btn flous-bg-primary"
                  type="submit"
                  value={t("modal.create.submit")}
                />
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-default"
              data-dismiss="modal"
            >
              {t("modal.cancel")}
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
      <NewClientSuccessModal 
        type='Client' 
        isOpen={isOpen}
        onClose={handleClose}
      />
    </div>
  );
};

export default CreateNewCustomersModal;
