import React from 'react';
import './ScrollingMessage.css';

const ScrollingMessage = ({ message, duration = 10, repeat = 3 }) => {

  return (
    <div className="scrolling-container">
      <div
        className="scrolling-message"
        style={{
          animationDuration: `${duration}s`,
        }}
      >
        {message}
      </div>
    </div>
  );
};

export default ScrollingMessage;