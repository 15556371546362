import React from 'react';

import { useTranslation } from 'react-i18next';

export default function SpecialProgramResumeSection(props) {
  const { t } = useTranslation();
  // Define the special program information
  // const completedCareAssistanceProgram = props.specialProgram.homeCareProgram; //  
  const completedDiplomaRelatedToOrderly = props.specialProgram.proposeProgram; //  

  return (
    <div className='flous-bg-thirty p-3 elevation-2 mb-3' style={{ borderRadius: '15px' }}>
      <div className='text-bold text-center mb-3'>{t("forms.title.specialprogram")}</div>
      <div className='mx-3'>
        {/*<div className="form-group row">
          <label htmlFor="inputCompletedCareAssistanceProgram" className="col-sm-9 col-form-label">
          {t("forms.formsquestion.educationInCountry.homeCareProgram}
          </label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputCompletedCareAssistanceProgram" value={completedCareAssistanceProgram} />
          </div>
        </div> */}
        <div className="form-group row">
          <label htmlFor="inputCompletedDiplomaRelatedToOrderly" className="col-sm-9 col-form-label">
          {t("forms.formsquestion.educationInCountry.proposeProgram")}
          </label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputCompletedDiplomaRelatedToOrderly" value={completedDiplomaRelatedToOrderly === 'Yes' ? `${t('forms.click.yes')}`: `${t('forms.click.no')}`} placeholder="N.A"/>
          </div>
        </div>
      </div>
    </div>
  );
}
