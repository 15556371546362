import React, { useState, useEffect } from 'react';
import axios from 'axios';
import api from '../../../../../services/api';
import CountryShow from '../../../../general/countryShow';

import { useTranslation } from 'react-i18next';

export default function CompanyDetailsTwoResumeSection(props) {
  const { t } = useTranslation();
  // Define the company details information
  const companyDetails = {
    establishedDate: props.companyDetails.establishmentDate, // Replace with actual establishment date
    country:props.companyDetails.country, // Replace with actual country
    province: props.companyDetails.provinceState, // Replace with actual province/state
    city: props.companyDetails.city, // Replace with actual city
    streetNumber: props.companyDetails.streetNumber, // Replace with actual street number
    postalCode: props.companyDetails.postalCode // Replace with actual postal code
  };

  const [provinces, setProvinces] = useState([]);
  const countryName = CountryShow({ value: companyDetails.country });

  useEffect(() => {
      const fetchProvinces = async() => {
        if (companyDetails.country === 'CAN') {
          const response = await api.get('/canada-provinces/');
          const provinceOptions = Object.entries(response.data).map(([key, val]) => ({
            value: key,
            label: val,
          }));
          setProvinces(provinceOptions);
        }
      };
  
      fetchProvinces();
  }, []);

  return (
    <div className='flous-bg-thirty p-3 elevation-2 mb-3' style={{ borderRadius: '15px' }}>
      <div className='text-bold text-center mb-3'>{t("forms.title.companydetail")}</div>
      <div className='mx-3'>
        <div className="form-group row">
          <label htmlFor="inputEstablishedDate" className="col-sm-6 col-form-label">
          {t("forms.formsquestion.entrepreunarialExperience.establishmentDate")}
          </label>
          <div className="col-sm-6">
            <input type="text" className="form-control" id="inputEstablishedDate" value={companyDetails.establishedDate} placeholder="N.A"/>
          </div>
        </div>
        <div className="form-group row">
          {/*<label htmlFor="inputCountry" className="col-sm-3 col-form-label">Country</label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputCountry" value={countryName} placeholder="N.A"/>
          </div>*/}
          <label htmlFor="inputProvince" className="col-sm-3 col-form-label">{t("forms.formsquestion.entrepreunarialExperience.provinceState")}</label>
          <div className="col-sm-3">
            {companyDetails.country === 'CAN' ? (<input type="text" className="form-control" id="inputProvince" value={provinces.find(province => province.value === companyDetails.province)?.label || ''} placeholder="N.A"/>)
            : (<input type="text" className="form-control" id="inputProvince" value={companyDetails.province} placeholder="N.A"/>)}
          </div>
          <label htmlFor="inputCity" className="col-sm-3 col-form-label">{t("forms.formsquestion.entrepreunarialExperience.city")}</label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputCity" value={companyDetails.city} placeholder="N.A"/>
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="inputStreetNumber" className="col-sm-3 col-form-label">{t("forms.formsquestion.entrepreunarialExperience.streetNumber")}</label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputStreetNumber" value={companyDetails.streetNumber} placeholder="N.A"/>
          </div>
          <label htmlFor="inputPostalCode" className="col-sm-3 col-form-label">Postal code</label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputPostalCode" value={companyDetails.postalCode} placeholder="N.A"/>
          </div>
        </div>
        {companyDetails.province === 'BC' && (
          <>
          <div className="form-group row">
          <label htmlFor="haveVisitBC" className="col-sm-3 col-form-label">{t("forms.formsquestion.entrepreunarialExperience.haveVisitBC")}</label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="haveVisitBC" value={companyDetails.haveVisitBC === 'Yes' ? `${t('forms.click.yes')}`: `${t('forms.click.no')}`} placeholder="N.A"/>
          </div>
          <label htmlFor="visitedCity" className="col-sm-3 col-form-label">{t("forms.formsquestion.entrepreunarialExperience.visitedCity")}</label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="visitedCity" value={companyDetails.visitedCity} placeholder="N.A"/>
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="visitDate" className="col-sm-3 col-form-label">{t("forms.formsquestion.entrepreunarialExperience.visitDate")}</label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="visitDate" value={companyDetails.visitDate} placeholder="N.A"/>
          </div>
        </div></>
        )}
        
      </div>
    </div>
  );
}
