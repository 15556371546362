import React, { useEffect, useState } from 'react';
import api from '../../../../../services/api';
import { sortDictionaryByValues } from '../../../../../services/utils';
import { toast, ToastContainer } from 'react-toastify';
import Select from 'react-select';
import RequiredIndicator from '../../../../general/requiredIndicator';

import { useTranslation } from "react-i18next";
import { translateDictionaryList } from '../../../../../services/general/TranslationServices';

const EducationalCredentialAssessmentSection = (props) => {
  const [formData, setFormData] = useState(props.educationalCredentialAssessment);
  const [previousCredentials, setPreviousCredentials] = useState(props.previousCredentials);

  const [studyFields, setStudyFields] = useState([]);
  const [studyLevels, setStudyLevels] = useState([]);
  const [issuedOrganizations, setIssuedOrganizations] = useState([]);
  const [errors, setErrors] = useState({});
  const { t } = useTranslation();
  
  const lang = localStorage.getItem('flouslang') || 'en';
  const [studyFieldsOriginal, setStudyFieldsOriginal] = useState([]);
  const [studyLevelsOriginal, setStudyLevelsOriginal] = useState([]);
  const [issuedOrganizationsOriginal, setIssuedOrganizationsOriginal] = useState([]);

  const handleTranslate = async (data)=>{
    if (lang !== 'en') {
      const studyFieldsTranslated = await translateDictionaryList(studyFieldsOriginal, lang);
      setStudyFields(studyFieldsTranslated);
      const studyLevelsTranslated = await translateDictionaryList(studyLevelsOriginal, lang);
      setStudyLevels(studyLevelsTranslated);
      const issuedOrganizationsTranslated = await translateDictionaryList(issuedOrganizationsOriginal, lang);
      setIssuedOrganizations(issuedOrganizationsTranslated);
    } else {
      setStudyFields(studyFieldsOriginal);
      setStudyLevels(studyLevelsOriginal);
      setIssuedOrganizations(issuedOrganizationsOriginal);
    }
  };

  useEffect(()=>{
    handleTranslate();
  }, [lang, studyFieldsOriginal, studyLevelsOriginal, issuedOrganizationsOriginal]);

  useEffect(() => {
    const fetchStudyField = async () =>{
        const response = await api.get('/trainingfieldinside/');
        const sorted = sortDictionaryByValues(response.data);
        const options = getOptions(sorted);
        setStudyFields(options);
        setStudyFieldsOriginal(options);
    };
    const fetchStudyLevel = async () =>{
        const response = await api.get('/educationlevel/');
        const sorted = sortDictionaryByValues(response.data);
        const options = getOptions(sorted);
        setStudyLevels(options);
        setStudyLevelsOriginal(options);
    };
    const fetchOrganization = async () =>{
        const response = await api.get('/recoginitionorganization/');
        const sorted = sortDictionaryByValues(response.data);
        const options = getOptions(sorted);
        setIssuedOrganizations(options);
        setIssuedOrganizationsOriginal(options);
    };

    fetchStudyField();
    fetchStudyLevel();
    fetchOrganization();

  }, []);

  const getOptions = (data) => {
    return Object.entries(data).map(([key, val]) => ({
      value: key,
      label: val,
    }));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    const updatedData = {
      ...formData,
      [name]: value,
    };
    setFormData(updatedData);
    props.sendEducationalCredentialAssessment(updatedData);
  };

  const handleAddCredential = () => {

    if (checkEducationCA()) {
    
      const updatedData = [...previousCredentials, formData];
      setPreviousCredentials(updatedData);
      props.sendPreviousCredentials(updatedData);
      setFormData({
        ecaObtained: formData.ecaObtained,
        issuingOrganization: '',
        recognizedCredential: '',
        recognizedDiplomaLevel: ''
      });
      /*
      props.sendEducationalCredentialAssessment({
        ecaObtained: formData.ecaObtained,
        issuingOrganization: '',
        recognizedCredential: '',
        recognizedDiplomaLevel: ''
      });*/
    }
    else {
      return;
    }    
  };

  const checkFilledECA = async () =>{
    if (!formData.recognizedCredential || !formData.issuingOrganization || !formData.recognizedDiplomaLevel){
      // toast.error("Please complete the Credential block !");
      setErrors(prevErrors =>({
        ...prevErrors,
        filledECA: "Please complete the Credential block !"
      }));
      return false;
    } else {
      delete errors.filledECA;
      return true;
    }
  }

  const checkEducationCA = async () => {
    if (!formData.ecaObtained || 
      (formData.ecaObtained === 'Yes' && await checkFilledECA())){
      // toast.error("Please complete the Education Credential Assessment block !");
      setErrors(prevErrors =>({
        ...prevErrors,
        educationCA: "Please complete the Education Credential Assessment block !"
      }));
      return false;
    } else {
      delete errors.educationCA;
      return true;
    }
  }
  

  useEffect(() => {
    props.sendPreviousCredentials(previousCredentials);
  }, [previousCredentials, props]);

  return (
    <div className="form-card">
      <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
        <ul style={{ color: 'red' }}>
          {Object.values(errors).map((value, index) => {
              if (value !== '') {
                  return <li key={index}>{value}</li>;
              }
              return null;
          })}
        </ul> 
        <p className='text-bold text-center'>{t("forms.title.eca")}</p>
        <div className='row p-2'>
          <p htmlFor="ecaObtained" className="col-form-label col-sm-10">{t("forms.formsquestion.educationOutsideCountry.ecaObtained")}<RequiredIndicator /></p>
          <div className='col-sm-2'>
            <div className='row'>
              <div className="col form-check">
                <input 
                  type="radio"
                  className="form-check-input"
                  name="ecaObtained"
                  value="Yes"
                  onChange={handleChange}
                  checked={formData.ecaObtained === 'Yes'}
                />
                <p htmlFor="ecaObtainedYes" className="form-check-p">{t("forms.click.yes")}</p>
              </div>
              <div className="col form-check">
                <input 
                  type="radio"
                  className="form-check-input"
                  name="ecaObtained"
                  value="No"
                  onChange={handleChange}
                  checked={formData.ecaObtained === 'No'}
                />
                <p htmlFor="ecaObtainedNo" className="form-check-p">{t("forms.click.no")}</p>
              </div>
            </div>
          </div>
        </div>
        {formData.ecaObtained === 'Yes' && (
          <>
          <div>
          <div>
            { /*<div className='card-header p-1 mb-1 shadow bg-white' style={{ borderRadius: '15px 15px 0px 0px' }}>
              <div className='row'>
                <div className='col-sm col-6 mt-2 text-center'>Name of issuing organization</div>
                <div className='col-sm col-6 mt-2 text-center'>Name of recognized credential</div>
                <div className='col-sm col-6 mt-2 text-center'>Level of recognized diploma</div>
                <div className='col-sm col-6 mt-2 text-center'>Actions</div>
              </div>
            </div>
            <div>
              {previousCredentials && previousCredentials.length > 0 ? (
                previousCredentials.map((credential, index) => (
                  <div className='card-header p-1 mb-1 shadow bg-white' style={{ borderRadius: '15px' }} key={index}>
                    <div className='row'>
                      <div className='col-sm col-6 mt-2 text-center'>{credential.issuingOrganization}</div>
                      <div className='col-sm col-6 mt-2 text-center'>{credential.recognizedCredential}</div>
                      <div className='col-sm col-6 mt-2 text-center'>{credential.recognizedDiplomaLevel}</div>
                      <div className='col-sm col-6 mt-2 text-center'>
                        <button
                          className="btn btn-danger"
                          onClick={() => setPreviousCredentials(previousCredentials.filter((_, i) => i !== index))}
                        >
                          <i className="nav-icon fas fa-trash"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className='text-center'>{t("general.table.empty.noElement")}</div>
              )}
            </div> */ }
          </div>
          </div>
          <div className='row p-2'>
            <p htmlFor="issuingOrganization" className="col-sm-4 col-form-label">{t("forms.formsquestion.educationOutsideCountry.issuingOrganization")}<RequiredIndicator /></p>
            <div className="col-sm-8">
              <Select
                name="issuingOrganization"
                className="react-select-container rounded-pill flous-input-color"
                classNamePrefix="react-select"
                value={issuedOrganizations.find(option => option.value === formData.issuingOrganization)}
                onChange={(selectedOption) => handleChange({ target: { name: 'issuingOrganization', value: selectedOption ? selectedOption.value : '' } })}
                options={issuedOrganizations}
                placeholder={`${t('forms.formsquestion.placeholder.option')}`}
                isSearchable
                isClearable
                styles={{
                  control: (provided) => ({
                    ...provided,
                    width: '100%',
                    borderRadius: '20px', // Ajustez la valeur pour l'arrondissement
                    borderColor: '#ccc',  // Vous pouvez également ajuster la couleur de la bordure si nécessaire
                  }),
                }}
                required
              />
            </div>
          </div>
          <div className='row p-2'>
            <p htmlFor="recognizedCredential" className="col-sm-4 col-form-label">{t("forms.formsquestion.educationOutsideCountry.recognizedCredential")}<RequiredIndicator /></p>
            <div className="col-sm-8">
              <Select
                name="recognizedCredential"
                className="react-select-container rounded-pill flous-input-color"
                classNamePrefix="react-select"
                value={studyFields.find(option => option.value === formData.recognizedCredential)}
                onChange={(selectedOption) => handleChange({ target: { name: 'recognizedCredential', value: selectedOption ? selectedOption.value : '' } })}
                options={studyFields}
                placeholder={`${t('forms.formsquestion.placeholder.option')}`}
                isSearchable
                isClearable
                styles={{
                  control: (provided) => ({
                    ...provided,
                    width: '100%',
                    borderRadius: '20px', // Ajustez la valeur pour l'arrondissement
                    borderColor: '#ccc',  // Vous pouvez également ajuster la couleur de la bordure si nécessaire
                  }),
                }}
                required
              />
            </div>
          </div>
          <div className='row p-2'>
            <p htmlFor="recognizedDiplomaLevel" className="col-sm-4 col-form-label">{t("forms.formsquestion.educationOutsideCountry.recognizedDiplomaLevel")}<RequiredIndicator /></p>
            <div className="col-sm-8">
              <Select
                name="recognizedDiplomaLevel"
                className="react-select-container rounded-pill flous-input-color"
                classNamePrefix="react-select"
                value={studyLevels.find(option => option.value === formData.recognizedDiplomaLevel)}
                onChange={(selectedOption) => handleChange({ target: { name: 'recognizedDiplomaLevel', value: selectedOption ? selectedOption.value : '' } })}
                options={studyLevels}
                placeholder={`${t('forms.formsquestion.placeholder.option')}`}
                isSearchable
                isClearable
                styles={{
                  control: (provided) => ({
                    ...provided,
                    width: '100%',
                    borderRadius: '20px', // Ajustez la valeur pour l'arrondissement
                    borderColor: '#ccc',  // Vous pouvez également ajuster la couleur de la bordure si nécessaire
                  }),
                }}
                required
              />
            </div>
          </div>
          { /*<button
            type='button'
            className='btn btn-block btn-secondary rounded-pill'
            onClick={handleAddCredential}
          >
            Add
          </button> */ }
        </>
        
        )}
      </div>
    </div>
  );
};

export default EducationalCredentialAssessmentSection;