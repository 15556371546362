import React from 'react';

const Completion = () => {

  return (
    <div className='relative overflow-hidden bg-[linear-gradient(270deg,#7FA1F8_0%,#FDFEFE_88%)] min-h-screen'>
      <div className="top-[20px]  bottom-0">
        <img src={`${process.env.PUBLIC_URL}/flous/bg/login-bg.png`} alt="" className="w-[900px] ml-[35%] sm:ml-[60%]   object-cover z-0 " />
      </div>
      <div className='absolute top-0 left-0 right-0 bottom-0 p-2'>
        <div className="flex justify-center items-start min-h-screen pt-10"> {/* Ajustement de l'alignement vers le haut */}
          <div className="col-lg-5 mx-auto">
            <div className="card card-outline elevation-2 p-5" style={{ borderRadius: '15px' }}>
              <div className="mx-auto my-5">
                <img
                  src={`${process.env.PUBLIC_URL}/flous/stripe/success_check.png`}
                  alt="Flous Logo"
                  className="brand-image text-center"
                  style={{ opacity: 0.8, height: '150px', width: '150px' }}
                />
              </div>
              <h3 className="flous-police-one text-center flous-input-color">Payment Successful!</h3>
              <p className="text-center text-bold">Reference number (#) : 213456987</p>
              <a href="/cancel" id="submit" className="btn btn-lg text-center mt-3 rounded-filled flous-btn-primary rounded-pill">
                <span id="button-text" className="">
                  Return to home page
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Completion;
