import React from 'react';
import { Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';

const styles = StyleSheet.create({
  page: {
    padding: 20,
    backgroundColor: '#ffffff',
  },
  mainTitle: {
    fontSize: 20,
    fontFamily: 'Helvetica-Bold',
    color: '#0066CC',
    textAlign: 'center',
    marginBottom: 20,
  },
  card: {
    backgroundColor: '#f8f9ff',
    borderRadius: 8,
    padding: 16,
    paddingBottom: 5,
    marginBottom: 10,
    width: '100%',
  },
  cardTitle: {
    fontSize: 14,
    fontFamily: 'Helvetica-Bold',
    textAlign: 'center',
    marginBottom: 10,
  },
  row: {
    flexDirection: 'row',
    marginBottom: 8,
    gap: 8,
  },
  labelColumn: {
    width: '30%',
  },
  valueColumn: {
    width: '20%',
  },
  label: {
    fontSize: 10,
    color: '#000000',
    fontFamily: 'Helvetica',
  },
  value: {
    fontSize: 10,
    padding: '6 8',
    backgroundColor: '#ffffff',
    borderRadius: 4,
    minHeight: 28,
    border: '1px solid #e2e8f0',
    fontFamily: 'Helvetica',
  },
});

export default function LangueResumeSectionPDF(props) {
  const { t } = useTranslation();

  const renderLanguageProficiency = () => (
    <View style={styles.card}>
      <Text style={styles.cardTitle}>{t("forms.title.languageproficiency")}</Text>
      <View style={styles.row}>
        <View style={{width: '70%'}}>
          <Text style={styles.label}>{t("forms.formsquestion.language.firstOfficialLanguage")}</Text>
        </View>
        <View style={{width: '30%'}}>
          <Text style={styles.value}>{props.languageProficiency.firstOfficialLanguage}</Text>
        </View>
      </View>
    </View>
  );

  const renderLanguageSection = (language) => {
    const isEnglish = language === 'english';
    const langData = isEnglish ? props.languageProficiency.englishLanguageProficiency : props.languageProficiency.frenchLanguageProficiency;
    const testKey = isEnglish ? 'englishtest' : 'frenchtest';
    const educationKey = isEnglish ? 'englishEducation' : 'frenchEducation';

    return (
      <View style={styles.card}>
        <Text style={styles.cardTitle}>{t(isEnglish ? "forms.title.englishlanguage" : "forms.title.frenchlanguage")}</Text>
        <View style={styles.row}>
          <View style={{width: '70%'}}>
            <Text style={styles.label}>{t(`forms.formsquestion.language.${testKey}`)}</Text>
          </View>
          <View style={{width: '30%'}}>
            <Text style={styles.value}>{langData[testKey] === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}</Text>
          </View>
        </View>

        {(langData[testKey] === 'Yes') && (
          <>
            <View style={styles.row}>
              <View style={styles.labelColumn}>
                <Text style={styles.label}>{t("forms.formsquestion.language.testType")}</Text>
              </View>
              <View style={styles.valueColumn}>
                <Text style={styles.value}>{langData.testType}</Text>
              </View>
              <View style={styles.labelColumn}>
                <Text style={styles.label}>{t("forms.formsquestion.language.testDate")}</Text>
              </View>
              <View style={styles.valueColumn}>
                <Text style={styles.value}>{langData.testDate}</Text>
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.labelColumn}>
                <Text style={styles.label}>{t("forms.formsquestion.language.speaking")}</Text>
              </View>
              <View style={styles.valueColumn}>
                <Text style={styles.value}>{langData.speakingScore}</Text>
              </View>
              <View style={styles.labelColumn}>
                <Text style={styles.label}>{t("forms.formsquestion.language.listening")}</Text>
              </View>
              <View style={styles.valueColumn}>
                <Text style={styles.value}>{langData.listeningScore}</Text>
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.labelColumn}>
                <Text style={styles.label}>{t("forms.formsquestion.language.writing")}</Text>
              </View>
              <View style={styles.valueColumn}>
                <Text style={styles.value}>{langData.writingScore}</Text>
              </View>
              <View style={styles.labelColumn}>
                <Text style={styles.label}>{t("forms.formsquestion.language.reading")}</Text>
              </View>
              <View style={styles.valueColumn}>
                <Text style={styles.value}>{langData.readingScore}</Text>
              </View>
            </View>
          </>
        )}

        {langData[testKey] === 'No' && (
          <>
            <View style={styles.row}>
              <View style={{width: '70%'}}>
                <Text style={styles.label}>{t(`forms.formsquestion.language.${educationKey}`)}</Text>
              </View>
              <View style={{width: '30%'}}>
                <Text style={styles.value}>{langData[educationKey] === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}</Text>
              </View>
            </View>

            {(langData[educationKey] === 'Yes' ||  langData[educationKey] === 'No') && (
              <>
                <View style={styles.row}>
                  <View style={styles.labelColumn}>
                    <Text style={styles.label}>{t("forms.formsquestion.language.speaking")}</Text>
                  </View>
                  <View style={styles.valueColumn}>
                    <Text style={styles.value}>{langData.speakingLevel}</Text>
                  </View>
                  <View style={styles.labelColumn}>
                    <Text style={styles.label}>{t("forms.formsquestion.language.listening")}</Text>
                  </View>
                  <View style={styles.valueColumn}>
                    <Text style={styles.value}>{langData.listeningLevel}</Text>
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={styles.labelColumn}>
                    <Text style={styles.label}>{t("forms.formsquestion.language.writing")}</Text>
                  </View>
                  <View style={styles.valueColumn}>
                    <Text style={styles.value}>{langData.writingLevel}</Text>
                  </View>
                  <View style={styles.labelColumn}>
                    <Text style={styles.label}>{t("forms.formsquestion.language.reading")}</Text>
                  </View>
                  <View style={styles.valueColumn}>
                    <Text style={styles.value}>{langData.readingLevel}</Text>
                  </View>
                </View>
              </>
            )}
          </>
        )}
      </View>
    );
  };

  return (
    <Page style={styles.page}>
      <Text style={styles.mainTitle}>{t("client.results.blockTitle.bloc4")}</Text>
      {renderLanguageProficiency()}
      {renderLanguageSection('french')}
      {renderLanguageSection('english')}
    </Page>
  );
}

