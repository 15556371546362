import React, { useState, useEffect } from 'react';
import JobDetailSection from './section_professional_experience/JobDetailSection';
import CompanyInfoSection from './section_professional_experience/CompanyInfoSection';
import JobSpecificsSection from './section_professional_experience/JobSpecificsSection';
import JobAddressSection from './section_professional_experience/JobAddressSection';
import { toast, ToastContainer } from 'react-toastify';
import RequiredIndicator from '../../../general/requiredIndicator';

import { useTranslation } from "react-i18next";

const ProfessionalExperienceSection = (props) => {
  const [professionalExperience, setProfessionalExperience] = useState(props.professionalExperience.professionalExperience);
  const [allProfessionalExperience, setAllProfessionalExperience] = useState(props.allProfessionalExperience);
  const [jobDetail, setJobDetail] = useState(props.professionalExperience.jobDetail);
  const [companyInfo, setCompanyInfo] = useState(props.professionalExperience.companyInfo);
  const [jobSpecifics, setJobSpecifics] = useState(props.professionalExperience.jobSpecifics);
  const [jobAddress, setJobAddress] = useState(props.professionalExperience.jobAddress);
  const [errors, setErrors] = useState({});
  const [addClicked, setAddClicked] = useState(false);
  const { t } = useTranslation();
  const [currentExpe, setCurrentExpe] = useState(null);

  const handleChange = (event) => {
    const { value } = event.target;
    setProfessionalExperience(value);
    const professionalExperienceParent = {
      professionalExperience: value,
      jobDetail,
      // jobDuration,
      companyInfo,
      jobSpecifics,
      jobAddress
    };
    props.sendProfessionalExperience(professionalExperienceParent);
    setErrors({});
  };

  useEffect(() => {
    const professionalExperienceParent = {
        professionalExperience,
        jobDetail,
        companyInfo,
        jobSpecifics,
        jobAddress
    };
    props.sendProfessionalExperience(professionalExperienceParent);
    props.sendAllProfessionalExperience(allProfessionalExperience);
    props.onValidate('professionalExperience', false);
  }, [allProfessionalExperience, professionalExperience, 
    jobDetail, companyInfo, jobSpecifics, 
    jobAddress, props]);

  const handleAllProfession = () => {

    if (check()) {
      setErrors({});
      const professionalExperienceParent = {
        jobDetail,
        companyInfo,
        jobSpecifics,
        jobAddress
      };
      setAddClicked(false);
      if (currentExpe === null) {
        setAllProfessionalExperience([...allProfessionalExperience, professionalExperienceParent]);
      }else {
        const updateData = [...allProfessionalExperience];
        updateData[currentExpe] = professionalExperienceParent;
        setAllProfessionalExperience(updateData);
      }
      setCurrentExpe(null);
      setJobDetail({
        jobTitle: '',
        selfEmployed: '',
        currentJob: '',
        startDate: '',
        endDate: '',
        authorization: '',
        obtainingAuthorization: '',
        jobInAIOrIT: ''
      });
  
      setCompanyInfo({
        companyName: '',
        hoursPerWeek: '',
        hourlyRate: '',
      });
      setJobSpecifics({
        paydayLoans: '',
        explicitContent: '',
        realEstate: ''
      });
      setJobAddress({
        country: '',
        provinceState: '',
        city: '',
        streetNumber: '',
        postalCode: ''
      });
    }
  }

  const handleCancelExpe = () => {
    setAddClicked(false);
    setCurrentExpe(null);
    setJobDetail({
      jobTitle: '',
      selfEmployed: '',
      currentJob: '',
      startDate: '',
      endDate: '',
      authorization: '',
      obtainingAuthorization: '',
      jobInAIOrIT: ''
    });
    setCompanyInfo({
      companyName: '',
      hoursPerWeek: '',
      hourlyRate: '',
    });
    setJobSpecifics({
      paydayLoans: '',
      explicitContent: '',
      realEstate: ''
    });
    setJobAddress({
      country: '',
      provinceState: '',
      city: '',
      streetNumber: '',
      postalCode: ''
    });
    delete errors.jobDetail;
    delete errors.companyInfo;
    delete errors.jobSpecifics;
    delete errors.jobAddress;
  }

  const handleEditExpe = (index) => {
    const updatedFormData = [...allProfessionalExperience];
    const expe = updatedFormData[index];
    setJobDetail(expe.jobDetail);
    setCompanyInfo(expe.companyInfo);
    setJobSpecifics(expe.jobSpecifics);
    setJobAddress(expe.jobAddress);
    setAddClicked(true);
    setCurrentExpe(index);
    delete errors.jobDetail;
    delete errors.companyInfo;
    delete errors.jobSpecifics;
    delete errors.jobAddress;
  }

  const receivedJobDetail = (data) => {setJobDetail(data);};
  const receivedCompanyInfo = (data) => {setCompanyInfo(data);};
  const receivedJobSpecifics = (data) => {setJobSpecifics(data);};
  const receivedJobAddress = (data) => {setJobAddress(data);};

  const pressNext = () => {
    props.sendNext("Professional Expertise");
  };

  const saveForm = () => {
    props.onSave();
  };

  const checkJobAddress = () => {
    if (jobAddress.country === '' || jobAddress.provinceState === '' || 
      jobAddress.city === '' || jobAddress.streetNumber === '') {
      toast.error(`${t('forms.formsquestion.toast.professionalExperience.jobAddress')}`);
      setErrors(prevErrors =>({
        ...prevErrors,
        jobAddress: `${t('forms.formsquestion.toast.professionalExperience.jobAddress')}`
      }));
      return false;
    }else {
      delete errors.jobAddress;
      return true;
    }
  }

  const checkJobDetails = () => {
    if ((jobDetail.jobTitle === '' || jobDetail.selfEmployed === ''  || jobDetail.currentJob === ''
       || jobDetail.startDate === '' || jobDetail.jobInAIOrIT === '') || 
       (jobDetail.currentJob === 'No' &&  jobDetail.endDate === '')){

        toast.error(`${t('forms.formsquestion.toast.professionalExperience.jobDetail')}`);
        setErrors(prevErrors =>({
          ...prevErrors,
          jobDetail: `${t('forms.formsquestion.toast.professionalExperience.jobDetail')}`
        }));
        return false;
    } else if ((jobDetail.isRegulated && jobDetail.authorization === '') || 
      ((jobDetail.isRegulated && jobDetail.authorization === 'No') && jobDetail.obtainingAuthorization === '') ) {
      toast.error(`${t('forms.formsquestion.toast.professionalExperience.jobDetail')}`);
      setErrors(prevErrors =>({
        ...prevErrors,
        jobDetail: `${t('forms.formsquestion.toast.professionalExperience.jobDetail')}`
      }));
      return false;
    }
    else{
      delete errors.jobDetail;
      return true;
    }
  }

  const checkCompanyInformation = () => {
    if (companyInfo.companyName === '' || companyInfo.hoursPerWeek === '' || companyInfo.hourlyRate === '') {
      toast.error(`${t('forms.formsquestion.toast.professionalExperience.companyInfo')}`);
      setErrors(prevErrors =>({
        ...prevErrors,
        companyInfo: `${t('forms.formsquestion.toast.professionalExperience.companyInfo')}`
      }));
      return false;
    }else {
      delete errors.companyInfo;
      return true;
    }
  }

  const checkJobSpecifications = () => {
    if (jobSpecifics.paydayLoans === '' || jobSpecifics.explicitContent === '' || jobSpecifics.realEstate === '') {
      toast.error(`${t('forms.formsquestion.toast.professionalExperience.companyInfo')}`);
      setErrors(prevErrors =>({
        ...prevErrors,
        jobSpecifications: `${t('forms.formsquestion.toast.professionalExperience.jobSpecs')}`
      }));
      return false;
    }else {
      delete errors.jobSpecifications;
      return true;
    }
  }

  const check = () =>{
    if (checkJobAddress() && checkJobDetails() && checkCompanyInformation() && 
    checkJobSpecifications()){
      return true
    } else {
      return false
    }
  }

  const validateSection = () => {
    props.onValidate('professionalExperience', false);
    if (professionalExperience === 'No') {
      props.onValidate('professionalExperience', true);
      toast.success(`${t('forms.formsquestion.toast.sectionValidate')}`);
      setErrors({});
    } else if (professionalExperience === 'Yes') {
      if (allProfessionalExperience.length !== 0) {
        props.onValidate('professionalExperience', true);
        toast.success(`${t('forms.formsquestion.toast.sectionValidate')}`);
        setErrors({});
      } else{
        toast.error(`${t('forms.formsquestion.toast.professionalExperience.atLeastOne')}`);
        setErrors(prevErrors =>({
          ...prevErrors,
          option: `${t('forms.formsquestion.toast.professionalExperience.atLeastOne')}`
        }));
        props.onValidate('professionalExperience', false);
      }
    } else {
      toast.error(`${t('forms.formsquestion.toast.professionalExperience.option')}`);
      setErrors(prevErrors =>({
        ...prevErrors,
        option: `${t('forms.formsquestion.toast.professionalExperience.option')}`
      }));
      props.onValidate('professionalExperience', false);
    }
  };

  return (
    <fieldset id="step6">
      <ul style={{ color: 'red' }}>
        {Object.values(errors).map((value, index) => {
            if (value !== '') {
                return <li key={index}>{value}</li>;
            }
            return null;
        })}
      </ul> 
      <div className="form-card">
        <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
          <p className='text-bold text-center'>{t("forms.title.professionalexperience")}</p>
          <div className='row p-2'>
            <p className="col-form-label col-sm-10">{t("forms.formsquestion.professionalExperience.professionalExperience")}<RequiredIndicator /></p>
            <div className="col-sm-2">
              <div className='row'>
                <div className="form-check col">
                  <input 
                    type="radio"
                    className="form-check-input"
                    value="Yes"
                    onChange={handleChange}
                    checked={professionalExperience === 'Yes'}
                  />
                  <p className="form-check-label">{t("forms.click.yes")}</p>
                </div>
                <div className="form-check col">
                  <input 
                    type="radio"
                    className="form-check-input"
                    value="No"
                    onChange={handleChange}
                    checked={professionalExperience === 'No'}
                  />
                  <p className="form-check-label">{t("forms.click.no")}</p>
                </div>
              </div>
            </div>
          </div>
          {professionalExperience === 'Yes' && (
            <div>
              <div className='card-header p-1 mb-1 shadow bg-white' style={{ borderRadius: '15px 15px 0px 0px' }}>
                <div className='row'>
                  <div className='col-sm col-6 mt-2 text-center'>CNP</div>
                  <div className='col-sm col-6 mt-2 text-center'>{t("general.table.company")}</div>
                  <div className='col-sm col-6 mt-2 text-center'>{t("general.table.startdate")}</div>
                  <div className='col-sm col-6 mt-2 text-center'>{t("general.table.iscurrentjob")}</div>
                  <div className='col-sm col-6 mt-2 text-center'>{t("general.table.actions")}</div>
                </div>
              </div>
              <div>
                {allProfessionalExperience && allProfessionalExperience.length > 0 ? (
                  allProfessionalExperience.map((profession, index) => (
                    <div className='card-header p-1 mb-1 shadow bg-white' style={{ borderRadius: '15px' }} key={index}>
                      <div className='row'>
                        <div className='col-sm col-6 mt-2 text-center'>{profession.jobDetail.jobTitle}</div>
                        <div className='col-sm col-6 mt-2 text-center'>{profession.companyInfo.companyName}</div>
                        <div className='col-sm col-6 mt-2 text-center'>{profession.jobDetail.startDate}</div>
                        <div className='col-sm col-6 mt-2 text-center'>{profession.jobDetail.currentJob === 'Yes' ? `${t('forms.click.yes')}`: `${t('forms.click.no')}`}</div>
                        <div className='col-sm col-6 mt-2 text-center'>
                          <button type='button'
                              className="btn flous-bg-primary mr-1" 
                              onClick={() => handleEditExpe(index)}
                          >
                              <i className="nav-icon fas fa-edit"></i>
                          </button>
                          <button
                            className="btn btn-secondary"
                            onClick={() => setAllProfessionalExperience(allProfessionalExperience.filter((_, i) => i !== index))}
                          >
                            <i className="nav-icon fas fa-trash"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className='text-center'>{t("general.table.empty.noexperience")}</div>
                )}
              
                {!addClicked &&
                  <div className='mt-3'>
                      <button type='button' className='btn btn-block btn-secondary rounded-pill' onClick={()=>{setAddClicked(true)}}>{t("forms.addMoreButton.addExperience")}</button>
                  </div>
                }
              </div>
            </div>          
          )}
        </div>
        {addClicked && (
          <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
        
        {professionalExperience === 'Yes' && (
            <div>
              <JobAddressSection error={errors.jobAddress ? errors.jobAddress : ''}
                sendJobAddress={receivedJobAddress} jobAddress={jobAddress} />
              <JobDetailSection error={errors.jobDetail ? errors.jobDetail : ''}
                evaluationCountry={props.evaluationCountry}
              sendJobDetail={receivedJobDetail} jobDetail={jobDetail}  />
              {/* 
              <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
                <JobDurationSection sendJobDuration={receivedJobDuration} jobDuration={jobDuration} jobDetail={jobDetail}
                />
              </div>*/}
              <CompanyInfoSection error={errors.companyInfo ? errors.companyInfo : ''}
                sendCompanyInfo={receivedCompanyInfo} companyInfo={companyInfo} />
              <JobSpecificsSection error={errors.jobSpecifications ? errors.jobSpecifications : ''}
                sendJobSpecifics={receivedJobSpecifics} jobSpecifics={jobSpecifics}  />
            </div>
        )}
        {/*{professionalExperience === 'Yes' && (
            <div className='d-flex justify-content-center mt-2 m-2'>
              <button type='button' className='col-sm-10 btn btn-block btn-secondary rounded-pill' onClick={handleAllProfession}>Save and Add another</button>
            </div>
          )} */}
        <div className='d-flex justify-content-center mb-3'>
            <div className='col-sm-10'>
              <div className='d-flex justify-content-between'>
                <input type="button" className="col-6 btn flous-btn-gradient rounded-pill mx-1" value={`${t("forms.addMoreButton.saveExperience")}`} onClick={handleAllProfession}/>
                <input type="button" className="col-6 btn btn-block btn-secondary rounded-pill mx-1" value={`${t("forms.addMoreButton.cancel")}`} onClick={()=>{handleCancelExpe()}}/>
              </div>
            </div>
          </div>
          </div>
        )}
        <div className='d-flex justify-content-center'>
          <div className='col-sm-10'>
            <div className='d-flex justify-content-between'>
              <input type="button" name="save" className="col-6 btn flous-btn-gradient rounded-pill mx-1" value={t("forms.click.save")} onClick={saveForm}/>
              <input type="button" name="next" className="col-6 btn flous-btn-gradient rounded-pill mx-1" value={t("forms.click.next")} onClick={pressNext}/>
            </div>
          </div>
          </div>
          <div className='d-flex justify-content-center'>
            <input type="button" name="validate" className="col-sm-6 btn flous-btn-primary rounded-pill mt-2 mx-2" value={t("forms.click.validate")} onClick={validateSection}/>
          </div>
        </div>
      <ToastContainer />
    </fieldset>
  );
};

export default ProfessionalExperienceSection;
