import { useEffect, useState } from "react";
import React from 'react';
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./checkoutForm";
import { loadStripe } from "@stripe/stripe-js";
import { createPaymentIntent } from "../../services/payment/paymentServices";

const Payment = () => {

  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const [price, setPrice] = useState("50"); // in dollars CAD
  const [simulation, setSimulation] = useState("10"); 
  
  useEffect(() => {
      const public_key = "pk_test_51QBNcb2NLn5mqgoDXPVvAPXkN8XeDtIUjm1jhCIGIUCfbay6ElOVplLejUMGknH0c7Q0j0V88qrtJ1OcEpGhAAW900HMTVTa5J";
      setStripePromise(loadStripe(public_key));
  }, []);

  useEffect(() => {
    const getPayIntent = async () => {
        try {
            setPrice(simulation * 10);
            const response = await createPaymentIntent(price);
            const clientSecret = response.data.clientSecret;
            setClientSecret(clientSecret);
        } catch (error) {

        }
    }
    getPayIntent()
  }, [simulation]);

  return (
    <div className='relative overflow-hidden bg-[linear-gradient(270deg,#7FA1F8_0%,#FDFEFE_88%)] min-h-screen'>
      <div className="top-[20px]  bottom-0">
        <img src={`${process.env.PUBLIC_URL}/flous/bg/login-bg.png`} alt="" className="w-[900px] ml-[35%] sm:ml-[60%]   object-cover z-0 " />
      </div>
      <div className='absolute top-0 left-0 right-0 bottom-0 p-2'>
        <div className="flex justify-center items-start min-h-screen pt-10"> {/* Ajustement de l'alignement vers le haut */}
          <div className="col-lg-4 mx-auto">
            <div className="card card-outline elevation-2 p-5" style={{ borderRadius: '15px' }}>
              <div className="card-header text-center">
                <h3 className="flous-police-one text-black">Paying for your subscription</h3>
              </div>
              <div className="mt-2">Simulation number</div>
              <input
                type="number"
                className="form-control"
                id="simulation"
                placeholder="Simulation number"
                name="simulation"
                min={5}
                value={simulation}
                onChange={(e) => setSimulation(e.target.value)}
                required
              />
              <h3 className="flous-police-one text-black m-2">You must pay {price} CAD</h3>
              {clientSecret && stripePromise && (
                <Elements stripe={stripePromise} options={{ clientSecret }}>
                  <CheckoutForm />
                </Elements>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;
