import React, {useState, useEffect} from 'react';
import { getCurrentItems } from '../../services/users/FormsServices';
import PaginationSection from '../Layout/PaginationSection';
import UserMessageItem from './item/userMessagesItem';
import NoItemsMessage from '../general/NoItemsToDisplaysItem';
import { useNavigate, useOutletContext } from 'react-router-dom';
import LoadingOverlay from '../general/LoadingOverlay';
import { fetchInboxMessagesData, setGetInTouchMessageRating } from '../../services/general/MessagesServices';
import { toast, ToastContainer } from 'react-toastify';
import FlousMessageForm from './messageFormSection';
import { useTranslation } from 'react-i18next';

export default function UserMessagesPage({user_type}) {

  const [messages, setMessages] = useState([]);
  const [currentMessage, setCurrentMessage] = useState('');
  const [currentSubject, setCurrentSubject] = useState('');
  const [currentAnswer, setCurrentAnswer] = useState('');
  const [currentMessageID, setCurrentMessageID] = useState('');
  const [currentRating, setCurrentRating] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { user } = useOutletContext();
  const navigate = useNavigate();
  const { t } = useTranslation();
    
  const [page, setPage] = useState(1);
  const pageSize = 5;
  const onPageChange = (page) => {
      setPage(page + 1);
  };

  useEffect( () => {
    let isMounted = true;

    const getInboxMessageDataList = async () => {
      if (user.id) {
        try {
          const response = await fetchInboxMessagesData();
          if (isMounted) {
            setMessages(response.data.data);
            setLoading(false);
          }
        } catch (error) {
          if (isMounted){
            setLoading(false);
            if (error.response && [400,, 401, 404, 500].includes(error.response.status)) {
              setError(error);
              toast.error(error.response.data.error);
            }
          }
        }
      }
    };

    getInboxMessageDataList();

    return () => {
      isMounted = false;
    };

  }, []);

  const handleViewMessage = (index) =>{
    setCurrentSubject(messages[index].subject);
    setCurrentMessage(messages[index].message);
    setCurrentMessageID(messages[index].id);
    setCurrentRating(messages[index].rating);
    setCurrentAnswer(messages[index].answer? messages[index].answer: "N.A");
    setCurrentMessageID(messages[index].id);
    // go to Top
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }
  
  const currentItems = getCurrentItems(messages, page, pageSize);

  const handleRating = async (index) => {

    if (!currentMessageID) {
      toast.error("Please select message to rate.");
      return;
    }

    setCurrentRating(index + 1);
    try {
      setLoading(true)
      const data = {
        rating: index+1
      }

      const response = await setGetInTouchMessageRating(currentMessageID, data);
      if (response && response.status === 200) {
        setLoading(false);
        toast.success("Thanks for your rate. Your feedback is important for us!");
      }
    } catch (error) {
      setLoading(false);
      if (error.response && [400, 401, 404, 500].includes(error.response.status)) {
        setError(error);
        toast.error(error.response.data.error);
      }
    }
  };

  if (loading) {
    return <LoadingOverlay />;
  }

  if (error) {
    return <div>Error : {error.message}</div>;
  }


  return (
    <div className="content">
      <div className="container-fluid">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 flous-police-one flous-input-color">{t("general.message.title")}</h1>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="col">
            <div className="card" style={{ borderRadius: '15px' }}>
            <FlousMessageForm/>
            </div>
          </div>
            <div className="">
            {currentMessageID !== '' && (
              <div className='col'>
                <div className='card card-body' style={{ borderRadius: '15px' }}>
                    <div className="flous-police-one flous-input-color">Selected Subject : {currentSubject}</div>
                    <div className='flous-bg-thirty p-3 elevation-1' style={{ borderRadius: '15px' }}>
                        <div className='row'>
                            <div className="col-md-2 col-12 card card-primary" style={{ borderRadius: '15px' }}>
                                <div className="card-body box-profile">
                                <div className="d-flex justify-content-center">
                                    <div className="image">
                                    {user.profile_picture_url ? (
                                      <>
                                      <img
                                        src={user.profile_picture_url}
                                        className="rounded-full"
                                        alt="User profile"
                                        style={{ width: '100', height: '100', objectFit: 'cover' }}
                                      />
                                      </>
                                    ):(
                                      <>
                                      <img
                                        src={`${process.env.PUBLIC_URL}/avatar/avatar.png`}
                                        className="rounded-full"
                                        alt="User profile"
                                        style={{ width: '100', height: '100', objectFit: 'cover' }}
                                      />
                                      </>
                                    )}
                                    </div>
                                </div>
                            </div>
                            {/*<p className="text-center flous-police-one flous-input-color">Me</p>*/}
                            </div>
                            <div className='col-md col-12'>
                                <div className="form-group">
                                    <textarea 
                                      className="form-control" 
                                      rows="5" placeholder="Message ..." 
                                      name="message" 
                                      value={currentMessage}
                                    >

                                    </textarea>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-md-2 col-12 card card-primary" style={{ borderRadius: '15px', height: '180px' }}>
                                <div className="card-body box-profile">
                                <div className="d-flex justify-content-center">
                                    <div className="image">
                                        <img src={`${process.env.PUBLIC_URL}/flous/logos/logo_2.svg`} className="border rounded-circle" alt="Flous logo" width="100" height="100"/>
                                    </div>
                                </div>
                                </div>
                                {/*<p className="text-center flous-police-one flous-input-color">Flous</p>*/}
                            </div>
                            <div className='col-md col-12'>
                                <div className="form-group">
                                    <textarea 
                                        className="form-control" 
                                        rows="20" 
                                        style={{ minHeight: '100px', maxHeight: '300px' }} 
                                        placeholder="Message ..." 
                                        name="message" 
                                        value={currentAnswer}>
                                    </textarea>
                                </div>
                                <div className='card card-body mx-1'>
                                  <div className='row'>
                                    <div className='col-sm-6 col-12 flous-input-color text-bold'>
                                    {t("general.message.feedback")}
                                    </div>
                                    <div className='col-sm-6 col-12'>
                                      {[...Array(5)].map((_, index) => (
                                        <i
                                          key={index}
                                          className={`fas fa-star`}
                                          style={{
                                            fontSize: '32px',
                                            marginRight: '10px',
                                            color: index < currentRating ? '#235CBE' : 'gray',
                                            cursor: 'pointer'
                                          }}
                                          onClick={() => handleRating(index)}
                                        ></i>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
            )}
            <div className="col">
              <div className="card" style={{ borderRadius: '15px' }}>
                <div className="card-header">
                    <div className="row">
                      <div className='col-sm col-12 ml-3 flous-input-color text-bold'>
                        History
                      </div>
                      <div className='col-sm-2 col-6 btn flous-input-color'><i className="nav-icon fas fa-search"></i> {t("general.table.search")}</div>
                      <div className='col-sm-2 col-6 btn flous-input-color'><i className="nav-icon fas fa-sort"></i> {t("general.table.sort")}</div>
                    </div>
                </div>
                <div className="card-body">
                  <div className='flous-bg-thirty' style={{ borderRadius: '15px' }}>
                    <div className='card-header p-3 mb-2 shadow bg-white' style={{ borderRadius: '15px 15px 0px 0px' }}>
                        <div className='row text-bold'>
                            <div className='col-sm-1 col-12 mt-2 text-center'>{t("general.table.type")}</div>
                            <div className='col-sm-1 col-12 mt-2 text-center'>{t("general.table.subject")}</div>
                            <div className='col-sm col-12 mt-2 text-center'>{t("general.table.message")}</div>
                            <div className='col-sm col-12 mt-2 text-center'>{t("general.table.flousans")}</div>
                            <div className='col-sm-1 col-6 mt-2 text-center'>{t("general.table.date")}</div>
                            <div className='col-sm-1 col-6 mt-2 text-center'>{t("general.table.state")}</div>
                            <div className='col-sm-2 col-12 mt-2 text-center'>{t("general.table.actionss")}</div>
                        </div>
                    </div>
                    {currentItems.length > 0 ? (
                      currentItems.map((message, index) => (
                      <UserMessageItem 
                        message={message} user={user_type}
                        index={index} sendSelectedIndex={handleViewMessage} />
                    ))) : (
                      <NoItemsMessage />
                    )}
                  </div>
                </div>
                <PaginationSection
                      page={page}
                      pageSize={pageSize}
                      totalItems={messages.length}
                      onPageChange={onPageChange}
                    />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};