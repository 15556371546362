import React from 'react';
import { Page, View, Text, StyleSheet } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';

const styles = StyleSheet.create({
  page: {
    padding: 20,
    backgroundColor: '#ffffff',
  },
  card: {
    backgroundColor: '#f8f9ff',
    borderRadius: 8,
    padding: 16,
    paddingBottom: 5,
    marginBottom: 10,
    width: '100%',
  },
  title: {
    fontSize: 16,
    marginBottom: 0,
    fontFamily: 'Helvetica-Bold',
  },
  row: {
    flexDirection: 'row',
    marginBottom: 12,
    gap: 12,
  },
  labelColumn: {
    width: '30%',
  },
  valueColumn: {
    width: '20%',
  },
  label: {
    fontSize: 10,
    color: '#000000',
    fontWeight: "bold",
    marginBottom: 0,
    fontFamily: 'Helvetica',
  },
  value: {
    fontSize: 10,
    padding: '6 8',
    backgroundColor: '#ffffff',
    borderRadius: 4,
    minHeight: 20,
    border: '1px solid #e2e8f0',
    fontFamily: 'Helvetica',
  },
  separator: {
    marginVertical: 10,
    borderBottom: '1 solid #ccc',
  },
})

const FormDetailIntroductionPDF = ({ data }) => {

  const { t } = useTranslation();
  
  return (
    <Page size="A4" style={styles.page}>
      <View style={styles.card}>
        <Text style={styles.title}>{t('client.forms.myforms.summary.title')}</Text>
        <View style={styles.separator} />
        <View style={styles.row}>
          <View style={styles.labelColumn}>
            <Text style={styles.label}>{t('client.forms.myforms.summary.formTitle')}</Text>
          </View>
          <View style={styles.valueColumn}>
            <Text style={styles.value}>{data.formTitle || ''}</Text>
          </View>
          <View style={styles.labelColumn}>
            <Text style={styles.label}>{t('client.forms.myforms.summary.formCode')}</Text>
          </View>
          <View style={styles.valueColumn}>
            <Text style={styles.value}>{data.formCode || ''}</Text>
          </View>
        </View>

        <View style={styles.row}>
          <View style={styles.labelColumn}>
            <Text style={styles.label}>{t('client.forms.myforms.summary.evaluationCountry')}</Text>
          </View>
          <View style={styles.valueColumn}>
            <Text style={styles.value}>{data.evaluationCountry || 'Canada'}</Text>
          </View>
          <View style={styles.labelColumn}>
            <Text style={styles.label}>{t('client.forms.myforms.summary.creationDate')}</Text>
          </View>
          <View style={styles.valueColumn}>
            <Text style={styles.value}>{data.creationDate || ''}</Text>
          </View>
        </View>

        <View style={styles.row}>
          <View style={styles.labelColumn}>
            <Text style={styles.label}>{t('client.forms.myforms.summary.lastUpdate')}</Text>
          </View>
          <View style={styles.valueColumn}>
            <Text style={styles.value}>{data.updateCompletedDate || ''}</Text>
          </View>
          <View style={styles.labelColumn}>
            <Text style={styles.label}>{t('client.forms.myforms.summary.status')}</Text>
          </View>
          <View style={styles.valueColumn}>
            <Text style={styles.value}>{data.status || 'IN PROGRESS'}</Text>
          </View>
        </View>

        <View style={styles.row}>
          <View style={styles.labelColumn}>
            <Text style={styles.label}>{t('client.forms.myforms.summary.isCoApplication')}</Text>
          </View>
          <View style={styles.valueColumn}>
            <Text style={styles.value}>{data.isCoApplication === 'Yes' ? `${t('forms.click.yes')}`: `${t('forms.click.no')}`}</Text>
          </View>
          <View style={styles.labelColumn} />
          <View style={styles.valueColumn} />
        </View>
      </View>
    </Page>
  );
};

export default FormDetailIntroductionPDF;

