import React from 'react';
import { useTranslation } from 'react-i18next';

const NewFormModal = ({ isOpen, onClose, onConfirm }) => {
  const { t } = useTranslation();
  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal fade" id="newFormModal" tabindex="-1" aria-labelledby="newFormModalLabel" aria-hidden="true">
        <div className="modal-dialog">
            <div className="modal-content p-5" style={{ borderRadius: '15px' }}>
                <div className="modal-header d-flex justify-content-center flous-input-color">
                    <h4 className='flous-police-one text-center'>{t("modal.evaluation.message")}</h4>
                </div>
                <div className="modal-body text-center">
                    <h5 className="modal-title" id="newFormModalLabel">{t("modal.evaluation.title")}</h5>
                    <hr/>
                    <div type="button" className="btn btn-block text-bold btn-lg btn-light elevation-1" data-dismiss="modal">$15</div>
                    <div className='flous-input-color m-3'>{t("modal.evaluation.message2")}</div>
                    <button type="button" className="btn btn-lg text-bold flous-bg-menu rounded-pill px-5" data-dismiss="modal" onClick={onConfirm}>{t("modal.evaluation.submit")}</button>
                </div>
                <div className="modal-footer d-flex justify-content-center">
                    <button type="button" className="btn btn-lg flous-input-color flous-bg-thirty rounded-pill px-5" data-dismiss="modal" onClick={onClose}>{t("modal.cancel")}</button>
                </div>
            </div>
        </div>
    </div>
  );
};

export default NewFormModal;
