import React, { useState } from 'react';
import RequiredIndicator from '../../../../../general/requiredIndicator';

import { useTranslation } from 'react-i18next';

const CompanyFinancySection = (props) => {
  const [formData, setFormData] = useState(props.companyFinances);
  const { t } = useTranslation()

  const handleChange = (event) => {
    const { name, value } = event.target;
    const updatedData = {
      ...formData,
      [name]: value,
    };
    setFormData(updatedData);
    props.sendCompanyFinances(updatedData);
  };

  return (
    <div className="form-card">
      <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
      <div style={{ color: 'red' }}>{props.error !== '' ? `${t('forms.formsquestion.toast.completeSection')}` : ''}</div>
        <p className='text-bold text-center'>{t("forms.title.companyfinance")}</p>
        <div className='row p-2'>
          <p className="col-sm-6 col-form-label">{t("forms.formsquestion.entrepreunarialExperience.companyValue")}<RequiredIndicator /></p>
          <div className="col-sm-6">
            <input 
              type="number" 
              className="form-control rounded-pill flous-input-color"
              name="companyValue"
              value={formData.companyValue}
              onChange={handleChange} 
              placeholder='$ (CAD)' min={0}
            />
          </div>
        </div>
        <div className='row p-2'>
          <p className="col-sm-6 col-form-label">{t("forms.formsquestion.entrepreunarialExperience.startupExpenses")}<RequiredIndicator /></p>
          <div className="col-sm-6">
            <input 
              type="number" 
              className="form-control rounded-pill flous-input-color"
              name="startupExpenses"
              value={formData.startupExpenses}
              onChange={handleChange} 
              placeholder='$ (CAD)' min={0}
            />
          </div>
        </div>
        <div className='row p-2'>
          <p className="col-sm-6 col-form-label">{t("forms.formsquestion.entrepreunarialExperience.operatingExpenses")}<RequiredIndicator /></p>
          <div className="col-sm-6">
            <input 
              type="number" 
              className="form-control rounded-pill flous-input-color"
              name="operatingExpenses"
              value={formData.operatingExpenses}
              onChange={handleChange} 
              placeholder='$ (CAD)' min={0}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyFinancySection;