import React, { useEffect, useState } from 'react';
import { Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import CountryShow from '../../../general/countryShow';

const styles = StyleSheet.create({
  page: {
    padding: 20,
    backgroundColor: '#ffffff',
  },
  mainTitle: {
    fontSize: 20,
    fontFamily: 'Helvetica-Bold',
    color: '#0066CC',
    textAlign: 'center',
    marginBottom: 20,
  },
  card: {
    backgroundColor: '#f8f9ff',
    borderRadius: 8,
    padding: 16,
    paddingBottom: 5,
    marginBottom: 10,
    width: '100%',
  },
  cardTitle: {
    fontSize: 14,
    fontFamily: 'Helvetica-Bold',
    textAlign: 'center',
    marginBottom: 10,
  },
  sectionTitle: {
    fontSize: 12,
    fontFamily: 'Helvetica-Bold',
    marginBottom: 8,
  },
  row: {
    flexDirection: 'row',
    marginBottom: 8,
    gap: 8,
  },
  labelColumn: {
    width: '30%',
  },
  valueColumn: {
    width: '20%',
  },
  label: {
    fontSize: 10,
    color: '#000000',
    fontFamily: 'Helvetica',
  },
  value: {
    fontSize: 10,
    padding: '6 8',
    backgroundColor: '#ffffff',
    borderRadius: 4,
    minHeight: 28,
    border: '1px solid #e2e8f0',
    fontFamily: 'Helvetica',
  },
});

export default function JobOfferResumeSectionPDF(props) {
  const { t } = useTranslation();
  

  const renderCompanyAddress = () => (
    <View style={styles.card}>
      <Text style={styles.cardTitle}>{t("forms.title.companyaddress")}</Text>
      <View style={styles.row}>
        <View style={styles.labelColumn}>
          <Text style={styles.label}>{t("forms.formsquestion.jobOffer.jobOffer")} Canada?</Text>
          </View>
                      <View style={styles.valueColumn}>
                        <Text style={styles.value}>
            {props.jobOffer.jobOffer === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}
          </Text>
        </View>
        <View style={styles.labelColumn}>
          <Text style={styles.label}>{t("forms.formsquestion.jobOffer.provinceState")}</Text>
          </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>
            {props.jobOffer.companyDetail.provinceState}
          </Text>
        </View>
      </View>
      <View style={styles.row}>
        <View style={styles.labelColumn}>
          <Text style={styles.label}>{t("forms.formsquestion.jobOffer.city")}</Text>
          </View>
          <View style={styles.valueColumn}>
              <Text style={styles.value}>{props.jobOffer.companyDetail.city}</Text>
        </View>
        <View style={styles.labelColumn}>
          <Text style={styles.label}>{t("forms.formsquestion.jobOffer.streetNumber")}</Text>
          </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>{props.jobOffer.companyDetail.streetNumber}</Text>
        </View>
      </View>
      <View style={styles.row}>
        <View style={styles.labelColumn}>
          <Text style={styles.label}>{t("forms.formsquestion.jobOffer.postalCode")}</Text>
          </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>{props.jobOffer.companyDetail.postalCode}</Text>
        </View>
      </View>
    </View>
  );

  const renderJobDetail = () => (
    <View style={styles.card}>
      <Text style={styles.cardTitle}>{t("forms.title.jobdetail")}</Text>
      <View style={styles.row}>
        <View style={styles.labelColumn}>
          <Text style={styles.label}>{t("forms.formsquestion.jobOffer.dateOfIssued")}</Text>
          </View>
          <View style={styles.valueColumn}>
            <Text style={styles.value}>{props.jobOffer.jobDetail.dateOfIssued}</Text>
        </View>
        <View style={styles.labelColumn}>
          <Text style={styles.label}>{t("forms.formsquestion.jobOffer.currentJob")}</Text>
          </View>
          <View style={styles.valueColumn}>
            <Text style={styles.value}>
            {props.jobOffer.jobDetail.currentJob === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}
          </Text>
        </View>
      </View>
      <View style={styles.row}>
        <View style={styles.labelColumn}>
          <Text style={styles.label}>{t("forms.formsquestion.jobOffer.lmiaExempt")}</Text>
          </View>
          <View style={styles.valueColumn}>
            <Text style={styles.value}>
            {props.jobOffer.jobDetail.lmiaExempt === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}
          </Text>
        </View>
        <View style={styles.labelColumn}>
          <Text style={styles.label}>{t("forms.formsquestion.jobOffer.provinceValidated")}</Text>
          </View>
          <View style={styles.valueColumn}>
            <Text style={styles.value}>
            {props.jobOffer.jobDetail.provinceValidated === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}
          </Text>
        </View>
      </View>
    </View>
  );

  const renderProfessionalDetail = () => (
    <View style={styles.card}>
      <Text style={styles.cardTitle}>{t("forms.title.professionaldetail")}</Text>
      <View style={styles.row}>
        <View style={{width: '70%'}}>
          <Text style={styles.label}>{t("forms.formsquestion.jobOffer.professionalField")}</Text>
          </View>
          <View style={{width: '30%'}}>
            <Text style={styles.value}>
            {props.jobOffer.professionalDetail.professionalField}
          </Text>
        </View>
      </View>
      {props.jobOffer.professionalDetail.isRegulated && (
        <View style={styles.row}>
          <View style={{width: '70%'}}>
            <Text style={styles.label}>{t("forms.formsquestion.jobOffer.authorizationToPractice")}</Text>
            </View>
          <View style={{width: '30%'}}>
            <Text style={styles.value}>
              {props.jobOffer.professionalDetail.authorizationToPractice === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}
            </Text>
          </View>
        </View>
      )}
      {props.jobOffer.professionalDetail.isRegulated && props.jobOffer.professionalDetail.authorizationToPractice === 'No' && (
        <View style={styles.row}>
          <View style={{width: '70%'}}>
            <Text style={styles.label}>{t("forms.formsquestion.jobOffer.processOfObtainingAuthorization")}</Text>
            </View>
          <View style={{width: '30%'}}>
            <Text style={styles.value}>
              {props.jobOffer.professionalDetail.processOfObtainingAuthorization === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}
            </Text>
          </View>
        </View>
      )}
      {props.jobOffer.professionalDetail.isRegulated && props.jobOffer.professionalDetail.processOfObtainingAuthorization === 'No' && (
        <View style={styles.row}>
          <View style={{width: '70%'}}>
            <Text style={styles.label}>{t("forms.formsquestion.jobOffer.diplomaAuthorization")}</Text>
            </View>
          <View style={{width: '30%'}}>
            <Text style={styles.value}>
              {props.professionalDetail.diplomaAuthorization === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}
            </Text>
          </View>
        </View>
      )}
    </View>
  );

  const renderCompanyInfo = () => (
    <View style={styles.card}>
      <Text style={styles.cardTitle}>{t("forms.title.company")}</Text>
      <Text style={styles.sectionTitle}>Job Compensation</Text>
      <View style={styles.row}>
        <View style={styles.labelColumn}>
          <Text style={styles.label}>{t("forms.formsquestion.jobOffer.hourlyRate")}</Text>
          </View>
          <View style={styles.valueColumn}>
            <Text style={styles.value}>{props.jobOffer.jobCompensation.hourlyRate}</Text>
        </View>
        <View style={styles.labelColumn}>
          <Text style={styles.label}>{t("forms.formsquestion.jobOffer.annualSalary")}</Text>
          </View>
          <View style={styles.valueColumn}>
            <Text style={styles.value}>{props.jobOffer.jobCompensation.annualSalary}</Text>
        </View>
      </View>
      <Text style={styles.sectionTitle}>Company Information</Text>
      <View style={styles.row}>
        <View style={styles.labelColumn}>
          <Text style={styles.label}>{t("forms.formsquestion.jobOffer.companyName")}</Text>
          </View>
          <View style={styles.valueColumn}>
            <Text style={styles.value}>{props.jobOffer.companyInfo.companyName}</Text>
        </View>
        <View style={styles.labelColumn}>
          <Text style={styles.label}>{t("forms.formsquestion.jobOffer.numberOfEmployed")}</Text>
          </View>
          <View style={styles.valueColumn}>
            <Text style={styles.value}>{props.jobOffer.companyInfo.numberOfEmployed}</Text>
        </View>
      </View>
      <View style={styles.row}>
        <View style={styles.labelColumn}>
          <Text style={styles.label}>{t("forms.formsquestion.jobOffer.annualGrossRevenue")}</Text>
          </View>
          <View style={styles.valueColumn}>
            <Text style={styles.value}>{props.jobOffer.companyInfo.annualGrossRevenue}</Text>
        </View>
        <View style={styles.labelColumn}>
          <Text style={styles.label}>{t("forms.formsquestion.jobOffer.foundingDate")}</Text>
          </View>
          <View style={styles.valueColumn}>
            <Text style={styles.value}>{props.jobOffer.companyInfo.foundingDate}</Text>
        </View>
      </View>
    </View>
  );

  return (
    <Page style={styles.page}>
      <Text style={styles.mainTitle}>{t("client.results.blockTitle.bloc8")}</Text>
      {props.jobOffer.jobOffer === 'Yes' ? (
        <>
          {renderCompanyAddress()}
          {renderJobDetail()}
          {renderProfessionalDetail()}
          {renderCompanyInfo()}
        </>
      ) : (
        <View style={styles.card}>
          <Text style={styles.cardTitle}>{t("general.table.empty.nojob")}</Text>
        </View>
      )}
    </Page>
  );
}

