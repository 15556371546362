import React from 'react';
import CalendlyLinkWidget from '../Layout/calendly_test2';
import FlagComponent from '../general/FlagComponent';
import { useTranslation } from 'react-i18next';

const FlousFileItem = ({ item }) => {

    const { t } = useTranslation();

  return (
    <div className='card p-3 mb-3 mx-1' style={{ borderRadius: '15px' }}>
        <div className='row'>
            <div className="col-sm col-12 mt-2 text-center">
                <div className="flex items-center justify-center">
                    <span className="image">
                        <img src={item.logo_url} className="rounded-circle" alt="User Image" width="30" height="30" />
                    </span>
                    <span className="ml-2">{item.company_name}</span>
                </div>
            </div>
            <div className='col-sm col-6 mt-2 text-center'>{item.type} </div>
            <div className='col-sm col-6 mt-2 text-center'>
                <FlagComponent cca3={item.country_cca3} />
            </div>
            <div className='col-sm col-6 mt-2 text-center'>  {item.languages} </div>
            <div className='col-sm col-6 mt-2 text-center'>{item.contact} </div>
            <div className='col-sm col-12 mt-2 text-center'> {item.instagram}, {item.linkedin}, {item.website}</div>
            <div className='col-sm col-12 mt-2 text-center'>
                <a href={`/user/asking/${item.id}`} className="btn btn-sm flous-bg-primary m-1">{t("general.table.moreinfo")} <i className="nav-icon fas fa-eye"></i></a>
                <button type="button" className="btn btn-sm flous-bg-secondary mr-2">
                <CalendlyLinkWidget />
                </button>
            </div>
        </div>
    </div>
  );
};

export default FlousFileItem;
