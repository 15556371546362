import React from 'react';

import { useTranslation } from 'react-i18next';

export default function CompanyActivitiesResumeSection(props) {
  const { t } = useTranslation();
  // Define the company activities information
  const companyActivities = {
    companyDescription: props.companyActivites.businessSector, //  
    involvesPaydayLoans: props.companyActivites.paydayLoans, //  
    involvesExplicitContent: props.companyActivites.explicitContent, //  
    involvesRealEstate: props.companyActivites.realEstate //  
  };

  return (
    <div className='flous-bg-thirty p-3 elevation-2 mb-3' style={{ borderRadius: '15px' }}>
      <div className='text-bold text-center mb-3'>{t("forms.title.companyactivities")}</div>
      <div className='mx-3'>
        <div className="form-group row">
          <label htmlFor="inputCompanyDescription" className="col-sm-6 col-form-label">
          {t("forms.formsquestion.entrepreunarialExperience.businessSector")}
          </label>
          <div className="col-sm-6">
            <input type="text" className="form-control" id="inputCompanyDescription" value={companyActivities.companyDescription} placeholder="N.A"/>
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="inputPaydayLoans" className="col-sm-6 col-form-label">
          {t("forms.formsquestion.entrepreunarialExperience.paydayLoans")}
          </label>
          <div className="col-sm-6">
            <input type="text" className="form-control" id="inputPaydayLoans" value={companyActivities.involvesPaydayLoans === 'Yes' ? `${t('forms.click.yes')}`: `${t('forms.click.no')}`} placeholder="N.A"/>
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="inputExplicitContent" className="col-sm-6 col-form-label">
          {t("forms.formsquestion.entrepreunarialExperience.explicitContent")}
          </label>
          <div className="col-sm-6">
            <input type="text" className="form-control" id="inputExplicitContent" value={companyActivities.involvesExplicitContent === 'Yes' ? `${t('forms.click.yes')}`: `${t('forms.click.no')}`} placeholder="N.A"/>
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="inputRealEstate" className="col-sm-6 col-form-label">
          {t("forms.formsquestion.entrepreunarialExperience.realEstate")}
          </label>
          <div className="col-sm-6">
            <input type="text" className="form-control" id="inputRealEstate" value={companyActivities.involvesRealEstate === 'Yes' ? `${t('forms.click.yes')}`: `${t('forms.click.no')}`} placeholder="N.A"/>
          </div>
        </div>
      </div>
    </div>
  );
}
