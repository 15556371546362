import React from 'react';
import { useTranslation } from 'react-i18next';

export default function SupportbySpecializedOResumeSection(props) {
  const { t } = useTranslation();
  
  return (
    <div className='flous-bg-thirty p-3 elevation-2 mb-3' style={{ borderRadius: '15px' }}>
      <div className='text-bold text-center mb-3'>{t("forms.title.supportby")}</div>
      <div className='mx-3'>
        <div className="form-group row">
          <label htmlFor="inputHasMadeBid" className="col-sm-6 col-form-label">
          {t("forms.formsquestion.entrepreunarialExperience.receivedServiceOffer")}
          </label>
          <div className="col-sm-6">
            <input type="text" className="form-control" id="inputHasMadeBid" value={props.supportby.receivedServiceOffer === 'Yes' ? `${t('forms.click.yes')}`: `${t('forms.click.no')}`} placeholder="N.A"/>
          </div>
        </div>
      </div>
    </div>
  );
}
