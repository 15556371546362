import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const AssignToCustomersModal = () => {
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const { t } = useTranslation();

  const handleCustomerChange = (e) => {
    setSelectedCustomer(e.target.value);
  };

  const handleSubmit = () => {
    
    console.log("Client assigné:", selectedCustomer);
  };

  return (
    <div id="generalModal" className="modal fade" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="modalTitle">{t("modal.assigntoadvisor.title")}</h4>
            <button type="button" className="close" data-dismiss="modal">&times;</button>
          </div>
          <div className="modal-body">
            <div className="col">
              <div className="form-group">
                <label htmlFor="customers">{t("modal.assigntoadvisor.message2")}</label>
                <select
                  name="customers"
                  id="customers"
                  className="form-control select2bs4"
                  value={selectedCustomer} // Liaison de la sélection au state
                  onChange={handleCustomerChange} // Gestionnaire d'événements pour la sélection
                >
                  <option value="">Select a Client</option>
                  <option value="Aly">Aly</option>
                  <option value="Keita">Keita</option>
                </select>
              </div>
            </div>
            <div className="col">
              <button className="btn flous-bg-primary" onClick={handleSubmit}>
              {t("modal.assigntoadvisor.submit")}
              </button>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-default" data-dismiss="modal">{t("modal.cancel")}</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignToCustomersModal;