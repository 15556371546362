import React from 'react';

import { useTranslation } from 'react-i18next';

export default function ContactInfoResumeSection(props) {
  const { t } = useTranslation();
  // Define the contact information
  const email = props.contactInfo.email; //  
  const phoneNumber = props.contactInfo.phone; //  

  return (
    <div className='flous-bg-thirty p-3 elevation-2 mb-3' style={{ borderRadius: '15px' }}>
      <div className='text-bold text-center mb-3'>{t("forms.title.contactinfo")}</div>
      <div className='mx-3'>
        <div className="form-group row">
          <label htmlFor="inputEmail" className="col-sm-3 col-form-label">{t("forms.formsquestion.personalInfo.email")}</label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputEmail" value={email} placeholder="N.A"/>
          </div>
          <label htmlFor="inputPhoneNumber" className="col-sm-3 col-form-label">{t("forms.formsquestion.personalInfo.phone")}</label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputPhoneNumber" value={phoneNumber} placeholder="N.A"/>
          </div>
        </div>
      </div>
    </div>
  );
}
