import React, { useState } from 'react';
import GoToBackButton from '../general/GoBackLink';
import { useLocation } from 'react-router-dom';
import LoadingOverlay from '../general/LoadingOverlay';
import { toast, ToastContainer } from 'react-toastify';
import { updateContactUsMessage } from '../../services/flous/inboxServices';
import { useTranslation } from 'react-i18next';


export default function FlousCustomersOldMessagesPage() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const location = useLocation();
    const message = location.state?.data;
    const isContactUs = location.state?.isContactUs;
    const [flousAnswer, setFlousAnswer] = useState(message.answer? message.answer:'');
    const { t } = useTranslation();

    const handleChange = (e) => {
        const { value } = e.target;
        setFlousAnswer(value);
    }

    const handleSaveAnswer = async () => {
        setLoading(true);
        try {
        const data = {
            answer: flousAnswer,
        }
        const response = await updateContactUsMessage(message.id, data);
        if (response && response.status === 200) {
            setLoading(false);
            toast.success(response.data.message);
        }
        } catch (error) {
        setLoading(false);
        if (error.response && [400, 500].includes(error.response.status)) {
            toast.error(error.response.data.error);
        }
        }
    }

    if (loading) {
      return <LoadingOverlay />;
    }
  
    if (error) {
      return <div>Error : {error.message}</div>;
    }

    return (
    <div className="content">
      <div className="container-fluid">
        <div className="content-header">
          <div className="container-fluid">
            <div className="mb-2">
             <GoToBackButton />
              <div className="col-sm-6">
                <h1 className="m-0 flous-police-one flous-input-color">{t("flous.messages.title")}</h1>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="">
          <div className='col'>
                <div className='card card-body' style={{ borderRadius: '15px' }}>
                    <div className='flous-bg-thirty p-3 elevation-1' style={{ borderRadius: '15px' }}>
                        <div className='row'>
                            <div className="col-md-2 col-12 card card-primary" style={{ borderRadius: '15px' }}>
                                <div className="card-body box-profile">
                                <div className="d-flex justify-content-center">
                                    <div className="image">
                                        <img src={`${process.env.PUBLIC_URL}/avatar/avatar.png`} className="border rounded-circle" alt="User Image" width="100" height="100"/>
                                    </div>
                                </div>
                            </div>
                            <p className="text-center flous-police-one flous-input-color">{message.firstName} {message.lastName} </p>
                            {isContactUs && 
                                <p className="text-center flous-police-one">{message.company} </p>
                            }
                            </div>
                            <div className='col-md col-12'>
                                <div className="form-group">
                                    <textarea className="form-control" rows="3" placeholder="Message ..." name="message" value={message.message}></textarea>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-md-2 col-12 card card-primary" style={{ borderRadius: '15px', height: '180px' }}>
                                <div className="card-body box-profile">
                                <div className="d-flex justify-content-center">
                                    <div className="image">
                                        <img src={`${process.env.PUBLIC_URL}/flous/logos/trans_1.svg`} className="border rounded-circle" alt="User Image" width="100" height="100"/>
                                    </div>
                                </div>
                                </div>
                                <p className="text-center flous-police-one flous-input-color">Flous</p>
                            </div>
                            <div className='col-md col-12'>
                                {isContactUs ? (
                                    <>
                                    <div className="form-group">
                                        <textarea 
                                            className="form-control" rows="10" 
                                            placeholder="Enter ..." name="flousAnswer" value={flousAnswer}
                                            onChange={handleChange}
                                        >
                                        </textarea>
                                    </div>
                                    <div onClick={handleSaveAnswer} className="d-flex justify-content-end">
                                        <button type="submit" className="col-4 btn flous-bg-menu btn-block">{t("flous.messages.submit")}</button>
                                    </div>
                                    </>
                                ):(
                                    <>
                                    <div className="form-group">
                                        <textarea 
                                            className="form-control" 
                                            rows="20" 
                                            style={{ minHeight: '100px', maxHeight: '300px' }} 
                                            placeholder="Message ..." 
                                            name="answer" 
                                            value={message.answer}
                                            >
                                        </textarea>
                                    </div>
                                    <div className='card card-body mx-1'>
                                        <div className='row'>
                                            <div className='col-sm-6 col-12 flous-input-color text-bold'>
                                            {t("flous.messages.review")}
                                            </div>
                                            <div className='col-sm-6 col-12'>
                                                {[...Array(5)].map((_, index) => (
                                                    <i
                                                        key={index}
                                                        className={`fas fa-star`}
                                                        style={{
                                                            fontSize: '32px',
                                                            marginRight: '10px',
                                                            color: index < message.rating ? '#235CBE' : 'gray',
                                                            cursor: 'pointer'
                                                        }}
                                                    ></i>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};