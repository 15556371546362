import React from 'react';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className="main-footer">
      {/* To the right */}
        {/* Default to the left */}
        <strong>Copyright &copy; 2025 <a href="https://myflous.com">Flous</a>.</strong> {t('version.title')}.
        <div className="float-right d-none d-sm-inline">
          Version: 1.0.0
        </div>
    </footer>
  );
};

export default Footer;
