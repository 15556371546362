import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { createNewAdvisor } from "../../../services/enterprise/AdvisorsServices";
import LoadingOverlay from "../../general/LoadingOverlay";
import { useTranslation } from "react-i18next";

const CreateNewAdvisorsModal = () => {

  const [formData, setFormData] = useState({
    lastname: '',
    firstname: '',
    email: '',
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const {user} = useOutletContext();
  const { t } = useTranslation();

  const handleChange = (event) => {
    const {name, value} = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSaveNewAdvisor = async (e) => {

    e.preventDefault();

    if (!formData.lastname || !formData.firstname || !formData.email) {
      toast.error("You must provide new Advisor informations.")
      return;
    } else {

      setLoading(true);
      if (user.id) {
        try{
  
          const response = await createNewAdvisor(formData);

          if (response && response.status===200) {
              toast.error(response.data.message);
              setLoading(false);
          }
  
          window.location.reload();
  
        }  catch (error){
          if (error.response && [400, 500].includes(error.response.status)){
              toast.error(error.response.data.error);
              setLoading(false);
          }
        }
      } else {
        setLoading(false);
        return;
      }
    }
    
  }

  if (loading) {
    return <LoadingOverlay />;
  }

  if (error) {
    return <div>Error : {error.message}</div>;
  }
  /*
  if (!isOpen) {
    return null;
  } */

  return (
    <div id="newAdvisor" className="modal fade" role="dialog">
        <div className="modal-dialog">
            <div className="modal-content">
            <div className="modal-header">
                <h4 className="modal-title">{t("modal.create.title")}</h4>
                <button type="button" className="close" data-dismiss="modal">&times;</button>
            </div>
            <div className="modal-body">
                <form onSubmit={handleSaveNewAdvisor} method="POST">
                    <div className="col">
                        <div className="form-group">
                        <label htmlFor="firstname">{t("signin.create.form.name")}</label>
                        <input onChange={handleChange} value={formData.firstname} name="firstname" type="text" className="form-control" placeholder="Enter advisor first name ..." />
                        </div>
                        <div className="form-group">
                        <label htmlFor="lastname">{t("signin.create.form.surname")}</label>
                        <input onChange={handleChange} value={formData.lastname} name="lastname" type="text" className="form-control" placeholder="Enter advisor last name ..." />
                        </div>
                        <div className="form-group">
                        <label htmlFor="email">{t("signin.create.form.email")}</label>
                        <input onChange={handleChange} value={formData.email} name="email" type="email" className="form-control" placeholder="Enter advisor email ..." />
                        </div>
                    </div>
                    <div className="col">
                        <input className="btn flous-bg-primary" type="submit" value={t("modal.create.submit")} />
                    </div>
                </form>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-default" data-dismiss="modal">{t("modal.cancel")}</button>
            </div>
            </div>
        </div>
        <ToastContainer />
    </div>
  );
};

export default CreateNewAdvisorsModal;
