import React from 'react';
import { useTranslation } from 'react-i18next';

const NoItemsMessage = () => {
  const { t } = useTranslation();
  return (
    <div className="text-center p-3 flous-police-one">
      {t("general.table.empty.noitems")}
    </div>
  );
};

export default NoItemsMessage;