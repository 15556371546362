import React from 'react';
import { Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import CountryShow from '../../../general/countryShow';

const styles = StyleSheet.create({
  page: {
    padding: 20,
    backgroundColor: '#ffffff',
  },
  mainTitle: {
    fontSize: 20,
    fontFamily: 'Helvetica-Bold',
    color: '#0066CC',
    textAlign: 'center',
    marginBottom: 20,
  },
  card: {
    backgroundColor: '#f8f9ff',
    borderRadius: 8,
    padding: 16,
    paddingBottom: 5,
    marginBottom: 10,
    width: '100%',
  },
  experienceCard: {
    backgroundColor: '#ffffff',
    borderRadius: 4,
    paddingHorizontal: 10,
    paddingVertical: 5,
    marginBottom: 5,
    border: '1px solid #e2e8f0',
  },
  sectionTitle: {
    fontSize: 16,
    fontFamily: 'Helvetica-Bold',
    textAlign: 'center',
    marginBottom: 16,
    color: '#000000',
  },
  row: {
    flexDirection: 'row',
    marginBottom: 12,
    gap: 12,
  },
  labelColumn: {
    width: '30%',
  },
  valueColumn: {
    width: '20%',
  },
  label: {
    fontSize: 10,
    color: '#000000',
    fontFamily: 'Helvetica',
  },
  value: {
    fontSize: 10,
    padding: '6 8',
    backgroundColor: '#ffffff',
    borderRadius: 4,
    minHeight: 28,
    border: '1px solid #e2e8f0',
    fontFamily: 'Helvetica',
  },
});

export default function ProfessionalExperienceResumeSectionPDF({ professionalExperience, allProfessionalExperience, jobs, provinces, show }) {
  const { t } = useTranslation();

  const renderExperienceDetails = (profession) => (
    <>
      <View style={styles.row}>
        <View style={styles.labelColumn}>
          <Text style={styles.label}>{t("forms.formsquestion.professionalExperience.selfEmployed")}</Text>
        </View>
        <View style={styles.valueColumn}>
          <Text style={styles.value}>{profession.jobDetail.selfEmployed === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}</Text>
        </View>
        <View style={styles.labelColumn}>
          <Text style={styles.label}>{t("forms.formsquestion.professionalExperience.currentJob")}</Text>
        </View>
        <View style={styles.valueColumn}>
          <Text style={styles.value}>{profession.jobDetail.currentJob === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}</Text>
        </View>
      </View>
      
      <View style={styles.row}>
        <View style={styles.labelColumn}>
          <Text style={styles.label}>{t("forms.formsquestion.professionalExperience.startDate")}</Text>
        </View>
        <View style={styles.valueColumn}>
          <Text style={styles.value}>{profession.jobDetail.startDate}</Text>
        </View>
        <View style={styles.labelColumn}>
          <Text style={styles.label}>{t("forms.formsquestion.professionalExperience.endDate")}</Text>
        </View>
        <View style={styles.valueColumn}>
          <Text style={styles.value}>{profession.jobDetail.endDate || 'N/A'}</Text>
        </View>
      </View>

      <View style={styles.row}>
        <View style={styles.labelColumn}>
          <Text style={styles.label}>{t("forms.formsquestion.professionalExperience.authorization")}</Text>
        </View>
        <View style={styles.valueColumn}>
          <Text style={styles.value}>{profession.jobDetail.authorization === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}</Text>
        </View>
        <View style={styles.labelColumn}>
          <Text style={styles.label}>{t("forms.formsquestion.professionalExperience.obtainingAuthorization")}</Text>
        </View>
        <View style={styles.valueColumn}>
          <Text style={styles.value}>{profession.jobDetail.obtainingAuthorization === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}</Text>
        </View>
      </View>

      <View style={styles.row}>
        <View style={styles.labelColumn}>
          <Text style={styles.label}>{t("forms.formsquestion.professionalExperience.jobInAIOrIT")}</Text>
        </View>
        <View style={styles.valueColumn}>
          <Text style={styles.value}>{profession.jobDetail.jobInAIOrIT === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}</Text>
        </View>
        <View style={styles.labelColumn}>
          <Text style={styles.label}>{t("forms.formsquestion.professionalExperience.hoursPerWeek")}</Text>
        </View>
        <View style={styles.valueColumn}>
          <Text style={styles.value}>{profession.companyInfo.hoursPerWeek}</Text>
        </View>
      </View>

      <View style={styles.row}>
        <View style={styles.labelColumn}>
          <Text style={styles.label}>{t("forms.formsquestion.professionalExperience.hourlyRate")}</Text>
        </View>
        <View style={styles.valueColumn}>
          <Text style={styles.value}>{profession.companyInfo.hourlyRate}</Text>
        </View>
        <View style={styles.labelColumn}>
          <Text style={styles.label}>{t("forms.formsquestion.professionalExperience.paydayLoans")}</Text>
        </View>
        <View style={styles.valueColumn}>
          <Text style={styles.value}>{profession.jobSpecifics.paydayLoans === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}</Text>
        </View>
      </View>

      <View style={styles.row}>
        <View style={styles.labelColumn}>
          <Text style={styles.label}>{t("forms.formsquestion.professionalExperience.explicitContent")}</Text>
        </View>
        <View style={styles.valueColumn}>
          <Text style={styles.value}>{profession.jobSpecifics.explicitContent === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}</Text>
        </View>
        <View style={styles.labelColumn}>
          <Text style={styles.label}>{t("forms.formsquestion.professionalExperience.realEstate")}</Text>
        </View>
        <View style={styles.valueColumn}>
          <Text style={styles.value}>{profession.jobSpecifics.realEstate === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}</Text>
        </View>
      </View>

      <View style={styles.row}>
        <View style={styles.labelColumn}>
          <Text style={styles.label}>{t("forms.formsquestion.professionalExperience.city")}</Text>
        </View>
        <View style={styles.valueColumn}>
          <Text style={styles.value}>{profession.jobAddress.city}</Text>
        </View>
        <View style={styles.labelColumn}>
          <Text style={styles.label}>{t("forms.formsquestion.professionalExperience.streetNumber")}</Text>
        </View>
        <View style={styles.valueColumn}>
          <Text style={styles.value}>{profession.jobAddress.streetNumber}</Text>
        </View>
      </View>

      <View style={styles.row}>
        <View style={styles.labelColumn}>
          <Text style={styles.label}>{t("forms.formsquestion.professionalExperience.postalCode")}</Text>
        </View>
        <View style={styles.valueColumn}>
          <Text style={styles.value}>{profession.jobAddress.postalCode}</Text>
        </View>
      </View>
    </>
  );

  return (
    <Page style={styles.page}>
      <Text style={styles.mainTitle}>{t("client.results.blockTitle.bloc6")}</Text>
      
      <View style={styles.card}>
        <View style={styles.row}>
          <View style={{width: '70%'}}>
            <Text style={styles.label}>{t("forms.formsquestion.professionalExperience.professionalExperience")}</Text>
          </View>
          <View style={styles.valueColumn}>
            <Text style={styles.value}>
              {professionalExperience.professionalExperience === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}
            </Text>
          </View>
        </View>
      </View>

      {professionalExperience.professionalExperience === 'Yes' ? (
        <View style={styles.card}>
          {allProfessionalExperience.map((profession, index) => (
            <View key={index} style={styles.experienceCard}>
              <View style={styles.row}>
                <View style={styles.labelColumn}>
                  <Text style={styles.label}>{t("general.table.title")}</Text>
                </View>
                <View style={styles.valueColumn}>
                  <Text style={styles.value}>{/*jobs.find(option => option.value === profession.jobDetail.jobTitle)?.label*/}</Text>
                </View>
                <View style={styles.labelColumn}>
                  <Text style={styles.label}>{t("general.table.company")}</Text>
                </View>
                <View style={styles.valueColumn}>
                  <Text style={styles.value}>{profession.companyInfo.companyName}</Text>
                </View>
              </View>
              
              <View style={styles.row}>
                <View style={styles.labelColumn}>
                  <Text style={styles.label}>{t("general.table.startdate")}</Text>
                </View>
                <View style={styles.valueColumn}>
                  <Text style={styles.value}>{profession.jobDetail.startDate}</Text>
                </View>
                <View style={styles.labelColumn}>
                  <Text style={styles.label}>{t("general.table.iscurrentjob")}</Text>
                </View>
                <View style={styles.valueColumn}>
                  <Text style={styles.value}>{profession.jobDetail.currentJob === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}</Text>
                </View>
              </View>
              
              <View style={styles.row}>
                <View style={styles.labelColumn}>
                  <Text style={styles.label}>{t("general.table.country")}</Text>
                </View>
                <View style={styles.valueColumn}>
                  <Text style={styles.value}><CountryShow value={profession.jobAddress.country}/></Text>
                </View>
                <View style={styles.labelColumn}>
                  <Text style={styles.label}>{t("general.table.province")}</Text>
                </View>
                <View style={styles.valueColumn}>
                  <Text style={styles.value}>
                    {/*profession.jobAddress.country === 'CAN'
                      ? provinces.find(province => province.value === profession.jobAddress.provinceState)?.label || ''
                      : profession.jobAddress.provinceState*/}
                  </Text>
                </View>
              </View>

              {show && renderExperienceDetails(profession)}
            </View>
          ))}
        </View>
      ) : (
        <View style={styles.card}>
          <Text style={styles.cardTitle}>You don't have professional experience in the past 10 years</Text>
        </View>
      )}
    </Page>
  );
}

