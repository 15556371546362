import React, { useEffect, useState } from 'react';
import FrenchLanguageProficiencySection from './section_language_proficiency/FrenchLanguageProficientSection';
import EnglishLanguageProficiencySection from './section_language_proficiency/EnglishLanguageProficiencySection';
import { toast, ToastContainer } from 'react-toastify';
import Select from 'react-select';
import RequiredIndicator from '../../../general/requiredIndicator';

import { useTranslation } from "react-i18next";

const LanguageProficiency = (props) => {
  const [firstOfficialLanguage, setFirstOfficialLanguage] = useState(props.languageProficiency.firstOfficialLanguage);
  const [frenchLanguageProficiency, setFrenchLanguageProficiency] = useState(props.languageProficiency.frenchLanguageProficiency);
  const [englishLanguageProficiency, setEnglishLanguageProficiency] = useState(props.languageProficiency.englishLanguageProficiency);
  const [errors, setErrors] = useState({});
  const {t} = useTranslation();

  const languages = [
    { value: 'FR', label: 'Français' },
    { value: 'EN', label: 'English' },
    // Ajoutez d'autres langues ici
  ];
  
  const handleFirstOfficialLanguageChange = (event) => {
    const {value} = event.target;
    setFirstOfficialLanguage(value);
    setErrors({});
  };

  useEffect(()=>{
    const languageProficiencyParent = {
        firstOfficialLanguage,
        frenchLanguageProficiency,
        englishLanguageProficiency
    }
    props.sendLanguageProficiency(languageProficiencyParent);
    props.onValidate('languageProficiency', false);
  }, [firstOfficialLanguage, frenchLanguageProficiency, englishLanguageProficiency, props]);

  const receivedFrenchLanguageProficiency = (data) => {setFrenchLanguageProficiency(data);};
  const receivedEnglishLanguageProficiency = (data) => {setEnglishLanguageProficiency(data);};

  const pressNext = ()=> {
    props.sendNext("Education in country");
  };

  const saveForm = () => {
    props.onSave();
  };

  const checkFrenchProficiency = () =>{
    // French Test check
    if(frenchLanguageProficiency.frenchtest === 'Yes'){

      if (frenchLanguageProficiency.testType === '' || frenchLanguageProficiency.testDate === '' || frenchLanguageProficiency.speakingScore === ''
        || frenchLanguageProficiency.listeningScore === '' || frenchLanguageProficiency.writingScore === '' || frenchLanguageProficiency.readingScore === ''
      ) {
        toast.error(`${t('forms.formsquestion.toast.languageProficiency.frenchTest')}`);
        setErrors(prevErrors =>({
          ...prevErrors,
          frenchTest: `${t('forms.formsquestion.toast.languageProficiency.frenchTest')}`
        }));
        return false;
      }else {
        delete errors.frenchTest;
        return true;
      }

    }else if(frenchLanguageProficiency.frenchtest === 'No') {
      
      if (frenchLanguageProficiency.frenchEducation) {
        if (frenchLanguageProficiency.speakingLevel === ''|| frenchLanguageProficiency.listeningLevel === ''
          || frenchLanguageProficiency.writingLevel === ''|| frenchLanguageProficiency.readingLevel === '') {
          toast.error(`${t('forms.formsquestion.toast.languageProficiency.frenchTest')}`);
          setErrors(prevErrors =>({
            ...prevErrors,
            frenchTest: `${t('forms.formsquestion.toast.languageProficiency.frenchTest')}`
          }));
          return false;
        } else {
          delete errors.frenchTest;
          return true;
        }
      } else if (frenchLanguageProficiency.frenchEducation === 'Yes') {
        delete errors.frenchTest;
        return true;
      } else {
        toast.error(`${t('forms.formsquestion.toast.languageProficiency.frenchTest')}`);
        setErrors(prevErrors =>({
          ...prevErrors,
          frenchTest: `${t('forms.formsquestion.toast.languageProficiency.frenchTest')}`
        }));
        return false;
      }
      
    } else if (frenchLanguageProficiency.frenchtest === '') {
      toast.error(`${t('forms.formsquestion.toast.languageProficiency.frenchOption')}`);
      setErrors(prevErrors =>({
        ...prevErrors,
        frenchTest: `${t('forms.formsquestion.toast.languageProficiency.frenchOption')}`
      }));
      return false;
    } 
  }

  const checkEnglishProficiency = () => {
    
    // English Test Section
    if(englishLanguageProficiency.englishtest === 'Yes'){
      
      if ( englishLanguageProficiency.testType === '' || englishLanguageProficiency.testDate === ''|| englishLanguageProficiency.speakingScore === ''
        || englishLanguageProficiency.listeningScore === ''|| englishLanguageProficiency.writingScore === ''|| englishLanguageProficiency.readingScore === ''
      ) {
        toast.error(`${t('forms.formsquestion.toast.languageProficiency.englishTest')}`);
        setErrors(prevErrors =>({
          ...prevErrors,
          englishtest: `${t('forms.formsquestion.toast.languageProficiency.englishTest')}`
        }));
        return false;
      }else {
        delete errors.englishtest;
        return true;
      }

    } else if(englishLanguageProficiency.englishtest === 'No') {
      delete errors.englishtest;
      if (englishLanguageProficiency.englishEducation){
        if (englishLanguageProficiency.speakingLevel === ''|| englishLanguageProficiency.listeningLevel === ''
          || englishLanguageProficiency.writingLevel === ''|| englishLanguageProficiency.readingLevel === '') {
          toast.error(`${t('forms.formsquestion.toast.languageProficiency.englishTest')}`);
          setErrors(prevErrors =>({
            ...prevErrors,
            englishtest: `${t('forms.formsquestion.toast.languageProficiency.englishTest')}`
          }));
          return false;
        }else {
          delete errors.englishtest;
          return true;
        }
      } else if (englishLanguageProficiency.englishEducation === 'Yes') {
        delete errors.englishtest;
        return true
      } else if (englishLanguageProficiency.englishEducation === ''){
        toast.error(`${t('forms.formsquestion.toast.languageProficiency.englishTest')}`);
        setErrors(prevErrors =>({
          ...prevErrors,
          englishtest: `${t('forms.formsquestion.toast.languageProficiency.englishTest')}`
        }));
        return false
      }
    } else if (englishLanguageProficiency.englishtest === '') {
      toast.error(`${t('forms.formsquestion.toast.languageProficiency.englishOption')}`);
      setErrors(prevErrors =>({
        ...prevErrors,
        englishtest: `${t('forms.formsquestion.toast.languageProficiency.englishOption')}`
      }));
      return false;
    } 
  }

  const check = async () => {
    if (firstOfficialLanguage === ''){
      toast.error(`${t('forms.formsquestion.toast.languageProficiency.firstOfficialLanguage')}`);
      setErrors(prevErrors =>({
        ...prevErrors,
        languageProficiency: `${t('forms.formsquestion.toast.languageProficiency.firstOfficialLanguage')}`
      }));
    } else {

      delete errors.languageProficiency;

      if (checkFrenchProficiency() && checkEnglishProficiency()){
            props.onValidate('languageProficiency', true);
            toast.success(`${t('forms.formsquestion.toast.sectionValidate')}`);
        } else {
          props.onValidate('languageProficiency', false);
        }
      }
    return true
  }

  const validateSection = async () => {
    props.onValidate('languageProficiency', false);
    check();
  };

  return (
    <fieldset id="step3">
      <ul style={{ color: 'red' }}>
        {Object.values(errors).map((value, index) => {
            if (value !== '') {
                return <li key={index}>{value}</li>;
            }
            return null;
        })}
      </ul>
      {/*<legend>Informations personnelles</legend>*/}
      <div className="form-card">
        <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
        <p className='text-bold text-center'>{t("forms.title.languageproficiency")}</p>
            <div className='row mt-3'>
                <p className='col-sm-4'>{t("forms.formsquestion.language.firstOfficialLanguage")}<RequiredIndicator /></p>
                <Select
                  id="firstOfficialLanguage"
                  name="firstOfficialLanguage"
                  className="col-sm-8 flous-input-color"
                  classNamePrefix="react-select"
                  onChange={selectedOption => handleFirstOfficialLanguageChange({ target: { name: 'firstOfficialLanguage', value: selectedOption? selectedOption.value: ''} })}
                  value={firstOfficialLanguage ? languages.find(language => language.value === firstOfficialLanguage) : { value: '', label: `${t('forms.formsquestion.placeholder.option')}` }}
                  options={languages}
                  placeholder={`${t('forms.formsquestion.placeholder.option')}`}
                  isSearchable
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      borderRadius: '20px', // Ajustez la valeur pour l'arrondissement
                      borderColor: '#ccc',  // Vous pouvez également ajuster la couleur de la bordure si nécessaire
                    }),
                  }}
                />
            </div>
        </div>
        <FrenchLanguageProficiencySection error={errors.frenchTest ? errors.frenchTest : ''}
        sendFrenchLanguageProficiency={receivedFrenchLanguageProficiency} 
        frenchLanguageProficiency={frenchLanguageProficiency} 
        />
        <EnglishLanguageProficiencySection error={errors.englishtest ? errors.englishtest : ''}
        sendEnglishLanguageProficiency={receivedEnglishLanguageProficiency} 
        englishLanguageProficiency={englishLanguageProficiency} />
        <div className='d-flex justify-content-center'>
                  <div className='col-sm-10'>
                    <div className='d-flex justify-content-between'>
                      <input type="button" name="save" className="col-6 btn flous-btn-gradient rounded-pill mx-1" value={t("forms.click.save")} onClick={saveForm}/>
                      <input type="button" name="next" className="col-6 btn flous-btn-gradient rounded-pill mx-1" value={t("forms.click.next")} onClick={pressNext}/>
                    </div>
                  </div>
                </div>
                <div className='d-flex justify-content-center'>
                  <input type="button" name="validate" className="col-sm-6 btn flous-btn-primary rounded-pill mt-2 mx-2" value={t("forms.click.validate")} onClick={validateSection}/>
                </div>
            </div>
      <ToastContainer />
    </fieldset>
  );
};

export default LanguageProficiency;
