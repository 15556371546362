import React from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

const DeleteProfilePictureModal = ({ onDelete }) => {
    const { user } = useOutletContext();
    const { t } = useTranslation();

  return (
      <div id="deletePicture" className="modal fade" tabindex="-1" aria-labelledby="newPictureLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="flous-police-one flous-input-color">{t("modal.delete.title")}</h4>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">
                <div className="col">
                <div className="d-flex justify-content-center">
                    <div className="image">
                      {user.profile_picture_url ? (
                        <img src={user.profile_picture_url} className="border rounded-circle" alt="User Profile" style={{ width: '200px', height: '200px', objectFit: 'cover' }}/>
                      ):(
                        <img src={`${process.env.PUBLIC_URL}/avatar/avatar.png`} className="border rounded-circle" alt="User Profile" style={{ width: '200px', height: '200px', objectFit: 'cover' }}/>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col text-center">
                  <button type="button" className="btn btn-lg text-bold flous-bg-menu rounded-pill px-5" data-dismiss="modal" onClick={onDelete}>{t("modal.delete.submit")}</button>
                </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
              >
                {t("modal.cancel")}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
};

export default DeleteProfilePictureModal;
