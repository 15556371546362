import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationFR from '../components/lang/fr.json';
import translationEN from '../components/lang/en.json';
import translationES from '../components/lang/es.json';

const language = localStorage.getItem('flouslang') || 'en';

const resources = {
    en: {
      translation: translationEN,
    },
    fr: {
      translation: translationFR,
    },
    es: {
      translation: translationES,
    },
  };

i18n.use(initReactI18next).init({
  resources: resources,
  lng: language, // Langue par défaut
  fallbackLng: "en", // Langue de secours
  interpolation: {
    escapeValue: false, // React s'occupe déjà de l'échappement
  },
});

export default i18n;
