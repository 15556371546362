import React, {useEffect, useState} from 'react';
import { getCurrentItems } from '../../services/users/FormsServices';
import PaginationSection from '../Layout/PaginationSection';
import FlousCustomersMessageItem from './item/FlousCustomersMessagesItem';
import NoItemsMessage from '../general/NoItemsToDisplaysItem';
import GoToBackButton from '../general/GoBackLink';
import { useNavigate, useParams } from 'react-router-dom';
import { getMessageData, updateMessage } from '../../services/flous/inboxServices';
import LoadingOverlay from '../general/LoadingOverlay';
import { MessageStatesConverter } from '../../services/utils';
import { toast, ToastContainer } from 'react-toastify';
import { useTranslation } from 'react-i18next';

export default function FlousCustomersMessagesPage() {

  const {id} = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [userOldMessages, setUserOldMessages] = useState([]);
  const [message, setMessage] = useState({});
  const [flousAnswer, setFlousAnswer] = useState(message.answer? message.answer:'');
  const { t } = useTranslation();

  const navigate = useNavigate();

  useEffect(()=>{
    let isMounted = true;

    const getData = async () => {
        try {
          const response = await getMessageData(id);
          if (isMounted) {
            setMessage(response.data.message);
            setFlousAnswer(response.data.message.answer);
            setUserOldMessages(response.data.old);
          }
          setLoading(false);
        } catch (error) {
          if (isMounted){
            setLoading(false);
            if (error.response && [400, 500].includes(error.response.status)) {
              setError(error);
            }
          }
        }
    };

    getData();

    return () => {
      isMounted = false;
    };
  }, [])
      
  const [page, setPage] = useState(1);
  const pageSize = 5;
  const onPageChange = (page) => {
    setPage(page + 1);
  };

  

  const handleChange = (e) => {
    const { value } = e.target;
    setFlousAnswer(value);
  }

  const handleSaveAnswer = async () => {
    setLoading(true);
    try {
      const data = {
        answer: flousAnswer,
      }
      const response = await updateMessage(message.id, data);
      if (response && response.status === 200) {
        setLoading(false);
        toast.success(response.data.message);
        navigate(-1);
      }
    } catch (error) {
      setLoading(false);
      if (error.response && [400, 500].includes(error.response.status)) {
        toast.error(error.response.data.error);
      }
    }
  }

  
  const currentItems = getCurrentItems(userOldMessages, page, pageSize);

  if (loading) {
    return <LoadingOverlay />;
  }

  if (error) {
    return <div>Error : {error.message}</div>;
  }

  return (
    <div className="content">
      <div className="container-fluid">
        <div className="content-header">
          <div className="container-fluid">
            <div className="mb-2">
              <GoToBackButton />
              <div className="col-sm-6">
                <h1 className="m-0 flous-police-one flous-input-color">{t("flous.messages.title")}</h1>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="">
          <div className='col'>
                <div className='card card-body' style={{ borderRadius: '15px' }}>
                    <div className='flous-bg-thirty p-3 elevation-1' style={{ borderRadius: '15px' }}>
                        <div className='row'>
                            <div className='col-sm-2 col-12 text-center'>
                                <div className='d-flex justify-content-center'>
                                  <img src={`${process.env.PUBLIC_URL}/avatar/avatar.png`} className="border rounded-circle" alt="User Image" width="150" height="150"/>
                                </div>
                                <h4 className='text-bold'>{message.firstName} {message.lastName} </h4>
                                <p className=''>{message.userRole} </p>
                            </div>
                            <div className='col'>
                                <div className='mt-3'>
                                    <p className='flous-police-one flous-input-color'>{t("flous.messages.subject")} : {message.subject} - {MessageStatesConverter(message.state)} </p>
                                </div>
                                <div className="form-group">
                                    <textarea className="form-control" rows="3" placeholder="Message ..." name="message" value={message.message}></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col'>
                <div className='card card-body' style={{ borderRadius: '15px' }}>
                    <div className='flous-bg-thirty p-3 elevation-1' style={{ borderRadius: '15px' }}>
                        <div className='d-flex justify-content-left'>
                            <div className="image">
                                <img src={`${process.env.PUBLIC_URL}/flous/logos/trans_1.svg`} alt="Flous logo" className="rounded-circle" width="70" height="70"/>
                            </div>
                            <div className='mt-3'>
                                <p className='flous-police-one flous-input-color'>{t("flous.messages.answer")}</p>
                            </div>
                        </div>
                        <div className="form-group">
                            <textarea 
                              className="form-control" rows="3" 
                              placeholder="Enter ..." name="flousAnswer" value={flousAnswer}
                              onChange={handleChange}
                            >
                            </textarea>
                        </div>
                        <div className="d-flex justify-content-end">
                            <button onClick={handleSaveAnswer} type="submit" className="col-4 btn flous-bg-menu btn-block">{t("flous.messages.submit")}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col">
              <div className="card" style={{ borderRadius: '15px' }}>
                <div className="card-header">
                    <div className="row">
                      <div className='col-sm col-12 ml-3 flous-input-color text-bold'>
                        {message.firstName}'s message history
                      </div>
                      <div className='col-sm-2 col-6 btn flous-input-color'><i className="nav-icon fas fa-search"></i> Search</div>
                      <div className='col-sm-2 col-6 btn flous-input-color'><i className="nav-icon fas fa-sort"></i> Sort</div>
                    </div>
                </div>
                <div className="card-body">
                  <div className='flous-bg-thirty' style={{ borderRadius: '15px' }}>
                    <div className='card-header p-3 mb-2 shadow bg-white' style={{ borderRadius: '15px 15px 0px 0px' }}>
                        <div className='row text-bold'>
                            <div className='col-sm-3 col-12 mt-2'>{t("flous.messages.subject")}</div>
                            <div className='col-sm col-12 mt-2'>{t("flous.messages.message")}</div>
                            <div className='col-sm-2 col-6 mt-2 text-center'>{t("flous.messages.date")}</div>
                            <div className='col-sm-1 col-6 mt-2 text-center'>{t("flous.messages.action")}</div>
                        </div>
                    </div>
                    {currentItems.length > 0 ? (
                      currentItems.map((item) => (
                      <FlousCustomersMessageItem message={item} currentID={message.id} />
                      ))
                    ) : (
                      <NoItemsMessage />
                    )}
                  </div>
                </div>
                <PaginationSection
                      page={page}
                      pageSize={pageSize}
                      totalItems={userOldMessages.length}
                      onPageChange={onPageChange}
                    />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};