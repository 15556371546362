import React from 'react';
import { useTranslation } from "react-i18next";
import LanguageSelect from '../general/languageSelect';

const ChooseActorPage = () => {

  const { t } = useTranslation();

  return (
    <div className='relative bg-[linear-gradient(270deg,#7FA1F8_0%,#FDFEFE_88%)] min-h-screen overflow-hidden'>
      {/* Background image */}
      <div className="absolute inset-0">
        <img 
          src={`${process.env.PUBLIC_URL}/flous/bg/background-test.svg`} 
          alt="" 
          className="w-full h-full object-cover opacity-20"
        />
      </div>

      {/* Content */}
      <div className='relative z-10 p-2 min-h-screen'>
        {/* Logo */}
        <div className='flex justify-between'>
          <div className="sm:ml-5 mt-3">
            <a href="/">
              <img
                src={`${process.env.PUBLIC_URL}/flous/logos/trans_1.svg`}
                alt="Flous Logo"
                className="brand-image"
                style={{ opacity: 0.8, height: '150px', width: 'auto' }}
              />
            </a>
          </div>
          <div className="sm:ml-5 mt-5 pt-3">
            <div className='right-0'><LanguageSelect /></div>
          </div>
        </div>

        {/* Main content */}
        <div className="flex justify-center items-start min-h-screen">
          <div className="col-lg-8">
            <div className="text-center mb-4">
              <h3 className="flous-police-one">
              {t("signin.actor.message1")}<br />{t("signin.actor.message2")}
              </h3>
            </div>
            <div className="card-body text-center">
              <div className="row">
                {/* Profile options */}
                <div className="col-sm col-12">
                  <a href="/createaccount/self">
                    <div className="card card-body mx-3" style={{ borderRadius: '15px' }}>
                      <label>{t("signin.actor.solo.title")}</label>
                      <img alt="" src={`${process.env.PUBLIC_URL}/flous/svg/profile_3.svg`} />
                      <p>{t("signin.actor.solo.text")}</p>
                    </div>
                  </a>
                </div>

                <div className="col-sm col-12">
                  <a href="/createaccount/pro">
                    <div className="card card-body mx-3" style={{ borderRadius: '15px' }}>
                      <label>{t("signin.actor.professional.title")}</label>
                      <img alt="" src={`${process.env.PUBLIC_URL}/flous/svg/profile_2.svg`} />
                      <p>{t("signin.actor.professional.text")}</p>
                    </div>
                  </a>
                </div>

                <div className="col-sm col-12">
                  <a href="/createaccount/enterprise">
                    <div className="card card-body mx-3" style={{ borderRadius: '15px' }}>
                      <label>{t("signin.actor.enterprise.title")}</label>
                      <img alt="" src={`${process.env.PUBLIC_URL}/flous/svg/profile_1.svg`} />
                      <p>{t("signin.actor.enterprise.text")}</p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChooseActorPage;
