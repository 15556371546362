import React from 'react';
import CurrentResidenceResumeSection from './section_residences/currentResidenceInfoSection';
import StatusResumeSection from './section_residences/statusInfoSection';
import PreviousAddressResumeSection from './section_residences/previousAddress_section';
import ImmigrationDocumentResumeSection from './section_residences/immigrationDocumentSection';
import { useTranslation } from 'react-i18next';

export default function ResidenceInfoResume(props) {
  
  const { t } = useTranslation();

  return (
    <div id='evaluation'>
      <h3 className='flous-police-one flous-input-color text-center'>{t("client.results.blockTitle.bloc3")}</h3>
      <CurrentResidenceResumeSection currentResidence={props.residences.currentResidence} />
      <StatusResumeSection currentResidence={props.residences.currentResidence} statusInCountry={props.residences.statusInCountry} />
      {(props.residences.statusInCountry.currentStatus !== '' && props.residences.statusInCountry.currentStatus !=='NS' && props.residences.statusInCountry.currentStatus !=='No') && (
        <ImmigrationDocumentResumeSection immigrationDocument={props.residences.immigrationDocument} />
      )}
      {props.residences.livedInDifferentAddress === 'Yes'  && (
        <PreviousAddressResumeSection previousAddresses={props.residences.previousAddresses}  show={props.show} />
      )}
    </div>
  );
}
