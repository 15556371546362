import React from 'react';
import { useTranslation } from 'react-i18next';
import { mapStateToDescription } from '../../../../services/utils';

export default function FormDetailIntroduction(props) {

  const lang = localStorage.getItem('flouslang') || 'en';
  
  const { t } = useTranslation();

  return (
    <div id='evaluation'>
      <div className='flous-bg-thirty p-3 elevation-2 mb-3' style={{ borderRadius: '15px' }}>
        <h4 className='text-bold'>{t('client.forms.myforms.summary.title')}</h4>
        <hr />
        <div className='mx-3'>
          <div className="form-group row">
            <label htmlFor="inputCountry" className="col-sm-3 col-form-label">{t('client.forms.myforms.summary.formTitle')}</label>
            <div className="col-sm-3">
              <input type="text" className="form-control" id="inputCountry" value={props.formTitle} />
            </div>
            <label htmlFor="inputEvaluationNumber" className="col-sm-3 col-form-label">{t('client.forms.myforms.summary.formCode')}</label>
            <div className="col-sm-3">
              <input type="text" className="form-control" id="inputEvaluationNumber" value={props.formCode} />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="inputCountry" className="col-sm-3 col-form-label">{t('client.forms.myforms.summary.evaluationCountry')}</label>
            <div className="col-sm-3">
              <input type="text" className="form-control" id="inputCountry" value={props.evaluationCountry} />
            </div>
            <label htmlFor="inputEvaluationNumber" className="col-sm-3 col-form-label">{t('client.forms.myforms.summary.creationDate')}</label>
            <div className="col-sm-3">
              <input type="text" className="form-control" id="inputEvaluationNumber" value={props.creationDate} />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="inputDate" className="col-sm-3 col-form-label">{t('client.forms.myforms.summary.lastUpdate')}</label>
            <div className="col-sm-3">
              <input type="text" className="form-control" id="inputDate" value={props.updateCompletedDate} />
            </div>
            <label htmlFor="inputTotalProg" className="col-sm-3 col-form-label">{t('client.forms.myforms.summary.status')} </label>
            <div className="col-sm-3">
              <input type="text" className="form-control" id="inputTotalProg" value={mapStateToDescription(props.formStatus, lang)} />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="inputDate" className="col-sm-3 col-form-label">{t('client.forms.myforms.summary.isCoApplication')}</label>
            <div className="col-sm-3">
              <input type="text" className="form-control" id="inputDate" value={props.isCoApplication === 'Yes' ? `${t('forms.click.yes')}`: `${t('forms.click.no')}`} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
