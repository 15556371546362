import React from 'react';
import { Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import CountryShow from '../../../general/countryShow';
import { companyType } from '../../../../services/utils';

const styles = StyleSheet.create({
  page: {
    padding: 20,
    backgroundColor: '#ffffff',
  },
  mainTitle: {
    fontSize: 20,
    fontFamily: 'Helvetica-Bold',
    color: '#0066CC',
    textAlign: 'center',
    marginBottom: 20,
  },
  card: {
    backgroundColor: '#f8f9ff',
    borderRadius: 8,
    padding: 16,
    paddingBottom: 5,
    marginBottom: 10,
    width: '100%',
  },
  cardTitle: {
    fontSize: 14,
    fontFamily: 'Helvetica-Bold',
    textAlign: 'center',
    marginBottom: 10,
  },
  row: {
    flexDirection: 'row',
    marginBottom: 8,
    gap: 8,
  },
  labelColumn: {
    width: '30%',
  },
  valueColumn: {
    width: '20%',
  },
  label: {
    fontSize: 10,
    color: '#000000',
    fontFamily: 'Helvetica',
  },
  value: {
    fontSize: 10,
    padding: '6 8',
    backgroundColor: '#ffffff',
    borderRadius: 4,
    minHeight: 28,
    border: '1px solid #e2e8f0',
    fontFamily: 'Helvetica',
  },
  experienceCard: {
    backgroundColor: '#ffffff',
    borderRadius: 4,
    padding: 8,
    marginBottom: 8,
    border: '1px solid #e2e8f0',
  },
});

export default function EntrepreneurialExperienceResumeSectionPDF(props) {
  const { t } = useTranslation();
  const lang = 'en'; // Assuming 'en' as default language

  const renderExperienceCard = (entreExperience, index) => {
    const entrepreneurialExperience = {
      isOwnerOrPartner: entreExperience.entrepreunarialExperience.ownership,
      entrepreneurialGoal: entreExperience.entrepreunarialExperience.newcompany,
    };

    const takeoverBidInfo = {
      hasMadeBid: entreExperience.takeoverBid,
    };

    const companyDetails = {
      establishedDate: entreExperience.companyDetails.establishmentDate,
      country: entreExperience.companyDetails.country,
      province: entreExperience.companyDetails.provinceState,
      city: entreExperience.companyDetails.city,
      streetNumber: entreExperience.companyDetails.streetNumber,
      postalCode: entreExperience.companyDetails.postalCode,
    };

    const companyDetailss = {
      ownerOrPartner: entreExperience.ownershipDetail.ownershipType,
      companyName: entreExperience.ownershipDetail.companyName,
      ownershipPercentage: entreExperience.ownershipDetail.ownershipPercentage,
      managementExperienceMonths: entreExperience.ownershipDetail.managementExperience,
      personalEquityInvestment: entreExperience.ownershipDetail.personalEquityInvestment,
    };

    const companyActivities = {
      companyDescription: entreExperience.companyActivites.businessSector,
      involvesPaydayLoans: entreExperience.companyActivites.paydayLoans,
      involvesExplicitContent: entreExperience.companyActivites.explicitContent,
      involvesRealEstate: entreExperience.companyActivites.realEstate,
    };

    const companyFinance = {
      companyValue: entreExperience.companyFinances.companyValue,
      startupExpenses: entreExperience.companyFinances.startupExpenses,
      operatingExpenses: entreExperience.companyFinances.operatingExpenses,
    };

    const roleExperience = {
      position: entreExperience.roleAndExperience.position,
      startDate: entreExperience.roleAndExperience.startDate,
      minOperatingPeriod: entreExperience.roleAndExperience.operatingPeriod,
    };

    return (
      <View key={index} style={styles.experienceCard}>
        <View style={styles.row}>
          <View style={styles.labelColumn}>
            <Text style={styles.label}>{t("general.table.type")}</Text>
          </View>
          <View style={styles.valueColumn}>
            <Text style={styles.value}>{entrepreneurialExperience.isOwnerOrPartner === 'Yes' ? companyType('Existing', lang) : companyType(entrepreneurialExperience.entrepreneurialGoal, lang)}</Text>
          </View>
          <View style={styles.labelColumn}>
            <Text style={styles.label}>{t("general.table.company")}</Text>
          </View>
          <View style={styles.valueColumn}>
            <Text style={styles.value}>{companyDetailss.companyName}</Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.labelColumn}>
            <Text style={styles.label}>{t("general.table.ownershippercentage")}</Text>
            </View>
          <View style={styles.valueColumn}>
            <Text style={styles.value}>{companyDetailss.ownershipPercentage}%</Text>
          </View>
          <View style={styles.labelColumn}>
            <Text style={styles.label}>{t("general.table.country")}</Text>
            </View>
          <View style={styles.valueColumn}>
            <Text style={styles.value}><CountryShow value={companyDetails.country} /></Text>
          </View>
        </View>

        {/* Entrepreneurial Experience */}
        <View style={styles.card}>
          <Text style={styles.cardTitle}>{t("forms.title.entrepreneurialexperience")}</Text>
          <View style={styles.row}>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("forms.formsquestion.entrepreunarialExperience.ownership")}</Text>
              </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>{entrepreneurialExperience.isOwnerOrPartner === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}</Text>
            </View>
            {entrepreneurialExperience.isOwnerOrPartner === 'No' && (
              <>
              <View style={styles.labelColumn}>
                <Text style={styles.label}>{t("forms.formsquestion.entrepreunarialExperience.newcompany")} Canada</Text>
                </View>
              <View style={styles.valueColumn}>
                <Text style={styles.value}>{companyType(entrepreneurialExperience.entrepreneurialGoal, lang)}</Text>
              </View>
              </>
            )}
          </View>
        </View>

        {/* Takeover Bid */}
        {entrepreneurialExperience.entrepreneurialGoal === 'Undertake an existing company' && (
          <View style={styles.card}>
            <Text style={styles.cardTitle}>{t("forms.title.takeoverBid")}</Text>
            <View style={styles.row}>
              <View style={{width: '70%'}}>
                <Text style={styles.label}>{t("forms.formsquestion.entrepreunarialExperience.takeoverBid")}</Text>
                </View>
              <View style={{width: '30%'}}>
                <Text style={styles.value}>{takeoverBidInfo.hasMadeBid === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}</Text>
              </View>
            </View>
          </View>
        )}

        {/* Company Details */}
        <View style={styles.card}>
          <Text style={styles.cardTitle}>{t("forms.title.companydetail")}</Text>
          <View style={styles.row}>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("forms.formsquestion.entrepreunarialExperience.establishmentDate")}</Text>
              </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>{companyDetails.establishedDate}</Text>
            </View>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("forms.formsquestion.entrepreunarialExperience.provinceState")}</Text>
              </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>
                {companyDetails.province}
              </Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("forms.formsquestion.entrepreunarialExperience.city")}</Text>
              </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>{companyDetails.city}</Text>
            </View>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("forms.formsquestion.entrepreunarialExperience.streetNumber")}</Text>
              </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>{companyDetails.streetNumber}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("forms.formsquestion.entrepreunarialExperience.postalCode")}</Text>
              </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>{companyDetails.postalCode}</Text>
            </View>
          </View>
        </View>

        {/* Company Activities */}
        <View style={styles.card}>
          <Text style={styles.cardTitle}>{t("forms.title.companyactivities")}</Text>
          <View style={styles.row}>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("forms.formsquestion.entrepreunarialExperience.businessSector")}</Text>
              </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>{companyActivities.companyDescription}</Text>
            </View>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("forms.formsquestion.entrepreunarialExperience.paydayLoans")}</Text>
              </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>{companyActivities.involvesPaydayLoans === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("forms.formsquestion.entrepreunarialExperience.explicitContent")}</Text>
              </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>{companyActivities.involvesExplicitContent === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}</Text>
            </View>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("forms.formsquestion.entrepreunarialExperience.realEstate")}</Text>
              </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>{companyActivities.involvesRealEstate === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}</Text>
          </View>
          </View>
        </View>

        {/* Company Finance */}
        <View style={styles.card}>
          <Text style={styles.cardTitle}>{t("forms.title.companyfinance")}</Text>
          <View style={styles.row}>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("forms.formsquestion.entrepreunarialExperience.companyValue")}</Text>
              </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>{companyFinance.companyValue}</Text>
            </View>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("forms.formsquestion.entrepreunarialExperience.startupExpenses")}</Text>
              </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>{companyFinance.startupExpenses}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("forms.formsquestion.entrepreunarialExperience.operatingExpenses")}</Text>
              </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>{companyFinance.operatingExpenses}</Text>
          </View>
          </View>
        </View>

        {/* Role and Experience */
        <View style={styles.card}>
          <Text style={styles.cardTitle}>{t("forms.title.role")}</Text>
          <View style={styles.row}>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("forms.formsquestion.entrepreunarialExperience.position")}</Text>
              </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>{roleExperience.position}</Text>
            </View>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("forms.formsquestion.entrepreunarialExperience.startDate")}</Text>
              </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>{roleExperience.startDate}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("forms.formsquestion.entrepreunarialExperience.operatingPeriod")}</Text>
              </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>{roleExperience.minOperatingPeriod}</Text>
            </View>
          </View>
        </View> /**/}
      </View>
    );
  };

  return (
    <Page style={styles.page}>
      <Text style={styles.mainTitle}>{t("client.results.blockTitle.bloc9")}</Text>
      {props.allEntrepreneurialExperience && props.allEntrepreneurialExperience.length > 0 ? (
        props.allEntrepreneurialExperience.map((entreExperience, index) => renderExperienceCard(entreExperience, index))
      ) : (
        <View style={styles.card}>
          <Text style={styles.cardTitle}>{t("general.table.empty.noElement")}</Text>
        </View>
      )}
    </Page>
  );
}

