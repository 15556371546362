import React, { useState } from 'react';
import FlagComponent from '../../general/FlagComponent';
import { DeleteEvaluation } from '../../../services/users/ResultsServices';
import LoadingOverlay from '../../general/LoadingOverlay';
import { toast } from 'react-toastify';

const AgentCustomersResultsItem = ({ item, userType}) => {
  const [loading, setLoading] = useState(false);

  const pageType = "result"

  const handleDeleteEvaluation = async (code) =>{
    setLoading(true);
    try{
      const response = await DeleteEvaluation(code);
      if (response && [200, 201].includes(response.status)) {
        setLoading(false);
        window.location.reload();
      }
    }  catch (error){
      setLoading(false);
      if (error.response && [400, 500].includes(error.response.status)){
        toast.error(error.response.data.error);
      }
    }
  }

  if (loading) {
      return <LoadingOverlay />;
  }

  return (
      <div className='card-header p-3 mb-3 shadow bg-white' style={{ borderRadius: '15px' }}>
        <div className='row'>
            <div className='col-sm col-6 mt-2 text-center'><FlagComponent cca3={item.country_cca3} /></div>
            <div className='col-sm col-6 mt-2 text-center'>{item.creation_date}</div>
            <div className='col-sm col-6 mt-2 text-center'>{item.title}</div>
            <div className='col-sm col-6 mt-2 text-center'>
              <a href={`/${userType}/customer/view/${pageType}/${item.code}`} className="btn flous-bg-menu  mr-1 mt-1">
                <i className="nav-icon fas fa-eye"></i>
              </a>
              <span onClick={() => handleDeleteEvaluation(item.code)}>
                  <span className="btn btn-secondary ml-1 mt-1">
                      <i className="nav-icon fas fa-trash"></i>
                  </span>
              </span>
            </div>
        </div>
      </div>
  );
}

export default AgentCustomersResultsItem;