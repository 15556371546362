import api from "../api";

export const createPaymentIntent = async (price) => {
    try{
        const response = await api.post('/create-payment-intent/', {
            'price': price
          });
        return response;

    } catch (error){
        throw error;
    }   
};

export const fetchPricingData = async (agent, forms) => {
    try{
        const response = await api.get(`/subscription/get-costs/Enterprise/${agent}/${forms}`);
        return response;
  
    } catch (error){
        throw error;
    } 
  };