import React, { useContext, useEffect , useState} from 'react';
import { useParams } from 'react-router-dom';
import { validatePassword } from './validation';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import CountrySelect from '../general/CountrySelect';
import { AuthContext } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import ProvinceSelect from '../general/ProvinceSelect';
import CitySelect from '../general/CitySelect';
import { countriesWithProvinces, provincesWithCities } from '../../services/utils';
import { toast } from 'react-toastify';
import { useTranslation } from "react-i18next";


const CreateAccountPage = () => {

    const { registerSelfUser, registerProUser } = useContext(AuthContext)
    const navigate = useNavigate();


    const { actor } = useParams();
    const typepackage = actor === 'pro'? 'Professional':'Enterprise'
    const [errorMessage, setErrorMessage] = useState('');

    ///////////////////////////////////////////
    const [password, setPassword] = useState('');
    const [confirmpassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setconfirmPasswordError] = useState('');
    const [firstname, setFirstName] = useState('');
    const [lastname, setLastName] = useState('');
    const [email, setEmail] = useState('');


    // Address
    const [country, setCountry] = useState('');
    const [province, setProvince] = useState('');
    const [abbreviation, setAbbreviation] = useState('');
    const [city, setCity] = useState('');

    // Company details
    const [company_name, setCompanyName] = useState('');
    const [company_register_number, setCompanyRegisterNumber] = useState('');
    const [lawyerLicense, setLawyerLicense] = useState('')
    const [immigrationLicense, setImmigrationLicense] = useState('')
    // const [typepackage, setTypePackage] = useState(actor === 'pro'? 'Professional':'Enterprise')


    // User self
    const [phone, setPhone] = useState('');
    const [date_of_birth, setBirthdate] = useState(null);
    const [showPassword, setShowPassword] = useState(false);

    const { t } = useTranslation();
  
    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };


    useEffect(() =>{
        if (password !== confirmpassword){
            setconfirmPasswordError("The passwords are different !");
        }else {
            setconfirmPasswordError('');
        }

    }, [password, confirmpassword]);

    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
        setPasswordError(validatePassword(newPassword));
    };
    
    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
    };

    const handleFirstName = (e) => {
        setFirstName(e.target.value)
    };

    const handleLastName = (e) => {
        setLastName(e.target.value)
    };

    const handleEmail = (e) => {
        setEmail(e.target.value)
    };

    const handleBirthDate = (e) => {
        setBirthdate(e.target.value)
    };

    const handleCompanyName = (e) => {
        setCompanyName(e.target.value)
    };

    const handleCompanyRegisterNumber = (e) => {
        setCompanyRegisterNumber(e.target.value)
    };

    const handleLawyerLicense = (e) => {
        setLawyerLicense(e.target.value)
    };

    const handleImmigrationLicense = (e) => {
        setImmigrationLicense(e.target.value)
    };

    const handleCountryChange = (e) => {
        const { value } = e.target;
        setCountry(value);
      };

    const handleProvince = (e) => {
        setProvince(e.target.value)
    };

    const handleCity = (e) => {
        setCity(e.target.value)
    };

    const handleSubmit = async(e) => {
        e.preventDefault();

        if (password === confirmpassword){
            if (actor === 'self') {
                try {
                    const response = await registerSelfUser(firstname, lastname, date_of_birth, phone, email, password);
                    if (response.status === 201){
                        navigate('/login', { state: { message: "Your account has been successfully created, \nplease log in below." } })
                    } else {
                        const data = await response.json();
                        setErrorMessage(data.message || 'Account creation error.');
                    }
                    
                } catch (error) {
                    // navigate('/chooseactor')
                    setErrorMessage('An error has occurred. Please try again later.')
                    if (error.response && [400, 500].includes(error.response.status)){
                        toast.error(error.response.data.error);
                    }
                }
            }
            
            if (actor === 'pro' || actor === 'enterprise') {
                
                try {
                    
                    const prepareData = async () => {
                        
                        setAbbreviation(province.slice(0, 2));
    
                        const associate = {
                            'name': company_name,
                            'company_register_number': company_register_number,
                            'immigration_consultant_license': immigrationLicense,
                            'lawyer_license': lawyerLicense,
                            'address':{
                                'city': {
                                    'name': city,
                                    'province': {
                                        'abbreviation': abbreviation,
                                        'country': country,
                                        'name': province,
                                    }
                                }
                                
                            },
                            'type_package': typepackage
                        }

                        return associate;
                    }
                    
                    const associate = await prepareData();
                    
                    const response = await registerProUser(firstname, lastname, date_of_birth, phone, email, password, associate)
                    if (response.status === 201){
                        navigate('/login', { state: { message: "Your account has been successfully created, \nplease log in below." } });
                        setPassword('');
                        setConfirmPassword('');
                        setPasswordError('');

                    } else {
                        const data = await response.json();
                        setErrorMessage(data.message || 'Account creation error.');
                        setPassword('');
                        setConfirmPassword('');
                        setPasswordError('');
                    }
                } catch (error) {
                    setErrorMessage('An error has occurred. Please try again later.');
                    setPassword('');
                    setConfirmPassword('');
                    setPasswordError('');
                    if (error.response && [400, 500].includes(error.response.status)){
                        toast.error(error.response.data.error);
                    }
                }
            }
        }else {
            setconfirmPasswordError("The passwords are different ! Please correct the error. ");
        }
    };


    return (
      <div className='relative bg-[linear-gradient(270deg,#7FA1F8_0%,#FDFEFE_88%)] min-h-screen overflow-hidden'>
      {/* Background image */}
        <div className="absolute inset-0">
            <img 
            src={`${process.env.PUBLIC_URL}/flous/bg/login-bg.png`} 
            alt="" 
            className="w-full h-full object-cover opacity-20"
            />
        </div>

        {/* Content */}
        <div className='relative z-10 p-2 min-h-screen'>
            <div className="flex justify-start ml-5 mt-3">
            <a href="/">
                <img
                src={`${process.env.PUBLIC_URL}/flous/logos/trans_1.svg`}
                alt="Flous Logo"
                className="brand-image"
                style={{ opacity: 0.8, height: '150px', width: 'auto' }}
                />
            </a>
            </div>
            <div className="col-lg-10 mx-auto">
                <div className='row'>
                    <div className='col-md d-flex flex-column justify-content-center'>
                        <h3 className='flous-police-one'>{t("signin.create.message.title")}</h3>
                        <p className='text-bold'>{t("signin.create.message.message1")}</p>
                        <p>{t("signin.create.message.message2")}</p>
                    </div>
                    <div className='col-md'>
                        <div className="card card-outline elevation-2 pb-5 pt-5" style={{ borderRadius: '15px' }}>
                            <div className="card-header text-left">
                            <h3 className='ml-4 flous-police-one'>{t("signin.create.form.title")}</h3>
                            </div>
                            {errorMessage && <div className="text-center mb-2 mt-2" style={{ color: 'red' }}>{errorMessage}</div>}
                            <form onSubmit={handleSubmit}>
                                <div className="card-body text-center">
                                
                                    {actor === 'self' && (
                                    <>
                                        <div className='input-group mb-3 col-sm-10 mx-auto'>
                                            <div className='row' style={{ flex: 1 }}>
                                                <div className='col-6'>
                                                    <label className="input-group">{t("signin.create.form.name")}</label>
                                                    <div className="input-group">
                                                        <input type="text" placeholder={t("signin.create.form.name")} style={{ color: 'blue' }} className="form-control" name='firstname' required  onChange={handleFirstName}/>
                                                    </div>
                                                </div>
                                                <div className='col-6'>
                                                    <label className="input-group">{t("signin.create.form.surname")}</label>
                                                    <div className="input-group">
                                                        <input type="text" placeholder={t("signin.create.form.surname")} className="form-control" name='lastname' required  onChange={handleLastName}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <label className="input-group col-lg-10 mx-auto">{t("signin.create.form.birthday")}</label>
                                        <div className="input-group mb-3 col-lg-10 mx-auto">
                                            <input type="date" placeholder={t("signin.create.form.birthday")} className="form-control" name='birthdate' required  onChange={handleBirthDate} max={new Date().toISOString().split("T")[0]}/>
                                        </div>
                                        <label className="input-group col-lg-10 mx-auto">{t("signin.create.form.phone")}</label>
                                        <div className="input-group mb-3 col-lg-10 mx-auto">
                                            <div style={{ flex: 1 }}>
                                                <PhoneInput
                                                defaultCountry={'ca'}
                                                value={phone}
                                                onChange={(phone) => setPhone(phone)}
                                                containerStyle={{ width: '100%' }}
                                                inputStyle={{ width: '100%' }}
                                                />
                                            </div>
                                        </div>
                                        
                                    </>
                                    )}

                                { actor === 'pro' && (
                                <>
                                <div className='input-group mb-3 col-lg-10 mx-auto'>
                                    <div className='row' style={{ flex: 1 }}>
                                        <div className='col-6'>
                                            <label className="input-group">{t("signin.create.form.name")}</label>
                                            <div className="input-group">
                                                <input type="text" placeholder={t("signin.create.form.name")} className="form-control" name='firstname' required onChange={handleFirstName}/>
                                            </div>
                                        </div>
                                        <div className='col-6'>
                                            <label className="input-group">{t("signin.create.form.surname")}</label>
                                            <div className="input-group">
                                                <input type="text" placeholder={t("signin.create.form.surname")} className="form-control" name='lastname' required onChange={handleLastName}/>
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                                <label className="input-group col-lg-10 mx-auto">{t("signin.create.form.companyname")}  (optional)</label>
                                <div className="input-group mb-3 col-lg-10 mx-auto">
                                    <input type="text" placeholder={t("signin.create.form.companyname")} className="form-control" name='companyname' onChange={handleCompanyName}/>
                                </div>
                                <label className="input-group col-lg-10 mx-auto">{t("signin.create.form.register")} (optional)</label>
                                <div className="input-group mb-3 col-lg-10 mx-auto">
                                    <input type="text" placeholder="Company incorporation number" className="form-control" name='register_number' onChange={handleCompanyRegisterNumber} />
                                </div>
                                <label className="input-group col-lg-10 mx-auto">{t("signin.create.form.immigrationlicence")}</label>
                                <div className="input-group mb-3 col-lg-10 mx-auto">
                                    <input type="text" placeholder="Immigration consultant licence number" className="form-control" name='immigrationlicence' onChange={handleImmigrationLicense} />
                                </div>

                                <label className="input-group col-lg-10 mx-auto">{t("signin.create.form.lawyerlicence")}</label>
                                <div className="input-group mb-3 col-lg-10 mx-auto">
                                    <input type="text" placeholder="Lawyer license number" className="form-control" name='lawyerlicence' onChange={handleLawyerLicense} />
                                </div>

                                <label className="input-group col-lg-10 mx-auto">{t("signin.create.form.country")}</label>
                                <div className="mb-3 col-lg-10 mx-auto">
                                    <CountrySelect 
                                    className="flous-input-color"
                                    value={country} onChange={handleCountryChange} 
                                    required />
                                </div>

                                <div className='input-group mb-3 col-lg-10 mx-auto'>
                                <div className='row' style={{ flex: 1 }}>
                                    <div className='col-6'>
                                        <label className="input-group">{t("signin.create.form.province")}</label>
                                            {countriesWithProvinces.includes(country) ? (
                                                <ProvinceSelect 
                                                className="flous-input-color" value={province} 
                                                onChange={handleProvince} country={country} required/>):
                                            (<div className="input-group">
                                                <input type="text" placeholder="Province" onChange={handleProvince} className="form-control" name='province' required/>
                                            </div>)}
                                    </div>
                                    <div className='col-6'>
                                        <label className="input-group">{t("signin.create.form.city")}</label>
                                            {countriesWithProvinces.includes(country) ? (
                                                <CitySelect 
                                                className="flous-input-color" value={city} province={province}
                                                onChange={handleCity} required/>):
                                            (<div className="input-group">
                                                <input type="text" placeholder={`${t('forms.formsquestion.placeholder.city')}`} onChange={handleCity} className="form-control" name='city' required/>
                                            </div>
                                            )}
                                    </div>
                                </div> 
                                </div>
                                
                                </>
                                )}
                                { actor === 'enterprise' && (
                                <>
                                <label className="input-group col-lg-10 mx-auto">{t("signin.create.form.companyname")}</label>
                                <div className="input-group mb-3 col-lg-10 mx-auto">
                                    <input type="text" placeholder={`${t('forms.formsquestion.placeholder.companyName')}`} className="form-control" name='companyname' required onChange={handleCompanyName}/>
                                </div>
                                <label className="input-group col-lg-10 mx-auto">{t("signin.create.form.register")}</label>
                                <div className="input-group mb-3 col-lg-10 mx-auto">
                                    <input type="text" placeholder="Company incorporation number" className="form-control" name='register_number' required onChange={handleCompanyRegisterNumber}/>
                                </div>
                                <label className="input-group col-lg-10 mx-auto">{t("signin.create.form.ma,agername")}</label>
                                <div className="input-group mb-3 col-lg-10 mx-auto">
                                    <input type="text" placeholder={`${t('forms.formsquestion.placeholder.firstname')}`} className="form-control" name='firstname' required onChange={handleFirstName}/>
                                </div>
                                <label className="input-group col-lg-10 mx-auto">{t("signin.create.form.managerlastname")}</label>
                                <div className="input-group mb-3 col-lg-10 mx-auto">
                                    <input type="text" placeholder={`${t('forms.formsquestion.placeholder.lastname')}`} className="form-control" name='lastname' required onChange={handleLastName}/>
                                </div>
                                <label className="input-group col-lg-10 mx-auto">{t("signin.create.form.immigrationlicence")}</label>
                                <div className="input-group mb-3 col-lg-10 mx-auto">
                                    <input type="text" placeholder="Immigration consultant licence number" className="form-control" name='immigrationlicence' onChange={handleImmigrationLicense}/>
                                </div>

                                <label className="input-group col-lg-10 mx-auto">{t("signin.create.form.lawyerlicence")}</label>
                                <div className="input-group mb-3 col-lg-10 mx-auto">
                                    <input type="text" placeholder="Lawyer license number" className="form-control" name='lawyerlicence' onChange={handleLawyerLicense} />
                                </div>

                                <label className="input-group col-lg-10 mx-auto">{t("signin.create.form.country")}</label>
                                <div className="mb-3 col-lg-10 mx-auto">
                                    <CountrySelect 
                                    className="flous-input-color" value={country} 
                                    onChange={handleCountryChange} required 
                                    />
                                </div>

                                <div className='input-group mb-3 col-lg-10 mx-auto'>
                                    <div className='row' style={{ flex: 1 }}>
                                        <div className='col-6'>
                                            <label className="input-group">{t("signin.create.form.province")}</label>
                                                {countriesWithProvinces.includes(country) ? (
                                                    <ProvinceSelect 
                                                    className="flous-input-color" value={province} 
                                                    onChange={handleProvince} country={country} required/>):
                                                (<div className="input-group">
                                                    <input type="text" placeholder="Province" onChange={handleProvince} className="form-control" name='province' required/>
                                                </div>)}
                                        </div>
                                        <div className='col-6'>
                                            <label className="input-group">{t("signin.create.form.city")}</label>
                                                {countriesWithProvinces.includes(country) ? (
                                                    <CitySelect 
                                                    className="flous-input-color" value={city} province={province}
                                                    onChange={handleCity} required/>):
                                                (<div className="input-group">
                                                    <input type="text" placeholder={`${t('forms.formsquestion.placeholder.city')}`} onChange={handleCity} className="form-control" name='city' required/>
                                                </div>)}
                                        </div>
                                    </div> 
                                </div>
                                </>
                                )}
                                
                                <label className="input-group col-lg-10 mx-auto">{t("signin.create.form.email")}</label>
                                <div className="input-group mb-3 col-lg-10 mx-auto">
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-envelope"></span>
                                        </div>
                                    </div>
                                    <input type="email" placeholder="Email" className="form-control" name='email' required onChange={handleEmail}/>
                                </div>
                                <label className="input-group col-lg-10 mx-auto">{t("signin.create.form.password")}</label>
                                {passwordError && <p className="error">{passwordError}</p>}
                                <div className="input-group mb-3 col-lg-10 mx-auto">
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-lock"></span>
                                        </div>
                                    </div>
                                    <input type={showPassword ? 'text' : 'password'} placeholder="Password" className="form-control" name='password' required onChange={handlePasswordChange}/>
                    
                                    <button className='btn' 
                                    style={{
                                        position: 'absolute',
                                        right: '10px',
                                        top: '50%',
                                        transform: 'translateY(-50%)',
                                        background: 'none',
                                        border: 'none',
                                        cursor: 'pointer',
                                    }}
                                    onClick={togglePasswordVisibility}>
                                    {showPassword ? (
                                        <i className="nav-icon fas fa-eye-slash"></i>
                                        ) : (
                                        <i className="nav-icon fas fa-eye"></i>
                                    )}
                                    </button>
                                </div>
                                <label className="input-group col-lg-10 mx-auto">{t("signin.create.form.confirmpass")}</label>
                                {confirmPasswordError && <p className="error">{confirmPasswordError}</p>}
                                <div className="input-group mb-3 col-lg-10 mx-auto">
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-lock"></span>
                                        </div>
                                    </div>
                                    <input type={showPassword ? 'text' : 'password'} placeholder="Confirm Password" className="form-control" name='confirmpassword' required onChange={handleConfirmPasswordChange}/>
                                    <button className='btn' 
                                    style={{
                                        position: 'absolute',
                                        right: '10px',
                                        top: '50%',
                                        transform: 'translateY(-50%)',
                                        background: 'none',
                                        border: 'none',
                                        cursor: 'pointer',
                                    }}
                                    onClick={togglePasswordVisibility}>
                                    {showPassword ? (
                                        <i className="nav-icon fas fa-eye-slash"></i>
                                        ) : (
                                        <i className="nav-icon fas fa-eye"></i>
                                    )}
                                    </button>
                                </div>

                                <div className="col-lg-10 mx-auto">
                                    <button type="submit" className="btn btn-lg flous-btn-gradient btn-block rounded-pill">{t("signin.create.form.submit")}</button>
                                </div> 

                                {actor === '' && <div>{t("signin.create.message.message3")} <a href='/chooseactor'>{t("signin.create.message.message4")}</a> {t("signin.create.message.message5")} </div> }
                            
                                </div>
                            </form>
                        </div>
                        
                    </div>
                    
                </div>
                
            </div>
        </div>
      </div>
    );
};

export default CreateAccountPage;
