import api from '../api';

let isLoading = false;

export const fetchAdvisorsData = async () => {

    if (isLoading) return null;
    isLoading = true;

    try{
        const response = await api.get(`/enterprise/get-all-advisors`);
        return response;

    } catch (error){
        throw error;
    } finally {
        isLoading = false;
    }  
};

export const getAdvisorsData = async (advisor) => {

    if (isLoading) return null;
    isLoading = true;

    try{
        const response = await api.get(`/enterprise/get-advisor/${advisor}`);
        return response;

    } catch (error){
        throw error;
    } finally {
        isLoading = false;
    }  
};

export const createNewAdvisor = async (data) => {
    try{
        const response = await api.post('/enterprise/create-new-advisor/', data);
        return response;

    } catch (error){
        // console.error('Error when creating advisor:', error);
        throw error;
    }   
};

export const deleteAdvisor = async (advisor) => {

    try{
        const response = await api.get(`/enterprise/delete-advisor/${advisor}`);
        return response;

    } catch (error){
        throw error;
    }   
};

export const assignToCustomer = async (data) => {
    try{
        const response = await api.post('/enterprise/assign-customer', data);
        return response;

    } catch (error){
        throw error;
    }   
};