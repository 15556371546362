import React, { useState } from 'react';
import RequiredIndicator from '../../../../general/requiredIndicator';

import { useTranslation } from "react-i18next";

const CompanyInfoSection = (props) => {
  const [formData, setFormData] = useState(props.companyInfo);
  const { t } = useTranslation();

  const handleChange = (event) => {
    const { name, value } = event.target;
    const updatedData = {
      ...formData,
      [name]: value,
    };
    setFormData(updatedData);
    props.sendCompanyInfo(updatedData);
  };

  return (
    <div className="form-card">
      <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
      <div style={{ color: 'red' }}>{props.error !== '' ? `${t('forms.formsquestion.toast.completeSection')}` : ''}</div>
        <p className='text-bold text-center'>{t("forms.title.company")}</p>
        <div className='row p-2'>
          <p htmlFor="companyName" className="col-sm-4 col-form-label">{t("forms.formsquestion.professionalExperience.companyName")}<RequiredIndicator /></p>
          <div className="col-sm-8">
            <input 
              type="text" 
              className="form-control rounded-pill flous-input-color"
              id="companyName"
              name="companyName"
              value={formData.companyName}
              onChange={handleChange}
              placeholder={`${t('forms.formsquestion.placeholder.companyName')}`}
            />
          </div>
        </div>
        <div className='row p-2'>
          <p htmlFor="hoursPerWeek" className="col-sm-4 col-form-label">{t("forms.formsquestion.professionalExperience.hoursPerWeek")}<RequiredIndicator /></p>
          <div className="col-sm-8">
            <input 
              type="number" 
              className="form-control rounded-pill flous-input-color"
              id="hoursPerWeek"
              name="hoursPerWeek"
              value={formData.hoursPerWeek}
              onChange={handleChange}
              placeholder='Hours per week'
              min={0}
            />
          </div>
        </div>
        <div className='row p-2'>
          <p htmlFor="hourlyRate" className="col-sm-4 col-form-label">{t("forms.formsquestion.professionalExperience.hourlyRate")}<RequiredIndicator /></p>
          <div className="col-sm-8">
            <input 
              type="number" 
              className="form-control rounded-pill flous-input-color"
              id="hourlyRate"
              name="hourlyRate"
              value={formData.hourlyRate}
              onChange={handleChange}
              placeholder='$ 0.00'
              min={0}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyInfoSection;
