import React, {useState} from 'react';
import CountryShow from '../../../general/countryShow';

import { useTranslation } from 'react-i18next';
import { countryName, familyRelation } from '../../../../services/utils';


export default function FamilyMembersResumeSection(props) {

  const hasDependantChildren = props.children.dependantChildren; //  
  const totalDependantChildren = props.children.totalDependantChildren; //  
  const childrenUnder22 = props.children.childrenUnder22; // 

  const [visibleDetails, setVisibleDetails] = useState({});
  const { t } = useTranslation();

  const lang = localStorage.getItem('flouslang') || 'en';
  
    const toggleDetailVisibility = (index) => {
      setVisibleDetails(prevState => ({
        ...prevState,
        [index]: !prevState[index]
      }));
    };

  return (
    <div id='language'>
      <h3 className='flous-police-one flous-input-color text-center'>{t("client.results.blockTitle.bloc2")}</h3>
      <div className='flous-bg-thirty p-3 elevation-2 mb-3' style={{ borderRadius: '15px' }}>
        <div className="form-group row">
          <label htmlFor="inputHasDependantChildren" className="col col-form-label">{t("forms.formsquestion.familyMembers.dependantChildren")}</label>
          <div className="col-sm-6">
            <input type="text" className="form-control" id="inputHasDependantChildren" value={hasDependantChildren === 'Yes' ? `${t('forms.click.yes')}`: `${t('forms.click.no')}`} placeholder="N.A"/>
          </div>
        </div>
        {hasDependantChildren === 'Yes' && (
        <div className="form-group row">
          <label htmlFor="inputTotalDependantChildren" className="col-sm-3 col-form-label">{t("forms.formsquestion.familyMembers.totalDependantChildren")}</label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputTotalDependantChildren" value={totalDependantChildren} placeholder="N.A"/>
          </div>
          <label htmlFor="inputChildrenUnder22" className="col-sm-3 col-form-label">{t("forms.formsquestion.familyMembers.childrenUnder22")}</label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputChildrenUnder22" value={childrenUnder22} placeholder="N.A"/>
          </div>
        </div>)}
      </div>
      <div className='flous-bg-thirty p-3 elevation-2 mb-3' style={{ borderRadius: '15px' }}>
        <div className='mx-3'>
            {(!props.familyMembers || props.familyMembers.length === 0) ? 
            (
              <div className='text-center'>{t("general.table.empty.familyone")}</div>
            ):(
            <div className="form-group">
            <div>
              <div className='card-header p-1 mb-1 shadow bg-white' style={{ borderRadius: '15px 15px 0px 0px' }}>
                <div className='row'>
                    <div className="col-sm col-6 mt-2 text-center">{t("general.table.firstname")}</div>
                    <div className="col-sm col-6 mt-2 text-center">{t("general.table.lastname")}</div>
                    <div className="col-sm col-6 mt-2 text-center">{t("general.table.nationality")}</div>
                    <div className="col-sm col-6 mt-2 text-center">{t("general.table.address")}</div>
                    <div className="col-sm col-6 mt-2 text-center">{t("general.table.birthday")}</div>
                    <div className="col-sm col-6 mt-2 text-center">{t("general.table.Isdead")}</div>
                    {props.show !==true && <div className="col-sm col-6 mt-2 text-center">{t("general.table.actions")}</div>}
                </div>
              </div>
              
              {props.familyMembers.map((member, index) => (
                <div key={index} className='card-header p-1 mb-1 shadow bg-white' style={{ borderRadius: '15px' }}>
                  <div className='row'>
                    <div className="col-sm col-6 mt-2 text-center">{member.firstname}</div>
                    <div className="col-sm col-6 mt-2 text-center">{member.lastname}</div>
                    <div className="col-sm col-6 mt-2 text-center"><CountryShow value={member.nationality}/></div>
                    <div className="col-sm col-6 mt-2 text-center"><CountryShow value={member.country}/></div>
                    <div className="col-sm col-6 mt-2 text-center">{member.birthday}</div>
                    <div className="col-sm col-6 mt-2 text-center">{member.deathday === '' ? `${t('forms.click.no')}`: member.deathday}</div>
                    {props.show !==true && <div className='col-sm col-6 mt-2 text-center'>
                      <button onClick={() => toggleDetailVisibility(index)} className="btn flous-bg-primary">
                        <i className="nav-icon fas fa-eye"></i>
                      </button>
                    </div>}
                  </div>
                  {(visibleDetails[index] || props.show ===true) && (
                  <div className='m-3'>
                      <div className="form-group row">
                        <div htmlFor="" className="col-sm-3">{t("forms.formsquestion.familyMembers.familyRelationship")}</div>
                        <div className="col-sm-3">
                          <input type="text" className="form-control" id="" value={familyRelation(member.familyRelationship, lang)} placeholder="N.A"/>
                        </div>
                        <div htmlFor="" className="col-sm-3">{t("forms.formsquestion.familyMembers.permanentOrCan")}</div>
                        <div className="col-sm-3">
                          <input type="text" className="form-control" id="" value={member.permanent === 'Yes' ? `${t('forms.click.yes')}`: `${t('forms.click.no')}`} placeholder="N.A"/>
                        </div>
                      </div>
                      {member.liveWith === "No" && (
                      <>
                        <div className="form-group row">
                          <div htmlFor="" className="col-sm-3">{t("forms.formsquestion.familyMembers.country")}</div>
                          <div className="col-sm-3">
                            <input type="text" className="form-control" id="" value={member.country} placeholder="N.A"/>
                          </div>
                          <div htmlFor="" className="col-sm-3">{t("forms.formsquestion.familyMembers.provinceState")}</div>
                          <div className="col-sm-3">
                            <input type="text" className="form-control" id="" value={member.provinceState} placeholder="N.A"/>
                          </div>
                        </div>
                        <div className="form-group row">
                          <div htmlFor="" className="col-sm-3">{t("forms.formsquestion.familyMembers.city")}</div>
                          <div className="col-sm-3">
                            <input type="text" className="form-control" id="" value={member.city} placeholder="N.A"/>
                          </div>
                          <div htmlFor="" className="col-sm-3">{t("forms.formsquestion.familyMembers.streetNumber")}</div>
                          <div className="col-sm-3">
                            <input type="text" className="form-control" id="" value={member.streetNumber} placeholder="N.A"/>
                          </div>
                        </div>
                        <div className="form-group row">
                          <div htmlFor="" className="col-sm-3">{t("forms.formsquestion.familyMembers.postalCode")}</div>
                          <div className="col-sm-3">
                            <input type="text" className="form-control" id="" value={member.postalCode} placeholder="N.A"/>
                          </div>
                        </div>
                      </>
                      )}
                  </div>
                  )}
                </div>
              ))}
            </div>
            </div>
            )}
        </div>
      </div>
    </div>
  );
}
