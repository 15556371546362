import React, {useState, useEffect} from 'react';
import { getCurrentItems } from '../../services/users/FormsServices';
import FlagComponent from '../general/FlagComponent';
import PaginationSection from '../Layout/PaginationSection';
import CreateNewCompanyModal from './modal/createNewCompanyAccount';
import InfoBoxPrimaryLink from '../general/InfoBoxPrimaryLink';
import InfoBoxThirtyLink from '../general/InfoBoxThirtyLink';
import NoItemsMessage from '../general/NoItemsToDisplaysItem';
import { useOutletContext } from 'react-router-dom';
import LoadingOverlay from '../general/LoadingOverlay';
import { toast } from 'react-toastify';
import { fetchCompaniesData } from '../../services/flous/ProfesionnalServices';
import CustomerEvolutionCard from '../general/CustomersEvoluationsChart';
import GoToBackButton from '../general/GoBackLink';
import { FlousProEvolutions } from '../../services/general/DashbordServices';
import { useTranslation } from 'react-i18next';

export default function FlousCompagnies(){

  const [thisYear, setThisYear] = useState([, , , , , , , , , , , ]);
  const [lastYear, setLastYear] = useState([, , , , , , , , , , , ]);
  const [labels, setLabels] = useState(['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']);

  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { user } = useOutletContext();
  const { t } = useTranslation();

  const [page, setPage] = useState(1);
  const pageSize = 2;
  const onPageChange = (page) => {
    setPage(page + 1);
  };

  useEffect( () => {
    let isMounted = true;

    const getCompaniesDataList = async () => {
      if (user.id) {
        try {
          const response = await fetchCompaniesData();
          const response_evolution = await FlousProEvolutions();
          if (isMounted) {
            setCompanies(response.data.data);
            setThisYear(response_evolution.data.data.thisYear)
            setLastYear(response_evolution.data.data.lastYear)
            setLabels(response_evolution.data.data.labels)
            setLoading(false);
          }
        } catch (error) {
          if (isMounted){
            setLoading(false);
            if (error.response && [400, 401, 500].includes(error.response.status)) {
              setError(error);
              toast.error(error.response.data.error);
            }
          }
        }
      }
    };

    getCompaniesDataList();

    return () => {
      isMounted = false;
    };

  }, []);
  
  const currentItems = getCurrentItems(companies, page, pageSize);
  

  if (loading) {
    return <LoadingOverlay />;
  }

  if (error) {
    return <div>Error : {error.message}</div>;
  }

  return (
    <div className="content">
      <div className="container-fluid">
        <div className="content-header">
          <div className="container-fluid">
            <div className="mb-2">
              <GoToBackButton />
              <div className="col-sm-6">
                <h1 className="m-0 flous-police-one flous-input-color">{t("flous.pro.title")}</h1>
              </div>
            </div>
          </div>
        </div>

        <section className="content">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col">
                <div className='card p-2 px-5 elevation-2' style={{ borderRadius: '15px' }}>
                  <div className="row">
                    <div className="col-lg-4 col-6">
                      <InfoBoxPrimaryLink
                        value={58}
                        text={t("flous.pro.infobox.one")}
                        link={t("flous.pro.infobox.link")}
                        href="/flous/compagnies/list"
                      />
                    </div>
                    <div className="col-lg-4 col-6">
                      <InfoBoxThirtyLink
                        value={15}
                        text={t("flous.pro.infobox.two")}
                        link={t("flous.pro.infobox.link")}
                        href="/flous/self/list"
                      />
                    </div>
                    <div className="col-lg-4 col-6">
                      <InfoBoxPrimaryLink
                        value={2}
                        text={t("flous.pro.infobox.three")}
                        link={t("flous.pro.infobox.link")}
                        href="/flous/compagnies/list"
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-6">
                    <CustomerEvolutionCard elementId="customers-evoluation" thisYear={thisYear} lastYear={lastYear} totalCustomers={companies.length} labels={labels} title={t("flous.pro.cardtitle")} />
                  </div>

                  <div className="col-lg-6">
                    <div className="card" style={{ borderRadius: '15px' }}>
                      <div className="card-header border-0">
                        <div className="card btn">
                          <h4 className="flous-police-one">{t("flous.pro.company")}</h4>
                        </div>
                        <div className="text-right">
                          <div className="btn flous-bg-primary" data-toggle="modal" data-target="#newCompany">{t("flous.pro.create")}</div>
                        </div>
                        <CreateNewCompanyModal request={null}/>
                      </div>
                      <div className="card-body">
                        <div className='flous-bg-thirty' style={{ borderRadius: '15px' }}>
                            <div className='card-header p-2 mb-2 shadow bg-white' style={{ borderRadius: '15px 15px 0px 0px' }}>
                                <div className='row text-bold'>
                                    <div className='col-sm col-6 mt-2 text-center'>{t("general.table.name")}</div>
                                    <div className='col-sm col-6 mt-2 text-center'>{t("general.table.location")}</div>
                                </div>
                            </div>
                            {currentItems.length > 0 ? (
                              currentItems.map((company) => (
                              <div className='card-header p-3 mb-2 shadow bg-white' style={{ borderRadius: '15px' }}>
                                <div className='row'>
                                    <div className='col-sm col-6 mt-2 text-center'>{company.name}</div>
                                    <div className='col-sm col-6 mt-2 text-center'>
                                        <FlagComponent cca3={company.location} />
                                    </div>
                                </div>
                              </div>
                            ))
                          ) : (
                            <NoItemsMessage />
                          )}
                        </div>
                        <PaginationSection
                            page={page}
                            pageSize={pageSize}
                            totalItems={companies.length}
                            onPageChange={onPageChange}
                          />
                      </div>
                      <div className="card-footer text-center">
                        <a className="btn flous-btn-primary" href="/flous/compagnies/list">{t('general.seeMore')}</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};