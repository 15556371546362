import React, { useEffect, useState } from 'react';
import EnterpriseAdvisorsItem from './item/EnterpriseAdvisorsItem';
import PaginationSection from '../Layout/PaginationSection';
import { getCurrentItems } from '../../services/users/FormsServices';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { fetchAdvisorsData } from '../../services/enterprise/AdvisorsServices';
import { toast, ToastContainer } from 'react-toastify';
import LoadingOverlay from '../general/LoadingOverlay';
import CreateNewAdvisorsModal from './modal/createNewAdvisorsModal';
import AssignToCustomersModal from './modal/assignToCustomersModal';
import NoItemsMessage from '../general/NoItemsToDisplaysItem';
import GoToBackButton from '../general/GoBackLink';
import Select from 'react-select';
import UpgradeSubcriptionModal from './modal/upgradeSubcription';
import { useTranslation } from 'react-i18next';

export default function EntrepriseAdvisors () {

  const [selectedStatus, setSelectedStatus] = useState(null);

  const [isOpen, setIsOpen] = useState(false);
  const [reachedLimitSeats, setReachedLimitSeats] = useState(false);

  const [advisors, setAdvisors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { user } = useOutletContext();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const statusOptions = [
    { value: '', label: 'All status' },
    { value: 'Active', label: 'Active' },
    { value: 'Inactive', label: 'Inactive' }
  ];

  const [page, setPage] = useState(1);
  const pageSize = 5;
  const startIndex = (page - 1) * pageSize;
  const endIndex = startIndex + pageSize;

  const handleSubmit = (event) => {
    event.preventDefault();
    const queryParams = new URLSearchParams({
      status: selectedStatus || ''
    }).toString();
    filterItems();
    //console.log(queryParams);
  };

  const filterItems = async () => {
    if (user.id) {
        try {
          const response = await fetchAdvisorsData();
          if (response && [200, 201].includes(response.status)){
            if (!selectedStatus) {
              setAdvisors(response.data.data);
              setLoading(false);
              return;
            }
            const filteredItems = response.data.data.filter((item) => {
              const matchesStatus = selectedStatus ? item.status === selectedStatus : true;
              return matchesStatus;
            });
            setAdvisors(filteredItems);
            setLoading(false);
          }
        } catch (error) {
          setLoading(false);
          if (error.response && [400, 500].includes(error.response.status)){
            toast.error(error.response.data.error);
            setError(error.response.data.error);
          }
        }
      }
  };
  
  useEffect( () => {
    let isMounted = true;

    const getAdvisorsDataList = async () => {
      if (user.id) {
        try {
          const response = await fetchAdvisorsData();
          if (isMounted) {
            if (response && response.status === 200){
              setAdvisors(response.data.data);
              setLoading(false);
              isMounted = false;
            }
          }
        } catch (error) {
          if (isMounted){
            setLoading(false);
            if (error.response && [400, 500].includes(error.response.status)){
              setError(error.response.data.error);
              toast.error(error.response.data.error);
              isMounted = false;
            }
          }
        }
      }
    };

    getAdvisorsDataList();

    return () => {
      isMounted = false;
    };

  }, []);

  const onPageChange = (page) => {
    setPage(page + 1);
  };

  useEffect(()=>{
    if (user.total_registered_seats >= user.total_seats){
      setReachedLimitSeats(true);
    }
  }, [user]);

  const handleOpen = () =>{
    setIsOpen(true);
  }

  const handleClose = () =>{
    setIsOpen(false);
  }
  
  const currentItems = getCurrentItems(advisors, page, pageSize);
 

  if (loading) {
    return <LoadingOverlay />;
  }

  if (error) {
    return <div>Error : {error.message}</div>;
  }

  return (
    <div className="content">
      <div className="container-fluid">
        {/* Content Header */}
        <div className="content-header">
          <div className="mb-2">
            <GoToBackButton />
            <div className="col-sm-6">
              <h3 className="m-0 flous-police-one flous-input-color">{t("enterprise.advisors.title")}</h3>
            </div>
          </div>
        </div>
        {/* /.content-header */}

        {/* Main content */}
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <div className="row">
                  {/* Add new Advisors Account Button */}
                  {reachedLimitSeats ? (
                    <div className="btn btn-lg flous-bg-primary mx-2 col-md-6 col-12" 
                      onClick={handleOpen}>
                      {t("enterprise.advisors.create")} <i className="nav-icon fas fa-user"></i>
                    </div>
                  ):(
                    <div className="btn btn-lg flous-bg-primary mx-2 col-md-6 col-12" 
                      data-toggle="modal" data-target="#newAdvisor">
                      {t("enterprise.advisors.create")} <i className="nav-icon fas fa-user"></i>
                    </div>
                  )}
                  <CreateNewAdvisorsModal />

                  <UpgradeSubcriptionModal 
                    type="Advisors"
                    isOpen={isOpen}
                    onClose={handleClose} />
                </div>
                <br />

                {/* List of Advisors Card */}
                <div className="card elevation-2">
                  <div className="card-header">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-sm col-12">
                        <div className="form-group">
                          <Select
                            name="status"
                            classNamePrefix="react-select"
                            value={statusOptions.find(option => option.value === selectedStatus)}
                            onChange={(selectedOption) => setSelectedStatus(selectedOption ? selectedOption.value : '')}
                            options={statusOptions}
                            placeholder={`${t('forms.formsquestion.placeholder.option')}`}
                            isSearchable={false}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                width: '100%',
                                borderRadius: '20px', // Ajustez la valeur pour l'arrondissement
                                borderColor: '#ccc',  // Vous pouvez également ajuster la couleur de la bordure si nécessaire
                              }),
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-sm-2 col-6">
                        <button type="submit" className="btn flous-input-color">
                          <i className="nav-icon fas fa-search"></i> {t("general.table.search")}
                        </button>
                      </div>
                      <div className="col-sm-1 col-6 btn flous-input-color">
                        <i className="nav-icon fas fa-sort"></i> {t("general.table.sort")}
                      </div>
                    </div>
                  </form>
                  </div>
                  {/* /.card-header */}
                  <div className="card-body">
                    <div className='flous-bg-thirty m-2' style={{ borderRadius: '15px' }}>
                        <div className='card-header p-3 mb-3 shadow bg-white' style={{ borderRadius: '15px 15px 0px 0px' }}>
                            <div className='row text-bold'>
                                <div className='col-sm col-6 mt-2 text-center'>.</div>
                                <div className='col-sm col-6 mt-2 text-center'>{t("general.table.firstname")}</div>
                                <div className='col-sm col-6 mt-2 text-center'>{t("general.table.lastname")}</div>
                                <div className='col-sm col-6 mt-2 text-center'>{t("general.table.numberclients")}</div>
                                <div className='col-sm col-12 mt-2 text-center'>{t("general.table.status")}</div>
                                <div className='col-sm-4 col-12 mt-2 text-center'>{t("general.table.actions")}</div>
                            </div>
                        </div>
                        {currentItems.length > 0 ? (currentItems.map((advisor) => (
                            <EnterpriseAdvisorsItem key={advisor.code} advisor={advisor}/>
                          ))
                        ) : (
                          <NoItemsMessage />
                        )}
                    </div>
                  </div>
                  {/* /.card-body */}
                  <PaginationSection
                    page={page}
                    pageSize={pageSize}
                    totalItems={advisors.length}
                    onPageChange={onPageChange}
                  />
                </div>
                {/* End List of Advisors Card */}

              </div>
            </div>
          </div>
        </div>
        {/* /.content */}
      </div>

      <AssignToCustomersModal/>

    </div>
  );
};