import React from 'react';

const InfoBoxThirty = ({ value, text, href='#', link='More info' }) => {
  return (
    <div className="small-box elevation-2 flous-bg-thirty" style={{ borderRadius: '15px' }}>
      <div className="inner">
        <h3 className='flous-police-one'>{value}</h3>
        <p>{text}</p>
      </div>
      <div className="icon">
        <i className="ion ion-pie-graph"></i>
      </div>
    </div>
  );
};

export default InfoBoxThirty;