import React, { useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from './AuthContext';

const SubscriptionRedirector = () => {
    const { user } = useContext(AuthContext);
    
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        
        if (user && user.role !== 'Flous' && user.role !== 'Customer' && !user.active_subscription) {
            let redirectPath = '/enterprise/profile'; 

            if (user.role === 'Advisor') {
                redirectPath = '/agent/profile';
            } else if (user.role === 'AssociateAdmin' && user.type_package === 'Professional') {
                redirectPath = '/professional/profile';
            }

            // Prevent redirection if the user is already on the target page
            if (location.pathname !== redirectPath) {
                navigate(redirectPath, { replace: true });
            }
        }
    }, [user, location, navigate]);

    return null; // This component does not render anything visually
};

export default SubscriptionRedirector;
