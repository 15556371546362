import React, { useState, useEffect } from 'react';
import axios from 'axios';

const CountryShow = ({ value }) => {
    const [countryName, setCountryName] = useState('');

  useEffect(() => {
    axios.get(`https://restcountries.com/v3.1/alpha/${value}`)
      .then(response => {
        const country = response.data[0];
        const name = country.name.common;
        setCountryName(name);
      })
      .catch(error => {
        console.error('Error fetching the flag data:', error);
      });
  }, [value]);

  return countryName;
};

export default CountryShow;
