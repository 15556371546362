import api from '../api';

let isLoading = false;

export const fetchCustomersData = async () => {

    if (isLoading) return null;
    isLoading = true;

    try{
        const response = await api.get(`/enterprise/get-all-customers`);
        return response;

    } catch (error){
        throw error;
    } finally {
        isLoading = false;
    }  
};

export const fetchFilteredCustomers = async (code, country) => {

    if (isLoading) return null;
    isLoading = true;

    try{
        const response = await api.get(`/enterprise/get-filtered-customers/${code}/${country}`);
        return response;

    } catch (error){
        throw error;
    } finally {
        isLoading = false;
    }  
};

export const getCustomersData = async (customer) => {

    try{
        const response = await api.post(`/enterprise/get-customer-details/${customer}`);
        return response;

    } catch (error){
        // console.error('Error when getting one customer data:', error);
        throw error;
    } 
};

export const createNewCustomer = async (data) => {
    try{
        const response = await api.post('/enterprise/create-new-customer/', data);
        return response;

    } catch (error){
        throw error;
    }   
};

export const deleteCustomer = async (customer) => {

    try{
        const response = await api.get(`/enterprise/delete-customer/${customer}`);
        return response;

    } catch (error){
        // console.error('Error when deleting customer:', error);
        throw error;
    }   
};

export const assignCustomerToAdvisor = async (customer, advisor) => {
    try{
        const response = await api.get(`/enterprise/assign-customer-to-advisor/${customer}/${advisor}`);
        return response;

    } catch (error){
        // console.error('Error when assgining advisor to customer:', error);
        throw error;
    }   
};


export const viewMoreCustomer = async (customer) => {
    try{
        const response = await api.get(`/enterprise/view-more-customer/${customer}`);
        return response;

    } catch (error){
        throw error;
    }  
}


export const isMyClientCheck = async (customer) => {
    try{
        const response = await api.get(`/enterprise/is-my-customer/${customer}`);
        return response;

    } catch (error){
        throw error;
    }  
}

