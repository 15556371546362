import React from "react";
import { useTranslation } from "react-i18next";

const NewClientSuccessModal = ({  isOpen, onClose, type="Client" }) => {
  const { t } = useTranslation();

  if (!isOpen) {
    return null;
  }

  return (
    <div id="newClientSuccess" className="modal fade show" style={{ display: "block" }} role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal">&times;</button>
          </div>
          <div className="modal-body text-center">
            <div className="d-flex align-items-center justify-content-center w-100 my-3">
                <img
                  src={`${process.env.PUBLIC_URL}/flous/stripe/capa_${type}.png`}
                  alt="Flous Logo"
                  className="brand-image text-center"
                  style={{height: '150px', width: '150px' }}
                />
            </div>
            <h4 className="flous-police-one flous-input-color" id="modalTitle">{t("modal.clientsuccess.title")} {type}!</h4>
            <div className="text-bold mb-3">{t("modal.clientsuccess.title2")} {type} {t("modal.clientsuccess.title3")}</div>
            <div className="col-lg-8 mx-auto mb-3">{t("modal.clientsuccess.message")}</div>
            <div className="col">
              <button className="btn flous-bg-primary rounded-pill px-5" data-dismiss="modal">
              {t("modal.clientsuccess.submit")}
              </button>
            </div>
          </div>
          <div className="modal-footer text-center">
            <button type="button" className="btn btn-default" data-dismiss="modal" onClick={onClose}>
            {t("modal.cancel")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewClientSuccessModal;