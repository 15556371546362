import React, { useState, useEffect } from 'react';
import api from '../../services/api';
// import {sortDictionaryByValues} from '../../services/utils';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';


const CitySelect = ({ value, onChange, province, className="react-select-container", name='city' }) => {
  const [cities, setCities] = useState([]);
  
  const { t } = useTranslation();

  useEffect( () => {
    const fetchCities = async() => {
      try{
        if (province) {
          const response = await api.get(`/province-cities/${province}/`);
          // const response = await api.get(`/quebeccities`);
          const cityOptions = Object.entries(response.data).map(([key, val]) => ({
            value: key,
            label: val,
          }));
          setCities(cityOptions);
        }
      } catch (error){
        setCities([])
      }
    };

    fetchCities();
    
  }, [province]);

  return (
    <Select
      classNamePrefix="react-select"
      className={className}
      value={cities.find(city => city.value === value)}
      onChange={(selectedOption) => onChange({ target: { name: name, value: selectedOption ? selectedOption.value :''} })}
      options={cities}
      placeholder={`${t('forms.formsquestion.placeholder.city')}`}
      isSearchable
      isClearable
      styles={{
        control: (provided) => ({
          ...provided,
          width: '100%',
          borderRadius: '20px', // Ajustez la valeur pour l'arrondissement
          borderColor: '#ccc',  // Vous pouvez également ajuster la couleur de la bordure si nécessaire
        }),
      }}
      required
    />
  );
};

export default CitySelect;
