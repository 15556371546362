import React from 'react';

import { useTranslation } from 'react-i18next';
import { LegalStatus } from '../../../../../services/utils';

export default function StatusResumeSection(props) {
  const { t } = useTranslation();

  const lang = localStorage.getItem('flouslang') || 'en';

  // Define the Canada status information
  const hasStatus = props.currentResidence.haveStatus; //  
  const currentStatus = props.statusInCountry.currentStatus; //  

  return (
    <div className='flous-bg-thirty p-3 elevation-2 mb-3' style={{ borderRadius: '15px' }}>
      <div className='text-bold text-center mb-3'>{t("forms.title.statusin")} Canada</div>
      <div className='mx-3'>
        <div className="form-group row">
          <label htmlFor="inputHasStatus" className="col-sm-6 col-form-label">
          {t("forms.formsquestion.residences.haveStatus")} Canada?
          </label>
          <div className="col-sm-6">
            <input type="text" className="form-control" id="inputHasStatus" value={hasStatus === 'Yes' ? `${t('forms.click.yes')}`: `${t('forms.click.no')}`} placeholder="N.A"/>
          </div>
        </div>
        {props.currentResidence.haveStatus === 'Yes' && (
        <div className="form-group row">
          <label htmlFor="inputCurrentStatus" className="col-sm-6 col-form-label">
          {t("forms.formsquestion.residences.currentStatus")} Canada?
          </label>
          <div className="col-sm-6">
            <input type="text" className="form-control" id="inputCurrentStatus" value={LegalStatus(currentStatus, lang)} placeholder="N.A"/>
          </div>
        </div>
        )}
      </div>
    </div>
  );
}
