import React from 'react';

import { useTranslation } from 'react-i18next';

export default function FrenchLanguageTestResumeSection(props) {
  const { t } = useTranslation();
  // Define the French language test information
  const takenFrenchTest = props.frenchLanguageProficiency.frenchtest; //  
  const testType = props.frenchLanguageProficiency.testType; //  
  const testDate = props.frenchLanguageProficiency.testDate; //  
  const speakingScore = props.frenchLanguageProficiency.speakingScore; //  
  const listeningScore = props.frenchLanguageProficiency.listeningScore; //  
  const writingScore = props.frenchLanguageProficiency.writingScore; //  
  const readingScore = props.frenchLanguageProficiency.readingScore; //  

  const completedEducationInFrench = props.frenchLanguageProficiency.frenchEducation; //  
  const speakingLevel = props.frenchLanguageProficiency.speakingLevel; //  
  const listeningLevel = props.frenchLanguageProficiency.listeningLevel; //  
  const writingLevel = props.frenchLanguageProficiency.writingLevel; //  
  const readingLevel = props.frenchLanguageProficiency.readingLevel; //  

  return (
    <div className='flous-bg-thirty p-3 elevation-2 mb-3' style={{ borderRadius: '15px' }}>
      <div className='text-bold text-center mb-3'>{t("forms.title.frenchlanguage")}</div>
      <div className='mx-3'>
        <div className="form-group row">
          <label htmlFor="inputTakenFrenchTest" className="col-sm-6 col-form-label">
            {t("forms.formsquestion.language.frenchtest")}
          </label>
          <div className="col-sm-6">
            <input type="text" className="form-control" id="inputTakenFrenchTest" value={takenFrenchTest === 'Yes' ? `${t('forms.click.yes')}`: `${t('forms.click.no')}`} placeholder="N.A"/>
          </div>
        </div>
        {props.frenchLanguageProficiency.frenchtest === 'Yes' && (
        <>
        <div className="form-group row">
          <label htmlFor="inputTestType" className="col-sm-3 col-form-label">{t("forms.formsquestion.language.testType")}</label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputTestType" value={testType} placeholder="N.A"/>
          </div>
          <label htmlFor="inputTestDate" className="col-sm-3 col-form-label">{t("forms.formsquestion.language.testDate")}</label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputTestDate" value={testDate} placeholder="N.A"/>
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="inputSpeakingScore" className="col-sm-3 col-form-label">{t("forms.formsquestion.language.speaking")}</label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputSpeakingScore" value={speakingScore} placeholder="N.A"/>
          </div>
          <label htmlFor="inputListeningScore" className="col-sm-3 col-form-label">{t("forms.formsquestion.language.listening")}</label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputListeningScore" value={listeningScore} placeholder="N.A"/>
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="inputWritingScore" className="col-sm-3 col-form-label">{t("forms.formsquestion.language.writing")}</label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputWritingScore" value={writingScore} placeholder="N.A"/>
          </div>
          <label htmlFor="inputReadingScore" className="col-sm-3 col-form-label">{t("forms.formsquestion.language.reading")}</label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputReadingScore" value={readingScore} placeholder="N.A"/>
          </div>
        </div>
        </>
        )}
      </div>
      {props.frenchLanguageProficiency.frenchtest === 'No' && (
      <div className='mx-3'>
        <div className="form-group row">
          <label htmlFor="inputCompletedEducationInFrench" className="col-sm-6 col-form-label">
          {t("forms.formsquestion.language.frenchEducation")}
          </label>
          <div className="col-sm-6">
            <input type="text" className="form-control" id="inputCompletedEducationInFrench" value={completedEducationInFrench === 'Yes' ? `${t('forms.click.yes')}`: `${t('forms.click.no')}`} placeholder="N.A"/>
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="inputSpeakingLevel" className="col-sm-3 col-form-label">{t("forms.formsquestion.language.speaking")}</label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputSpeakingLevel" value={speakingLevel} placeholder="N.A"/>
          </div>
          <label htmlFor="inputListeningLevel" className="col-sm-3 col-form-label">{t("forms.formsquestion.language.listening")}</label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputListeningLevel" value={listeningLevel} placeholder="N.A"/>
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="inputWritingLevel" className="col-sm-3 col-form-label">{t("forms.formsquestion.language.writing")}</label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputWritingLevel" value={writingLevel} placeholder="N.A"/>
          </div>
          <label htmlFor="inputReadingLevel" className="col-sm-3 col-form-label">{t("forms.formsquestion.language.reading")}</label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputReadingLevel" value={readingLevel} placeholder="N.A"/>
          </div>
        </div>
      </div>
      )}
    </div>
  );
}