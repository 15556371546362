import axios from "axios";

export const sortDictionaryByValues = (dict) => {
    return Object.fromEntries(
      Object.entries(dict).sort(([, a], [, b]) => a.localeCompare(b))
    );
};

export const countryName = (value) => {

  axios.get(`https://restcountries.com/v3.1/alpha/${value}`)
    .then(response => {
      // const country = response.data[0];
      // const name = country.name.common;
       // console.log(response.data[0].name.common)

      return value;
    })
    .catch(error => {
      // console.error('Error fetching the flag data:', error);
    return value;
  });

}


export const countriesWithProvinces = ['CAN'];
export const provincesWithCities = ['QC'];

export const levelChoices = {
  "A1":"A1",
  "A2":"A2",
  "B1":"B1",
  "B2":"B2",
  "C1":"C1",
  "C2":"C2"
}

export const evaluationCountryConverter = (evaluationCountry) =>{

  const country_name_to_code = {
    'Canada': 'CAN',
    'New Zealand': 'NZW',
  }
  
  return country_name_to_code[evaluationCountry]
}

export const evaluationCountryConverterCodeToName = (code) =>{

  const country_name_to_code = {
    'CAN': 'Canada',
    'NZL': 'New Zealand',
  }
  
  return country_name_to_code[code]
}

export const canadaStatesConverter = (stateCode) =>{

  const state_code_to_name = {
    'FD': 'Federal',
    'QC': 'Québec',
    'ON': 'Ontario',
    'BC': 'British Columbia',
    'AB': 'Alberta',
    'MA': 'Manitoba',
    'NB': 'New Brunswick',
    'NL': 'Newfoundland and Labrador',
    'NS': 'Nova Scotia',
    'NT': 'NorthWest Territories (Territory)',
    'NU': 'Nanuvut (Territory)',
    'PE': 'Prince Edouard Island',
    'SA': 'Saskatchewan',
    'YU': 'Yukon (Territory)'
  }
  
  return state_code_to_name[stateCode]
}

export const languageConverter = (languageCode) => {
  const language_code_to_name = {
    'FR': 'French',
    'EN': 'English',
    'ES': 'Spanish',
    // Ajoutez d'autres codes de langue et noms selon vos besoins
  };
  
  return language_code_to_name[languageCode];
};

export const MessageStatesConverter = (state) => {
  const statesConverter = {
    'PD': 'PENDING',
    'DN': 'DONE',
  };
  
  return statesConverter[state];
};

export const MessageTypesConverter = (type) => {
  const typesConverter = {
    'URG': 'URGENT',
    'TEC': 'TECHNICAL',
    'COM': 'COMMENT/SUGGESTION',
  };
  
  return typesConverter[type];
};

export const FirmTypesConverter = (type) => {
  const typesConverter = {
    'LF': 'Law Firm',
    'CF': 'Consultant Firm',
    'LCF': 'Law & Consultant Firm',
  };
  
  return typesConverter[type];
};

export const OccupationConverter = (occupation) => {
  const occupationConverter = {
    'IML': 'Immigration Lawyer',
    'IMC': 'Immigration Consultant',
    'NAT': 'Not A Request',
  };
  
  return occupationConverter[occupation];
};

export const truncateMessage = (str, limit=15) => {
  return str.length > limit ? str.substring(0, limit) + '...' : str;
};

export const createShortName = (firstName, lastName) => {
  // Helper function to get letters from the name or duplicate if not enough
  const getLetters = (name, requiredLength) => {
    let result = name.substring(0, requiredLength);
    while (result.length < requiredLength) {
      result += name.charAt(result.length % name.length);
    }
    return result;
  };

  // Try to get 3 letters from firstName, if not enough, take from lastName
  let shortFirstName = getLetters(firstName, 3);
  if (shortFirstName.length < 3) {
    shortFirstName += getLetters(lastName, 3 - shortFirstName.length);
  }

  // Try to get 2 letters from lastName, if not enough, take from firstName
  let shortLastName = getLetters(lastName, 2);
  if (shortLastName.length < 2) {
    shortLastName += getLetters(firstName, 2 - shortLastName.length);
  }

  return shortFirstName + shortLastName;
}

export const admissibleUsers = ['tatianaoviedot@gmail.com', 'inoussa.legrene.1@ens.etsmtl.ca', 
  'inous.legrene@gmail.com', 'flousdev@gmail.com']


export const sectionList = [
  "Summary",
  "Personal Informations",
  "Family Members",
  "Residences",
  "Languages",
  "Education",
  "Experience",
  "Expertise",
  "Job offer",
  "Entrepreneurial Experience"
];

export const sectionListFR = [
  "Sommaire",
  "Informations Personnelles",
  "Membres de la Famille",
  "Residences",
  "Langues",
  "Éducation",
  "Expérience",
  "Expertise",
  "Offre d'Emploi",
  "Expérience entrepreneuriale"
];

export const sectionListES = [
  "Resumen",
  "Información personal",
  "Miembros de la Familia",
  "Residencias",
  "Idiomas",
  "Educación",
  "Experiencia",
  "Experiencia",
  "Oferta de Empleo",
  "Experiencia Empresarial"
];

export const mapStateToDescription = (v, lang) => {
  const dataEN = {
    CR: "CREATED",
    IP: "IN PROGRESS",
    AB: "ABANDONED",
    CP: "COMPLETED",
    PS: "EVALUATION IN PROGRESS",
    DL: "DELETED",
    EV: "EVALUATED",
  };
  const dataFR = {
    CR: "CRÉÉ",
    IP: "EN COURS",
    AB: "ABANDONNÉ",
    CP: "COMPLETÉ",
    PS: "EVALUATION EN COURS",
    DL: "SUPPRIMÉ",
    EV: "ÉVALUÉ",
  };
  const dataES = {
    CR: "CREADO",
    IP: "EN CURSO",
    AB: "ABANDONED",
    CP: "COMPLETADO",
    PS: "EVALUACIÓN EN CURSO",
    DL: "SUPRIMIDO",
    EV: "EVALUADO",
  };
  return lang === 'en' ? dataEN[v]:(lang === 'fr' ? dataFR[v]:dataES[v])
};

export const gender = (g, lang) =>{

  const genderEN = {
    'Fem': "Female",
    'Mal': "Male",
    'Oth': "Other",
    'Unk': "Prefer not to say"
  }
  const genderFR = {
    'Fem': "Femme",
    'Mal': "Homme",
    'Oth': "Autre",
    'Unk': "Préfère ne pas dire"
  }
  const genderES = {
    'Fem': "Mujer",
    'Mal': "Hombre",
    'Oth': "Otros",
    'Unk': "Prefiero no decirlo"
  }
  
  return lang === 'en' ? genderEN[g]:(lang === 'fr' ? genderFR[g]:genderES[g])
}

export const mStatus = (s, lang) =>{

  const statusEN = {
    "Single":"Single / Never Married",
    "Common_law_partner":"Common-law partner",
    "Married": "Married",
    "Married_Cancelled": "Annulled Marriage",
    "Divorced": "Divorced",
    "Separated": "Legally separated",
    "Widow": "Widowed",
  }
  const statusFR = {
    "Single":"Célibataire / Jamais Marié(e)",
    "Common_law_partner":"Conjoint de fait",
    "Married": "Marié(e)",
    "Married_Cancelled": "Mariage annulé",
    "Divorced": "Divorcé",
    "Separated": "Séparation légale",
    "Widow": "Veuve",
  }
  const statusES = {
    "Single":"Único / Nunca se casó",
    "Common_law_partner":"Pareja de hecho",
    "Married": "Casado",
    "Married_Cancelled": "Matrimonio anulado",
    "Divorced": "Divorciado",
    "Separated": "Legalmente separado",
    "Widow": "Viuda",
  }
  
  return lang === 'en' ? statusEN[s]:(lang === 'fr' ? statusFR[s]:statusES[s])
}

export const LegalStatus = (s, lang) =>{

  const statusEN = {
    "TP_OWP": "Open Work Permit Holder",
    "TP_PGWP": "Post-Graduation Work Permit Holder",
    "TP_CWP": "Closed Work Permit Holder",
    "TP_SP": "Study Permit Holder",
    "VS": "Visitor Visa",
    "RC": "Refugee Claimant",
    "HCA": "Humanitarian and Compassionate Applicant",
    "NS": "No Status",
  }
  const statusFR = {
    "TP_OWP": "Détenteur d'un permis de travail ouvert",
    "TP_PGWP": "Titulaire d'un permis de travail post-diplôme",
    "TP_CWP": "Titulaire d'un permis de travail fermé",
    "TP_SP": "Titulaire du permis d'étude",
    "VS": "Visa de visiteur",
    "RC": "Demandeur d'asile",
    "HCA": "Candidat humanitaire et compatissant",
    "NS": "Pas de statut",
  }
  const statusES = {
    "TP_OWP": "Titular de un permiso de trabajo abierto",
    "TP_PGWP": "Titular de un permiso de trabajo de postgrado",
    "TP_CWP": "Titular de un permiso de trabajo cerrado",
    "TP_SP": "Titular del permiso de estudios",
    "VS": "Visado de visitante",
    "RC": "Solicitante de la condición de refugiado",
    "HCA": "Solicitante humanitario y compasivo",
    "NS": "Sin estado",
  }
  
  return lang === 'en' ? statusEN[s]:(lang === 'fr' ? statusFR[s]:statusES[s])
}

export const familyRelation = (v, lang) =>{

  const dataEN = {
    "Father": "Father",
    "Stepfather": "Stepfather",
    "Mother": "Mother",
    "Stepmother": "Stepmother",
    "Sister": "Sister",
    "Stepsister": "Stepsister",
    "Brother": "Brother",
    "Stepbrother": "Stepbrother",
    "Grandfather": "Grandfather",
    "Grandmother": "Grandmother",
    "Uncle": "Uncle",
    "Aunt": "Aunt",
    "Niece": "Niece",
    "Nephew": "Nephew",
    "Other": "Other",
  }
  const dataFR = {
    "Father": "Père",
    "Stepfather": "Beau-père",
    "Mother": "Mère",
    "Stepmother": "Belle-mère",
    "Sister": "Sœur",
    "Stepsister": "Belle-sœur",
    "Brother": "Frère",
    "Stepbrother": "Demi-frère",
    "Grandfather": "Grand-père",
    "Grandmother": "Grand-mère",
    "Uncle": "Oncle",
    "Aunt": "Tante",
    "Niece": "Nièce",
    "Nephew": "Neveu",
    "Other": "Autre",
  }
  const dataES = {
    "Father": "Padre",
    "Stepfather": "Padrastro",
    "Mother": "Madre",
    "Stepmother": "Madrastra",
    "Sister": "Hermana",
    "Stepsister": "Hermanastra",
    "Brother": "Hermano",
    "Stepbrother": "Hermanastro",
    "Grandfather": "Abuelo",
    "Grandmother": "Abuela",
    "Uncle": "Tío",
    "Aunt": "Tía",
    "Niece": "Sobrina",
    "Nephew": "Sobrino",
    "Other": "Otro",
  }
  
  return lang === 'en' ? dataEN[v]:(lang === 'fr' ? dataFR[v]:dataES[v])
}

export const companyType = (v, lang) =>{

  const dataEN = {
    "Existing": "Existing",
    "Start a new company": "Start a new company",
    "Undertake an existing company": "Undertake an existing company",
  }
  const dataFR = {
    "Existing": "Existante",
    "Start a new company": "Créer une nouvelle entreprise",
    "Undertake an existing company": "Acquérir une entreprise existante",
  }
  const dataES = {
    "Existing": "Existente",
    "Start a new company": "Crear una nueva empresa",
    "Undertake an existing company": "Adquisición de una empresa existente",
  }
  
  return lang === 'en' ? dataEN[v]:(lang === 'fr' ? dataFR[v]:dataES[v])
}

export const preferredLang = (v) =>{

  const languages = {
    "EN":"English",
    "FR":"Français",
    "ES":"Spanish",
  }
  
  return languages[v];
}

export const educationLevel = (v, lang) => {
  const dataEN = {
    LHS: "LHS - None, or less than secondary (High School)",
    HSG: "HSG - Secondary Diploma (Hish school graduation)",
    PRG: "PRG - Program in a college, trade or trade of technical school, or other institute",
    PDL: "PDL - Professional degree needed to practice in a licensed profession",
    UND: "UND - Undergraduate program at a university",
    BAC: "BAC - Bachelor's degree ( three or more year program at a university, college, trade or trade of technical school, or other institute)",
    GRD: "GRD - Graduate program at a university (Specialized or Master's degree)",
    PGR: "PGR - Postgraduate program at a university (PhD)",
  };
  const dataFR = {
    LHS: "LHS - Aucun, ou moins que le secondaire (High School)",
    HSG: "HSG - Diplôme d'études secondaires (Hish school graduation)",
    PRG: "PRG - Programme dans un collège, une école professionnelle ou technique, ou un autre institut",
    PDL: "PDL - Diplôme professionnel nécessaire pour exercer une profession autorisée",
    UND: "UND - Programme de premier cycle dans une université",
    BAC: "BAC - Licence (programme de trois ans ou plus dans une université, un collège, une école professionnelle ou technique, ou un autre institut)",
    GRD: "GRD - Programme d'études supérieures dans une université (diplôme de spécialisation ou de maîtrise)",
    PGR: "PGR - Programme de troisième cycle dans une université (doctorat)",
  };
  const dataES = {
    LHS: "LHS - Ninguna, o menos de secundaria (Bachillerato)",
    HSG: "HSG - Diploma de Secundaria (graduación escolar en Hish)",
    PRG: "PRG - Programa en una universidad, escuela técnica u otro instituto",
    PDL: "PDL - Título profesional necesario para ejercer una profesión autorizada",
    UND: "UND - Programa de licenciatura en una universidad",
    BAC: "BAC - Licenciatura (programa de tres años o más en una universidad, escuela superior, escuela técnica u otro instituto)",
    GRD: "GRD - Programa de posgrado en una universidad (especialización o máster)",
    PGR: "PGR - Programa de posgrado en una universidad (doctorado)",
  };
  return lang === 'en' ? dataEN[v]:(lang === 'fr' ? dataFR[v]:dataES[v])
};

export const generalField = (v, lang) => {
  const dataEN = {
    AGR: "AGR - Agriculture, agriculture operations and related sciences",
    BIO: "BIO - Biological and biomedical sciences",
    CIS: "CIS - Computer and information sciences and support services",
    ENG: "ENG - Engineering technology and engineering-related fields",
    HEA: "HEA - Health professions and related programs",
    NAS: "NAS - Natural and applied sciences",
    NRR: "NRR - Natural resources and related",
    PHY: "PHY - Physical sciences",
    ARC: "ARC - Architecture",
    FIN: "FIN - Finances and administration",
    TRA: "TRA - Trades, transport and equipment operators and related",
    LAW: "LAW - Education, law, social, community and government",
    ART: "ART - Art, culture, recreation and sport",
    SAL: "SAL - Sales and services",
    MAN: "MAN - Manufacturing and utilities",
    OTH: "OTH - Others",
  };
  const dataFR = {
    AGR: "AGR - Agriculture, opérations agricoles et sciences connexes",
    BIO: "BIO - Sciences biologiques et biomédicales",
    CIS: "CIS - Sciences informatiques et de l'information et services de soutien",
    ENG: "ENG - Technologie de l'ingénierie et domaines liés à l'ingénierie",
    HEA: "HEA - Professions de la santé et programmes connexes",
    NAS: "NAS - Sciences naturelles et appliquées",
    NRR: "NRR - Ressources naturelles et domaines connexes",
    PHY: "PHY - Sciences physiques",
    ARC: "ARC - Architecture",
    FIN: "FIN - Finances et administration",
    TRA: "TRA - Métiers, transport et opérateurs d'équipement et domaines connexes",
    LAW: "LAW - Éducation, droit, social, communautaire et gouvernement",
    ART: "ART - Art, culture, loisirs et sport",
    SAL: "SAL - Ventes et services",
    MAN: "MAN - Fabrication et services publics",
    OTH: "OTH - Autres"
};
  const dataES = {
    AGR: "AGR - Agricultura, operaciones agrícolas y ciencias relacionadas",
    BIO: "BIO - Ciencias biológicas y biomédicas",
    CIS: "CIS - Ciencias de la computación e información y servicios de apoyo",
    ENG: "ENG - Tecnología de ingeniería y campos relacionados con la ingeniería",
    HEA: "HEA - Profesiones de la salud y programas relacionados",
    NAS: "NAS - Ciencias naturales y aplicadas",
    NRR: "NRR - Recursos naturales y relacionados",
    PHY: "PHY - Ciencias físicas",
    ARC: "ARC - Arquitectura",
    FIN: "FIN - Finanzas y administración",
    TRA: "TRA - Oficios, transporte y operadores de equipos y relacionados",
    LAW: "LAW - Educación, derecho, social, comunitario y gobierno",
    ART: "ART - Arte, cultura, recreación y deporte",
    SAL: "SAL - Ventas y servicios",
    MAN: "MAN - Manufactura y servicios públicos",
    OTH: "OTH - Otros"
  };
  return lang === 'en' ? dataEN[v]:(lang === 'fr' ? dataFR[v]:dataES[v])
};
