import React from 'react';
import EducationInCountryInfoResumeSection from './section_education/educationInCountrySection';
import EducationOutsideCountryInfoResumeSection from './section_education/educationOutsideCountrySection';
import SpecialProgramResumeSection from './section_education/specialProgramSection';
import { useTranslation } from 'react-i18next';

export default function EducationResumeSection(props) {
  
  const { t } = useTranslation();

  return (
    <div id='language'>
      <h3 className='flous-police-one flous-input-color text-center'>{t("client.results.blockTitle.bloc5")}</h3>
      {props.educationInCountry.educationInCountry === 'Yes' && (
        <EducationInCountryInfoResumeSection allEducationInCountry={props.allEducationInCountry} show={props.show} />
      )}
      <SpecialProgramResumeSection specialProgram={props.specialProgram} />
      {props.educationOutsideCountry.educationOutsideCountry === 'Yes' && (
        <EducationOutsideCountryInfoResumeSection allEducationOutsideCountry={props.allEducationOutsideCountry} show={props.show} />
      )}
    </div>
  );
}
