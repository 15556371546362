import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';

export default function PersonalInfoResumePDF(props) {
  const { t } = useTranslation();

  const styles = StyleSheet.create({
    page: {
      padding: 20,
      backgroundColor: '#ffffff',
    },
    mainTitle: {
      fontSize: 20,
      fontFamily: 'Helvetica-Bold',
      color: '#0066CC',
      textAlign: 'center',
      marginBottom: 20,
    },
    card: {
        backgroundColor: '#f8f9ff',
        borderRadius: 8,
        padding: 16,
        paddingBottom: 5,
        marginBottom: 10,
        width: '100%',
      },
    sectionTitle: {
      fontSize: 12,
      fontFamily: 'Helvetica-Bold',
      textAlign: 'center',
      marginBottom: 16,
      color: '#000000',
    },
    row: {
      flexDirection: 'row',
      marginBottom: 12,
      gap: 12,
    },
    labelColumn: {
      width: '30%',
    },
    valueColumn: {
      width: '20%',
    },
    label: {
      fontSize: 10,
      color: '#000000',
      fontFamily: 'Helvetica',
    },
    value: {
      fontSize: 10,
      padding: '6 8',
      backgroundColor: '#ffffff',
      borderRadius: 4,
      minHeight: 28,
      border: '1px solid #e2e8f0',
      fontFamily: 'Helvetica',
    }
  });

  return (
    <Page size="A4" style={styles.page}>
      <Text style={styles.mainTitle}>{t("client.results.blockTitle.bloc1")}</Text>

      {/* Identification Section */}
      <View style={styles.card}>
        <Text style={styles.sectionTitle}>{t("forms.title.identification")}</Text>
        <View style={styles.row}>
          <View style={styles.labelColumn}>
            <Text style={styles.label}>{t("forms.formsquestion.personalInfo.firstname")}</Text>
          </View>
          <View style={styles.valueColumn}>
            <Text style={styles.value}>{props.personalInformation.identification.firstname || 'N.A'}</Text>
          </View>
          <View style={styles.labelColumn}>
            <Text style={styles.label}>{t("forms.formsquestion.personalInfo.lastname")}</Text>
          </View>
          <View style={styles.valueColumn}>
            <Text style={styles.value}>{props.personalInformation.identification.lastname || 'N.A'}</Text>
          </View>
        </View>

        <View style={styles.row}>
          <View style={styles.labelColumn}>
            <Text style={styles.label}>{t("forms.formsquestion.personalInfo.dateofbirth")}</Text>
          </View>
          <View style={styles.valueColumn}>
            <Text style={styles.value}>{props.personalInformation.identification.dateofbirth || 'N.A'}</Text>
          </View>
          <View style={styles.labelColumn}>
            <Text style={styles.label}>{t("forms.formsquestion.personalInfo.countryofbirth")}</Text>
          </View>
          <View style={styles.valueColumn}>
            <Text style={styles.value}>{props.personalInformation.identification.countryofbirth || 'N.A'}</Text>
          </View>
        </View>
      </View>

      {/* Personal Details Section */}
      <View style={styles.card}>
        <Text style={styles.sectionTitle}>{t("forms.title.personaldetail")}</Text>
        <View style={styles.row}>
          <View style={styles.labelColumn}>
            <Text style={styles.label}>{t("forms.formsquestion.personalInfo.genderIdentity")}</Text>
          </View>
          <View style={styles.valueColumn}>
            <Text style={styles.value}>{props.personalInformation.personalDetail.genderIdentity || 'N.A'}</Text>
          </View>
          <View style={styles.labelColumn}>
            <Text style={styles.label}>{t("forms.formsquestion.personalInfo.nationality")}</Text>
          </View>
          <View style={styles.valueColumn}>
            <Text style={styles.value}>{props.personalInformation.personalDetail.nationality || 'N.A'}</Text>
          </View>
        </View>

        <View style={styles.row}>
          <View style={styles.labelColumn}>
            <Text style={styles.label}>{t("forms.formsquestion.personalInfo.maritalStatus")}</Text>
          </View>
          <View style={styles.valueColumn}>
            <Text style={styles.value}>{props.personalInformation.personalDetail.maritalStatus || 'N.A'}</Text>
          </View>
          <View style={styles.labelColumn}>
            <Text style={styles.label}>{t("forms.formsquestion.personalInfo.preferredLanguage")}</Text>
          </View>
          <View style={styles.valueColumn}>
            <Text style={styles.value}>{props.personalInformation.preferredLanguage || 'N.A'}</Text>
          </View>
        </View>
      </View>

      {/* Contact Information Section */}
      <View style={styles.card}>
        <Text style={styles.sectionTitle}>{t("forms.title.contactinfo")}</Text>
        <View style={styles.row}>
          <View style={{width: "20%"}}>
            <Text style={styles.label}>{t("forms.formsquestion.personalInfo.email")}</Text>
          </View>
          <View style={{width: "30%"}}>
            <Text style={styles.value}>{props.personalInformation.contactInfo.email || 'N.A'}</Text>
          </View>
          <View style={{width: "20%"}}>
            <Text style={styles.label}>{t("forms.formsquestion.personalInfo.phone")}</Text>
          </View>
          <View style={{width: "30%"}}>
            <Text style={styles.value}>{props.personalInformation.contactInfo.phone || 'N.A'}</Text>
          </View>
        </View>
      </View>

      {/* Additional Information Section */}
      <View style={styles.card}>
        <Text style={styles.sectionTitle}>{t("forms.title.addinfo")}</Text>
        <View style={styles.row}>
          <View style={styles.labelColumn}>
            <Text style={styles.label}>{t("forms.formsquestion.personalInfo.netWorth")}</Text>
          </View>
          <View style={styles.valueColumn}>
            <Text style={styles.value}>{props.personalInformation.addInfo.netWorth || 'N.A'}</Text>
          </View>
          <View style={styles.labelColumn}>
            <Text style={styles.label}>{t("forms.formsquestion.personalInfo.earningsHistory")}</Text>
          </View>
          <View style={styles.valueColumn}>
            <Text style={styles.value}>{props.personalInformation.addInfo.earningsHistory || 'N.A'}</Text>
          </View>
        </View>

        <View style={styles.row}>
          <View style={styles.labelColumn}>
            <Text style={styles.label}>{t("forms.formsquestion.personalInfo.totalCurrentAssets")}</Text>
          </View>
          <View style={styles.valueColumn}>
            <Text style={styles.value}>{props.personalInformation.addInfo.totalCurrentAssets || 'N.A'}</Text>
          </View>
          <View style={styles.labelColumn}>
            <Text style={styles.label}>{t("forms.formsquestion.personalInfo.provincialNomination")} Canada?</Text>
          </View>
          <View style={styles.valueColumn}>
            <Text style={styles.value}>
              {props.personalInformation.provincialNomination === 'Yes'
                ? t('forms.click.yes')
                : t('forms.click.no')}
            </Text>
          </View>
        </View>
      </View>
    </Page>
  );
}

