import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import CountryShow from '../../../general/countryShow';
import { useTranslation } from 'react-i18next';
import { familyRelation } from '../../../../services/utils';

const styles = StyleSheet.create({
  page: {
    padding: 20,
    backgroundColor: '#ffffff',
  },
  mainTitle: {
    fontSize: 20,
    fontFamily: 'Helvetica-Bold',
    color: '#0066CC',
    textAlign: 'center',
    marginBottom: 20,
  },
  card: {
    backgroundColor: '#f8f9ff',
    borderRadius: 8,
    padding: 16,
    paddingBottom: 5,
    marginBottom: 10,
    width: '100%',
  },
  memberCard: {
    backgroundColor: '#ffffff',
    borderRadius: 4,
    paddingHorizontal: 10,
    paddingVertical: 5,
    marginBottom: 5,
    border: '1px solid #e2e8f0',
  },
  sectionTitle: {
    fontSize: 16,
    fontFamily: 'Helvetica-Bold',
    textAlign: 'center',
    marginBottom: 16,
    color: '#000000',
  },
  row: {
    flexDirection: 'row',
    marginBottom: 12,
    gap: 12,
  },
  labelColumn: {
    width: '30%',
  },
  valueColumn: {
    width: '20%',
  },
  label: {
    fontSize: 10,
    color: '#000000',
    fontFamily: 'Helvetica',
  },
  value: {
    fontSize: 10,
    padding: '6 8',
    backgroundColor: '#ffffff',
    borderRadius: 4,
    minHeight: 28,
    border: '1px solid #e2e8f0',
    fontFamily: 'Helvetica',
  },
  memberRow: {
    flexDirection: 'row',
    marginBottom: 8,
    gap: 8,
  },
  memberLabel: {
    fontSize: 10,
    color: '#000000',
    fontFamily: 'Helvetica',
    flex: 1,
  },
  memberValue: {
    fontSize: 10,
    color: '#000000',
    fontFamily: 'Helvetica',
    flex: 1,
  }
});

export default function FamilyMembersResumeSectionPDF(props) {
  const { t } = useTranslation();
  const lang = localStorage.getItem('flouslang') || 'en';

  const {
    children: { dependantChildren, totalDependantChildren, childrenUnder22 },
    familyMembers,
    show,
  } = props;

  return (
    <Page style={styles.page}>
      <Text style={styles.mainTitle}>{t("client.results.blockTitle.bloc2")}</Text>

      <View style={styles.card}>
        <View style={styles.row}>
          <View style={{width: '70%'}}>
            <Text style={styles.label}>{t('forms.formsquestion.familyMembers.dependantChildren')}</Text>
          </View>
          <View style={styles.valueColumn}>
            <Text style={styles.value}>
              {dependantChildren === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}
            </Text>
          </View>
        </View>

        {dependantChildren === 'Yes' && (
          <>
            <View style={styles.row}>
              <View style={styles.labelColumn}>
                <Text style={styles.label}>{t('forms.formsquestion.familyMembers.totalDependantChildren')}</Text>
              </View>
              <View style={styles.valueColumn}>
                <Text style={styles.value}>{totalDependantChildren}</Text>
              </View>
              <View style={styles.labelColumn}>
                <Text style={styles.label}>{t('forms.formsquestion.familyMembers.childrenUnder22')}</Text>
              </View>
              <View style={styles.valueColumn}>
                <Text style={styles.value}>{childrenUnder22}</Text>
              </View>
            </View>
          </>
        )}
      </View>

      <View style={styles.card}>
        {!familyMembers || familyMembers.length === 0 ? (
          <Text style={styles.label}>{t('general.table.empty.familyone')}</Text>
        ) : (
          familyMembers.map((member, index) => (
            <View key={index} style={styles.memberCard}>
              <View style={styles.row}>
                <View style={styles.labelColumn}>
                  <Text style={styles.label}>{t('general.table.firstname')}</Text>
                </View>
                <View style={styles.valueColumn}>
                  <Text style={styles.value}>{member.firstname}</Text>
                </View>
                <View style={styles.labelColumn}>
                  <Text style={styles.label}>{t('general.table.lastname')}</Text>
                </View>
                <View style={styles.valueColumn}>
                  <Text style={styles.value}>{member.lastname}</Text>
                </View>
              </View>

              <View style={styles.row}>
                <View style={styles.labelColumn}>
                  <Text style={styles.label}>{t('general.table.nationality')}</Text>
                </View>
                <View style={styles.valueColumn}>
                  <Text style={styles.value}><CountryShow value={member.nationality} /></Text>
                </View>
                <View style={styles.labelColumn}>
                  <Text style={styles.label}>{t('general.table.address')}</Text>
                </View>
                <View style={styles.valueColumn}>
                  <Text style={styles.value}><CountryShow value={member.country} /></Text>
                </View>
              </View>

              <View style={styles.row}>
                <View style={styles.labelColumn}>
                  <Text style={styles.label}>{t('general.table.birthday')}</Text>
                </View>
                <View style={styles.valueColumn}>
                  <Text style={styles.value}>{member.birthday}</Text>
                </View>
                <View style={styles.labelColumn}>
                  <Text style={styles.label}>{t('general.table.Isdead')}</Text>
                </View>
                <View style={styles.valueColumn}>
                  <Text style={styles.value}>{member.deathday || t('forms.click.no')}</Text>
                </View>
              </View>

              {(show || member.visibleDetails) && (
                <>
                  <View style={styles.row}>
                    <View style={styles.labelColumn}>
                      <Text style={styles.label}>{t('forms.formsquestion.familyMembers.familyRelationship')}</Text>
                    </View>
                    <View style={styles.valueColumn}>
                      <Text style={styles.value}>{familyRelation(member.familyRelationship, lang)}</Text>
                    </View>
                    <View style={styles.labelColumn}>
                      <Text style={styles.label}>{t('forms.formsquestion.familyMembers.permanentOrCan')}</Text>
                    </View>
                    <View style={styles.valueColumn}>
                      <Text style={styles.value}>
                        {member.permanent === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}
                      </Text>
                    </View>
                  </View>
                </>
              )}
            </View>
          ))
        )}
      </View>
    </Page>
  );
}

