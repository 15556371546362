import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext } from "react-router-dom";

const UpgradeSubcriptionModal = ({type, isOpen, onClose }) => {

  const {user} = useOutletContext();
  const navigate = useNavigate()
  const { t } = useTranslation();

  const handleContinue = () =>{
    if (user.type_package === 'Professional') {
      navigate('/professional/profile');
    } else {
      navigate('/enterprise/profile');
    }
  }

  
  if (!isOpen) {
    return null;
  }

  return (
    <div id="upgradeSubcription" className="modal fade show" style={{ display: "block" }} role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="modalTitle">{t("modal.upgradesubscription.title")}</h4>
            <button type="button" className="close" data-dismiss="modal">&times;</button>
          </div>
          <div className="modal-body">
            <div className="col mb-3">{t("modal.upgradesubscription.message")}</div>
            <div className="col mb-3">{t("modal.upgradesubscription.message2")}</div>
            <div className="col">
              <button className="btn flous-bg-primary" onClick={handleContinue}>
              {t("modal.upgradesubscription.submit")}
              </button>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-default" data-dismiss="modal"  onClick={onClose}>
            {t("modal.cancel")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpgradeSubcriptionModal;
