import React, { useEffect, useState } from 'react';
import { levelChoices, sortDictionaryByValues } from '../../../../../services/utils';
import api from '../../../../../services/api';
import Select from 'react-select';
import RequiredIndicator from '../../../../general/requiredIndicator';

import { useTranslation } from "react-i18next";
import { translateDictionaryList } from '../../../../../services/general/TranslationServices';

const EnglishLanguageProficiencySection = (props) => {
  const [formData, setFormData] = useState(props.englishLanguageProficiency);
  const { t } = useTranslation();

  const [testTypes, setTestTypes] = useState([]);
  
  const lang = localStorage.getItem('flouslang') || 'en';
  const [testTypesOriginal, setTestTypesOriginal] = useState([]);

  const handleTranslate = async (data)=>{
    if (lang !== 'en') {
      const testTypesTranslated = await translateDictionaryList(testTypesOriginal, lang);
      setTestTypes(testTypesTranslated);
    } else {
      setTestTypes(testTypesOriginal);
    }
  };

  useEffect(()=>{
    handleTranslate();
  }, [lang, testTypesOriginal]);

  useEffect(() => {
    const fetchTestTypes = async () =>{
        const response = await api.get('/englishtest/');
        const sorted = sortDictionaryByValues(response.data);
        const options = getOptions(sorted);
        setTestTypes(options);
        setTestTypesOriginal(options);
    };
    fetchTestTypes();

  }, []);

  const getOptions = (data) => {
    return Object.entries(data).map(([key, val]) => ({
      value: key,
      label: val,
    }));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    const updatedFormData = {
      ...formData,
      [name]: value,
    };
    setFormData(updatedFormData);
    props.sendEnglishLanguageProficiency(updatedFormData);
  };

  const handleEnglishTestChange = (event) => {
    const value = event.target.value;
    const updatedFormData = {
      ...formData,
      englishtest: value,
      testType: '',
      testDate: '',
      speakingScore: '',
      listeningScore: '',
      writingScore: '',
      readingScore: '',
      speakingLevel: '',
      listeningLevel: '',
      writingLevel: '',
      readingLevel: '',
      englishEducation: '',

    };
    setFormData(updatedFormData);
    props.sendEnglishLanguageProficiency(updatedFormData);
  };

  const handleEnglishEducationChange = (event) => {
    const value = event.target.value;
    const updatedFormData = {
      ...formData,
      speakingScore: '',
      listeningScore: '',
      writingScore: '',
      readingScore: '',
      speakingLevel: '',
      listeningLevel: '',
      writingLevel: '',
      readingLevel: '',
      englishEducation: value,

    };
    setFormData(updatedFormData);
    props.sendEnglishLanguageProficiency(updatedFormData);
  };

  return (
    <div className="form-card">
      <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
      <div style={{ color: 'red' }}>{props.error !== '' ? `${t('forms.formsquestion.toast.completeSection')}` : ''}</div>
      <p className='text-bold text-center'>{t("forms.title.englishlanguage")}</p>
        <div className='row p-2'>
          <p htmlFor="englishtest" className="col-form-label col-sm-10">{t("forms.formsquestion.language.englishtest")}<RequiredIndicator /></p>
          <div className="form-check col">
            <input 
              type="radio"
              id='englishtestYes'
              className="form-check-input" 
              name='englishtest'
              value="Yes"
              onChange={handleEnglishTestChange}
              checked={formData.englishtest === "Yes"}
            />
            <p htmlFor="englishtestYes" className="form-check-p">{t("forms.click.yes")}</p>
          </div>
          <div className="form-check col">
            <input 
              type="radio"
              id='englishtestNo'
              name='englishtest'
              className="form-check-input" 
              value="No"
              onChange={handleEnglishTestChange}
              checked={formData.englishtest === "No"}
            />
            <p htmlFor="englishtestNo" className="form-check-p">{t("forms.click.no")}</p>
          </div>
        </div>
        {formData.englishtest === 'Yes' && (
          <div>
            <div className='row p-2'>
              <p htmlFor="testType" className="col-sm-4 col-form-label">{t("forms.formsquestion.language.testType")}<RequiredIndicator /></p>
              <div className="col-sm-8">
                <Select
                  id="testType"
                  name="testType"
                  className="react-select-container rounded-pill flous-input-color"
                  classNamePrefix="react-select"
                  value={testTypes.find(option => option.value === formData.testType)}
                  onChange={(selectedOption) => handleChange({ target: { name: 'testType', value: selectedOption ? selectedOption.value : '' } })}
                  options={testTypes}
                  placeholder={`${t('forms.formsquestion.placeholder.option')}`}
                  isSearchable
                  isClearable
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      width: '100%',
                      borderRadius: '20px', // Ajustez la valeur pour l'arrondissement
                      borderColor: '#ccc',  // Vous pouvez également ajuster la couleur de la bordure si nécessaire
                    }),
                  }}
                  required
                />
              </div>
            </div>
            <div className='row p-2'>
              <p htmlFor="testDate" className="col-sm-4 col-form-label">{t("forms.formsquestion.language.testDate")}<RequiredIndicator /></p>
              <div className="col-sm-8">
                <input 
                  type="date" 
                  id="testDate"
                  name="testDate"
                  className="form-control rounded-pill flous-input-color"
                  onChange={handleChange}
                  value={formData.testDate} max={new Date().toISOString().split("T")[0]}
                />
              </div>
            </div>
            <p>{t("forms.formsquestion.language.score")}</p>
            <div className='row p-2'>
              <p htmlFor="speakingScore" className="col-sm-4 col-form-label">{t("forms.formsquestion.language.speaking")}<RequiredIndicator /></p>
              <div className="col-sm-8">
                <input 
                  type="number" 
                  id="speakingScore"
                  name="speakingScore"
                  className="form-control rounded-pill flous-input-color"
                  onChange={handleChange}
                  value={formData.speakingScore}
                  placeholder="Score" min={0} max={9}
                />
              </div>
            </div>
            <div className='row p-2'>
              <p htmlFor="listeningScore" className="col-sm-4 col-form-label">{t("forms.formsquestion.language.listening")}<RequiredIndicator /></p>
              <div className="col-sm-8">
                <input 
                  type="number" 
                  id="listeningScore"
                  name="listeningScore"
                  className="form-control rounded-pill flous-input-color"
                  onChange={handleChange}
                  value={formData.listeningScore}
                  placeholder="Score" min={0} max={9}
                />
              </div>
            </div>
            <div className='row p-2'>
              <p htmlFor="writingScore" className="col-sm-4 col-form-label">{t("forms.formsquestion.language.writing")}<RequiredIndicator /></p>
              <div className="col-sm-8">
                <input 
                  type="number" 
                  id="writingScore"
                  name="writingScore"
                  className="form-control rounded-pill flous-input-color"
                  onChange={handleChange}
                  value={formData.writingScore}
                  placeholder="Score" min={0} max={9}
                />
              </div>
            </div>
            <div className='row p-2'>
              <p htmlFor="readingScore" className="col-sm-4 col-form-label">{t("forms.formsquestion.language.reading")}<RequiredIndicator /></p>
              <div className="col-sm-8">
                <input 
                  type="number" 
                  id="readingScore"
                  name="readingScore"
                  className="form-control rounded-pill flous-input-color"
                  onChange={handleChange}
                  value={formData.readingScore}
                  placeholder="Score" min={0} max={9}
                />
              </div>
            </div>
          </div>
        )}
        {formData.englishtest === 'No' && (
          <div>
            <div className='row p-2'>
              <p htmlFor="englishEducation" className="col-form-label col-sm-10">{t("forms.formsquestion.language.englishEducation")}<RequiredIndicator /></p>
              <div className="form-check col">
                <input 
                  type="radio"
                  id="englishEducationYes"
                  name="englishEducation"
                  className="form-check-input" 
                  value="Yes"
                  onChange={handleEnglishEducationChange}
                  checked={formData.englishEducation === "Yes"}
                />
                <p htmlFor="englishEducationYes" className="form-check-p">{t("forms.click.yes")}</p>
              </div>
              <div className="form-check col">
                <input 
                  type="radio"
                  id="englishEducationNo"
                  name="englishEducation"
                  className="form-check-input" 
                  value="No"
                  onChange={handleEnglishEducationChange}
                  checked={formData.englishEducation === "No"}
                />
                <p htmlFor="englishEducationNo" className="form-check-p">{t("forms.click.no")}</p>
              </div>
            </div>
          </div>
        )}
        {(formData.englishtest === 'No' && (formData.englishEducation === 'No' || formData.englishEducation === 'Yes')) && (
          <div>
            
            <p>{t("forms.formsquestion.language.level")}</p>
            <div className='row p-2'>
              <p htmlFor="speakingLevel" className="col-sm-4 col-form-label">{t("forms.formsquestion.language.speaking")}<RequiredIndicator /></p>
              <div className="col-sm-8">
                <Select
                  id="speakingLevel"
                  name="speakingLevel"
                  className="react-select-container rounded-pill flous-input-color"
                  classNamePrefix="react-select"
                  value={Object.entries(levelChoices).map(([key, val]) => ({ value: key, label: val })).find(option => option.value === formData.speakingLevel)}
                  onChange={(selectedOption) => handleChange({ target: { name: 'speakingLevel', value: selectedOption ? selectedOption.value : '' } })}
                  options={Object.entries(levelChoices).map(([key, val]) => ({ value: key, label: val }))}
                  placeholder={`${t('forms.formsquestion.placeholder.option')}`}
                  isSearchable
                  isClearable
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      width: '100%',
                      borderRadius: '20px', // Ajustez la valeur pour l'arrondissement
                      borderColor: '#ccc',  // Vous pouvez également ajuster la couleur de la bordure si nécessaire
                    }),
                  }}
                  required
                />
              </div>
            </div>
            <div className='row p-2'>
              <p htmlFor="listeningLevel" className="col-sm-4 col-form-label">{t("forms.formsquestion.language.listening")}<RequiredIndicator /></p>
              <div className="col-sm-8">
                <Select
                  id="listeningLevel"
                  name="listeningLevel"
                  className="react-select-container rounded-pill flous-input-color"
                  classNamePrefix="react-select"
                  value={Object.entries(levelChoices).map(([key, val]) => ({ value: key, label: val })).find(option => option.value === formData.listeningLevel)}
                  onChange={(selectedOption) => handleChange({ target: { name: 'listeningLevel', value: selectedOption ? selectedOption.value : '' } })}
                  options={Object.entries(levelChoices).map(([key, val]) => ({ value: key, label: val }))}
                  placeholder={`${t('forms.formsquestion.placeholder.option')}`}
                  isSearchable
                  isClearable
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      width: '100%',
                      borderRadius: '20px', // Ajustez la valeur pour l'arrondissement
                      borderColor: '#ccc',  // Vous pouvez également ajuster la couleur de la bordure si nécessaire
                    }),
                  }}
                  required
                />
              </div>
            </div>
            <div className='row p-2'>
              <p htmlFor="writingLevel" className="col-sm-4 col-form-label">{t("forms.formsquestion.language.writing")}<RequiredIndicator /></p>
              <div className="col-sm-8">
                <Select
                  id="writingLevel"
                  name="writingLevel"
                  className="react-select-container rounded-pill flous-input-color"
                  classNamePrefix="react-select"
                  value={Object.entries(levelChoices).map(([key, val]) => ({ value: key, label: val })).find(option => option.value === formData.writingLevel)}
                  onChange={(selectedOption) => handleChange({ target: { name: 'writingLevel', value: selectedOption ? selectedOption.value : '' } })}
                  options={Object.entries(levelChoices).map(([key, val]) => ({ value: key, label: val }))}
                  placeholder={`${t('forms.formsquestion.placeholder.option')}`}
                  isSearchable
                  isClearable
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      width: '100%',
                      borderRadius: '20px', // Ajustez la valeur pour l'arrondissement
                      borderColor: '#ccc',  // Vous pouvez également ajuster la couleur de la bordure si nécessaire
                    }),
                  }}
                  required
                />
              </div>
            </div>
            <div className='row p-2'>
              <p htmlFor="readingLevel" className="col-sm-4 col-form-label">{t("forms.formsquestion.language.reading")}<RequiredIndicator /></p>
              <div className="col-sm-8">
                <Select
                  id="readingLevel"
                  name="readingLevel"
                  className="react-select-container rounded-pill flous-input-color"
                  classNamePrefix="react-select"
                  value={Object.entries(levelChoices).map(([key, val]) => ({ value: key, label: val })).find(option => option.value === formData.readingLevel)}
                  onChange={(selectedOption) => handleChange({ target: { name: 'readingLevel', value: selectedOption ? selectedOption.value : '' } })}
                  options={Object.entries(levelChoices).map(([key, val]) => ({ value: key, label: val }))}
                  placeholder={`${t('forms.formsquestion.placeholder.option')}`}
                  isSearchable
                  isClearable
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      width: '100%',
                      borderRadius: '20px', // Ajustez la valeur pour l'arrondissement
                      borderColor: '#ccc',  // Vous pouvez également ajuster la couleur de la bordure si nécessaire
                    }),
                  }}
                  required
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EnglishLanguageProficiencySection;