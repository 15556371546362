import React, { useEffect, useState } from 'react';
import { getScenarioDetail } from '../../../services/users/ResultsServices';
import { useOutletContext, useParams } from 'react-router-dom';
import LoadingOverlay from '../../general/LoadingOverlay';
import { admissibleUsers, canadaStatesConverter, evaluationCountryConverterCodeToName } from '../../../services/utils';
import GoToBackButton from '../../general/GoBackLink';
import { toast } from 'react-toastify';
import { translateProgramDetails } from '../../../services/general/TranslationServices';
import SuspendedProgramModal from '../Modals/suspendedProgram';
import { useTranslation } from 'react-i18next';

export default function ProgramResultsDetails() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { t } = useTranslation();
  const { user } = useOutletContext();

  const {scenario_code} = useParams();

  const [programName, setProgramName] = useState('');
  const [creationDate, setCreationDate] = useState('');
  const [province, setProvince] = useState('');
  const [evaluationType, setEvaluationType] = useState('');
  const [legislation, setLegislation] = useState('');
  const [score, setScore] = useState(0.0);
  const [points, setPoints] = useState(0.0);
  const [maxPoints, setMaxPoints] = useState(0.0);
  const [isSuspended, setIsSuspended] = useState(false);
  const [reactivationDate, setReactivationDate] = useState('');

  const [improvementsAreas, setImprovementsAreas] = useState([]);
  const [programComments, setProgramComments] = useState([]);
  const [checkingData, setCheckingData] = useState([]);

  const lang = localStorage.getItem('flouslang') || 'en';
  const [originalResponse, setOriginalResponse] = useState(null);

  const handleTranslate = async ()=>{
    if (lang !== '') {
      const translatedResponse = await translateProgramDetails(originalResponse, lang);
      await setEvaluationDetails(translatedResponse);
    } else {
      await setEvaluationDetails(originalResponse);
    }
  };

  useEffect(()=>{
    if (originalResponse !== null) {
      handleTranslate();
    }
  }, [lang, originalResponse]);

  const setEvaluationDetails = async (response) => {
    setProgramName(response.data.name);
    setCreationDate(response.data.date);
    setEvaluationType(response.data.evaluation_type);
    setLegislation(response.data.legislation);
    setScore(response.data.score);
    setPoints(response.data.points);
    setMaxPoints(response.data.max_points);
    setImprovementsAreas(response.data.comments.comments);
    setProgramComments(response.data.program_comments);
    setProvince(canadaStatesConverter(response.data.province));
    setIsSuspended(response.data.suspended);
    setReactivationDate(response.data.reactivation_date);

    setCheckingData(response.data.checking_data);
  }

  useEffect(() =>{
    let isMounted = true;

    const loadProgramEvaluationDetail = async () => {
      try {
        if (isMounted) {
          const response = await getScenarioDetail(scenario_code);
  
          if (response && response.status === 200) {
            setOriginalResponse(response.data);
            setEvaluationDetails(response.data);
            setLoading(false);
          }
  
        } 
      } catch (error) {
        setLoading(false);
        if (error.response && [400, 500].includes(error.response.status)){
          setError(error.response.data.error);
          toast.error(error.response.data.error);
          isMounted = false;
        };
      }
      
    }

    loadProgramEvaluationDetail();
  }, []);

  const [expandedIndexData, setExpandedIndexData] = useState(null);

  // Function to manage button clicks
  const handleToggleData = (index) => {
    setExpandedIndexData((prevIndex) => (prevIndex === index ? null : index));
  }

  const manageTitle = (chaine) => {
    chaine = chaine.split('_').join(' ');
    return chaine.charAt(0).toUpperCase() + chaine.slice(1);
  }


  const [expandedIndex, setExpandedIndex] = useState(null);
  const [expandedProgramIndex, setExpandedProgramIndex] = useState(null);

  // Function to manage button clicks
  const handleToggle = (index) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index)); // Toggle section status
  };
  const handleProgramToggle = (index) => {
    setExpandedProgramIndex((prevIndex) => (prevIndex === index ? null : index)); // Toggle section status
  };

  const handleClose = () =>{
    setIsSuspended(false);
  }


  if (loading) {
    return <LoadingOverlay />;
  }

  if (error) {
    return <div>Error : {error.message}</div>;
  }

  return (
    <div className="content">
      <div className="container-fluid">
        {/* Content Header */}
        <div className="content-header">
          <div className="mb-2">
            <GoToBackButton />
            <div className="col-sm-6">
              <h1 className="flous-police-one flous-input-color m-0">{t('client.results.title')}</h1>
            </div>
          </div>
        </div>

        {/* Main content */}
        <div className="card p-3" style={{ borderRadius: '15px' }}>
          <div className='flous-bg-thirty p-3 elevation-2 mb-3' style={{ borderRadius: '15px' }}>
            <h4 className='text-bold'>{t('client.results.programDetail')}</h4>
            <hr />
            <SuspendedProgramModal 
              isOpen={isSuspended}
              date={reactivationDate}
              onContinue={handleClose}
            />
            <div className='mx-3'>
              <div className="form-group row">
                <label htmlFor="inputEvaluationNumber" className="col-sm-3 col-form-label">{t('client.results.programName')}</label>
                <div className="col-sm-9">
                  <input type="text" className="form-control" id="inputEvaluationNumber" value={programName} />
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="inputDate" className="col-sm-3 col-form-label">{t('client.results.date')}</label>
                <div className="col-sm-3">
                  <input type="text" className="form-control" id="inputDate" value={creationDate} />
                </div>
                <label htmlFor="inputCountry" className="col-sm-3 col-form-label">{t('general.table.province')}</label>
                <div className="col-sm-3">
                  <input type="text" className="form-control" id="inputCountry" value={province} />
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="inputTotalProg" className="col-sm-3 col-form-label">{t('client.results.evaluationType')}</label>
                <div className="col-sm-3">
                  <input type="text" className="form-control" id="inputTotalProg" value={evaluationType} />
                </div>
                <label htmlFor="inputDate" className="col-sm-3 col-form-label">{t('client.results.legislation')}</label>
                <div className="col-sm-3">
                  <input type="text" className="form-control" id="inputDate" value={legislation} />
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="inputDate" className="col-sm-3 col-form-label">{t('client.results.obtainedPoints')}</label>
                <div className="col-sm-3">
                  <input type="text" className="form-control" id="inputDate" value={maxPoints === 0.0 ? 'Not applicable':points} />
                </div>
                <label htmlFor="inputTotalProg" className="col-sm-3 col-form-label">{t('client.results.percentageOfConditions')}</label>
                <div className="col-sm-3">
                  <input type="text" className="form-control" id="inputTotalProg" value={`${score} %`} />
                </div>
              </div>
            </div>
          </div>
          {/* Card 1: Points de debuggage */}
          {admissibleUsers.includes(user.email) && (
            <div className="card elevation-2 flous-bg-thirty" style={{ borderRadius: '15px' }}>
              <div className="card flous-bg-thirty p-2 m-1" style={{ borderRadius: '5px' }}>
                <h4 className="pl-1 text-bold">{t('client.results.subtitle1')}</h4>
              </div>
              <div className="card-body">
                {Object.entries(checkingData).map(([title, data], index) => (
                  <div key={index} className='card'>
                    <button
                      className="btn text-left btn-block m-2"
                      // role="button"
                      aria-expanded={expandedIndexData === index}
                      aria-controls={`improvementCollapse${index}`}
                      onClick={() => handleToggleData(index)}
                    >
                                        
                      {title} {' '}
                      <i className={`text-right expandable-table-caret fas fa-fw ${expandedIndexData === index ? 'fa-caret-down' : 'fa-caret-left'}`}></i>
                    </button>
                    <div id={`improvementCollapse${index}`} className={`${expandedIndexData === index ? 'block' : 'hidden'}`}>
                      <div className="card-body">
                        <ul className="list-disc pl-5">
                          {Object.entries(data).map(([key, value]) => (
                              <li key={key}  className="text-gray-800">
                                {manageTitle(key)}: {title === 'Conditions data' ? (value ? 'True':'False'):value}
                              </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          {/* Card 2: Program comments */}
          <div className="card elevation-2 flous-bg-thirty" style={{ borderRadius: '15px' }}>
              {Object.keys(programComments).length === 0 ?(
                
              <div className="card flous-bg-thirty p-2 m-1" style={{ borderRadius: '5px' }}>
                <h4 className="pl-1 text-bold">{t('client.results.noComment')}</h4>
              </div>
              ):(
                <>
                
                <div className="card flous-bg-thirty p-2 m-1" style={{ borderRadius: '5px' }}>
                  <h4 className="pl-1 text-bold">{t('client.results.subtitleKeyInformation')}</h4>
                </div>
                <div className="card-body">
                  {Object.entries(programComments).map(([title, comments], index) => (
                    <div key={index} className='card'>
                      <button
                        className="btn text-left btn-block m-2"
                        // role="button"
                        aria-expanded={expandedProgramIndex === index}
                        aria-controls={`improvementCollapse${index}`}
                        onClick={() => handleProgramToggle(index)}
                      >
                                          
                        {title}{' '}
                        <i className={`text-right expandable-table-caret fas fa-fw ${expandedProgramIndex === index ? 'fa-caret-down' : 'fa-caret-left'}`}></i>
                      </button>
                      <div id={`improvementCollapse${index}`} className={`${expandedProgramIndex === index ? 'block' : 'hidden'}`}>
                        <div className="card-body">
                          <ul className="list-disc pl-5">
                            {Array.isArray(comments) && comments.length > 0 ? (
                              comments.map((task, taskIndex) => (
                                <li key={taskIndex}  className="text-gray-800">
                                  {task}
                                </li>
                              ))
                            ) : (
                              <li className="text-gray-500">{t('client.results.noComment')}</li>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                </>
              )}
            
          </div>
          {/* Card 2: Piste d'amélioration */}
          <div className="card elevation-2 flous-bg-thirty" style={{ borderRadius: '15px' }}>
              {Object.keys(improvementsAreas).length === 0 ?(
                <div className="card flous-bg-thirty p-2 m-1" style={{ borderRadius: '5px' }}>
                  <h4 className="pl-1 text-bold">{t('client.results.subtitle3')}</h4>
                </div>
              ):(
                <>
                <div className="card flous-bg-thirty p-2 m-1" style={{ borderRadius: '5px' }}>
                  <h4 className="pl-1 text-bold">{t('client.results.subtitle2')}</h4>
                </div>
                <div className="card-body">
                  {Object.entries(improvementsAreas).map(([title, comments], index) => (
                    <div key={index} className='card'>
                      <button
                        className="btn text-left btn-block m-2"
                        // role="button"
                        aria-expanded={expandedIndex === index}
                        aria-controls={`improvementCollapse${index}`}
                        onClick={() => handleToggle(index)}
                      >
                                          
                        {title}{' '}
                        <i className={`text-right expandable-table-caret fas fa-fw ${expandedIndex === index ? 'fa-caret-down' : 'fa-caret-left'}`}></i>
                      </button>
                      <div id={`improvementCollapse${index}`} className={`${expandedIndex === index ? 'block' : 'hidden'}`}>
                        <div className="card-body">
                          <ul className="list-disc pl-5">
                            {Array.isArray(comments) && comments.length > 0 ? (
                              comments.map((task, taskIndex) => (
                                <li key={taskIndex}  className="text-gray-800">
                                  {task}
                                </li>
                              ))
                            ) : (
                              <li className="text-gray-500">{t('client.results.noComment')}</li>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                </>
              )}
          </div>
        </div>
      </div>
    </div>
  );
}
