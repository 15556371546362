import React from 'react';
import { Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import CountryShow from '../../../general/countryShow';

const styles = StyleSheet.create({
  page: {
    padding: 20,
    backgroundColor: '#ffffff',
  },
  mainTitle: {
    fontSize: 20,
    fontFamily: 'Helvetica-Bold',
    color: '#0066CC',
    textAlign: 'center',
    marginBottom: 20,
  },
  card: {
    backgroundColor: '#f8f9ff',
    borderRadius: 8,
    padding: 16,
    paddingBottom: 5,
    marginBottom: 10,
    width: '100%',
  },
  cardTitle: {
    fontSize: 14,
    fontFamily: 'Helvetica-Bold',
    textAlign: 'center',
    marginBottom: 10,
  },
  row: {
    flexDirection: 'row',
    marginBottom: 8,
    gap: 8,
  },
  labelColumn: {
    width: '30%',
  },
  valueColumn: {
    width: '20%',
  },
  label: {
    fontSize: 10,
    color: '#000000',
    fontFamily: 'Helvetica',
  },
  value: {
    fontSize: 10,
    padding: '6 8',
    backgroundColor: '#ffffff',
    borderRadius: 4,
    minHeight: 28,
    border: '1px solid #e2e8f0',
    fontFamily: 'Helvetica',
  },
  educationCard: {
    backgroundColor: '#ffffff',
    borderRadius: 4,
    padding: 8,
    marginBottom: 8,
    border: '1px solid #e2e8f0',
  },
});

export default function EducationResumeSectionPDF(props) {
  const { t } = useTranslation();

  const renderEducationInCanada = () => (
    <View style={styles.card}>
      <Text style={styles.cardTitle}>{t("forms.title.educationin")} Canada</Text>
      {props.allEducationInCountry.map((educationIn, index) => (
        <View key={index} style={styles.educationCard}>
          <View style={styles.row}>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("general.table.province")}</Text>
            </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>
                {educationIn.institution.provinceState}
              </Text>
            </View>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("general.table.city")}</Text>
            </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>
                {educationIn.institution.city}
              </Text>
            </View>
          </View>
          
          {/* Additional details */}
          <View style={styles.row}>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("forms.formsquestion.educationInCountry.languageOfInstruction")}</Text>
            </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>{educationIn.institution.languageOfInstruction}</Text>
            </View>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("forms.formsquestion.educationInCountry.generalFieldOfStudy")}</Text>
            </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>{educationIn.institution.generalFieldOfStudy}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("forms.formsquestion.educationInCountry.fieldOfStudy")}</Text>
              </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>{educationIn.institution.fieldOfStudy}</Text>
            </View>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("forms.formsquestion.educationInCountry.studyDuration")}</Text>
              </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>{educationIn.institution.studyDuration}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("general.table.name")}</Text>
            </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>{educationIn.institution.institutionName}</Text>
            </View>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("general.table.fulltime")}</Text>
            </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>{educationIn.institution.fullTimeStudent === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("general.table.level")}</Text>
            </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>{educationIn.degreeDiploma.degreeDiploma}</Text>
            </View>
            {educationIn.degreeDiploma.degreeDiploma === "PRG" && (
              <>
              <View style={styles.labelColumn}>
                <Text style={styles.label}>{t("forms.formsquestion.educationInCountry.prgDegreeDiploma")}</Text>
              </View>
              <View style={styles.valueColumn}>
                <Text style={styles.value}>{educationIn.degreeDiploma.prgDegreeDiploma}</Text>
              </View>
              </>
            )}
          </View>
          <View style={styles.row}>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("forms.formsquestion.educationInCountry.startDate")}</Text>
              </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>{educationIn.degreeDiploma.startDate}</Text>
            </View>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("forms.formsquestion.educationInCountry.endDate")}</Text>
              </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>{educationIn.degreeDiploma.endDate}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("forms.formsquestion.educationInCountry.pgwpEligibility")}</Text>
              </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>{educationIn.degreeDiploma.pgwpEligibility === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}</Text>
            </View>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("forms.formsquestion.educationInCountry.equivalentProcessCompleted")}</Text>
              </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>{educationIn.diplomaRecognition.equivalentProcessCompleted === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}</Text>
            </View>
          </View>
          { (educationIn.provinceProcessed && educationIn.provinceProcessed.length > 0) && (
            <View style={styles.row}>
            <View style={{width: '50%'}}>
              <Text style={styles.label}>{t("forms.formsquestion.educationInCountry.provinceProcessed")}</Text>
            </View>
          {educationIn.provinceProcessed && educationIn.provinceProcessed.length > 0 ? (
                educationIn.provinceProcessed.map((credential, index) => (
              <View style={{width: '50%'}}>
                <Text style={styles.value}>{credential.provinceState}</Text>
              </View>
            ))
            ) : (
              <Text style={styles.value}>{t("general.table.empty.noElement")}</Text>
          )}
          </View>
          )}
        </View>
      ))}
    </View>
  );

  const renderSpecialProgram = () => (
    <View style={styles.card}>
      <Text style={styles.cardTitle}>{t("forms.title.specialprogram")}</Text>
      <View style={styles.row}>
        <View style={{width: '70%'}}>
          <Text style={styles.label}>{t("forms.formsquestion.educationInCountry.homeCareProgram")}</Text>
        </View>
        <View style={{width: '30%'}}>
          <Text style={styles.value}>{props.specialProgram.homeCareProgram === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}</Text>
        </View>
      </View>
      {props.specialProgram.homeCareProgram === 'No' && (
        <View style={styles.row}>
        <View style={{width: '70%'}}>
          <Text style={styles.label}>{t("forms.formsquestion.educationInCountry.proposeProgram")}</Text>
        </View>
        <View style={{width: '30%'}}>
          <Text style={styles.value}>{props.specialProgram.proposeProgram === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}</Text>
        </View>
      </View>
      )}
    </View>
  );

  const renderEducationOutsideCanada = () => (
    <View style={styles.card}>
      <Text style={styles.cardTitle}>{t("forms.title.educationoutside")} Canada</Text>
      {props.allEducationOutsideCountry.map((educationOut, index) => (
        <View key={index} style={styles.educationCard}>
          <View style={styles.row}>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("general.table.level")}</Text>
            </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>{educationOut.educationDetail.degreelevel}</Text>
            </View>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("forms.formsquestion.educationOutsideCountry.generalFieldOfStudy")}</Text>
            </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>{educationOut.educationDetail.generalFieldOfStudy}</Text>
            </View>
          </View>
          <View style={styles.row}>
          {educationOut.educationDetail.degreelevel === "PRG" && (
              <>
              <View style={{width: '70%'}}>
                <Text style={styles.label}>{t("forms.formsquestion.educationInCountry.prgDegreeDiploma")}</Text>
              </View>
              <View style={{width: '30%'}}>
                <Text style={styles.value}>{educationOut.educationDetail.prgDegreeDiploma}</Text>
              </View>
              </>
            )}
          </View>
          <View style={styles.row}>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("forms.formsquestion.educationOutsideCountry.fieldOfStudy")}</Text>
            </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>{educationOut.educationDetail.fieldOfStudy}</Text>
            </View>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("forms.formsquestion.educationOutsideCountry.programDuration")}</Text>
            </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>{educationOut.educationDetail.programDuration}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("general.table.country")}</Text>
            </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}><CountryShow value={educationOut.educationInstitution.country}/></Text>
            </View>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("general.table.name")}</Text>
            </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>{educationOut.educationInstitution.institutionName}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("general.table.province")}</Text>
            </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>
                {educationOut.educationInstitution.provinceState}
              </Text>
            </View>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("general.table.city")}</Text>
            </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>{educationOut.educationInstitution.city}</Text>
            </View>
          </View>
          
          {/* Additional details */}
          <View style={styles.row}>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("general.table.fulltime")}</Text>
            </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>{educationOut.educationInstitution.fullTimeStudent === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}</Text>
            </View>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("forms.formsquestion.educationInCountry.languageOfInstruction")}</Text>
            </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>{educationOut.educationInstitution.languageOfInstruction}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("forms.formsquestion.educationOutsideCountry.startDate")}</Text>
            </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>{educationOut.dateofStudy.startDate}</Text>
            </View>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("forms.formsquestion.educationOutsideCountry.endDate")}</Text>
            </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>{educationOut.dateofStudy.endDate}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("forms.formsquestion.educationOutsideCountry.ecaObtained")}</Text>
            </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>{educationOut.educationalCredentialAssessment.ecaObtained === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}</Text>
            </View>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("forms.formsquestion.educationOutsideCountry.issuingOrganization")}</Text>
            </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>{educationOut.educationalCredentialAssessment.issuingOrganization}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("forms.formsquestion.educationOutsideCountry.recognizedCredential")}</Text>
            </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>{educationOut.educationalCredentialAssessment.recognizedCredential}</Text>
            </View>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("forms.formsquestion.educationOutsideCountry.recognizedDiplomaLevel")}</Text>
            </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>{educationOut.educationalCredentialAssessment.recognizedDiplomaLevel}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={{width: '70%'}}>
              <Text style={styles.label}>{t("forms.formsquestion.educationOutsideCountry.epqObtained")}</Text>
            </View>
            <View style={{width: '30%'}}>
              <Text style={styles.value}>{educationOut.equivalencyProcessQuebec.epqObtained === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}</Text>
            </View>
          </View>
          {educationOut.equivalencyProcessQuebec.epqObtained === 'Yes' && (
          <View style={styles.row}>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("forms.formsquestion.educationOutsideCountry.recognizedCredential")}</Text>
            </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>{educationOut.equivalencyProcessQuebec.recognizedCredential}</Text>
            </View>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("forms.formsquestion.educationOutsideCountry.recognizedDiplomaLevel")}</Text>
            </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>{educationOut.equivalencyProcessQuebec.recognizedDiplomaLevel}</Text>
            </View>
          </View>
          )}
        </View>
      ))}
    </View>
  );

  return (
    <Page style={styles.page}>
      <Text style={styles.mainTitle}>{t("client.results.blockTitle.bloc5")}</Text>
      {(props.educationInCountry.educationInCountry === 'Yes' || props.educationInCountry.currentlyStudy === 'Yes') && renderEducationInCanada()}
      {(props.educationInCountry.educationInCountry !== 'Yes' && props.educationInCountry.currentlyStudy !== 'Yes') && (
        <View style={styles.card}>
          <Text style={styles.cardTitle}>{t("general.table.empty.educationin")}</Text>
        </View>
      )}
      {renderSpecialProgram()}
      {(props.educationOutsideCountry.educationOutsideCountry === 'Yes' || props.educationOutsideCountry.currentlyStudy === 'Yes') && renderEducationOutsideCanada()}
      {(props.educationOutsideCountry.educationInCountry !== 'Yes' && props.educationOutsideCountry.currentlyStudy !== 'Yes') && (
        <View style={styles.card}>
          <Text style={styles.cardTitle}>{t("general.table.empty.educationout")}</Text>
        </View>
      )}
    </Page>
  );
}

