

export function IELTSLevelToScore(level) {
    const conversion = {
        "A1": 2.5,
        "A2": 3.5,
        "B1": 5.0,
        "B2": 7.0,
        "C1": 8.0,
        "C2": 9.0
    };
    return conversion[level] || 2.5;
}

export function TEFLevelToScore(level) {
    const conversion = {
        "A1": 199,
        "A2": 299,
        "B1": 399,
        "B2": 499,
        "C1": 599,
        "C2": 699
    };
    return conversion[level] || 199;
}

const fetchFrenchTestResults = async (testLanguageProficiency) =>{
    if (testLanguageProficiency.frenchtest === 'No') {
      if (testLanguageProficiency.frenchEducation){
        const currentDate = new Date();
        const formattedDate = currentDate.toISOString().split('T')[0] // .replace(/-/g, '/');

        testLanguageProficiency.testDate = formattedDate
        testLanguageProficiency.testType = 'TEF'
        testLanguageProficiency.speakingScore = TEFLevelToScore(testLanguageProficiency.speakingLevel);
        testLanguageProficiency.listeningScore = TEFLevelToScore(testLanguageProficiency.listeningLevel);
        testLanguageProficiency.writingScore = TEFLevelToScore(testLanguageProficiency.writingLevel);
        testLanguageProficiency.readingScore = TEFLevelToScore(testLanguageProficiency.readingLevel);
      }
    }
    return testLanguageProficiency
  }

  const fetchEnglishTestResults = async (testLanguageProficiency) =>{
    if (testLanguageProficiency.englishtest === 'No') {
      if (testLanguageProficiency.englishEducation){
        testLanguageProficiency.testType = 'IELTS';
        testLanguageProficiency.speakingScore = IELTSLevelToScore(testLanguageProficiency.speakingLevel);
        testLanguageProficiency.listeningScore = IELTSLevelToScore(testLanguageProficiency.listeningLevel);
        testLanguageProficiency.writingScore = IELTSLevelToScore(testLanguageProficiency.writingLevel);
        testLanguageProficiency.readingScore = IELTSLevelToScore(testLanguageProficiency.readingLevel);
      }
    }
    return testLanguageProficiency
  }

  export const fetchLanguageProficiency = async (languageProficiency) => {
    languageProficiency.englishLanguageProficiency = await fetchEnglishTestResults(languageProficiency.englishLanguageProficiency);
    languageProficiency.frenchLanguageProficiency = await fetchFrenchTestResults(languageProficiency.frenchLanguageProficiency);
    
    return languageProficiency;
  }
