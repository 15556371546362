import React, {useState, useEffect} from 'react';
import { getCurrentItems } from '../../services/users/FormsServices';
import PaginationSection from '../Layout/PaginationSection';
import FlousSelfItem from './item/FlousSelfItem';
import CountrySelect from '../general/CountrySelect';
import NoItemsMessage from '../general/NoItemsToDisplaysItem';
import { useOutletContext } from 'react-router-dom';
import { fetchSoloData } from '../../services/flous/SoloServices';
import { toast } from 'react-toastify';
import LoadingOverlay from '../general/LoadingOverlay';
import GoToBackButton from '../general/GoBackLink';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

export default function FlousSelfSufficientList() {

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedApplicationCountry, setSelectedApplicationCountry] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const { t } = useTranslation();

  const options = {
    countries: [
      { value: '', label: 'Application country' },
      { value: 'CA', label: 'Canada' }
    ],
    statuses: [
      { value: '', label: 'Status' },
      { value: true, label: 'Active' },
      { value: false, label: 'Deactive' }
    ]
  };

  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { user } = useOutletContext();

  const [page, setPage] = useState(1);
  const pageSize = 5;
  const onPageChange = (page) => {
    setPage(page + 1);
  };

  const handleChangeCountry = (event) => {
    setSelectedCountry(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const queryParams = new URLSearchParams({
      borncountry: selectedCountry || '',
      applicationCountry: selectedApplicationCountry || '',
      status: selectedStatus || ''
    }).toString();

    console.log(queryParams)
  };

  /*const filterItems = async () => {
    let isMounted = true;
    try {
        const response = await fetchCompaniesData();
        if (isMounted) {
          if (!selectedCountry && !selectedApplicationCountry && !selectedStatus) {
            setItems(response.data.data);
            setLoading(false);
            return;
          }
        
          const filteredItems = response.data.data.filter((item) => {
            const matchesCountry = selectedCountry ? item.country === selectedCountry : true;
            const matchesApplicationCountry = selectedApplicationCountry ? item.applicationCountry === selectedApplicationCountry : true;
            const matchesStatus = selectedStatus ? item.status === selectedStatus : true;
            
            return matchesCountry && matchesApplicationCountry && matchesStatus;
          });
        
          setItems(filteredItems);
          setLoading(false);
        }
        
      } catch (error) {
        // console.error('Error when preparing data:', error);
        if (isMounted){
          setError(error);
          setLoading(false);
          throw error;
        }
      }
  };*/

  useEffect( () => {
    let isMounted = true;

    const getSoloDataList = async () => {
      if (user.id) {
        try {
          const response = await fetchSoloData();
          if (isMounted) {
            setCustomers(response.data.data);
            setLoading(false);
          }
        } catch (error) {
          // console.error('Error when preparing data:', error);
          if (isMounted){
            setLoading(false);
            if (error.response && [400, 500].includes(error.response.status)) {
              setError(error);
              toast.error(error.response.data.error);
            }
          }
        }
      }
    };

    getSoloDataList();

    return () => {
      isMounted = false;
    };

  }, []);
  
  const currentItems = getCurrentItems(customers, page, pageSize);

  if (loading) {
    return <LoadingOverlay />;
  }

  if (error) {
    return <div>Error : {error.message}</div>;
  }

  return (
    <div className="content">
      <div className="container-fluid">
        <div className="content-header">
          <div className="container-fluid">
            <div className="mb-2">
            <GoToBackButton />
              <div className="col-sm-6">
                <h1 className="m-0 flous-police-one flous-input-color">{t("flous.self.title")}</h1>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <div className="row">
                <div className="btn btn-lg flous-bg-primary col-sm-6 col-12" data-toggle="modal" data-target="#newCompany">{t("flous.self.create")}</div>
              </div>
              <br />
              <div className="card elevation-2">
                <div className="card-header">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-sm col-12">
                      <CountrySelect onChange={handleChangeCountry} value={selectedCountry} required />
                    </div>
                    <div className="col-sm col-12">
                      <div className="form-group">
                        <Select
                          name="applicationCountry"
                          classNamePrefix="react-select"
                          value={options.countries.find(option => option.value === selectedApplicationCountry)}
                          onChange={(selectedOption) => setSelectedApplicationCountry(selectedOption ? selectedOption.value : '')}
                          options={options.countries}
                          placeholder="Application country"
                          isSearchable={false}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              width: '100%',
                              borderRadius: '20px', // Ajustez la valeur pour l'arrondissement
                              borderColor: '#ccc',  // Vous pouvez également ajuster la couleur de la bordure si nécessaire
                            }),
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-sm col-12">
                      <div className="form-group">
                        <Select
                          name="status"
                          classNamePrefix="react-select"
                          value={options.statuses.find(option => option.value === selectedStatus)}
                          onChange={(selectedOption) => setSelectedStatus(selectedOption ? selectedOption.value : '')}
                          options={options.statuses}
                          placeholder="Status"
                          isSearchable={false}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              width: '100%',
                              borderRadius: '20px', // Ajustez la valeur pour l'arrondissement
                              borderColor: '#ccc',  // Vous pouvez également ajuster la couleur de la bordure si nécessaire
                            }),
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-sm-2 col-6">
                      <button type="submit" className="btn flous-input-color">
                        <i className="nav-icon fas fa-search"></i> Search
                      </button>
                    </div>
                    <div className='col-sm-2 col-6 btn flous-input-color'>
                      <i className="nav-icon fas fa-sort"></i> Sort
                    </div>
                  </div>
                </form>
                </div>
                <div className="card-body">
                  <div className='flous-bg-thirty' style={{ borderRadius: '15px' }}>
                      <div className='card-header p-2 mb-2 shadow bg-white' style={{ borderRadius: '15px 15px 0px 0px' }}>
                          <div className='row text-bold'>
                              <div className='col-sm col-6 mt-2 text-center'>{t("general.table.firstname")}</div>
                              <div className='col-sm col-6 mt-2 text-center'>{t("general.table.lastname")}</div>
                              <div className='col-sm col-6 mt-2 text-center'>{t("general.table.countrynative")}</div>
                              <div className='col-sm col-6 mt-2 text-center'>{t("general.table.evaluation")}</div>
                              <div className='col-sm col-12 mt-2 text-center'>{t("general.table.status")}</div>
                              <div className='col-sm col-12 mt-2 text-center'>{t("general.table.actions")}</div>
                          </div>
                      </div>
                      {currentItems.length > 0 ? (
                        currentItems.map((customer) => (
                          <FlousSelfItem customer={customer}/>
                        ))
                      ) : (
                        <NoItemsMessage />
                      )}
                  </div>
                </div>
                <PaginationSection
                      page={page}
                      pageSize={pageSize}
                      totalItems={customers.length}
                      onPageChange={onPageChange}
                    />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};