
import React from 'react';

const LoadingOverlay = () => {
    return (
        <>
        
        {/*
        <div className="loading-overlay">
            <div className="spinner"></div>
        </div>
        */}
        <div className="fixed inset-0 flex items-center justify-center bg-black z-50">
            <div className="absolute inset-0 flex items-center justify-center">
                <img
                    alt="loading"
                    src={`${process.env.PUBLIC_URL}/video/spinner.gif`}
                    style={{ width: '512px' }}
                />
            </div>
        </div>
        </>
    );
};

export default LoadingOverlay

