import React, { useEffect, useState } from 'react';
import InfoBoxPrimaryLink from '../general/InfoBoxPrimaryLink';
import InfoBoxThirtyLink from '../general/InfoBoxThirtyLink';
import { useOutletContext, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AdvisorDashBordNotifications, AdvisorDashBordStates, AdvisorCustomersEvolutions } from '../../services/general/DashbordServices';
import { translateText, translateNotifications } from '../../services/general/TranslationServices';
import LoadingOverlay from '../general/LoadingOverlay';
import CustomerEvolutionCard from '../general/CustomersEvoluationsChart';
import { useTranslation } from 'react-i18next';

export default function AgentDashboard() {

  const lang = localStorage.getItem('flouslang') || 'en';

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { t } = useTranslation();

  const [thisYear, setThisYear] = useState([, , , , , , , , , , , ]);
  const [lastYear, setLastYear] = useState([, , , , , , , , , , , ]);
  const [labels, setLabels] = useState(['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']);
  
  const [formsData, setFormsData] = useState({
    formsInProgress: 0,
    formsCompleted: 0,
    formsAbandoned: 0,
    totalActiveCustomers: 0
  });

  const [notifications, setNotifications] = useState({
    dates: []
  })

  const {user} = useOutletContext();

  const {code} = useParams();

  const advisorCode = code ? code : user.code;

  useEffect(()=>{
    const handleTranslate = async ()=>{
      const notificationsTranslated = await translateNotifications(notifications, lang);
      setNotifications(notificationsTranslated);
    };
    handleTranslate();
  }, [lang]);

  
  useEffect( () => {
    let isMounted = true;

    const getDashbordStates = async () => {
      if (user.id) {
        try {
          const response_states = await AdvisorDashBordStates(advisorCode);
          const response_notifications = await AdvisorDashBordNotifications(advisorCode);
          const response_evolution = await AdvisorCustomersEvolutions(advisorCode);
          if (isMounted) {
            setFormsData(response_states.data.data);
            const notificationsTranslated = await translateNotifications(response_notifications.data.data, lang);
            setNotifications(notificationsTranslated);
            // setNotifications(response_notifications.data.data);
            setThisYear(response_evolution.data.data.thisYear)
            setLastYear(response_evolution.data.data.lastYear)
            setLabels(response_evolution.data.data.labels)
            setLoading(false);
          }
        } catch (error) {
          if (isMounted){
            setError(error.response.data.error);
            setLoading(false);
            if (error.response && [400, 500].includes(error.response.status)){
              toast.error(error.response.data.error);
              isMounted = false;
            }
          }
        }
      }
    };

    getDashbordStates();

    return () => {
      isMounted = false;
    };

  }, []);

  if (loading) {
    return <LoadingOverlay />;
  }

  if (error) {
    return <div>Error : {error.message}</div>;
  }

  return (
    <>
      {/* Content Header (Page header) */}
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0 flous-police-one flous-input-color">{code ? `${lang==='en'? 'Advisor':(lang === 'fr'? 'Conseiller':'Asesor')} ${code} ${t("advisor.dashboard.title")}` : `${t("advisor.dashboard.title")}`}</h1>
            </div>
          </div>
        </div>
      </div>
      {/* /.content-header */}

      <div className="content">
        <div className="container-fluid">
          {/* Small boxes (Stat box) */}
          <div className='card p-2 px-5 elevation-2' style={{ borderRadius: '15px' }}>
          <div className="row">
            <div className="col-lg-3 col-6">
              <InfoBoxPrimaryLink
                value={formsData.totalActiveCustomers}
                text={t("advisor.dashboard.infobox.one")}
                link={t("enterprise.dashboard.infobox.link")}
                href={code ? `/enterprise/advisor/${code}/customers`: (user.type_package === 'Professional'? `/professional/customers`: '/agent/customers')}
              />
            </div>
            <div className="col-lg-3 col-6">
              <InfoBoxThirtyLink
                value={formsData.formsCompleted}
                text={t("advisor.dashboard.infobox.two")}
                link={t("enterprise.dashboard.infobox.link")}
                href={code ? `/enterprise/advisor/${code}/customers`: (user.type_package === 'Professional'? `/professional/customers`: '/agent/customers')}
              />
            </div>
            <div className="col-lg-3 col-6">
              <InfoBoxPrimaryLink
                value={formsData.formsInProgress}
                text={t("advisor.dashboard.infobox.three")}
                link={t("enterprise.dashboard.infobox.link")}
                href={code ? `/enterprise/advisor/${code}/customers`: (user.type_package === 'Professional'? `/professional/customers`: '/agent/customers')}
              />
            </div>
            <div className="col-lg-3 col-6">
              <InfoBoxThirtyLink
                value={formsData.formsAbandoned}
                text={t("advisor.dashboard.infobox.four")}
                link={t("enterprise.dashboard.infobox.link")}
                href={code ? `/enterprise/advisor/${code}/customers`: (user.type_package === 'Professional'? `/professional/customers`: '/agent/customers')}
              />
            </div>
          </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
            <CustomerEvolutionCard 
              elementId="customers-evoluation" 
              thisYear={thisYear} lastYear={lastYear} 
              totalCustomers={formsData.totalActiveCustomers} 
              labels={labels} 
              />
            </div>
            <div className="col-lg-6">
              <div className="card elevation-2" style={{ borderRadius: '15px' }}>
                <div className="card-header">
                  <h4 className="flous-police-one text-center">{t("advisor.dashboard.graph.notification.title")}</h4>
                </div>
                <div className="card-body">
                  <div className="timeline timeline-inverse">
                    {notifications.dates.map((notif, index) => (
                      <React.Fragment key={index}>
                        <div className="bg-white">
                          <span className="btn flous-bg-thirty shadow" style={{ borderRadius: '15px' }}>{notif.date}</span>
                        </div>
                        {notif.notifications.map((notification, index) => (
                          <div key={index}>
                            <i className="fas fa-clock bg-primary"></i>
                            <div className="timeline-item shadow" style={{ borderRadius: '15px' }}>
                              <p>
                                <span className="btn" style={{ pointerEvents: 'none' }}>
                                  {notification.content}
                                </span>
                              </p>
                            </div>
                          </div>
                        ))}
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};