import React, { useState, useEffect } from 'react';
import api from '../../../../../services/api';

import { useTranslation } from 'react-i18next';

export default function CompanyAddressResumeSection(props) {
  const { t } = useTranslation();
  // Define the job offer information
  const companyAddress = {
      hasJobOffer: 'Yes', //  
      country: props.companyDetail.country, //  
      province: props.companyDetail.provinceState, //  
      city: props.companyDetail.city, //  
      streetNumber: props.companyDetail.streetNumber, //  
      postalCode: props.companyDetail.postalCode //  
  };

  const [provinces, setProvinces] = useState([]);

  useEffect(() => {
      const fetchProvinces = async() => {
          const response = await api.get('/canada-provinces/');
          const provinceOptions = Object.entries(response.data).map(([key, val]) => ({
            value: key,
            label: val,
          }));
          setProvinces(provinceOptions);
      };
  
      fetchProvinces();
  }, []);

  return (
    <div className='flous-bg-thirty p-3 elevation-2 mb-3' style={{ borderRadius: '15px' }}>
      <div className='text-bold text-center mb-3'>{t("forms.title.companyaddress")}</div>
      <div className='mx-3'>
        <div className="form-group row">
          <label htmlFor="inputHasJobOffer" className="col-sm-3 col-form-label">
          {t("forms.formsquestion.jobOffer.jobOffer")} Canada?
          </label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputHasJobOffer" value={companyAddress.hasJobOffer === 'Yes' ? `${t('forms.click.yes')}`: `${t('forms.click.no')}`} placeholder="N.A"/>
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="inputProvince" className="col-sm-3 col-form-label">{t("forms.formsquestion.jobOffer.provinceState")}</label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputProvince" value={provinces.find(province => province.value === companyAddress.province)?.label || ''} placeholder="N.A"/>
          </div>
          <label htmlFor="inputCity" className="col-sm-3 col-form-label">{t("forms.formsquestion.jobOffer.city")}</label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputCity" value={companyAddress.city} placeholder="N.A"/>
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="inputStreetNumber" className="col-sm-3 col-form-label">{t("forms.formsquestion.jobOffer.streetNumber")}</label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputStreetNumber" value={companyAddress.streetNumber} placeholder="N.A"/>
          </div>
          <label htmlFor="inputPostalCode" className="col-sm-3 col-form-label">{t("forms.formsquestion.jobOffer.postalCode")}</label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputPostalCode" value={companyAddress.postalCode} placeholder="N.A"/>
          </div>
        </div>
      </div>
    </div>
  );
}