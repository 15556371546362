import React, { useState } from 'react';
import ProvinceSelect from '../../../../general/ProvinceSelect';
import { countriesWithProvinces, evaluationCountryConverter } from '../../../../../services/utils';
import RequiredIndicator from '../../../../general/requiredIndicator';

import { useTranslation } from "react-i18next";

const ProvinceEquivalencyProcessSection = (props) => {
  const [provinceProcessed, setProvinceProcessed] = useState(props.provinceProcessed);
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    provinceState: '',
  });

  // console.log("FormData :", formData)

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedData = {
      provinceState: value,
    };
    setFormData(updatedData);
    props.sendRecognitionProvince(updatedData);
  };

  const handleAddProvince = () => {

    if (formData.provinceState !== '') {
    
      const updatedData = [...provinceProcessed, formData];
      setProvinceProcessed(updatedData);
      props.sendProvinceProcessed(updatedData);
      setFormData({
        provinceState: '',
      });
      props.sendRecognitionProvince({
        provinceState: '',
      });
    }
    else {
      return;
    }    
  };

  return (
    <div className="form-card">
      <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
      <p className='text-bold text-center'>{t("forms.title.provinceEquivalency")}</p>
      <>
        <div>
          { (provinceProcessed && provinceProcessed.length > 0) && (
          <div>
            <div className='card-header p-1 mb-1 shadow bg-white' style={{ borderRadius: '15px 15px 0px 0px' }}>
              <div className='row'>
                <div className='col-sm col-6 mt-2 text-center'>Province/State</div>
                <div className='col-sm col-6 mt-2 text-center'>Actions</div>
              </div>
            </div>
            <div>
              {provinceProcessed && provinceProcessed.length > 0 ? (
                provinceProcessed.map((credential, index) => (
                  <div className='card-header p-1 mb-1 shadow bg-white' style={{ borderRadius: '15px' }} key={index}>
                    <div className='row'>
                      <div className='col-sm col-6 mt-2 text-center'>{credential.provinceState}</div>
                      <div className='col-sm col-6 mt-2 text-center'>
                        <button
                          className="btn btn-secondary"
                          onClick={() => setProvinceProcessed(provinceProcessed.filter((_, i) => i !== index))}
                        >
                          <i className="nav-icon fas fa-trash"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className='text-center'>{t("general.table.empty.noElement")}</div>
              )}
            </div>
          </div>        
          )}
        </div>
        <div className='row p-2'>
          <p htmlFor="provinceProcessed" className="col-form-label col-sm-6">{t("forms.formsquestion.educationInCountry.provinceProcessed")}<RequiredIndicator /></p>
          {countriesWithProvinces.includes(evaluationCountryConverter(props.evaluationCountry)) ? 
            (<ProvinceSelect 
              className="flous-input-color col-sm-6" 
              value={formData.provinceState}
              onChange={handleChange}
              country={evaluationCountryConverter(props.evaluationCountry)} required
              />
            ):
            (<input 
              type="text" 
              className="form-control rounded-pill flous-input-color col-sm-6" 
              id="provinceProcessed" 
              name="provinceProcessed" 
              value={formData.provinceState} 
              onChange={handleChange} 
              placeholder={`${t('forms.formsquestion.placeholder.province')}`}
            />
            )}
        </div>
        <button
          type='button'
          className='btn btn-block btn-secondary rounded-pill'
          onClick={handleAddProvince}
        >
          {t("forms.addMoreButton.addProvince")}
        </button>
      </>
      </div>
    </div>
  );
};

export default ProvinceEquivalencyProcessSection;
