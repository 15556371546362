import api from '../api';

let isLoading = false;

export const evaluateForm = async (formCode) => {

    if (isLoading) return null;
    isLoading = true;

    try{
        const response = await api.get(`/user/evaluate/${formCode}`);
        return response;

    } catch (error){
        // console.error('Error when getting evaluation data:', error);
        throw error;
    } finally {
        isLoading = false;
    }  
};

export const getEvaluationData = async (ApplicationCode) => {

    if (isLoading) return null;
    isLoading = true;

    try{
        const response = await api.get(`/user/get-evaluation/${ApplicationCode}`);
        return response;

    } catch (error){
        // console.error('Error when getting evaluation data:', error);
        throw error;
    } finally {
        isLoading = false;
    }  
};

export const fetchEvaluationsData = async () => {

    try{
        const response = await api.get('/user/get-all-evaluations');
        return response.data;

    } catch (error){
        console.error('Error when fetching evaluations data:', error);
        throw error;
    }   
};

export const getScenarioDetail = async (ScenarioCode) => {

    try{
        const response = await api.get(`/user/get-scenario-detail/${ScenarioCode}`);
        return response;

    } catch (error){
        // console.error('Error when fetching scenario data:', error);
        throw error;
    }   
};

/////////////////////////////////////////////////////////////////////////
// Delete functions

export const DeleteEvaluation = async (EvaluationCode) => {

    try{
        const response = await api.get(`/user/delete-evaluation/${EvaluationCode}`);
        return response;

    } catch (error){
        // console.error('Error when deleting Evaluation:', error);
        throw error;
    }   
};

export const DeleteScenario = async (ScenarioCode) => {

    try{
        const response = await api.get(`/user/delete-scenario/${ScenarioCode}`);
        return response;

    } catch (error){
        console.error('Error when deleting Scenario:', error);
        throw error;
    }   
};
