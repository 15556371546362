import React from "react";
import { useTranslation } from "react-i18next";

const EvaluationModal = ({isOpen, onClose}) => {
  const { t } = useTranslation();

  if (!isOpen) {
      return null;
  }

  return (
    <div id="evaluationform" className="modal fade show" style={{ display: "block" }}  role="dialog" tabindex="-1" aria-labelledby="evaluationformLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-body text-center">
            <div className="d-flex align-items-center justify-content-center w-100 my-3">
                <img
                  src={`${process.env.PUBLIC_URL}/flous/stripe/evaluation_completed.png`}
                  alt="Flous Logo"
                  className="brand-image text-center"
                  style={{height: '150px', width: '150px' }}
                />
            </div>
            <h4 className="flous-police-one flous-input-color" id="modalTitle">{t("modal.evaluation.title")}</h4>
            <div className="text-bold mb-3">{t("modal.evaluation.message")}</div>
            <div>{t("modal.evaluation.message2")} </div>
            <div className="col">
              <button className="btn flous-bg-primary rounded-pill px-5" data-dismiss="modal" onClick={onClose}>
              {t("modal.evaluation.submit")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EvaluationModal;