import React, { Suspense, useContext, useState, useEffect } from 'react';
import { Navigate, Outlet} from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import api from '../services/api';
import LoadingOverlay from './general/LoadingOverlay';

const ProtectedRoute = ({ role, userData, selfAnalyst, typePackage }) => {

    const { setAccessToken } = useContext(AuthContext);
    const [loading, setLoading] = useState(true);

    const userInfo = useContext(AuthContext).user;

    const [user, setUser] = useState(userData || userInfo);

    const accessToken = useState(localStorage.getItem('accessToken') || '');

    const fetchUserData = async () => {
        if (accessToken) {
            try{
                const response = await api.get('/auth/user/');
                setUser(response.data);
            } catch (error){
                setUser("");
                localStorage.removeItem('refreshToken')
                localStorage.removeItem('accessToken')
            }                
        }
    };

    useEffect(() => {

        const checkAuth = async () => {
            if (accessToken && !user) {
                setAccessToken(accessToken);
                await fetchUserData();
            }
            setLoading(false);
        };

        checkAuth();
    }, [setAccessToken]);


    if (loading) {
        return <LoadingOverlay />;
    }

    if (!user) {
        return <Navigate to="/login" />;
    }else if (role && (user.role !== role || !(user.self_analyst === selfAnalyst) || user.type_package !== typePackage)) {
        return <Navigate to="/" />;
    }
    else if (role && user.role === role && user.self_analyst === selfAnalyst && user.type_package === typePackage) {
        return <Outlet context={{user}} />;
    }


};


export default ProtectedRoute;
