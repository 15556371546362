import React from 'react';
import LanguePrefSection from './section_langues/languagePrefSection';
import FrenchLanguageTestResumeSection from './section_langues/langueFrenchSection';
import EnglishLanguageTestResumeSection from './section_langues/langueEnglishSection';
import { useTranslation } from 'react-i18next';

export default function LangueResumeSection(props) {
  
  const { t } = useTranslation();

  return (
    <div id='language'>
      <h3 className='flous-police-one flous-input-color text-center'>{t("client.results.blockTitle.bloc4")}</h3>
      <LanguePrefSection languageProficiency={props.languageProficiency.firstOfficialLanguage} />
      <FrenchLanguageTestResumeSection frenchLanguageProficiency={props.languageProficiency.frenchLanguageProficiency} />
      <EnglishLanguageTestResumeSection englishLanguageProficiency={props.languageProficiency.englishLanguageProficiency} />
    </div>
  );
}
