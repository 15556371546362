import React from 'react';

import { useTranslation } from 'react-i18next';
import { companyType } from '../../../../../services/utils';

export default function EntreExpeResumeSection(props) {
  const { t } = useTranslation();

  const lang = localStorage.getItem('flouslang') || 'en';
  // Define the entrepreneurial experience information
  const entrepreneurialExperience = {
    isOwnerOrPartner: props.entrepreunarialExperience.ownership, //  
    entrepreneurialGoal: props.entrepreunarialExperience.newcompany //   (options: "Start a new company", "Undertake an existing company", "None")
  };

  return (
    <div className='flous-bg-thirty p-3 elevation-2 mb-3' style={{ borderRadius: '15px' }}>
      <div className='text-bold text-center mb-3'>{t("forms.title.entrepreneurialexperience")}</div>
      <div className='mx-3'>
        <div className="form-group row">
          <label htmlFor="inputIsOwnerOrPartner" className="col-sm-6 col-form-label">
          {t("forms.formsquestion.entrepreunarialExperience.ownership")}
          </label>
          <div className="col-sm-6">
            <input type="text" className="form-control" id="inputIsOwnerOrPartner" value={entrepreneurialExperience.isOwnerOrPartner === 'Yes' ? `${t('forms.click.yes')}`: `${t('forms.click.no')}`} placeholder="N.A"/>
          </div>
        </div>
        {props.entrepreunarialExperience.ownership === 'No' && (
        <div className="form-group row">
          <label htmlFor="inputEntrepreneurialGoal" className="col-sm-6 col-form-label">
          {t("forms.formsquestion.entrepreunarialExperience.newcompany")} Canada
          </label>
          <div className="col-sm-6">
            <input type="text" className="form-control" id="inputEntrepreneurialGoal" value={companyType(entrepreneurialExperience.entrepreneurialGoal, lang)} placeholder="None"/>
          </div>
        </div>
        )}
      </div>
    </div>
  );
}
