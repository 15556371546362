import React, { useState, useEffect } from 'react';
import api from '../../../../services/api';
import { sortDictionaryByValues } from '../../../../services/utils';
import CountryShow from '../../../general/countryShow';

import { useTranslation } from 'react-i18next';


export default function ProfessionalExperienceResumeSection(props) {

  const [provinces, setProvinces] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
      const fetchProvinces = async() => {
          const response = await api.get('/canada-provinces/');
          const provinceOptions = Object.entries(response.data).map(([key, val]) => ({
            value: key,
            label: val,
          }));
          setProvinces(provinceOptions);
      };
      const fetchJob = async () =>{
        const response = await api.get('/professions/');
        const sorted = sortDictionaryByValues(response.data);
        const options = getOptions(sorted);
        setJobs(options);
      };
      fetchJob();
  
      fetchProvinces();
  }, []);

  const [jobs, setJobs] = useState([]);

  const getOptions = (data) => {
    return Object.entries(data).map(([key, val]) => ({
      value: key,
      label: val,
    }));
  };

  const [visibleDetails, setVisibleDetails] = useState({});

  const toggleDetailVisibility = (index) => {
    setVisibleDetails(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  return (
    <div id='language'>
      <h3 className='flous-police-one flous-input-color text-center'>{t("client.results.blockTitle.bloc6")}</h3>
      <div className='flous-bg-thirty p-3 elevation-2 mb-3' style={{ borderRadius: '15px' }}>
        <div className='mx-3'>
          {props.professionalExperience.professionalExperience === 'Yes' && (
            <div className="form-group">
            <div>
              <div className='card-header p-1 mb-1 shadow bg-white' style={{ borderRadius: '15px 15px 0px 0px' }}>
                <div className='row'>
                  <div className='col-sm col-6 mt-2 text-center'>{t("general.table.title")}</div>
                  <div className='col-sm col-6 mt-2 text-center'>{t("general.table.company")}</div>
                  <div className='col-sm col-6 mt-2 text-center'>{t("general.table.startdate")}</div>
                  <div className='col-sm col-6 mt-2 text-center'>{t("general.table.iscurrentjob")}</div>
                  <div className='col-sm col-6 mt-2 text-center'>{t("general.table.country")}</div>
                  <div className='col-sm col-6 mt-2 text-center'>{t("general.table.province")}</div>
                  {props.show !==true && <div className='col-sm col-6 mt-2 text-center'>{t("general.table.actions")}</div>}
                </div>
              </div>
              
              {props.allProfessionalExperience.map((profession, index) => (
                <div key={index} className='card-header p-1 mb-1 shadow bg-white' style={{ borderRadius: '15px' }}>
                  <div className='row'>
                    <div className='col-sm col-6 mt-2 text-center'>{jobs.find(option => option.value === profession.jobDetail.jobTitle)?.label}</div>
                    <div className='col-sm col-6 mt-2 text-center'>{profession.companyInfo.companyName}</div>
                    <div className='col-sm col-6 mt-2 text-center'>{profession.jobDetail.startDate}</div>
                    <div className='col-sm col-6 mt-2 text-center'>{profession.jobDetail.currentJob === 'Yes' ? `${t('forms.click.yes')}`: `${t('forms.click.no')}`}</div>
                    <div className='col-sm col-6 mt-2 text-center'><CountryShow value={profession.jobAddress.country}/></div>
                    {profession.jobAddress.country === 'CAN' ? (
                    <div className='col-sm col-6 mt-2 text-center'>{provinces.find(province => province.value === profession.jobAddress.provinceState)?.label || ''}</div>
                    ): (<div className='col-sm col-6 mt-2 text-center'>{profession.jobAddress.provinceState}</div>)}
                    {props.show !==true && <div className='col-sm col-6 mt-2 text-center'>
                      <button onClick={() => toggleDetailVisibility(index)} className="btn flous-bg-primary">
                        <i className="nav-icon fas fa-eye"></i>
                      </button>
                    </div>}
                  </div>
                  {(visibleDetails[index] || props.show ===true) && (
                    <div className='m-3'>
                      <div className="form-group row">
                        <div htmlFor="" className="col-sm-3">{t("forms.formsquestion.professionalExperience.selfEmployed")}</div>
                        <div className="col-sm-3">
                          <input type="text" className="form-control" id="" value={profession.jobDetail.selfEmployed === 'Yes' ? `${t('forms.click.yes')}`: `${t('forms.click.no')}`} placeholder="N.A"/>
                        </div>
                        <div htmlFor="" className="col-sm-3">{t("forms.formsquestion.professionalExperience.currentJob")}</div>
                        <div className="col-sm-3">
                          <input type="text" className="form-control" id="" value={profession.jobDetail.currentJob === 'Yes' ? `${t('forms.click.yes')}`: `${t('forms.click.no')}`} placeholder="N.A"/>
                        </div>
                      </div>
                      <div className="form-group row">
                        <div htmlFor="" className="col-sm-3">{t("forms.formsquestion.professionalExperience.startDate")}</div>
                        <div className="col-sm-3">
                          <input type="date" className="form-control" id="" value={profession.jobDetail.startDate}/>
                        </div>
                        <div htmlFor="" className="col-sm-3">{t("forms.formsquestion.professionalExperience.endDate")}</div>
                        <div className="col-sm-3">
                          <input type="date" className="form-control" id="" value={profession.jobDetail.endDate}/>
                        </div>
                      </div>
                      <div className="form-group row">
                        <div htmlFor="" className="col-sm-3">{t("forms.formsquestion.professionalExperience.authorization")}</div>
                        <div className="col-sm-3">
                          <input type="text" className="form-control" id="" value={profession.jobDetail.authorization === 'Yes' ? `${t('forms.click.yes')}`: `${t('forms.click.no')}`} placeholder="N.A"/>
                        </div>
                        <div htmlFor="" className="col-sm-3">{t("forms.formsquestion.professionalExperience.obtainingAuthorization")}</div>
                        <div className="col-sm-3">
                          <input type="text" className="form-control" id="" value={profession.jobDetail.obtainingAuthorization === 'Yes' ? `${t('forms.click.yes')}`: `${t('forms.click.no')}`} placeholder="N.A"/>
                        </div>
                      </div>
                      <div className="form-group row">
                        <div htmlFor="" className="col-sm-3">{t("forms.formsquestion.professionalExperience.jobInAIOrIT")}</div>
                        <div className="col-sm-3">
                          <input type="text" className="form-control" id="" value={profession.jobDetail.jobInAIOrIT === 'Yes' ? `${t('forms.click.yes')}`: `${t('forms.click.no')}`} placeholder="N.A"/>
                        </div>
                        <div htmlFor="" className="col-sm-3">{t("forms.formsquestion.professionalExperience.companyName")}</div>
                        <div className="col-sm-3">
                          <input type="text" className="form-control" id="" value={profession.companyInfo.companyName} placeholder="N.A"/>
                        </div>
                      </div>
                      <div className="form-group row">
                        <div htmlFor="" className="col-sm-3">{t("forms.formsquestion.professionalExperience.hoursPerWeek")}</div>
                        <div className="col-sm-3">
                          <input type="number" className="form-control" id="" value={profession.companyInfo.hoursPerWeek} placeholder="N.A"/>
                        </div>
                        <div htmlFor="" className="col-sm-3">{t("forms.formsquestion.professionalExperience.hourlyRate")}</div>
                        <div className="col-sm-3">
                          <input type="number" className="form-control" id="" value={profession.companyInfo.hourlyRate} placeholder="N.A"/>
                        </div>
                      </div>
                      <div className="form-group row">
                        <div htmlFor="" className="col-sm-3">{t("forms.formsquestion.professionalExperience.paydayLoans")}</div>
                        <div className="col-sm-3">
                          <input type="text" className="form-control" id="" value={profession.jobSpecifics.paydayLoans === 'Yes' ? `${t('forms.click.yes')}`: `${t('forms.click.no')}`} placeholder="N.A"/>
                        </div>
                        <div htmlFor="" className="col-sm-3">{t("forms.formsquestion.professionalExperience.explicitContent")}</div>
                        <div className="col-sm-3">
                          <input type="text" className="form-control" id="" value={profession.jobSpecifics.explicitContent === 'Yes' ? `${t('forms.click.yes')}`: `${t('forms.click.no')}`} placeholder="N.A"/>
                        </div>
                      </div>
                      <div className="form-group row">
                        <div htmlFor="" className="col-sm-3">{t("forms.formsquestion.professionalExperience.realEstate")}</div>
                        <div className="col-sm-3">
                          <input type="text" className="form-control" id="" value={profession.jobSpecifics.realEstate === 'Yes' ? `${t('forms.click.yes')}`: `${t('forms.click.no')}`} placeholder="N.A"/>
                        </div>
                        <div htmlFor="" className="col-sm-3">{t("forms.formsquestion.professionalExperience.country")}</div>
                        <div className="col-sm-3">
                          <input type="text" className="form-control" id="" value={profession.jobAddress.country} placeholder="N.A"/>
                        </div>
                      </div>
                      <div className="form-group row">
                        <div htmlFor="" className="col-sm-3">{t("forms.formsquestion.professionalExperience.provinceState")}</div>
                        <div className="col-sm-3">
                          <input type="text" className="form-control" id="" value={profession.jobAddress.provinceState} placeholder="N.A"/>
                        </div>
                        <div htmlFor="" className="col-sm-3">{t("forms.formsquestion.professionalExperience.city")}</div>
                        <div className="col-sm-3">
                          <input type="text" className="form-control" id="" value={profession.jobAddress.city} placeholder="N.A"/>
                        </div>
                      </div>
                      <div className="form-group row">
                        <div htmlFor="" className="col-sm-3">{t("forms.formsquestion.professionalExperience.streetNumber")}</div>
                        <div className="col-sm-3">
                          <input type="text" className="form-control" id="" value={profession.jobAddress.streetNumber} placeholder="N.A"/>
                        </div>
                        <div htmlFor="" className="col-sm-3">{t("forms.formsquestion.professionalExperience.postalCode")}</div>
                        <div className="col-sm-3">
                          <input type="text" className="form-control" id="" value={profession.jobAddress.postalCode} placeholder="N.A"/>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
            </div>
          )}
          {props.professionalExperience.professionalExperience !== 'Yes' && (
            <div className='text-center'>{t("general.table.empty.noexperience")}</div>
          )}
        </div>
      </div>
    </div>
  );
}
