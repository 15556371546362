import React, {useState, useEffect} from 'react';
import { loadStripe } from '@stripe/stripe-js';
import api from "../../services/api";
import { fetchPricingData } from '../../services/payment/paymentServices';
import { toast, ToastContainer } from 'react-toastify';
import { useOutletContext } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const stripePromise = loadStripe('pk_test_51QBNcb2NLn5mqgoDXPVvAPXkN8XeDtIUjm1jhCIGIUCfbay6ElOVplLejUMGknH0c7Q0j0V88qrtJ1OcEpGhAAW900HMTVTa5J');

export default function EnterprisePricingSection() {

    const [numAgents, setNumAgents] = useState(2);
    const [numFormulaires, setNumFormulaires] = useState(50);
    const [pricingForm, setPricing] = useState(0);
    const [error, setError] = useState(null);
    const { user } = useOutletContext();
    const { t } = useTranslation();
    
    const calculatePricing = async () => {
        try {
            const response = await fetchPricingData(numAgents, numFormulaires);
            const formattedCost = parseFloat(response.data.data.monthly_costs).toFixed(2);
            setPricing(formattedCost);
        } catch (error) {
            if (error.response && [400, 500].includes(error.response.status)) {
                setError(error);
            }
        }
    };

    const handleNumAgentsChange = (e) => {
        setNumAgents(Number(e.target.value));
    };

    const handleNumFormulairesChange = (e) => {
        setNumFormulaires(Number(e.target.value));
    };

    useEffect(() => {
        calculatePricing();
    }, [numAgents, numFormulaires])

    //const [price, setPrice] = useState('');
    const handleSubscribe = async (price, type, produit_id) => {
        const stripe = await stripePromise;

        // Appeler l'API pour créer une session de checkout
        const response = await api.post('/create-checkout-session/', { "price": price, "type": type, "product_id": produit_id});
        const { sessionId } = response.data;
        const { error } = await stripe.redirectToCheckout({ sessionId });

        if (error) {
            toast.error('Error redirecting to checkout:', error);
        }
    };

    const handleChangePlan = async (price, type, produit_id) => {
        try {
            const response = await api.post('/change-plan/', { "price": price, "type": type, "product_id": produit_id});
            const { message } = response.data;
            toast.success(message)
            window.location.reload();
        } catch (error) {
            if (error.response && [400, 500].includes(error.response.status)){
                toast.error("Network error, try again");
            }else {
                toast.error("Network error");
            }
        }
        
    };

    const handleSubscribeSolo = ()=> {
        if (user.active_subscription === false) {
            handleSubscribe(59.99, "Basic", "price_1QWzOP2NLn5mqgoDftAhNqvl")
        } else {
            handleChangePlan(59.99, "Basic", "price_1QWzOP2NLn5mqgoDftAhNqvl")
        }
    }
    const handleSubscribePro = ()=> {
        if (user.active_subscription === false) {
            handleSubscribe(99.99, "Professional", "price_1QWzPw2NLn5mqgoDsISUbE5s")
        }   else {
            handleChangePlan(99.99, "Professional", "price_1QWzPw2NLn5mqgoDsISUbE5s")
        }
    }
    const handleSubscribeEnterprise = ()=> {
        if (numAgents >= 2 && numFormulaires >= 50) {
            if (user.active_subscription === false) {
                handleSubscribe(Number(pricingForm), "Enterprise", "")
            } else {
                handleChangePlan(Number(pricingForm), "Enterprise", "")
            }
        }
    }

    return <div id='pricing'>
        <div className='row mt-1'>
            <div className='col'>
                <div className='flous-bg-thirty p-3 elevation-2 mb-3 text-center' style={{ borderRadius: '15px' }}>
                <h3 className='flous-police-one flous-input-color'>{t("enterprise.profile.pricing.basic.title")}</h3>
                <div className='btn btn-light btn-block elevation-1 '>
                    <h4 className='text-bold'>{t("enterprise.profile.pricing.basic.unitcost")}</h4>
                    <div>{t("enterprise.profile.pricing.basic.monthly")}</div>
                </div>
                <div>{t("enterprise.profile.pricing.basic.message")}</div>
                <ul className='text-left'>
                    <li className="m-2 d-inline-flex align-items-center">
                        <img src={`${process.env.PUBLIC_URL}/flous/svg/puce.png`} alt="" className="me-2" />
                        {t("enterprise.profile.pricing.basic.list.one")}
                    </li>
                    <li className="m-2 d-inline-flex align-items-center">
                        <img src={`${process.env.PUBLIC_URL}/flous/svg/puce.png`} alt="" className="me-2" />
                        {t("enterprise.profile.pricing.basic.list.two")}
                    </li>
                    <li className="m-2 d-inline-flex align-items-center">
                        <img src={`${process.env.PUBLIC_URL}/flous/svg/puce.png`} alt="" className="me-2" />
                        {t("enterprise.profile.pricing.basic.list.three")} {user.total_forms}
                    </li>
                </ul>
                <button
                    className={`col-8 btn flous-btn-gradient rounded-pill mx-1 ${user.current_plan === "Basic" && user.active_subscription ? "disabled" : ""}`}
                    onClick={user.current_plan === "Basic" && user.active_subscription ? null : handleSubscribeSolo}
                    >
                    {user.current_plan === "Basic" && user.active_subscription 
                        ? "Current Plan" 
                        : user.current_plan === "Basic" && !user.active_subscription 
                        ? "Get Started" 
                        : "Change your Plan"
                    }
                </button>
                </div>
            </div>
            <div className='col'>
                <div className='flous-bg-thirty p-3 elevation-2 mb-3 text-center' style={{ borderRadius: '15px' }}>
                <h3 className='flous-police-one flous-input-color'>{t("enterprise.profile.pricing.professional.title")}</h3>
                <div className='btn btn-light btn-block elevation-1 '>
                    <h4 className='text-bold'>{t("enterprise.profile.pricing.professional.unitcost")}</h4>
                    <div>{t("enterprise.profile.pricing.professional.monthly")}</div>
                </div>
                <div>{t("enterprise.profile.pricing.professional.message")}</div>
                <ul className="text-left">
                    <li className="m-2 d-inline-flex align-items-center">
                        <img src={`${process.env.PUBLIC_URL}/flous/svg/puce.png`} alt="" className="me-2" />
                        {t("enterprise.profile.pricing.professional.list.one")}
                    </li>
                    <li className="m-2 d-inline-flex align-items-center">
                        <img src={`${process.env.PUBLIC_URL}/flous/svg/puce.png`} alt="" className="me-2" />
                        {t("enterprise.profile.pricing.professional.list.two")}
                    </li>
                    <li className="m-2 d-inline-flex align-items-center">
                        <img src={`${process.env.PUBLIC_URL}/flous/svg/puce.png`} alt="" className="me-2" />
                        {t("enterprise.profile.pricing.professional.list.three")}
                    </li>
                </ul>
                <button 
                    className={`col-8 btn flous-btn-gradient rounded-pill mx-1 ${user.current_plan === "Professional" && user.active_subscription ? "disabled" : ""}`} 
                    onClick={
                        user.current_plan === "Professional" && user.active_subscription
                            ? null 
                            : () => handleSubscribePro()
                    }
                >
                    {user.current_plan === "Professional" && user.active_subscription
                        ? "Current Plan" 
                        : user.current_plan === "Professional" && !user.active_subscription 
                        ? "Get Started" 
                        : "Change your Plan"
                    }
                </button>
                </div>
            </div>
            <div className='col'>
                <div className='flous-bg-thirty p-3 elevation-2 mb-3 text-center' style={{ borderRadius: '15px' }}>
                <h3 className='flous-police-one flous-input-color'>{t("enterprise.profile.pricing.enterprise.title")}</h3>
                <div className='btn btn-light btn-block elevation-1 '>
                    <h4 className='text-bold'>{t("enterprise.profile.pricing.enterprise.unitcost")}</h4>
                    <div>$ {pricingForm} {t("enterprise.profile.pricing.enterprise.monthly")}</div>
                    <div className="flex justify-center gap-4 mb-1">
                        <div className="flex flex-col items-center">
                            <label htmlFor="numAgents" className="text-[12px] mb-1">{t("enterprise.profile.pricing.enterprise.advisors")}</label>
                            <input
                            id="numAgents"
                            type="number"
                            placeholder="Entrez le nombre"
                            min="2"
                            value={numAgents}
                            onChange={handleNumAgentsChange}
                            className="w-[120px] h-[30px] text-[12px] border border-[#B1C2D9] rounded-[8px] px-2"
                            />
                        </div>
                        <div className="flex flex-col items-center">
                            <label htmlFor="numFormulaires" className="text-[12px] mb-1">{t("enterprise.profile.pricing.enterprise.forms")}</label>
                            <input
                            id="numFormulaires"
                            type="number"
                            placeholder="Entrez le nombre"
                            min="50"
                            step="25"
                            value={numFormulaires}
                            onChange={handleNumFormulairesChange}
                            className="w-[120px] h-[30px] text-[12px] border border-[#B1C2D9] rounded-[8px] px-2"
                            />
                        </div>
                    </div>
                </div>
                <div>{t("enterprise.profile.pricing.enterprise.title")}</div>
                <ul className="text-left">
                    <li className="m-2 d-inline-flex align-items-center">
                        <img src={`${process.env.PUBLIC_URL}/flous/svg/puce.png`} alt="" className="me-2" />
                        {t("enterprise.profile.pricing.enterprise.list.one")}
                    </li>
                    <li className="m-2 d-inline-flex align-items-center">
                        <img src={`${process.env.PUBLIC_URL}/flous/svg/puce.png`} alt="" className="me-2" />
                        {t("enterprise.profile.pricing.enterprise.list.two")}
                    </li>
                    <li className="m-2 d-inline-flex align-items-center">
                        <img src={`${process.env.PUBLIC_URL}/flous/svg/puce.png`} alt="" className="me-2" />
                        {t("enterprise.profile.pricing.enterprise.list.three")}
                    </li>
                </ul>
                <div 
                    className={`col-8 btn flous-btn-gradient rounded-pill mx-1 ${user.current_plan === "Enterprise" && user.active_subscription ? "disabled" : ""}`} 
                    onClick={
                        user.current_plan === "Enterprise" && user.active_subscription 
                            ? null 
                            : () => handleSubscribeEnterprise()
                    }
                >
                    {user.current_plan === "Enterprise" && user.active_subscription 
                        ? "Current Plan" 
                        : user.current_plan === "Enterprise" && !user.active_subscription 
                        ? "Get Started" 
                        : "Upgrade Your Plan"
                    }
                </div>
                </div>
            </div>
        </div>
        <ToastContainer />
    </div>;
}