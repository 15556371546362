import React from 'react';
import { useTranslation } from 'react-i18next';

function PaginationSection({ page, pageSize, totalItems, onPageChange }) {

  const totalPages = Math.ceil(totalItems / pageSize);
  
  const { t } = useTranslation();

  if (totalItems === 0) {
    return <></>
  }

  return (
    <div className='d-flex justify-content-center'>
      <div className='m-2'>
        <button
          className='btn flous-bg-primary'
          onClick={() => onPageChange(page - 2)}
          disabled={page === 1}
        >
          {t("forms.click.previous")}
        </button>
      </div>
      
      {Array.from({ length: totalPages }).map((_, index) => (
        <div key={index} className='m-2' onClick={() => onPageChange(index)}>
          <div className={`btn ${page === (index + 1) ? 'flous-bg-secondary' : 'flous-bg-thirty'}`}>
            {String(index + 1)}
          </div>
        </div>
      ))}
      
      <div className='m-2'>
        <button
          className='btn flous-bg-primary'
          onClick={() => onPageChange(page)}
          disabled={page === totalPages}
        >
          {t("forms.click.next")}
        </button>
      </div>
    </div>
  );
}

export default PaginationSection;
