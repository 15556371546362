import React, { useState } from 'react';
import RequiredIndicator from '../../../../general/requiredIndicator';

import { useTranslation } from "react-i18next";

const JobCompensationSection = (props) => {
  const [formData, setFormData] = useState(props.jobCompensation);
  const { t } = useTranslation();

  const handleChange = (event) => {
    const { name, value } = event.target;
    const updatedData = {
      ...formData,
      [name]: value,
    };
    setFormData(updatedData);
    props.sendJobCompensation(updatedData);
  };

  return (
    <div className="form-card">
      <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
      <div style={{ color: 'red' }}>{props.error !== '' ? `${t('forms.formsquestion.toast.completeSection')}` : ''}</div>
        <p className='text-bold text-center'>{t("forms.title.jobcompensation")}</p>
        <div className='row p-2'>
          <p className="col-sm-4 col-form-label">{t("forms.formsquestion.jobOffer.hourlyRate")}<RequiredIndicator /></p>
          <div className="col-sm-8">
            <input 
              type="number" 
              className="form-control rounded-pill flous-input-color"
              name="hourlyRate"
              value={formData.hourlyRate}
              onChange={handleChange} min={0}
              placeholder='$ (CAD)'
            />
          </div>
        </div>
        <div className='row p-2'>
          <p className="col-sm-4 col-form-label">{t("forms.formsquestion.jobOffer.annualSalary")}<RequiredIndicator /></p>
          <div className="col-sm-8">
            <input 
              type="number" 
              className="form-control rounded-pill flous-input-color"
              name="annualSalary"
              value={formData.annualSalary}
              onChange={handleChange} min={0}
              placeholder='$ (CAD)'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobCompensationSection;