import React from 'react';
import { useTranslation } from 'react-i18next';

export default function AgentProfileSection({user}) {

    const { t } = useTranslation();

    return <div id='profile'>
        <div className='flous-bg-thirty p-3 elevation-2 mb-3' style={{ borderRadius: '15px' }}>
            <div className='text-center text-bold'>{t("general.profile.sections.profile.subtitle")}</div>
            <hr/>
            <div className='mx-3'>
                <dl className="row">
                    <dt className="col-sm-5">{t("general.profile.sections.profile.content.username")}</dt>
                    <dd className="col-sm-7">{user.first_name} {user.last_name}</dd>
                    <hr/>
                    <dt className="col-sm-5">{t("general.profile.sections.profile.content.email")}</dt>
                    <dd className="col-sm-7">{user.email}</dd>
                    <hr/>
                    <dt className="col-sm-5">{t("general.profile.sections.profile.content.phone")}</dt>
                    <dd className="col-sm-7">{user.cell_phone_number ? user.cell_phone_number: 'Undefined'}</dd>
                    <hr/>
                    <dt className="col-sm-5">{t("general.profile.sections.profile.content.status")}</dt>
                    {(user.role === 'Customer') ? (
                    <dd className="col-sm-7">{user.self_analyst ? 'Self Analyst' : user.role}</dd>
                    ) : (<dd className="col-sm-7">{user.role}</dd>)}
                </dl>
            </div>
        </div>
    </div>;
}