import React from 'react';

import { useTranslation } from 'react-i18next';

export default function ImmigrationDocumentResumeSection(props) {
  const { t } = useTranslation();
  // Define the immigration document information
  const immigrationDocument = {
    issueDate: props.immigrationDocument.dateOfIssue, // Replace with actual date of issue
    expirationDate: props.immigrationDocument.dateOfExpiration, // Replace with actual date of expiration
    entryDate: props.immigrationDocument.dateOfEntry // Replace with actual date of entry in Canada
  };

  return (
    <div className='flous-bg-thirty p-3 elevation-2 mb-3' style={{ borderRadius: '15px' }}>
      <div className='text-bold text-center mb-3'>{t("forms.title.immigrationdoc")}</div>
      <div className='mx-3'>
        <div className="form-group row">
          <label htmlFor="inputIssueDate" className="col-sm-6 col-form-label">{t("forms.formsquestion.residences.dateOfIssue")}</label>
          <div className="col-sm-6">
            <input type="text" className="form-control" id="inputIssueDate" value={immigrationDocument.issueDate} placeholder="N.A"/>
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="inputExpirationDate" className="col-sm-6 col-form-label">{t("forms.formsquestion.residences.dateOfExpiration")}</label>
          <div className="col-sm-6">
            <input type="text" className="form-control" id="inputExpirationDate" value={immigrationDocument.expirationDate} placeholder="N.A"/>
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="inputEntryDate" className="col-sm-6 col-form-label">{t("forms.formsquestion.residences.dateOfEntry")}</label>
          <div className="col-sm-6">
            <input type="text" className="form-control" id="inputEntryDate" value={immigrationDocument.entryDate} placeholder="N.A"/>
          </div>
        </div>
      </div>
    </div>
  );
}
